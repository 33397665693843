import React, { Fragment, useState } from 'react';
import { AccordionActions, Button, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Common, DATA, ExchangeController, Methods, Schemas } from '../../../utils';
import { formatWithPreviousHour } from '../../../utils/pipes';
import { Modal, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from '../../layout';
import { RemovalForm } from '../../forms';
import ExchangeScheduleSaveForm from '../../forms/market/ExchangeScheduleSaveForm';

const ScheduleConfig = ({ exchange, schedule, onChanged }) => {
	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState(undefined);

	const handleEdit = () => {
		setShowModal(true);
		setModalContent(DATA.MODAL.CONTENT.EXCHANGE.SCHEDULE.UPDATE);
	};

	const handleRemove = () => {
		setShowModal(true);
		setModalContent(DATA.MODAL.CONTENT.EXCHANGE.SCHEDULE.REMOVE);
	};

	const handleClose = () => {
		setShowModal(false);
		setModalContent(undefined);
	};

	const renderHours = () => {
		return schedule.map((record) => {
			const hourRange = formatWithPreviousHour(record.hora);
			return (
				<div
					key={`market-schedule-record-${record.id}`}
					className={Common.rowCenterMiddle() + ' inherit-w zero'}
				>
					<div className={Common.colJoin(6)}>
						<p className="txt-center">{hourRange}</p>
					</div>
					<div className={Common.colJoin(6)}>
						<p className="txt-center">{record.value}</p>
					</div>
				</div>
			);
		});
	};

	const getModalContent = () => {
		switch (modalContent) {
			case DATA.MODAL.CONTENT.EXCHANGE.SCHEDULE.UPDATE:
				return (
					<ExchangeScheduleSaveForm
						exchange={exchange}
						data={schedule}
						onUpdate={onChanged}
						onCancel={handleClose}
					/>
				);

			case DATA.MODAL.CONTENT.EXCHANGE.SCHEDULE.REMOVE:
				return (
					<RemovalForm
						id={exchange.id}
						entity={`horario, del intercambio ${exchange.name}`}
						prefix="el"
						validationSchema={Schemas.stringBasic(
							'name',
							'El nombre del intercambio es requerido'
						)}
						formInitialValues={{ name: '' }}
						formFields={[{ key: 'name', display: 'Nombre del intercambio' }]}
						validationMethod={Methods.compare(exchange.name)}
						removeEndpoint={ExchangeController.removeSchedule}
						removeMessage={`El horario del intercambio (${exchange.name}) ha sido eliminado!`}
						onRemove={onChanged}
						onCancel={handleClose}
					/>
				);

			default:
				return undefined;
		}
	};

	const renderModal = () => {
		const content = getModalContent();

		return (
			<Modal show={showModal} onClose={handleClose}>
				{content}
			</Modal>
		);
	};

	return (
		<Fragment>
			<StyledAccordion>
				<StyledAccordionSummary
					expandIcon={<FontAwesomeIcon icon={['fas', 'angle-down']} />}
					aria-controls="market-range-config-content"
					id="market-range-config-header"
				>
					<Typography>Horario</Typography>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					<div className={Common.colJoin(12) + ' zero'}>
						<div
							key={`exchange-${exchange.id}-range-config-headers`}
							className={Common.rowCenterMiddle() + ' inherit-w zero'}
						>
							<div className={Common.colJoin(6)}>
								<h4 className="txt-center">Horas</h4>
							</div>
							<div className={Common.colJoin(6)}>
								<h4 className="txt-center">Valor</h4>
							</div>
						</div>
						{renderHours()}
					</div>
				</StyledAccordionDetails>
				<hr className="gray" />
				<AccordionActions>
					<Button variant="outlined" size="small" onClick={handleRemove}>
						<FontAwesomeIcon icon={['far', 'trash-alt']} />
						&nbsp; Eliminar
					</Button>
					<Button
						disableElevation
						variant="contained"
						size="small"
						color="secondary"
						onClick={handleEdit}
					>
						<div className={Common.rowCenterMiddle() + ' inherit-w zero'}>
							<FontAwesomeIcon icon={['far', 'edit']} />
							&nbsp; Editar
						</div>
					</Button>
				</AccordionActions>
			</StyledAccordion>

			{showModal && renderModal()}
		</Fragment>
	);
};

export default ScheduleConfig;
