import React, { Fragment, useState } from 'react';
import { AccordionActions, Button, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Common, DATA, Methods, Schemas, TermicPlantController } from '../../../utils';
import { AllPurposeConfigForm, RemovalForm } from '../../forms';
import { Meta, Modal, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from '../../layout';
import AvailabilityConfig from './AvailabilityConfig';
import RConfig from './RConfig';
import ABConfig from './ABConfig';

const AllPurposeConfig = ({ plant, config, onChanged }) => {
	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState(undefined);

	const handleNew = () => {
		setShowModal(true);
		setModalContent(DATA.MODAL.CONTENT.PLANT.ALL_PURPOSE.CREATE);
	};

	const handleEdit = () => {
		setShowModal(true);
		setModalContent(DATA.MODAL.CONTENT.PLANT.ALL_PURPOSE.UPDATE);
	};

	const handleRemove = () => {
		setShowModal(true);
		setModalContent(DATA.MODAL.CONTENT.PLANT.ALL_PURPOSE.REMOVE);
	};

	const handleClose = () => {
		setShowModal(false);
		setModalContent(undefined);
	};

	const getModalContent = () => {
		const configNumber = config.num_config;

		switch (modalContent) {
			case DATA.MODAL.CONTENT.PLANT.ALL_PURPOSE.CREATE:
				return (
					<AllPurposeConfigForm
						isCreation
						plant={plant}
						onUpdate={onChanged}
						onCancel={handleClose}
					/>
				);

			case DATA.MODAL.CONTENT.PLANT.ALL_PURPOSE.UPDATE:
				const formattedPlant = {
					num_config: configNumber,
					disp_data: config.disponibilidades,
					config_data: config.configuraciones,
					arranque_caliente: config.arranques_calientes,
					arranque_tibio: config.arranques_tibios,
					arranque_frio: config.arranques_frios,
					ur: config.urs,
					min_ur: config.min_urs,
					max_ur: config.max_urs,
					dr: config.drs,
					min_dr: config.min_drs,
					max_dr: config.max_drs,
					rampa_salida: config.rampas_salida,
					rampa_aumento_modelo_3: config.rampas_aum_mod_3,
					rampa_disminucion_modelo_3: config.rampas_dism_mod_3,
				};

				return (
					<AllPurposeConfigForm
						data={formattedPlant}
						plant={plant}
						onUpdate={onChanged}
						onCancel={handleClose}
					/>
				);

			case DATA.MODAL.CONTENT.PLANT.ALL_PURPOSE.REMOVE:
				return (
					<RemovalForm
						id={plant.id}
						entity={`config. multipropósito, de la planta ${plant.name}`}
						prefix="la"
						validationSchema={Schemas.stringBasic('name', 'El nombre de la planta es requerido')}
						formInitialValues={{ name: '' }}
						formFields={[{ key: 'name', display: 'Nombre de la planta' }]}
						validationMethod={Methods.compare(plant.name)}
						removeEndpoint={async () => {
							return await TermicPlantController.removeConfiguration(plant.id, configNumber);
						}}
						removeMessage={`La config. multipropósito de la planta (${plant.name}) ha sido eliminada!`}
						onRemove={onChanged}
						onCancel={handleClose}
					/>
				);

			default:
				return undefined;
		}
	};

	const renderModal = () => {
		const content = getModalContent();

		return (
			<Modal show={showModal} onClose={handleClose}>
				{content}
			</Modal>
		);
	};

	const configNumber = config.num_config;

	const renderConfigNumber = () => <Fragment>- (#Config = {`${configNumber}`})</Fragment>;

	return (
		<Fragment>
			<StyledAccordion>
				<StyledAccordionSummary
					expandIcon={<FontAwesomeIcon icon={['fas', 'angle-down']} />}
					aria-controls="plant-config-content"
					id="plant-config-header"
				>
					<Typography>Configuraci&oacute;n {configNumber && renderConfigNumber()} </Typography>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					<div className={Common.colJoin(12) + ' zero'}>
						<div className="xs-es"></div>
						<div
							key={`plant-${plant.id}-config-meta-01`}
							className={Common.rowMiddle() + ' inherit-w zero'}
						>
							<div className={Common.colJoin(4) + ' zero'}>
								<Meta title="Mínimo técnico" content={config.configuraciones.min_tec} />
							</div>
							<div className={Common.colJoin(4) + ' zero'}>
								<Meta title="PAP" content={config.configuraciones.pap} />
							</div>
							<div className={Common.colJoin(4) + ' zero'}>
								<Meta title="RSGTMMT" content={config.configuraciones.rsgtmmt} />
							</div>
						</div>
						<div
							key={`plant-${plant.id}-config-meta-02`}
							className={Common.rowMiddle() + ' inherit-w zero'}
						>
							<div className={Common.colJoin(4) + ' zero'}>
								<Meta title="UR Prima" content={config.configuraciones.ur_prima} />
							</div>
							<div className={Common.colJoin(4) + ' zero'}>
								<Meta title="DR Prima" content={config.configuraciones.dr_prima} />
							</div>
						</div>
						<div
							key={`plant-${plant.id}-config-meta-03`}
							className={Common.rowMiddle() + ' inherit-w zero'}
						>
							<div className={Common.colJoin(4) + ' zero'}>
								<Meta title="UR Mod 3" content={config.configuraciones.ur_mod_3} />
							</div>
							<div className={Common.colJoin(4) + ' zero'}>
								<Meta title="DR Mod 3" content={config.configuraciones.dr_mod_3} />
							</div>
						</div>
						<div className="sm-es"></div>
						<AvailabilityConfig
							hideActions
							plant={{ id: plant.id, name: plant.name }}
							config={{ availabilities: config.disponibilidades }}
						/>
						<RConfig plant={plant} config={config} />
						<ABConfig plant={plant} config={config} />
					</div>
				</StyledAccordionDetails>

				<hr className="gray" />
				<AccordionActions>
					<div className={Common.rowBetween() + ' inherit-w zero'}>
						<div className={Common.colJoin(6) + ' zero'}>
							<Button
								disableElevation
								variant="contained"
								size="small"
								color="primary"
								onClick={handleNew}
							>
								<FontAwesomeIcon icon={['fas', 'plus']} />
								&nbsp; Agregar nueva configuraci&oacute;n
							</Button>
						</div>
						<div className={Common.colJoin(6) + ' zero flex-end'}>
							<div className="row zero flex-end">
								<Button variant="outlined" size="small" onClick={handleRemove}>
									<FontAwesomeIcon icon={['far', 'trash-alt']} />
									&nbsp; Eliminar
								</Button>
								<div className="vspace-xs"></div>
								<Button
									disableElevation
									variant="contained"
									size="small"
									color="secondary"
									onClick={handleEdit}
								>
									<div className={Common.rowCenterMiddle() + ' inherit-w zero'}>
										<FontAwesomeIcon icon={['far', 'edit']} />
										&nbsp; Editar
									</div>
								</Button>
							</div>
						</div>
					</div>
				</AccordionActions>
			</StyledAccordion>

			{showModal && renderModal()}
		</Fragment>
	);
};

export default AllPurposeConfig;
