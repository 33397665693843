import React, { Fragment, useEffect, useState } from 'react';

import styles from './token.module.scss';

import {
	CenteredContainer,
	Card,
	Modal,
	NoResults,
	Spinner,
	NoConnection,
	AddButton,
} from '../../../../layout';
import { TokenController, Common } from '../../../../../utils';
import { TokenSaveForm } from '../../../../forms';

/**
 * Componente que se encarga de renderizar el módulo de tokens.
 */
export const Token = () => {
	const [tokenCreation, setTokenCreation] = useState(false);
	const [tokens, setTokens] = useState([]);
	const [showToken, setShowTokens] = useState([]);
	const [loading, setLoading] = useState(true);
	const [noConnection, setNoConnection] = useState(false);

	/**
	 * Método que se encarga de consultar la lista de tokens.
	 */
	const fetch = async () => {
		try {
			setLoading(true);
			setNoConnection(false);
			const res = await TokenController.list();
			setLoading(false);
			if (res.status === 200) {
				// console.log(res.data);
				setTokens(res.data);
				setShowTokens(res.data.map((t) => false));
			}
		} catch (error) {
			setNoConnection(true);
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	return (
		<Fragment>
			<CenteredContainer colSize={10}>
				<div className="row inherit-w zero">
					<h2 className="tab-title">Tokens</h2>
				</div>
				<hr />
				<br />
				<div className="inherit-w">
					{noConnection && !loading ? <NoConnection /> : null}
					{loading ? <Spinner /> : null}

					{!loading && !noConnection && tokens.length === 0 ? (
						<NoResults />
					) : (
						tokens.map((token, index) => {
							const headers = [token.third_party, Common.formatDate(token.created_at)];
							const headerSizes = [5, 5, 1, 1];
							return (
								<Card
									key={token.id}
									headers={headers}
									headerSizes={headerSizes}
									cardType={Common.CardType.TOKEN}
									data={token}
									canEdit
									canRemove
									canExpand
									onUpdate={() => fetch()}
									onRemove={() => fetch()}
								>
									<p
										className={styles.wrap_tex + ' zero'}
										onClick={() => {
											let _tokens = [...tokens];
											_tokens[index] = true;
											setShowTokens(_tokens);
										}}
									>
										{showToken[index] ? token.token : 'Cliquee para ver el token'}
									</p>
								</Card>
							);
						})
					)}
				</div>
				<br />
				<AddButton onClick={() => setTokenCreation(true)} />
			</CenteredContainer>

			{tokenCreation ? (
				<Modal show={tokenCreation} onClose={() => setTokenCreation(false)}>
					<TokenSaveForm
						onUpdate={() => fetch()}
						onCancel={() => setTokenCreation(false)}
						isCreation
					/>
				</Modal>
			) : null}
		</Fragment>
	);
};
