import React, { useEffect } from 'react';
import { Common, ServingPlantController } from '../../utils';
import { useListRequest } from '../hooks';
import { RequestMeta } from '../layout';
import ServingView from './ServingView';

const ServingPlantTab = ({ shareItems, refresh, fetchCallback }) => {
	const { items, loading, error, fetch } = useListRequest(ServingPlantController, fetchCallback);

	useEffect(() => {
		if (refresh) fetch();
	}, [refresh, fetch]);

	useEffect(() => {
		shareItems(items);
	}, [shareItems, items]);

	return (
		<RequestMeta error={error} loading={loading} items={items}>
			<div className={Common.rowBetween() + ' inherit-w'}>
				<div className={Common.colJoin(12) + ' fixed-scroll-md'}>
					<ServingView items={items} onChanged={fetch} />
				</div>
			</div>
		</RequestMeta>
	);
};

export default ServingPlantTab;
