import React from 'react';
import { Common, DateHelper } from '../../utils';
import { Card, Meta } from '../layout';

const stateDictionary = {
	CR: 'Creado',
	EX: 'En ejecución',
	CP: 'Completado',
	ER: 'Error de ejecución',
};

const EnergyDispatchHistoryList = ({ selected, items, onView, isShrinked }) => {
	const longMetaSize = isShrinked ? 12 : 6;
	const defaultMetaSize = isShrinked ? 12 : 4;

	return items.map((item) => {
		const replaced = item.name.split('_').join(' ');
		const bySpaces = replaced.split(' ');
		const [first, second, third] = bySpaces;
		const formattedName = [
			first.slice(0, 4),
			first.slice(4, 6),
			first.slice(6),
			`\n${second}`,
			third,
		].join(' ');

		const createdAt = DateHelper.formatDate(new Date(item.created_at), 'yyyy-MM-dd');
		const start = DateHelper.formatDate(new Date(item.etapa_inicial), 'yyyy-MM-dd');
		const end = DateHelper.formatDate(new Date(item.etapa_final), 'yyyy-MM-dd');
		const headers = [formattedName, item.description || 'N/A', ''];
		const headerSizes = [4, 8];

		return (
			<Card
				key={item.id}
				headers={headers}
				headerSizes={headerSizes}
				canExpand
				canShowChart
				onViewToggled={() => onView(item)}
				isHighlighted={selected === item.id}
			>
				<div className={Common.colJoin(12) + ' zero'}>
					<div key={`opt-exec-${item.id}-metadata-01`} className="row zero">
						<div className={Common.colJoin(longMetaSize) + ' zero'}>
							<Meta title="Nombre" content={formattedName} />
						</div>
						<div className={Common.colJoin(longMetaSize) + ' zero'}>
							<Meta title="Ejecutado por" content={item.user.email} />
						</div>
					</div>
					<div key={`opt-exec-${item.id}-metadata-02`} className="row zero">
						<div className={Common.colJoin(longMetaSize) + ' zero'}>
							<Meta title="Optimizador" content={item.optimizador.name} />
						</div>
						<div className={Common.colJoin(longMetaSize) + ' zero'}>
							<Meta title="Estado" content={stateDictionary[item.state]} />
						</div>
					</div>
					<div key={`opt-exec-${item.id}-metadata-03`} className="row zero">
						<div className={Common.colJoin(defaultMetaSize) + ' zero'}>
							<Meta title="Ejecutado el" content={createdAt} />
						</div>
						<div className={Common.colJoin(defaultMetaSize) + ' zero'}>
							<Meta title="Inicio" content={start} />
						</div>
						<div className={Common.colJoin(defaultMetaSize) + ' zero'}>
							<Meta title="Fin" content={end} />
						</div>
					</div>
					{!isShrinked && (
						<div key={`opt-exec-${item.id}-metadata-04`} className="row zero">
							<Meta title="Descripción" content={item.description || 'N/A'} />
						</div>
					)}
				</div>
			</Card>
		);
	});
};

export default EnergyDispatchHistoryList;
