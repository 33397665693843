import React from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { Button, CircularProgress } from '@material-ui/core';
import {
	Common,
	HidraulicPlantController,
	RenewablePlantController,
	TermicPlantController,
	DATA,
} from '../../../utils';
import { DefaultField } from '../default';
import { ImprovedSelectField } from '../default/select/Improved';
import { useFetchAreas } from '../../hooks';
import { Spinner } from '../../layout';

const [RENEWABLE, HIDRAULIC, TERMIC] = Common.PLANT_TYPES;

const validationSchema = yup.object({
	plant_type: yup.string().required('El tipo de planta es requerido'),
	name: yup
		.string('El nombre de la planta es de tipo texto')
		.required('El nombre de la planta es requerido'),
	code: yup
		.string('El código de la planta es de tipo texto')
		.required('El código de la planta es requerido'),
	area: yup.string('El área de la planta es de tipo texto').required('El área de la planta es requerida'),

	unidades_equiv: yup.string().required('Las unidades equivalentes son requeridas'),

	// renewable validations
	type_planta: yup.string().when(['plant_type'], (plant_type, schema) => {
		return plant_type === RENEWABLE ? yup.string().required('El tipo de energía es requerido') : schema;
	}),

	capacidad: yup.string().when(['plant_type'], (plant_type, schema) => {
		return plant_type === RENEWABLE
			? yup.number().min(0, 'La capacidad mínima es 0').required('La capacidad es requerida')
			: schema;
	}),

	// termic validations
	tml: yup.string().when(['plant_type'], (plant_type, schema) => {
		return plant_type === TERMIC
			? yup
					.string('Tiempo mínimo en línea es de tipo texto')
					.required('Tiempo mínimo en línea es requerido')
			: schema;
	}),
	tmfl: yup.string().when(['plant_type'], (plant_type, schema) => {
		return plant_type === TERMIC
			? yup
					.string('Tiempo mínimo fuera de línea es de tipo texto')
					.required('Tiempo mínimo fuera de línea es requerido')
			: schema;
	}),
	no_arranques_dias: yup.number().when(['plant_type'], (plant_type, schema) => {
		return plant_type === TERMIC
			? yup.string('no_arranques_dias es de tipo texto').required('no_arranques_dias es requerido')
			: schema;
	}),
	te1: yup.number().when(['plant_type'], (plant_type, schema) => {
		return plant_type === TERMIC
			? yup.string('te1 es de tipo texto').required('te1 es requerido')
			: schema;
	}),
	te2: yup.number().when(['plant_type'], (plant_type, schema) => {
		return plant_type === TERMIC
			? yup.string('te2 es de tipo texto').required('te2 es requerido')
			: schema;
	}),
	te3: yup.number().when(['plant_type'], (plant_type, schema) => {
		return plant_type === TERMIC
			? yup.string('te3 es de tipo texto').required('te3 es requerido')
			: schema;
	}),
});

export const PlantSaveForm = ({ tab, isCreation = false, data: record = null, onCancel, onUpdate }) => {
	const areas = useFetchAreas();

	const formInitialValues = {
		plant_type: tab ? tab || '' : '',

		// RENEWABLE VARIABLES
		capacidad: record?.capacidad ?? 0,
		type_planta: record ? record.type_planta || '' : '',

		// TERMIC VARIABLES
		tml: record ? record.tml || '' : '',
		tmfl: record ? record.tmfl || '' : '',
		no_arranques_dias: record ? record.no_arranques_dias || '' : '',
		te1: record ? record.te1 || '' : '',
		te2: record ? record.te2 || '' : '',
		te3: record ? record.te3 || '' : '',

		// RENEWABLE & HIDRAULIC & TERMIC
		name: record ? record.name || '' : '',
		code: record ? record.code || '' : '',
		area: record ? record.area.id || '' : '',
		unidades_equiv: record?.unidades_equiv ?? 0,
	};

	const renderFormContentByPlantType = ({ values }) => {
		const { plant_type } = values;
		const renewableContent = (
			<>
				{/* FIELDS: nombre & capacidad & codigo */}
				<div className={Common.rowBetween() + ' zero'}>
					<div className={Common.colJoin(4) + ' zero-l'}>
						<DefaultField name="name" label="Nombre" size="small" />
					</div>

					<div className={Common.colJoin(4)}>
						<DefaultField name="capacidad" label="Capacidad" size="small" type="number" />
					</div>

					<div className={Common.colJoin(4) + ' zero-r'}>
						<DefaultField name="code" label="Código" size="small" />
					</div>
				</div>
				<div className="sm-es"></div>

				{/* FIELDS: id_area & tipo */}
				<div className={Common.rowBetween() + ' zero'}>
					<div className={Common.colJoin(6) + ' zero-l'}>
						<ImprovedSelectField
							inputProps={{
								opts: areas,
								label: 'Área',
								id: 'area-selection',
								name: 'area',
								useIdAsValue: true,
							}}
						/>
					</div>
					<div className={Common.colJoin(6) + ' zero-r'}>
						<DefaultField
							name="unidades_equiv"
							label="Unidades equivalentes"
							size="small"
							type="number"
						/>
					</div>
				</div>
				<div className="sm-es"></div>
				<ImprovedSelectField
					inputProps={{
						opts: DATA.PLANTS.RENEWABLE.TYPES,
						label: 'Tipo de energía',
						id: 'energy-type-selection',
						name: 'type_planta',
						useIdAsValue: true,
					}}
				/>
				<div className="md-es"></div>
			</>
		);
		const hidraulicContent = (
			<>
				{/* FIELDS: nombre & codigo */}
				<div className={Common.rowBetween() + ' zero'}>
					<div className={Common.colJoin(6) + ' zero-l'}>
						<DefaultField name="name" label="Nombre" size="small" />
					</div>
					<div className={Common.colJoin(6) + ' zero-r'}>
						<DefaultField name="code" label="Código" size="small" />
					</div>
				</div>
				<div className="sm-es"></div>

				{/* FIELDS: id_area & unidades_equiv */}
				<div className={Common.rowBetween() + ' zero'}>
					<div className={Common.colJoin(6) + ' zero-l'}>
						<ImprovedSelectField
							inputProps={{
								opts: areas,
								label: 'Área',
								id: 'area-selection',
								name: 'area',
								useIdAsValue: true,
							}}
						/>
					</div>
					<div className={Common.colJoin(6) + ' zero-r'}>
						<DefaultField
							name="unidades_equiv"
							label="Unidades equivalentes"
							size="small"
							type="number"
						/>
					</div>
				</div>
				<div className="md-es"></div>
			</>
		);
		const termicContent = (
			<>
				{/* FIELDS: nombre & codigo */}
				<div className={Common.rowBetween() + ' zero'}>
					<div className={Common.colJoin(6) + ' zero-l'}>
						<DefaultField name="name" label="Nombre" size="small" />
					</div>
					<div className={Common.colJoin(6) + ' zero-r'}>
						<DefaultField name="code" label="Código" size="small" />
					</div>
				</div>
				<div className="sm-es"></div>

				{/* FIELDS: id_area & unidades_equiv */}
				<div className={Common.rowBetween() + ' zero'}>
					<div className={Common.colJoin(6) + ' zero-l'}>
						<ImprovedSelectField
							inputProps={{
								opts: areas,
								label: 'Área',
								id: 'area-selection',
								name: 'area',
								useIdAsValue: true,
							}}
						/>
					</div>
					<div className={Common.colJoin(6) + ' zero-r'}>
						<DefaultField
							name="unidades_equiv"
							label="Unidades equivalentes"
							size="small"
							type="number"
						/>
					</div>
				</div>
				<div className="sm-es"></div>

				{/* FIELDS: tml & tmfl */}
				<div className={Common.rowBetween() + ' zero'}>
					<div className={Common.colJoin(6) + ' zero-l'}>
						<DefaultField name="tml" label="Tiempo mínimo en línea" size="small" />
					</div>
					<div className={Common.colJoin(6) + ' zero-r'}>
						<DefaultField name="tmfl" label="Tiempo mínimo fuera de línea" size="small" />
					</div>
				</div>
				<div className="sm-es"></div>

				{/* FIELD: no_arr_dias */}
				<DefaultField
					name="no_arranques_dias"
					label="Número de arranques por día"
					size="small"
					type="number"
				/>
				<div className="sm-es"></div>

				{/* FIELDS: te1 & te2 & te3 */}
				<div className={Common.rowBetween() + ' zero'}>
					<div className={Common.colJoin(4) + ' zero-l'}>
						<DefaultField
							name="te1"
							label="Tiempo de espera caliente"
							size="small"
							type="number"
						/>
					</div>
					<div className={Common.colJoin(4) + ' zero'}>
						<DefaultField name="te2" label="Tiempo de espera tibio" size="small" type="number" />
					</div>
					<div className={Common.colJoin(4) + ' zero-r'}>
						<DefaultField name="te3" label="Tiempo de espera frío" size="small" type="number" />
					</div>
				</div>
				<div className="md-es"></div>
			</>
		);

		if (plant_type === RENEWABLE) return renewableContent;
		if (plant_type === HIDRAULIC) return hidraulicContent;
		if (plant_type === TERMIC) return termicContent;
		return null;
	};

	const getBodyByType = (data) => {
		let formatted = {
			plant_type: data.plant_type,
			name: data.name,
			code: data.code,
			area: data.area,
			unidades_equiv: data.unidades_equiv,
		};

		if (data.plant_type === RENEWABLE) {
			formatted = {
				...formatted,
				capacidad: data.capacidad,
				type_planta: data.type_planta,
			};
		}

		if (data.plant_type === TERMIC) {
			formatted = {
				...formatted,
				tml: data.tml,
				tmfl: data.tmfl,
				no_arranques_dias: data.no_arranques_dias,
				te1: data.te1,
				te2: data.te2,
				te3: data.te3,
			};
		}

		return formatted;
	};

	const getControllerByType = ({ plant_type }) => {
		switch (plant_type) {
			case RENEWABLE:
				return RenewablePlantController;
			case HIDRAULIC:
				return HidraulicPlantController;
			case TERMIC:
				return TermicPlantController;
			default:
				return undefined;
		}
	};

	const onFormSubmit = async (data, setSubmitting, resetForm) => {
		try {
			setSubmitting(true);

			const body = getBodyByType(data);
			const controller = getControllerByType(data);

			const res = isCreation ? await controller.create(body) : await controller.update(record.id, body);

			if (res.status < 400) {
				const action = isCreation ? 'agregada' : 'actualizada';
				Common.fireMiniMessage(`Planta ${body.name} (${body.code}) ${action}!`);
				setSubmitting(false);
				resetForm();
				onUpdate();
				onCancel();
			}
		} catch (error) {
			console.log(error);
			setSubmitting(false);
		}
	};

	return areas.length === 0 ? (
		<Spinner />
	) : (
		<Formik
			initialValues={formInitialValues}
			validationSchema={validationSchema}
			onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
		>
			{({ isSubmitting, values }) => (
				<Form className="inherit-w">
					<ImprovedSelectField
						inputProps={{
							opts: Common.PLANT_TYPES,
							label: 'Tipo de planta energética',
							id: 'plant-selection',
							name: 'plant_type',
							useValuesAsArray: true,
						}}
					/>
					<div className="sm-es"></div>

					{renderFormContentByPlantType({ values })}
					<div className={Common.rowBetweenMiddle()}>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								color="default"
								variant="contained"
								size="medium"
								className="ls-custom btn-cancel btn-rmv"
								onClick={onCancel}
								disabled={isSubmitting}
								fullWidth
								disableElevation
							>
								Cancelar
							</Button>
						</div>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								size="medium"
								className="ls-custom fl-right"
								disabled={isSubmitting}
								fullWidth
								disableElevation
							>
								{isCreation ? 'Agregar' : 'Editar'} &nbsp;
								{isSubmitting ? <CircularProgress size={20} /> : null}
							</Button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};
