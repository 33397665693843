export const parse = (data, key) => {
	const columns = Object.keys(data[key][0]);
	const dateColumnIndex = columns.indexOf('date');
	columns.splice(dateColumnIndex, 1);

	const parsedData = {
		dates: data[key].map((entry) => entry.date),
	};

	for (const col of columns) {
		parsedData[col] = data[key].map((entry) => entry[col]);
	}
	return [parsedData, columns];
};
