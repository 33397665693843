import env from '../../../env.json';
import { api } from '../axios';

const controller_v1 = env.REACT_APP_API_VERSION + 'arch';
const controller = env.REACT_APP_API_VERSION_2 + 'arch';

/**
 * @deprecated
 * Realiza una petición con el método GET a la API bajo el controlador /arch, en la
 * ruta /list con el fin de listar las arquitecturas existentes en base de datos.
 */
export const list_v1 = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller_v1}/list`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

export const list = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller}/list`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /arch, en la
 * ruta /create con el fin de crear una arquitectura.
 * @param {Object} body Cuerpo de la petición con la arquitectura a crear.
 */
export const create = (body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/create`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método PUT a la API bajo el controlador /arch, en la
 * ruta /:id/update con el fin de actualizar la información de una arquitectura.
 * @param {number} id id correspondiente a la arquitectura a editar.
 * @param {Object} body Cuerpo de la petición con los cambios de la arquitectura correspondiente.
 */
export const update = (id, body) =>
	new Promise((resolve, reject) => {
		api.put(`${controller_v1}/${id}/update`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador /arch, en
 * la ruta /delete con el fin de eliminar una arquitectura de la base de datos de forma permanente.
 * @param {number} id id correspondiente de la arquitectura a eliminar.
 */
export const remove = (id) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller_v1}/${id}/delete`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
