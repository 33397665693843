import React from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { Button, CircularProgress } from '@material-ui/core';
import { Common, ExchangeController } from '../../../utils';
import { useFetchMarkets } from '../../hooks';
import { DefaultField } from '../default';
import { ImprovedSelectField } from '../default/select/Improved';

const validationSchema = yup.object({
	name: yup.string().required('El nombre del mercado es requerido'),
	mercado_1: yup.number().required('El primer mercado es requerido'),
	mercado_2: yup.number().required('El segundo mercado es requerido'),
	num_intercambio: yup
		.number()
		.required('El número del intercambio es requerido')
		.moreThan(0, 'El número del intercambio debe ser mayor a 0.'),
});

const ExchangeSaveForm = ({ isCreation = false, data: record = null, onCancel, onUpdate }) => {
	const markets = useFetchMarkets();

	const formInitialValues = {
		name: record ? record.name || '' : '',
		mercado_1: record ? record.mercado_1.id || '' : '',
		mercado_2: record ? record.mercado_2.id || '' : '',
		num_intercambio: record ? record.num_intercambio || '' : '',
	};

	const onFormSubmit = async (data, setSubmitting, resetForm) => {
		try {
			setSubmitting(true);
			const { name } = data;

			const res = isCreation
				? await ExchangeController.create(data)
				: await ExchangeController.update(record.id, data);

			if (res.status < 400) {
				const action = isCreation ? 'agregado' : 'actualizado';
				Common.fireMiniMessage(`El intercambio ${name} ha sido ${action}!`);
			}

			setSubmitting(false);
			resetForm();
			onUpdate();
			onCancel();
		} catch (error) {
			setSubmitting(false);
			console.log(error);
		}
	};

	return (
		markets.length > 0 && (
			<Formik
				initialValues={formInitialValues}
				validationSchema={validationSchema}
				onSubmit={(data, { setSubmitting, resetForm }) =>
					onFormSubmit(data, setSubmitting, resetForm)
				}
			>
				{({ isSubmitting }) => (
					<Form className="inherit-w">
						<div className={Common.rowBetween()}>
							<div className={Common.colXs(6)}>
								<DefaultField name="name" label="Nombre" size="small" />
							</div>
							<div className={Common.colXs(6)}>
								<DefaultField
									name="num_intercambio"
									label="Número del intercambio"
									size="small"
								/>
							</div>
						</div>

						<div className="sm-es"></div>
						<div className={Common.rowBetween()}>
							<div className={Common.colXs(6)}>
								<ImprovedSelectField
									inputProps={{
										opts: markets,
										label: 'Mercado #1',
										id: 'market_1-selection',
										name: 'mercado_1',
										useIdAsValue: true,
									}}
								/>
							</div>
							<div className={Common.colXs(6)}>
								<ImprovedSelectField
									inputProps={{
										opts: markets,
										label: 'Mercado #2',
										id: 'market_2-selection',
										name: 'mercado_2',
										useIdAsValue: true,
									}}
								/>
							</div>
						</div>

						<div className="sm-es"></div>
						<div className={Common.rowBetweenMiddle()}>
							<div className={Common.colJoinLg_MdSmXs(4, 5)}>
								<Button
									color="default"
									variant="contained"
									size="medium"
									className="ls-custom btn-cancel btn-rmv"
									onClick={onCancel}
									disabled={isSubmitting}
									fullWidth
									disableElevation
								>
									Cancelar
								</Button>
							</div>
							<div className={Common.colJoinLg_MdSmXs(4, 5)}>
								<Button
									type="submit"
									color="primary"
									variant="contained"
									size="medium"
									className="ls-custom fl-right"
									disabled={isSubmitting}
									fullWidth
									disableElevation
								>
									{isCreation ? 'Agregar' : 'Editar'} &nbsp;
									{isSubmitting && <CircularProgress size={20} />}
								</Button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		)
	);
};

export default ExchangeSaveForm;
