import { Accordion, withStyles } from '@material-ui/core';

const StyledAccordion = withStyles({
	root: {
		'margin-top': '10px',
		border: '1px solid rgba(0, 0, 0, .2)',
		'border-radius': '5px',
		boxShadow: 'none',
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			'margin-top': '10px',
		},
	},
	expanded: {},
})(Accordion);

export default StyledAccordion;
