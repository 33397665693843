import env from '../../../env.json';
import { api } from '../axios';
import { SyncController } from '.';

const controller = env.REACT_APP_API_VERSION + 'mercado';

/**
 * Realiza una petición con el método GET a la API bajo el controlador /mercado, en la
 * ruta /list con el fin de listar los mercados existentes en la base de datos.
 */
export const list = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller}/list`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /mercado, en la
 * ruta /create con el fin de crear un mercado.
 * @param {Object} body Cuerpo de la petición con el mercado a crear.
 */
export const create = (body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/create`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /mercado, en la
 * ruta /:id/add_ranges con el fin de aggregar el listado de rangos a un mercado.
 * @param {number} id Id del mercado en cuestión.
 * @param {Object} body Cuerpo de la petición con el mercado a crear.
 */
export const addRanges = (id, body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/${id}/add_ranges`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método PUT a la API bajo el controlador /mercado,
 * en la ruta /:id/update con el fin de actualizar la información de un mercado.
 * @param {number} id id correspondiente al mercado a editar.
 * @param {Object} body Cuerpo de la petición con los cambios del mercado correspondiente.
 */
export const update = (id, body) =>
	new Promise((resolve, reject) => {
		api.put(`${controller}/${id}/update`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método PUT a la API bajo el controlador /mercado,
 * en la ruta /:id/update_ranges con el fin de actualizar la configuración de rangos
 * de un mercado.
 * @param {number} id id correspondiente del mercado en cuestión.
 * @param {Object} body Cuerpo de la petición con los cambios del mercado correspondiente.
 */
export const updateRanges = (id, body) =>
	new Promise((resolve, reject) => {
		api.put(`${controller}/${id}/update_ranges`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador /mercado, en
 * la ruta /:id/delete con el fin de eliminar un mercado de la base de datos de forma permanente.
 * @param {number} id id correspondiente del mercado a eliminar.
 */
export const remove = (id) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller}/${id}/delete`)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador /mercado, en
 * la ruta /:id/delete_ranges con el fin de eliminar la configuracion de
 * rangos de un mercado de la base de datos de forma permanente.
 * @param {number} id id correspondiente del mercado en cuestión.
 */
export const removeRanges = (id) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller}/${id}/delete_ranges`)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});
