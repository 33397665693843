import React, { Fragment, useEffect, useState } from 'react';

import { AreaController, Common, SubareaController } from '../../../utils';

import { Field, FieldArray, Form, Formik } from 'formik';
import * as yup from 'yup';
import { DefaultField, DefaultSelectField } from '../default';
import { Button, CircularProgress, FormControl, FormHelperText, IconButton, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * API CALLS
 */
const FetchSubareas = () => {
    const [subareas, setSubareas] = useState([]);

    /**
     * metodo para crear o editar un area.
     */
    const fetch = async () => {
        try {
            const res = await SubareaController.list();
            if (res.status === 200) {
                const subareasFromRes = res.data.map((a) => {
                    return { id: a.id, value: a.name };
                });
                setSubareas(subareasFromRes);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    return subareas;
};

export const AreaSaveForm = ({ data: record, onUpdate, onCancel, isCreation = false }) => {
    const subareas = FetchSubareas();

    if (subareas.length === 0) return null;

    const formInitialValues = {
        name: record ? record.name : '',
        subareas:
            record && record.subareas && record.subareas.length > 0
                ? record.subareas.map((sa) => {
                      return { name: sa.id };
                  })
                : [{ name: '' }],
    };

    const validationSchema = yup.object({
        name: yup.string('El nombre del área es de tipo texto').required('El nombre del área es requerido'),
        subareas: yup.array().of(
            yup.object({
                name: yup.string().required('Es requerido seleccionar una subárea.'),
            })
        ),
    });

    const onFormSubmit = async (data, setSubmitting, resetForm) => {
        try {
            setSubmitting(true);

            const body = { name: data.name, subareas: data.subareas.map((sa) => sa.name) };
            const res = isCreation ? await AreaController.create(body) : await AreaController.update(record.id, body);
            const msj = isCreation ? 'Área registrada!' : 'Área actualizada!';

            setSubmitting(false);

            if (res.status < 400) {
                resetForm();
                Common.fireMiniMessage(msj);
                onCancel();
                onUpdate();
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
        >
            {({ touched, values, errors, isSubmitting }) => (
                <Form className="inherit-w">
                    <FieldArray name="subareas">
                        {(arrayHelpers) => (
                            <Fragment>
                                <div className={Common.rowBetweenMiddle()}>
                                    <div className={Common.colJoinTop(10)}>
                                        <DefaultField name="name" label="Nombre del área" size="small" />
                                    </div>
                                    <div className={Common.colJoinTop(2)}>
                                        <Tooltip title="Agregar subárea" placement="right">
                                            <IconButton onClick={() => arrayHelpers.push({ name: '' })}>
                                                <FontAwesomeIcon icon={['fas', 'plus']} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                                {values.subareas.length > 0 ? (
                                    <Fragment>
                                        <h3 style={{ color: '#173DB8', marginBottom: '0' }}>Subáreas</h3>
                                        <hr />
                                        <div className="sm-es"></div>

                                        {values.subareas.map((sub, idx) => (
                                            <div key={`subareas_field_${sub.id || idx}`}>
                                                <div className={Common.rowBetween() + ' zero'}>
                                                    <div className={Common.colJoinTop(10)}>
                                                        <FormControl
                                                            variant="outlined"
                                                            size="small"
                                                            style={{ minWidth: '100%', maxWidth: '100%' }}
                                                            error={
                                                                errors.subareas && touched.subareas
                                                                    ? errors.subareas[idx] && touched.subareas[idx]
                                                                        ? errors.subareas[idx].name &&
                                                                          touched.subareas[idx].name
                                                                            ? true
                                                                            : false
                                                                        : false
                                                                    : false
                                                            }
                                                        >
                                                            <Field
                                                                type="text"
                                                                component={DefaultSelectField}
                                                                name={`subareas.${idx}.name`}
                                                                inputProps={{
                                                                    opts: subareas,
                                                                    useIdAsValue: true,
                                                                    label: `Subárea #${(idx + 1)
                                                                        .toString()
                                                                        .padStart(2, '0')}`,
                                                                    id: `subareas-${idx}-name-selection`,
                                                                    name: `subareas.${idx}.name`,
                                                                }}
                                                            ></Field>
                                                            {errors.subareas && touched.subareas ? (
                                                                errors.subareas[idx] && touched.subareas[idx] ? (
                                                                    errors.subareas[idx].name &&
                                                                    touched.subareas[idx].name ? (
                                                                        <FormHelperText error>
                                                                            <span>{errors.subareas[idx].name}</span>
                                                                        </FormHelperText>
                                                                    ) : null
                                                                ) : null
                                                            ) : null}
                                                        </FormControl>
                                                    </div>
                                                    <div className={Common.colJoinTop(2)}>
                                                        {values.subareas.length > 1 ? (
                                                            <Tooltip title="Remover" placement="right">
                                                                <IconButton onClick={() => arrayHelpers.remove(idx)}>
                                                                    <FontAwesomeIcon icon={['fas', 'minus']} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="sm-es"></div>
                                            </div>
                                        ))}
                                    </Fragment>
                                ) : null}
                            </Fragment>
                        )}
                    </FieldArray>

                    <div className="md-es"></div>
                    <div className={Common.rowBetweenMiddle()}>
                        <div className={Common.colJoinLg_MdSmXs(4, 5)}>
                            <Button
                                color="default"
                                variant="contained"
                                size="medium"
                                className="ls-custom btn-cancel btn-rmv"
                                onClick={onCancel}
                                disabled={isSubmitting}
                                fullWidth
                                disableElevation
                            >
                                Cancelar
                            </Button>
                        </div>
                        <div className={Common.colJoinLg_MdSmXs(4, 5)}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                size="medium"
                                className="ls-custom fl-right"
                                disabled={isSubmitting}
                                fullWidth
                                disableElevation
                            >
                                {isCreation ? 'Agregar' : 'Editar'} &nbsp;
                                {isSubmitting ? <CircularProgress size={20} /> : null}
                            </Button>
                        </div>
                    </div>

                    <div className="sm-es"></div>
                </Form>
            )}
        </Formik>
    );
};
