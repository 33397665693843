import React from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { Button, CircularProgress } from '@material-ui/core';
import { Common, MarketController } from '../../../utils';
import { DefaultField } from '../default';
import { ImprovedSelectField } from '../default/select/Improved';
import { useFetchAreas } from '../../hooks';

const validationSchema = yup.object({
	name: yup.string().required('El nombre del mercado es requerido'),
	num_mercado: yup.number().required('El número del mercado es requerido'),
	area: yup.string('El área de la planta es de tipo texto').required('El área de la planta es requerida'),
});

export const MarketSaveForm = ({ isCreation = false, data: record = null, onCancel, onUpdate }) => {
	const areas = useFetchAreas();

	const formInitialValues = {
		name: record ? record.name || '' : '',
		num_mercado: record ? record.num_mercado || '' : '',
		area: record ? record.area.id || '' : '',
	};

	const onFormSubmit = async (data, setSubmitting, resetForm) => {
		try {
			setSubmitting(true);
			const { name } = data;

			const res = isCreation
				? await MarketController.create(data)
				: await MarketController.update(record.id, data);

			if (res.status < 400) {
				const action = isCreation ? 'agregado' : 'actualizado';
				Common.fireMiniMessage(`El mercado ${name} ha sido ${action}!`);
			}

			setSubmitting(false);
			resetForm();
			onUpdate();
			onCancel();
		} catch (error) {
			setSubmitting(false);
			console.log(error);
		}
	};

	return (
		areas.length > 0 && (
			<Formik
				initialValues={formInitialValues}
				validationSchema={validationSchema}
				onSubmit={(data, { setSubmitting, resetForm }) =>
					onFormSubmit(data, setSubmitting, resetForm)
				}
			>
				{({ isSubmitting }) => (
					<Form className="inherit-w">
						<div className={Common.rowBetween()}>
							<div className={Common.colXs(6)}>
								<DefaultField name="name" label="Nombre del mercado" size="small" />
							</div>
							<div className={Common.colXs(6)}>
								<DefaultField name="num_mercado" label="Número del mercado" size="small" />
							</div>
						</div>

						<div className="sm-es"></div>
						<ImprovedSelectField
							inputProps={{
								opts: areas,
								label: 'Área',
								id: 'area-selection',
								name: 'area',
								useIdAsValue: true,
							}}
						/>
						<div className="sm-es"></div>

						<div className={Common.rowBetweenMiddle()}>
							<div className={Common.colJoinLg_MdSmXs(4, 5)}>
								<Button
									color="default"
									variant="contained"
									size="medium"
									className="ls-custom btn-cancel btn-rmv"
									onClick={onCancel}
									disabled={isSubmitting}
									fullWidth
									disableElevation
								>
									Cancelar
								</Button>
							</div>
							<div className={Common.colJoinLg_MdSmXs(4, 5)}>
								<Button
									type="submit"
									color="primary"
									variant="contained"
									size="medium"
									className="ls-custom fl-right"
									disabled={isSubmitting}
									fullWidth
									disableElevation
								>
									{isCreation ? 'Agregar' : 'Editar'} &nbsp;
									{isSubmitting && <CircularProgress size={20} />}
								</Button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		)
	);
};
