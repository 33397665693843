import React, { useState } from 'react';
import { Common } from '../../../../../utils';
import { ForecastControls } from '../../../../forms';
import { CenteredContainer, NoConnection, Spinner } from '../../../../layout';
import { LineChart } from '../../../../charts';

/**
 * Componente encargado de renderizar el modulo de pronostico.
 */
export const Forecast = () => {
	const [odefId, setOdefId] = useState(() => null);
	const [chartMetadata, setChartMetada] = useState({});
	const [data, setData] = useState([]);
	const [areas, setAreas] = useState([]);
	const [hidden, setHidden] = useState([]);
	const [filterLimits, setFilterLimits] = useState([]);
	const [loading, setLoading] = useState(false);
	const [noConnection, setNoConnection] = useState(false);

	/**
	 * Este metodo actualiza la informacion renderizada por el grafico de pronostico.
	 * @param {number[][]} stats datos del grafico
	 * @param {number[]} limits limites del grafico
	 * @param {string[]} selectedAreas areas seleccionadas
	 */
	const fetch = async ({ stats, limits, selectedAreas, odefID, metadata }) => {
		console.log('---- AT VIEW LEVEL ----');
		console.log({ stats, limits, selectedAreas, odefID, metadata });
		try {
			// setLoading(true);
			setAreas(selectedAreas);
			setFilterLimits(limits);
			setData(stats);
			setOdefId(odefID);
			setLoading(false);
			setChartMetada(metadata);
		} catch (error) {
			console.log(error);
		} finally {
			// setLoading(false);
		}
	};

	return (
		<CenteredContainer colSize={10}>
			<div className="row inherit-w zero">
				<h2 className="tab-title">Pron&oacute;stico</h2>
			</div>
			<hr />
			<br />
			<div className="inherit-w">
				<div className={Common.colJoin(12) + ' zero'}>
					<div className={Common.colJoin(12)}>
						<ForecastControls
							onUpdate={(args) => fetch(args)}
							onLoad={() => setLoading(true)}
							onError={() => setNoConnection(true)}
							onSlideBarChange={setFilterLimits}
							setHidden={setHidden}
						/>
					</div>
					<div className="sm-es"></div>

					{/* <p>{JSON.stringify(filterLimits)}</p>
                    <p>{JSON.stringify(areas)}</p>
                    <p>
                        {JSON.stringify(
                            data.map((d) => {
                                return { id: d.id, name: d.name, color: d.color };
                            })
                        )}
                    </p> */}
					<hr />

					{noConnection && !loading ? <NoConnection /> : null}
					{loading ? <Spinner /> : null}

					{data.length > 0 && !loading ? (
						<LineChart
							areas={areas}
							range={filterLimits}
							data={data}
							hidden={hidden}
							odefId={odefId}
							metadata={chartMetadata}
						/>
					) : null}
				</div>
			</div>
			<br />
		</CenteredContainer>
	);
};
