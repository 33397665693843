import env from '../../../env.json';
import { api } from '../axios';

const controller = env.REACT_APP_API_VERSION + 'optimization_execution';

/**
 * Realiza una petición con el método POST a la API bajo el controlador /optimization_execution,
 * en la ruta /list con el fin de listar las ejecuciones.
 */
export const list = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller}/list`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /optimization_execution,
 * en la ruta /detail con el fin de obtener el resultado de una ejecucion.
 * @param {Object} id Id de la ejecución a consultar.
 */
export const detail = (id) =>
	new Promise((resolve, reject) => {
		api.get(`${controller}/${id}/detail`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /optimization_execution,
 * en la ruta /execute con el fin de ejecutar el optimizador deseado.
 * @param {Object} formData Cuerpo de la petición con la configuracion a ejecutar.
 */
export const execute = (formData) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/execute`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /optimization_execution,
 * en la ruta /check_demand con el fin de validar las fechas del archivo de demanda.
 * @param {Object} formData Cuerpo de la petición con la configuracion a ejecutar.
 */
export const checkDemandDates = (formData) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/check_demand`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
