import chroma, { bezier } from 'chroma-js';

const BASE_COLORS = ['#173db8', '#ff593e', '#f0a71f'];
const ALTERNATE_COLORS = ['#3ae87a', '#ea70c9'];

export class ChromaHelper {
	static getPallete = (colorsRequired) =>
		colorsRequired <= BASE_COLORS.length
			? BASE_COLORS.slice(0, colorsRequired)
			: bezier(BASE_COLORS).scale().correctLightness().colors(colorsRequired, { format: 'hex' });

	static getAlternatePallete = (colorsRequired) =>
		colorsRequired <= ALTERNATE_COLORS.length
			? ALTERNATE_COLORS.slice(0, colorsRequired)
			: bezier(ALTERNATE_COLORS).scale().correctLightness().colors(colorsRequired, { format: 'hex' });

	static brighten(color, delta) {
		return chroma(color).brighten(delta);
	}
}
