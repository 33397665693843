import React, { Fragment } from 'react';
import { InputLabel, MenuItem } from '@material-ui/core';
import { Select } from 'formik-material-ui';

export const DefaultSelectField = React.memo(({ inputProps, ...props }) => {
	const { useValuesAsArray = false, useIdAsValue = false } = inputProps;

	/**
	 * metodo para generar las opciones del select de forma dinamica.
	 */
	const getOpts = () => {
		const defaultValue = (
			<MenuItem key={0} value={''}>
				<em>Seleccione un valor...</em>
			</MenuItem>
		);

		const { opts } = inputProps;
		const values = useValuesAsArray
			? opts.map((opt) => (
					<MenuItem key={opt} value={opt}>
						<em>{opt}</em>
					</MenuItem>
			  ))
			: opts.map((opt) => (
					<MenuItem key={opt.id} value={useIdAsValue ? opt.id : opt.value}>
						<em>{opt.value}</em>
					</MenuItem>
			  ));

		return [defaultValue, ...values];
	};

	const options = getOpts();
	const { disabled = false } = inputProps;

	return (
		<Fragment>
			<InputLabel htmlFor={inputProps.id}>{inputProps.label}</InputLabel>
			<Select
				fullWidth
				disabled={disabled}
				color="primary"
				name={inputProps.name}
				id={inputProps.id}
				labelId={inputProps.label}
				size={inputProps.size}
				MenuProps={{
					getContentAnchorEl: () => null,
				}}
				renderValue={(selected) => {
					if (useIdAsValue) {
						if (props.multiple) {
							const filtered =
								typeof selected[0] === 'string'
									? selected.filter((s) => s.trim().length > 0)
									: selected;

							return filtered
								.map((s) => inputProps.opts.find((opt) => opt.id === s).value)
								.join(', ');
						} else {
							const query = inputProps.opts.find((opt) => opt.id === selected);
							const exists = Boolean(query);
							if (exists) {
								return query.value;
							} else {
								const complexQuery = inputProps.opts.find((opt) => opt.id === selected.id);
								return complexQuery.value;
							}
						}
					} else {
						return props.multiple
							? selected.filter((s) => s.trim().length > 0).join(', ')
							: selected;
					}
				}}
				{...props}
			>
				{options}
			</Select>
		</Fragment>
	);
});
