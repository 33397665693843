const normalizeSubConfig = (configNumber, configArray) => {
	const config = [...configArray];
	let [formatted] = config.filter((c) => c.num_config === configNumber);
	delete formatted['num_config'];
	return formatted;
};

const normalizeAllPurpose = (plant) => {
	const {
		configuraciones: configs,
		arranques_calientes,
		arranques_frios,
		arranques_tibios,
		disponibilidades,
		drs,
		max_drs,
		max_urs,
		min_drs,
		min_urs,
		rampas_aum_mod_3,
		rampas_dism_mod_3,
		rampas_salida,
		urs,
	} = plant;
	const reducedConfigNumber = Array.from(new Set(configs.map((a) => a.num_config))).sort();
	const normalizedConfig = reducedConfigNumber.map((configNumber) => {
		const disponibilidadesNormalized = disponibilidades
			.filter((d) => d.num_config === configNumber)
			.map(({ id, hora, disponibilidad }) => {
				return { id, hora, disponibilidad };
			});

		return {
			num_config: configNumber,
			disponibilidades: disponibilidadesNormalized,
			arranques_calientes: normalizeSubConfig(configNumber, arranques_calientes),
			arranques_frios: normalizeSubConfig(configNumber, arranques_frios),
			arranques_tibios: normalizeSubConfig(configNumber, arranques_tibios),
			configuraciones: normalizeSubConfig(configNumber, configs),
			drs: normalizeSubConfig(configNumber, drs),
			max_drs: normalizeSubConfig(configNumber, max_drs),
			max_urs: normalizeSubConfig(configNumber, max_urs),
			min_drs: normalizeSubConfig(configNumber, min_drs),
			min_urs: normalizeSubConfig(configNumber, min_urs),
			rampas_aum_mod_3: normalizeSubConfig(configNumber, rampas_aum_mod_3),
			rampas_dism_mod_3: normalizeSubConfig(configNumber, rampas_dism_mod_3),
			rampas_salida: normalizeSubConfig(configNumber, rampas_salida),
			urs: normalizeSubConfig(configNumber, urs),
		};
	});
	return normalizedConfig;
};

export default normalizeAllPurpose;
