const CONTENT = {
	MARKET: {
		CREATE: 'CREATE_MARKET',
		RANGE: {
			CREATE: 'CREATE_MARKET_RANGES',
			UPDATE: 'UPDATE_MARKET_RANGES',
			REMOVE: 'REMOVE_MARKET_RANGES',
		},
	},
	EXCHANGE: {
		CREATE: 'CREATE_EXCHANGE',
		SCHEDULE: {
			CREATE: 'CREATE_EXCHANGE_SCHEDULE',
			UPDATE: 'UPDATE_EXCHANGE_SCHEDULE',
			REMOVE: 'REMOVE_EXCHANGE_SCHEDULE',
		},
	},
	PLANT: {
		CREATE: 'CREATE_PLANT',
		EXCHANGE: {
			CREATE: 'CREATE_PLANT_UNITS',
			UPDATE: 'UPDATE_PLANT_UNITS',
			REMOVE: 'REMOVE_PLANT_UNITS',
		},
		AVAILABILITY: {
			CREATE: 'CREATE_PLANT_AVAILABILITY',
			UPDATE: 'UPDATE_PLANT_AVAILABILITY',
			REMOVE: 'REMOVE_PLANT_AVAILABILITY',
		},
		ALL_PURPOSE: {
			CREATE: 'CREATE_PLANT_ALL_PURPOSE',
			UPDATE: 'UPDATE_PLANT_ALL_PURPOSE',
			REMOVE: 'REMOVE_PLANT_ALL_PURPOSE',
		},
	},

	EMPTY: 'NO_CONTENT',
};

export default CONTENT;
