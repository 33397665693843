import React, { Fragment, useEffect, useState } from 'react';

import { CenteredContainer, Modal, AddButton, RequestMeta } from '../../../../layout';
import { SaveArchitectureForm } from '../../../../forms';

import { ArchController, Common } from '../../../../../utils';
import { useListRequest } from '../../../../hooks';
import { ArchitectureList } from '../../../../Architecture';

/**
 * Componente que se encarga de renderizar el modulo de áreas.
 */
const ArchitectureView = () => {
	const { items: architectures, loading, error, fetch } = useListRequest(ArchController);
	const [architectureCreation, setArchitectureCreation] = useState(false);

	useEffect(() => {
		fetch();
	}, [fetch]);

	return (
		<Fragment>
			<CenteredContainer colSize={10}>
				<div className="row inherit-w zero">
					<h2 className="tab-title">Arquitecturas</h2>
				</div>
				<hr />
				<br />
				<div className="inherit-w">
					<RequestMeta error={error} loading={loading} items={architectures}>
						<div className={Common.rowBetween() + ' inherit-w'}>
							<div className={Common.colJoin(12) + ' fixed-scroll-md'}>
								<ArchitectureList items={architectures} onItemUpdate={fetch} />
							</div>
						</div>
					</RequestMeta>
				</div>
				<br />
				{Common.isInAdmin() && <AddButton onClick={() => setArchitectureCreation(true)} />}
			</CenteredContainer>
			{architectureCreation ? (
				<Modal
					width={700}
					show={architectureCreation}
					onClose={() => setArchitectureCreation(false)}
					closeDisabled
				>
					<SaveArchitectureForm
						onUpdate={() => fetch()}
						onCancel={() => setArchitectureCreation(false)}
						isCreation
					/>
				</Modal>
			) : null}
		</Fragment>
	);
};

export default ArchitectureView;
