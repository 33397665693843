import React, { Fragment, useEffect, useState } from 'react';
import { Common, ExchangeController, Methods, Schemas } from '../../utils';
import { toTitleCase } from '../../utils/pipes';
import { Card, Meta, Modal, NoConnection, NoResults, Spinner } from '../layout';
import MissingConfigurationWarning from '../layout/missing-configuration/MissingConfigurationWarning';
import { useListRequest } from '../hooks';
import { ScheduleConfig } from '../configuration';
import ExchangeScheduleSaveForm from '../forms/market/ExchangeScheduleSaveForm';

const ExchangeTab = ({ refresh, fetchCallback }) => {
	const { items, loading, error, fetch } = useListRequest(ExchangeController, fetchCallback);
	const [showModal, setShowModal] = useState(false);
	const [selectedExchange, setSelectedExchange] = useState();

	useEffect(() => {
		if (refresh) fetch();
	}, [refresh, fetch]);

	const showCreateExchangeScheduleModalHandler = (exchange) => {
		setSelectedExchange(exchange);
		setShowModal(true);
	};

	const renderModal = () => {
		return (
			<Modal show={showModal} onClose={() => setShowModal(false)}>
				<ExchangeScheduleSaveForm
					isCreation
					exchange={{ id: selectedExchange.id, name: selectedExchange.name }}
					data={selectedExchange.values}
					onUpdate={fetch}
					onCancel={() => setShowModal(false)}
				/>
			</Modal>
		);
	};

	const renderExchanges = () =>
		items.map((exchange) => {
			const titleCaseName = toTitleCase(exchange.name);
			const firstMarketName = toTitleCase(exchange.mercado_1.name);
			const secondMarketName = toTitleCase(exchange.mercado_2.name);
			const headers = [exchange.num_intercambio, titleCaseName, firstMarketName, secondMarketName];
			const headerSizes = [1, 3, 3, 3];
			let configurations = [];
			let warnings = [];

			const showRangeWarning = exchange.values.length === 0;
			if (showRangeWarning) {
				warnings.push(
					<MissingConfigurationWarning
						key={`exchange-${exchange.id}-schedule-config`}
						configurationName="Horario"
						onClick={() => showCreateExchangeScheduleModalHandler(exchange)}
					/>
				);
			} else {
				configurations.push(
					<ScheduleConfig
						key={`exchange-${exchange.id}-schedule-config`}
						exchange={{ id: exchange.id, name: exchange.name }}
						schedule={exchange.values}
						onChanged={fetch}
					/>
				);
			}

			if (warnings.length > 0) {
				warnings.unshift(<div key={`market-${exchange.id}-spacer`} className="sm-es"></div>);
			}

			return (
				<Card
					key={exchange.id}
					headers={headers}
					headerSizes={headerSizes}
					cardType={Common.CardType.EXCHANGE}
					data={exchange}
					canEdit
					canRemove
					canExpand
					removalProps={{
						entity: 'intercambio',
						prefix: 'este',
						id: exchange.id,
						validationSchema: Schemas.stringBasic(
							'name',
							'El nombre del intercambio es requerido'
						),
						formInitialValues: { name: '' },
						formFields: [{ key: 'name', display: 'Nombre' }],
						validationMethod: Methods.compare(exchange.name),
						removeEndpoint: ExchangeController.remove,
						removeMessage: `El intercambio (${exchange.name}) ha sido eliminado!`,
					}}
					onUpdate={fetch}
					onRemove={fetch}
				>
					<div className={Common.colJoin(12) + ' zero'}>
						<div key={`market-${exchange.id}-metadata-01`} className="row zero">
							<div className={Common.colJoin(5) + ' zero'}>
								<Meta title="Nombre" content={titleCaseName} />
							</div>
							<div className={Common.colJoin(5) + ' zero'}>
								<Meta title="Número del intercambio" content={exchange.num_intercambio} />
							</div>
						</div>
						<div key={`market-${exchange.id}-metadata-02`} className="row zero">
							<div className={Common.colJoin(5) + ' zero'}>
								<Meta title="Primer mercado" content={firstMarketName} />
							</div>
							<div className={Common.colJoin(5) + ' zero'}>
								<Meta title="Segundo mercado" content={secondMarketName} />
							</div>
						</div>
						{configurations}
						{warnings}
					</div>
				</Card>
			);
		});

	return (
		<Fragment>
			{error && !loading && <NoConnection />}
			{loading && <Spinner />}

			{!loading && !error && items.length === 0 ? (
				<NoResults />
			) : (
				<div className={Common.rowBetween() + ' inherit-w'}>
					<div className={Common.colJoin(12) + ' fixed-scroll-md'}>{renderExchanges()}</div>
				</div>
			)}

			{showModal && renderModal()}
		</Fragment>
	);
};

export default ExchangeTab;
