import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { LocalStoreService, ROUTES } from '../../../utils';

const localStorage = new LocalStoreService();

/**
 * Este metodo valida si una ruta es valida dado la sesion actual. Siempre verificara si si hay sesion activa.
 * @param {Object} param0 props del componente.
 */
export const SessionRoute = ({ ...props }) =>
    /true/i.test(localStorage.getIsLoggedIn()) ? <Redirect to={ROUTES.CLIENT.HOME} /> : <Route {...props} />;
