import React from 'react';

import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, CircularProgress } from '@material-ui/core';

import { DefaultField } from '../default';
import * as Common from '../../../utils/common';
import { GroupController } from '../../../utils';

const formInitialValues = {
    name: '',
};

const validationSchema = yup.object({
    name: yup.string('El nombre es de tipo texto').required('El nombre es requerido'),
});

export const GroupRemovalForm = ({ id, validation, onRemove, onCancel }) => {
    /**
     * metodo de confirmacion.
     */
    const canSend = (value) => validation.trim().toLowerCase() === value.trim().toLowerCase();

    /**
     * metodo para remover un grupo.
     */
    const onFormSubmit = async (setSubmitting, resetForm) => {
        setSubmitting(true);
        const res = await GroupController.remove(id);
        setSubmitting(false);

        if (res.status < 400) {
            resetForm();
            Common.fireMiniMessage('Grupo Eliminado!');
            onCancel();
            onRemove();
        }
    };

    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(setSubmitting, resetForm)}
        >
            {({ values, isSubmitting }) => (
                <Form className="inherit-w">
                    <h3 className="alert-title">¿Desea eliminar este grupo?</h3>
                    <div className="sm-es"></div>
                    <p>Para eliminar este grupo, por favor ingrese su nombre</p>
                    <br />
                    <DefaultField name="name" label="Nombre" size="small" />
                    <div className="md-es"></div>
                    <div className={Common.rowBetweenMiddle()}>
                        <div className={Common.colJoinLg_MdSmXs(4, 5)}>
                            <Button
                                color="default"
                                variant="contained"
                                size="medium"
                                className="ls-custom btn-cancel btn-rmv"
                                onClick={onCancel}
                                disabled={isSubmitting}
                                fullWidth
                                disableElevation
                            >
                                Cancelar
                            </Button>
                        </div>
                        <div className={Common.colJoinLg_MdSmXs(4, 5)}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                size="medium"
                                className="ls-custom fl-right"
                                disabled={isSubmitting || !canSend(values.name)}
                                fullWidth
                                disableElevation
                            >
                                Eliminar &nbsp;
                                {isSubmitting ? <CircularProgress size={20} /> : null}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
