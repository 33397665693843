import env from '../../../env.json';
import { api } from '../axios';

const controller = env.REACT_APP_API_VERSION + 'odef';

/**
 * Realiza una petición con el método GET a la API bajo el controlador /odef, en
 * la ruta /list con el fin de listar el historico de los odef existentes en la base de
 * datos.
 */
export const list = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller}/list`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método GET a la API bajo el controlador /odef, en
 * la ruta /download con el fin de descargar el historico de la odef en formato csv.
 */
export const download = (id) =>
	new Promise((resolve, reject) => {
		api.get(`${controller}/${id}/download`, {
			responseType: 'blob',
		})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método GET a la API bajo el controlador /odef, en
 * la ruta /detail con el fin de obtener el datalle de la odef en formato json.
 */
export const detail = (id) =>
	new Promise((resolve, reject) => {
		api.get(`${controller}/${id}/detail`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
