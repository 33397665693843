import React from 'react';

import styles from './login.module.scss';
import { Common } from '../../../../utils';

import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, CircularProgress } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { DefaultField } from '../../../forms/default';

import { AuthController, LocalStoreService, ROUTES } from '../../../../utils';

const formInitialValues = {
    email: '',
    password: '',
};

const validationSchema = yup.object({
    email: yup
        .string('Usuario es de tipo texto')
        .required('Usuario requerido')
        .email('Usuario no es un correo electronico valido'),
    password: yup
        .string('Contraseña de tipo texto')
        .required('Contraseña requerida')
        .min(3, 'La contraseña debe tener una longitud mayor a 3 caracteres'),
});

const localStorage = new LocalStoreService();

export const LoginForm = ({ recovery }) => {
    const history = useHistory();

    /**
     * método para iniciar sesión.
     */
    const onFormSubmit = async (data, setSubmitting, resetForm) => {
        try {
            setSubmitting(true);
            const res = await AuthController.login(data);
            setSubmitting(false);
            if (res && res.status === 200) {
                resetForm();
                localStorage.setAccessToken(res.data.access_token);
                localStorage.setUserRol(res.data.user.rol);
                localStorage.setUserInfo(res.data.user);
                Common.fireMiniMessage('Sesión iniciada!');
                history.push(ROUTES.CLIENT.HOME);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
        >
            {({ isSubmitting }) => (
                <Form className="inherit-w">
                    <DefaultField name="email" label="Usuario" placeholder="name@example.com" />
                    <div className="md-es"></div>

                    <DefaultField name="password" label="Contraseña" type="password" />
                    <div className="md-es"></div>

                    <div className={Common.colJoin(12) + ' zero'}>
                        <div className={'row  ' + styles.center + ' zero'}>
                            <Button
                                disableElevation
                                disableRipple
                                disableFocusRipple
                                className={styles.link}
                                style={{ backgroundColor: 'transparent' }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    recovery(true);
                                }}
                            >
                                Recuperar contrase&ntilde;a
                            </Button>
                        </div>
                    </div>
                    <div className="sm-es"></div>

                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        className="ls-custom"
                        disabled={isSubmitting}
                        disableElevation
                        fullWidth
                    >
                        E N T R A R &nbsp; {isSubmitting ? <CircularProgress size={30} /> : null}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
