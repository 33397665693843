import { useCallback, useEffect, useState } from 'react';

/**
 * Método para obtener el listado de áreas.
 */
export const useListRequest = (controller, requestCallback = undefined) => {
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const fetch = useCallback(async () => {
		try {
			setLoading(true);
			setError(false);
			const res = await controller.list();
			setLoading(false);
			if (res.status === 200) {
				console.log(res.data);
				setItems(res.data);
			}
		} catch (error) {
			setError(true);
			console.log(error);
		} finally {
			setLoading(false);
			if (requestCallback) requestCallback();
		}
	}, [controller, requestCallback]);

	useEffect(() => {
		fetch();
	}, [fetch]);

	return { loading, error, items, fetch };
};
