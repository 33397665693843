import React, { Fragment, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Button, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Common, MarketController, Transformations } from '../../../utils';
import { ImprovedSelectField } from '../default/select/Improved';
import { DefaultField } from '../default';
import { toTitleCase } from '../../../utils/pipes';
import { useListRequest } from '../../hooks';

const defaultFormValues = [
	{
		id: `units_field_${Date.now()}`,
		mercado: '',
		unidades_equiv: '',
	},
];

const EquivalentUnitsConfigForm = ({
	plant,
	plantType,
	data: record,
	isCreation = false,
	onUpdate = () => {},
	onCancel = () => {},
}) => {
	const [addedFields, setAddedFields] = useState([]);
	// eslint-disable-next-line
	const { items: markets, _loading, _error, _fetch } = useListRequest(MarketController);

	// ignore aditional checks
	if (markets?.length === 0) {
		return (
			<Fragment>
				<h2>Se necesita informaci&oacute;n adicional</h2>
				<hr />
				<p>
					No hay mercados configurados por lo que no se puede agregar la configuracion de unidades
					equivalentes.
				</p>
			</Fragment>
		);
	}

	const marketsAsOpts = markets.map((market) => {
		return { id: market.id, value: market.name };
	});

	const formInitialValues = {
		units: record ? Transformations.normalizeExchanges(plantType, record) : defaultFormValues,
	};

	const validationSchema = yup.object({
		units: yup.array().of(
			yup.object({
				mercado: yup.string().required('Es requerido seleccionar un mercado.'),
				unidades_equiv: yup
					.number()
					.required('La cantidad de unidades equivalentes es requerida.')
					.min(0, 'La cantidad de unidades equivalentes debe ser mayor o igual a 0.'),
			})
		),
	});

	const addItemHandler = (arrayHelpers) => {
		const newId = `units_field_${Date.now()}`;

		arrayHelpers.push({
			id: newId,
			mercado: '',
			unidades_equiv: '',
		});

		setAddedFields((previous) => {
			const copy = [...previous];
			copy.push(newId);
			return copy;
		});
	};

	const removeItemHandler = (arrayHelpers, indexToRemove, id) => {
		arrayHelpers.remove(indexToRemove);

		setAddedFields((previous) => {
			const copy = [...previous];
			const idIndex = copy.indexOf(id);
			if (idIndex > -1) {
				copy.splice(idIndex, 1);
			}
			return copy;
		});
	};

	const renderUnits = (units, arrayHelpers) => {
		return units.map((unit, idx) => {
			return (
				<div key={unit.id}>
					<div className={Common.rowBetween() + ' zero'}>
						<div className={Common.colJoinTop(5)}>
							<ImprovedSelectField
								inputProps={{
									opts: marketsAsOpts,
									useIdAsValue: true,
									label: `Mercado #${(idx + 1).toString().padStart(2, '0')}`,
									id: `units-${idx}-market-selection`,
									name: `units.${idx}.mercado`,
								}}
							/>
						</div>
						<div className={Common.colJoinTop(5)}>
							<DefaultField
								name={`units.${idx}.unidades_equiv`}
								label="Unidades equivalentes"
								size="small"
								type="number"
							/>
						</div>
						<div className={Common.colJoinTop(2)}>
							{units.length > 1 ? (
								<Tooltip title="Remover" placement="right">
									<IconButton
										onClick={() => {
											removeItemHandler(arrayHelpers, idx, unit.id);
										}}
									>
										<FontAwesomeIcon icon={['fas', 'minus']} />
									</IconButton>
								</Tooltip>
							) : null}
						</div>
					</div>
					<div className="sm-es"></div>
				</div>
			);
		});
	};

	const formatData = (data) => {
		return data.units.map((unit) => {
			let unitPayload = { ...unit };
			if (isCreation) {
				delete unitPayload['id'];
			} else {
				if (addedFields.includes(unit.id)) {
					delete unitPayload['id'];
				} else {
					return {
						...unitPayload,
						mercado: unitPayload.mercado.id,
					};
				}
			}
			return unitPayload;
		});
	};

	const onFormSubmit = async (data, setSubmitting, resetForm) => {
		setSubmitting(true);
		try {
			const body = formatData(data);
			const controller = Transformations.plantTypeToController(plantType);
			const res = await controller.saveEquivalentUnits(plant.id, body);

			if (res.status < 400) {
				const action = isCreation ? 'agregado' : 'actualizado';
				Common.fireMiniMessage(
					`Se ha ${action} la configuración de unidades equivalentes de la planta ${plant.name}!`
				);
			}

			setSubmitting(false);
			resetForm();
			onUpdate();
			onCancel();
		} catch (error) {
			console.error(error);
			setSubmitting(false);
		}
	};

	return (
		<Formik
			initialValues={formInitialValues}
			validationSchema={validationSchema}
			onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
		>
			{({ values, isSubmitting }) => (
				<Form className="inherit-w">
					<FieldArray name="units">
						{(arrayHelpers) => (
							<Fragment>
								<div className={Common.rowMiddle() + ' zero'}>
									<h3
										style={{
											color: '#173DB8',
										}}
									>
										{toTitleCase(plant.name)}: Config. Unidades Equivalentes &nbsp;
									</h3>
									<Tooltip size="small" title="Agregar intercambio" placement="right">
										<IconButton onClick={addItemHandler.bind(null, arrayHelpers)}>
											<FontAwesomeIcon icon={['fas', 'plus']} />
										</IconButton>
									</Tooltip>
								</div>

								<hr />
								<div className="sm-es"></div>

								{renderUnits(values.units, arrayHelpers)}
							</Fragment>
						)}
					</FieldArray>

					<div className="md-es"></div>
					<div className={Common.rowBetweenMiddle()}>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								color="default"
								variant="contained"
								size="medium"
								className="ls-custom btn-cancel btn-rmv"
								onClick={onCancel}
								disabled={isSubmitting}
								fullWidth
								disableElevation
							>
								Cancelar
							</Button>
						</div>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								size="medium"
								className="ls-custom fl-right"
								disabled={isSubmitting}
								fullWidth
								disableElevation
							>
								{isCreation ? 'Agregar' : 'Editar'} &nbsp;
								{isSubmitting ? <CircularProgress size={20} /> : null}
							</Button>
						</div>
					</div>

					<div className="sm-es"></div>
				</Form>
			)}
		</Formik>
	);
};

export default EquivalentUnitsConfigForm;
