import React, { Fragment, useEffect, useState } from 'react';
import { Common, SubareaController } from '../../utils';
import { RequestMeta } from '../layout';
import { useListRequest } from '../hooks';
import SubareaList from './SubareaList';
import SaveSubareaForm from './SaveSubareaForm';

const SubareaTab = ({ refresh, fetchCallback }) => {
	const [selected, setSelected] = useState(null);
	const { items, loading, error, fetch } = useListRequest(SubareaController, fetchCallback);
	useEffect(() => {
		if (refresh) {
			fetch();
		}
	}, [refresh, fetch]);

	const cancelHandler = () => {
		setSelected(null);
	};

	return (
		<Fragment>
			<RequestMeta error={error} loading={loading} items={items}>
				<div className={Common.rowBetween() + ' inherit-w'}>
					<div className={Common.colJoin(12) + ' fixed-scroll-xs'}>
						<SubareaList
							items={items}
							onItemUpdate={fetch}
							selected={selected}
							onSelected={setSelected}
						/>
					</div>
				</div>
			</RequestMeta>

			<div className="sm-es"></div>
			<hr />
			<div className="xs-es"></div>

			<SaveSubareaForm
				isCreation={!Boolean(selected)}
				data={selected}
				onCancel={cancelHandler}
				onUpdate={fetch}
			/>
		</Fragment>
	);
};

export default SubareaTab;
