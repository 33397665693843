import React, { useEffect } from 'react';
import { useListRequest } from '../hooks';
import { Common, ConstantController } from '../../utils';
import { RequestMeta } from '../layout';
import ConstantList from './ConstantList';

const ConstantTab = ({ refresh, fetchCallback }) => {
	const { items, loading, error, fetch } = useListRequest(ConstantController, fetchCallback);

	useEffect(() => {
		if (refresh) fetch();
	}, [refresh, fetch]);

	return (
		<RequestMeta error={error} loading={loading} items={items}>
			<div className={Common.rowBetween() + ' inherit-w'}>
				<div className={Common.colJoin(12) + ' fixed-scroll-md'}>
					<ConstantList items={items} onItemUpdate={fetch} />
				</div>
			</div>
		</RequestMeta>
	);
};

export default ConstantTab;
