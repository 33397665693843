import env from '../../../env.json';
import { api } from '../axios';

const controller = env.REACT_APP_API_VERSION + 'group';

/**
 * Realiza una petición con el método GET a la API bajo el controlador /group, en la
 * ruta /list con el fin de listar los grupos existentes en la base de datos.
 */
export const list = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller}/list`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /group, en
 * la ruta /create con el fin de crear un grupo.
 * @param {Object} body Cuerpo de la petición con el grupo a crear.
 */
export const create = (body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/create`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /group, en
 * la ruta /add_user_multiple con el fin de agregar un usuario a multiples grupo.
 * @param {number} userId id correspondiente al usuario que se agregara a los grupos.
 * @param {number[]} groupsId vector de ids correspondiente a los grupos en los cuales se agregara el usuario.
 */
export const addUser = (userId, groupsId) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/add_user_multiple`, {
			user_id: userId,
			groups_id: groupsId,
		})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método PUT a la API bajo el controlador /group, en la
 * ruta /:id/update con el fin de actualizar la información de un grupo.
 * @param {number} id id correspondiente al grupo a editar.
 * @param {Object} body Cuerpo de la petición con los cambios del grupo correspondiente.
 */
export const update = (id, body) =>
	new Promise((resolve, reject) => {
		api.put(`${controller}/${id}/update`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador /group,
 * en la ruta /delete con el fin de eliminar un grupo de la base de datos de forma
 * permanente.
 * @param {number} id id correspondiente del grupo a eliminar.
 */
export const remove = (id) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller}/${id}/delete`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
