import * as Common from './common';

/**
 * Este archivo se encarga de generar el mapa de navegacion que sera utilizado
 * para definir las rutas de la aplicación.
 */
export const ROUTES = {
	AUTH: {
		BASE: '/auth',
		LOGIN: '/auth/login',
		RESET: '/auth/reset',
	},
	CLIENT: {
		BASE: '/client',
		HOME: '/client/home',
		GLOSARY: '/client/glosary',
		DEMAND: {
			BASE: '/client/energetic-variables',
			FORECAST: '/client/energetic-variables/forecast',
			HISTORY: '/client/energetic-variables/history',
			REPORT: '/client/energetic-variables/report',
			AREAS: '/client/energetic-variables/areas',
			DATASETS: '/client/energetic-variables/datasets',
			ARCHITECTURE: '/client/energetic-variables/architecture',
			MODELS: '/client/energetic-variables/models',
			DASHBOARD: '/client/energetic-variables/energy-report',
		},
		ODEF: '/client/odef',
		ENERGY_DISPATCH: {
			BASE: '/client/energy-dispatch',
			EXECUTE: '/client/energy-dispatch/execute',
			HISTORY: '/client/energy-dispatch/history',
			MARKETS: '/client/energy-dispatch/markets',
			PLANTS: '/client/energy-dispatch/plants',
			CONSTANTS: '/client/energy-dispatch/basic-data',
		},
		ADMIN: {
			BASE: '/client/admin',
			USERS: '/client/admin/users',
			TOKENS: '/client/admin/tokens',
			GROUPS: '/client/admin/groups',
		},
	},
};

const MAIN_ROUTES = {
	ENERGETIC_VARIABLES: 'Variables energéticas',
	ODEF: 'ODEF',
	ENERGY_DISPATCH: 'Despacho de energía',
	ADMIN: 'Administración',
};

//content, route, sublinks
export const NAV_BAR_ROUTES = [
	{
		content: MAIN_ROUTES.ENERGETIC_VARIABLES,
		route: '',
		name: 'energetic-variables',
		sublinks: [
			{
				parent: MAIN_ROUTES.ENERGETIC_VARIABLES,
				content: 'Pronósotico',
				name: 'forecast',
				route: ROUTES.CLIENT.DEMAND.FORECAST,
				rights: Common.getDefaultRights(),
			},
			{
				parent: MAIN_ROUTES.ENERGETIC_VARIABLES,
				content: 'Histórico',
				name: 'history',
				route: ROUTES.CLIENT.DEMAND.HISTORY,
				rights: Common.getDefaultRights(),
			},
			{
				parent: MAIN_ROUTES.ENERGETIC_VARIABLES,
				content: 'Informe',
				name: 'report',
				route: ROUTES.CLIENT.DEMAND.REPORT,
				rights: Common.getDefaultRights(),
			},
			{
				parent: MAIN_ROUTES.ENERGETIC_VARIABLES,
				content: 'Áreas',
				name: 'areas',
				route: ROUTES.CLIENT.DEMAND.AREAS,
				rights: Common.getDefaultRights(),
			},
			{
				parent: MAIN_ROUTES.ENERGETIC_VARIABLES,
				content: 'Datasets',
				route: ROUTES.CLIENT.DEMAND.DATASETS,
				name: 'datasets',
				rights: Common.getDefaultRights(),
			},
			{
				parent: MAIN_ROUTES.ENERGETIC_VARIABLES,
				content: 'Arquitecturas',
				route: ROUTES.CLIENT.DEMAND.ARCHITECTURE,
				name: 'architecture',
				rights: Common.getDefaultRights(),
			},
			{
				parent: MAIN_ROUTES.ENERGETIC_VARIABLES,
				content: 'Modelos',
				route: ROUTES.CLIENT.DEMAND.MODELS,
				name: 'models',
				rights: Common.getDefaultRights(),
			},
			{
				parent: MAIN_ROUTES.ENERGETIC_VARIABLES,
				content: 'Energy Report',
				route: ROUTES.CLIENT.DEMAND.DASHBOARD,
				name: 'energy-report',
				rights: Common.getDefaultRights(),
			},
		],
		rights: Common.getDefaultRights(),
	},
	{
		content: MAIN_ROUTES.ODEF,
		route: ROUTES.CLIENT.ODEF,
		name: 'odef',
		sublinks: [],
		rights: Common.getDefaultRights(),
	},
	{
		content: MAIN_ROUTES.ENERGY_DISPATCH,
		route: '',
		name: 'energy-dispatch',
		sublinks: [
			{
				parent: MAIN_ROUTES.ENERGY_DISPATCH,
				content: 'Ejecutar',
				name: 'forecast',
				route: ROUTES.CLIENT.ENERGY_DISPATCH.EXECUTE,
				rights: Common.getDefaultRights(),
			},
			{
				parent: MAIN_ROUTES.ENERGY_DISPATCH,
				content: 'Histórico',
				name: 'history',
				route: ROUTES.CLIENT.ENERGY_DISPATCH.HISTORY,
				rights: Common.getDefaultRights(),
			},
			{
				parent: MAIN_ROUTES.ENERGY_DISPATCH,
				content: 'Mercados e intercambios',
				name: 'markets_exchanges',
				route: ROUTES.CLIENT.ENERGY_DISPATCH.MARKETS,
				rights: Common.getAdminRights(),
			},
			{
				parent: MAIN_ROUTES.ENERGY_DISPATCH,
				content: 'Plantas de energía',
				route: ROUTES.CLIENT.ENERGY_DISPATCH.PLANTS,
				name: 'plants',
				rights: Common.getAdminRights(),
			},
			{
				parent: MAIN_ROUTES.ENERGY_DISPATCH,
				content: 'Información constante',
				route: ROUTES.CLIENT.ENERGY_DISPATCH.CONSTANTS,
				name: 'constant_info',
				rights: Common.getDefaultRights(),
			},
		],
		rights: Common.getDefaultRights(),
	},
	{
		content: MAIN_ROUTES.ADMIN,
		route: '',
		name: 'admin',
		sublinks: [
			{
				parent: MAIN_ROUTES.ADMIN,
				content: 'Usuarios',
				name: 'users',
				route: ROUTES.CLIENT.ADMIN.USERS,
				rights: Common.getAdminRights(),
			},
			{
				parent: MAIN_ROUTES.ADMIN,
				content: 'Grupos',
				name: 'groups',
				route: ROUTES.CLIENT.ADMIN.GROUPS,
				rights: Common.getAdminRights(),
			},
			{
				parent: MAIN_ROUTES.ADMIN,
				content: 'Tokens',
				name: 'tokens',
				route: ROUTES.CLIENT.ADMIN.TOKENS,
				rights: Common.getSuperAdminRights(),
			},
		],
		rights: Common.getAdminRights(),
	},
];
