import React, { useEffect, useRef, useState } from 'react';
import {
	axisBottom,
	axisLeft,
	curveCardinal,
	line,
	max,
	scaleBand,
	scaleLinear,
	select,
	stack,
	stackOrderAscending,
} from 'd3';

import { ChromaHelper, DateHelper } from '../../../utils';
import { useBarchartDataGenerator, useChartDataGenerator, useResizeObserver } from '../../hooks';

import classes from './StackedBarchart.module.scss';
import { Fragment } from 'react';
import StackedBarchartControls from './StackedBarchartControls';
import ChartActions from '../common/ChartActions';

const parseX = (values) => {
	return values.map((value, index) => (isNaN(value) ? DateHelper.strToDateTime(value) : value));
};

const StackedBarchart = ({ chartName, x, y, lines = [], barIds, lineIds = [], disableBezier = false }) => {
	const [xlsx, setXlsx] = React.useState([]);
	const [xlsxCols, setXlsxCols] = React.useState([]);
	const svgRef = useRef();
	const wrapperRef = useRef();
	const dimensions = useResizeObserver(wrapperRef);
	const chartData = useBarchartDataGenerator(x, y, barIds);
	const lineData = useChartDataGenerator(x, lines, lineIds, true);

	const xAsDates = parseX(x);
	const range = [xAsDates[0], xAsDates[xAsDates.length - 1]];
	const [limits, setLimits] = useState(range);

	/**
	 * Crear el formato para el archivo de excel.
	 */
	const chartLines = lineData?.lines;
	const barData = chartData?.bars.data;
	useEffect(() => {
		if (chartLines?.length === 0) return;
		if (barData?.length === 0) return;

		const mapper = chartLines.map((line, index) => {
			return {
				id: line.id,
				index: index,
			};
		});

		const excelCols = ['fecha', 'hora', ...lineIds, ...barIds];

		const excelData = [];

		for (let index = 0; index < barData.length; index++) {
			const date = barData[index].xAxis;
			const dateString = `${date.getFullYear()}-${(date.getMonth() + 1)
				.toString()
				.padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
			const hour = date.getHours() + 1;
			let row = {
				...barData[index],
				fecha: dateString,
				hora: hour,
			};

			delete row['xAxis'];

			for (const line of mapper) {
				row[line.id] = chartLines[line.index].points[index].yAxis;
			}

			excelData.push(row);
		}

		setXlsxCols(excelCols);
		setXlsx(excelData);
	}, [barData, barIds, chartLines, lineIds]);

	/**
	 * Handle linechart render with D3
	 */
	useEffect(() => {
		if (chartData.bars.length === 0) return;

		const filteredBarData = {
			...chartData,
			bars: {
				...chartData.bars,
				data: chartData.bars.data.filter(
					(bar) => bar.xAxis.getTime() >= limits[0] && bar.xAxis.getTime() <= limits[1]
				),
			},
		};

		const filteredLineData = {
			...lineData,
			lines: lineData.lines.map((line) => {
				const points = line.points.filter(
					(point) => point.xAxis.getTime() >= limits[0] && point.xAxis.getTime() <= limits[1]
				);

				return { ...line, points };
			}),
		};

		const svg = select(svgRef.current);
		const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();

		const stackGenerator = stack().keys(barIds).order(stackOrderAscending);
		const layers = stackGenerator(filteredBarData.bars.data);
		const extent = [0, max(layers, (layer) => max(layer, (sequence) => sequence[1]))];

		const xScale = scaleBand()
			.domain(filteredBarData.bars.data.map((record) => record.xAxis))
			.range([0, width])
			.padding(0.125);

		const yScale = scaleLinear().domain(extent).range([height, 0]);

		// Render bars

		const bars = svg.select('#sisprover-barchart-bars');

		bars.selectAll('.bar-layer')
			.data(layers)
			.join('g')
			.attr('class', 'clickable bar-layer')
			.attr('fill', (layer) => filteredBarData.colors[layer.key])
			.attr('key', (layer) => layer.key)
			.selectAll('rect')
			.data((layer) => layer)
			.join('rect')
			.attr('x', (sequence) => xScale(sequence.data.xAxis))
			.attr('width', xScale.bandwidth())
			.attr('y', (sequence) => yScale(sequence[1]))
			.attr('height', (sequence) => yScale(sequence[0]) - yScale(sequence[1]))
			.on('mouseover', (event, sequence) => {
				const layer = event.target.parentElement;
				const color = layer.getAttribute('fill');
				const key = layer.getAttribute('key');

				select(event.target).attr('fill', ChromaHelper.brighten(color, 2));

				const withBG = 200;
				const heightBG = 60;
				const yOffset = 15;
				const xCoordBG = event.layerX - withBG / 2;
				const xCoordText = xCoordBG + 10;
				const yCoordBG = event.layerY - heightBG - yOffset;
				const yCoordTitle = yCoordBG + 20;
				const yCoordDescription = yCoordTitle + 30;

				svg.selectAll('.d3-tooltip-bg')
					.data([event])
					.join((enter) => enter.append('rect').attr('y', yCoordBG - 10))
					.attr('class', 'd3-tooltip-bg')
					.attr('x', xCoordBG)
					.attr('text-anchor', 'middle')
					.attr('width', withBG)
					.attr('height', heightBG)
					.attr('fill', '#1A202C')
					.transition()
					.attr('y', yCoordBG)
					.attr('fill-opacity', 0.5);

				svg.selectAll('.d3-tooltip-title')
					.data([event])
					.join((enter) => enter.append('text').attr('y', yCoordTitle - 10))
					.attr('class', 'd3-tooltip-title')
					.text(key)
					.attr('font-family', "'Lato', sans-serif")
					.attr('x', xCoordText)
					.attr('text-anchor', 'left')
					.attr('width', 200)
					.attr('height', 30)
					.style('fill', 'white')
					.transition()
					.attr('y', yCoordTitle)
					.attr('opacity', 1);

				svg.selectAll('.d3-tooltip-text')
					.data([event])
					.join((enter) => enter.append('text').attr('y', yCoordDescription - 10))
					.attr('class', 'd3-tooltip-text')
					.text(`${sequence.data[key]} MW/h`)
					.attr('font-family', "'Lato', sans-serif")
					.attr('x', xCoordText)
					.attr('text-anchor', 'left')
					.attr('width', 200)
					.attr('height', 30)
					.style('fill', 'white')
					.transition()
					.attr('y', yCoordDescription)
					.attr('opacity', 1);
			})
			.on('mouseleave', (event, sequence) => {
				// if (date < selection[0] || date > selection[1])
				const elementColor = event.target.parentElement.getAttribute('fill');

				select(event.target).attr('fill', elementColor);
				svg.select('.d3-tooltip-bg').remove();
				svg.select('.d3-tooltip-title').remove();
				svg.select('.d3-tooltip-text').remove();
			});

		// render line
		const yLineScale = scaleLinear()
			.domain([filteredLineData.limits.y.min, filteredLineData.limits.y.max])
			.range([height, 0]);

		const lineGenerator = line()
			.x((record, index) => xScale(record.xAxis) + xScale.bandwidth() / 2)
			.y((record) => yLineScale(record.yAxis));

		if (!disableBezier) {
			lineGenerator.curve(curveCardinal);
		}

		const lines = svg.select('#sisprover-barchart-lines');

		// render the line
		for (const line of filteredLineData.lines) {
			const lineColor = line.color;
			// console.log(JSON.stringify(line));
			lines
				.selectAll(`.barchart-line-${line.id}`)
				.data([line.points])
				.join('path')
				.attr('class', `barchart-line-${line.id}`)
				.attr('stroke', lineColor)
				.attr('stroke-width', '2px')
				.attr('fill', 'none')
				.attr('d', lineGenerator);

			// render dots
			const dotColor = line.color;
			lines
				.selectAll(`.linechart-dot-${line.id}`)
				.data(line.points)
				.join('circle')
				.attr('class', `clickable linechart-dot-${line.id}`)
				.attr('stroke', dotColor)
				.attr('r', 2)
				.attr('fill', dotColor)
				.attr('cx', (point) => xScale(point.xAxis) + xScale.bandwidth() / 2)
				.attr('cy', (point) => yLineScale(point.yAxis))
				.on('mouseover', (event, { xAxis, yAxis }) => {
					select(event.target).attr('r', 4).attr('fill', ChromaHelper.brighten(dotColor, 2));

					svg.selectAll('.d3-tooltip-bg')
						.data([event])
						.join((enter) => enter.append('rect').attr('y', yLineScale(yAxis) - 120))
						.attr('class', 'd3-tooltip-bg')
						.attr('x', xScale(xAxis) - 80)
						.attr('text-anchor', 'middle')
						.attr('width', 165)
						.attr('height', '60px')
						.attr('fill', '#1A202C')
						.transition()
						.attr('y', yLineScale(yAxis) - 70)
						.attr('fill-opacity', 0.5);

					svg.selectAll('.d3-tooltip-title')
						.data([event])
						.join((enter) => enter.append('text').attr('y', yLineScale(yAxis) - 110))
						.attr('class', 'd3-tooltip-title')
						.text('UNIDADESEQ')
						.attr('font-family', "'Lato', sans-serif")
						.attr('x', xScale(xAxis))
						.attr('text-anchor', 'middle')
						.attr('width', 200)
						.attr('height', 30)
						.style('fill', 'white')
						.transition()
						.attr('y', yLineScale(yAxis) - 50)
						.attr('opacity', 1);

					svg.selectAll('.d3-tooltip-text')
						.data([event])
						.join((enter) => enter.append('text').attr('y', yLineScale(yAxis) - 110))
						.attr('class', 'd3-tooltip-text')
						.text(`${yAxis} MW/h`)
						.attr('font-family', "'Lato', sans-serif")
						.attr('x', xScale(xAxis))
						.attr('text-anchor', 'middle')
						.attr('width', 200)
						.attr('height', 30)
						.style('fill', 'white')
						.transition()
						.attr('y', yLineScale(yAxis) - 20)
						.attr('opacity', 1);
				})
				.on('mouseleave', (event, { date, value }) => {
					// if (date < selection[0] || date > selection[1])
					select(event.target).attr('r', 2).attr('fill', dotColor);
					svg.select('.d3-tooltip-bg').remove();
					svg.select('.d3-tooltip-title').remove();
					svg.select('.d3-tooltip-text').remove();
				});
		}

		const amountOfDays = filteredBarData.bars.data.length / 24;
		const monthDays = 31;
		// axes
		const xAxis = axisBottom(xScale)
			.tickValues(
				xScale.domain().filter((value) => {
					if (amountOfDays < 5) {
						return value.getHours() % 4 === 0;
					} else if (amountOfDays < 10) {
						return value.getHours() % 12 === 0;
					} else if (amountOfDays < monthDays) {
						return value.getHours() === 0;
					} else if (amountOfDays < monthDays * 5) {
						return value.getDate() % 4 === 0 && value.getHours() === 0;
					} else if (amountOfDays < monthDays * 10) {
						return value.getDate() % 8 === 0 && value.getHours() === 0;
					} else {
						return value.getDate() === 1 && value.getHours() === 0;
					}
				})
			)
			.tickFormat(DateHelper.d3Format);

		svg.select('.sisprover-barchart-x-axis')
			.attr('transform', `translate(0, ${height})`)
			.call(xAxis)
			.selectAll('text')
			.attr('y', 10)
			.attr('x', -10)
			.attr('transform', 'rotate(-45)')
			.style('text-anchor', 'end');

		const yAxis = axisLeft(yScale).ticks(10);
		svg.select('.sisprover-barchart-y-axis').call(yAxis);

		const margin = {
			top: 100,
			left: 60,
		};

		// Add X axis label:
		svg.select('.sisprover-barchart-x-axis-label')
			.join('text')
			.attr('class', 'sisprover-barchart-x-axis-label')
			.attr('text-anchor', 'end')
			.attr('x', width)
			.attr('y', height + margin.top)
			.text('Tiempo (Horas)');

		// Y axis label:
		svg.select('.sisprover-barchart-y-axis-label')
			.join('text')
			.attr('class', 'sisprover-barchart-y-axis-label')
			.attr('text-anchor', 'end')
			.attr('transform', 'rotate(-90)')
			.attr('y', -margin.left + 10)
			.attr('x', 0)
			.text(`MW/h `);
	}, [chartData, barIds, lineData, limits, dimensions, disableBezier]);

	/**
	 * Actualizar los valores del slider que controla el filtro de fechas
	 */
	const handleSliderChange = (event, newValue) => {
		if (newValue[0] < newValue[1]) setLimits(newValue);
	};

	/**
	 * Este metodo genera un vector en formato de base 64
	 * @returns {string} cadena de texto en base 64
	 */
	const svg2str = () => {
		const { width, height } = dimensions;

		const svg = document.getElementById(`sisprover-barchart-container`).cloneNode(true);
		svg.setAttribute('height', height + 130);
		svg.setAttribute('width', width + 100);

		for (const child of svg.children) {
			child.setAttribute('style', 'transform: translateX(70px)');
		}

		let svgAsXML = new XMLSerializer().serializeToString(svg);
		svgAsXML = svgAsXML.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
		svgAsXML = svgAsXML.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix
		return svgAsXML;
	};

	/**
	 * Descargar diagrama como .svg
	 */
	const downloadAsSVG = () => {
		const svgStr = svg2str();
		let a = document.createElement('a');
		a.download = `${chartName} ${DateHelper.formatDate(new Date(), 'yyyy-MM-dd')}.svg`;
		a.href = 'data:image/svg+xml,' + encodeURIComponent(svgStr);
		a.target = '_blank';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	/**
	 * Descargar diagrama como .png
	 */
	const downloadAsPNG = () => {
		const { width, height } = dimensions;
		const svgStr = svg2str();
		const src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgStr)));

		let canvas = document.createElement('canvas');
		let context = canvas.getContext('2d');

		canvas.width = width + 100;
		canvas.height = height + 130;

		let image = new Image();
		image.onload = function () {
			context.clearRect(0, 0, width + 100, height + 130);
			context.drawImage(image, 0, 0, width + 100, height + 130);

			canvas.toBlob(function (blob) {
				let a = document.createElement('a');
				a.download = `${chartName} ${DateHelper.formatDate(new Date(), 'yyyy-MM-dd')}.png`;
				a.href = URL.createObjectURL(blob);
				a.target = '_blank';
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			}, 'image/png');
		};

		image.src = src;
	};

	return (
		<Fragment>
			<StackedBarchartControls
				chartName={chartName}
				sliderValue={limits}
				sliderRange={range}
				onSliderChange={handleSliderChange}
			/>
			<div id="sisprover-barchart-wrapper" ref={wrapperRef} className={classes.root}>
				<svg
					id="sisprover-barchart-container"
					ref={svgRef}
					className={classes.svg}
					style={{ backgroundSize: '5% 5%' }}
				>
					<g id="sisprover-barchart-info" className={classes.svg_container}>
						<g className="sisprover-barchart-x-axis"></g>
						<text className="sisprover-barchart-x-axis-label"></text>
						<g className="sisprover-barchart-y-axis"></g>
						<text className="sisprover-barchart-y-axis-label"></text>
						<g className="sisprover-barchart-brush"></g>
					</g>
					<g id="sisprover-barchart-bars" className={classes.svg_container}>
						<g className="sisprover-barchart-x-axis"></g>
						<text className="sisprover-barchart-x-axis-label"></text>
						<g className="sisprover-barchart-y-axis"></g>
						<text className="sisprover-barchart-y-axis-label"></text>
						<g className="sisprover-barchart-brush"></g>
					</g>
					<g id="sisprover-barchart-lines" className={classes.svg_container}></g>
				</svg>
			</div>
			{xlsxCols.length > 0 && xlsx.length > 0 && (
				<ChartActions
					chartName={chartName}
					xlsx={xlsx}
					xlsxCols={xlsxCols}
					onDownloadSVG={downloadAsSVG}
					onDownloadPNG={downloadAsPNG}
				/>
			)}
		</Fragment>
	);
};

export default StackedBarchart;
