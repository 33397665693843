import { useEffect, useState } from 'react';
import { OptimizerController } from '../../utils';

/**
 * Método para obtener el listado de áreas.
 */
const useFetchOptimizers = () => {
	const [optimizers, setOptimizers] = useState([]);

	useEffect(() => {
		const fetch = async () => {
			try {
				const res = await OptimizerController.list();

				if (res.status === 200) {
					const _optimizers = res.data.map((o) => {
						return { id: o.id, value: o.name };
					});
					setOptimizers(_optimizers);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetch();
	}, []);

	return optimizers;
};

export default useFetchOptimizers;
