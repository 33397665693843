import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	selected: undefined,
	items: [
		{
			plant_type: 'Térmica',
			nombre: 'PlantaTER',
			codigo: 'TER001',
			id_area: 2,
			unidades_equiv: '8',
			tml: '1',
			tmfl: '2',
			no_arr_dias: '3',
			te1: 4,
			te2: 5,
			te3: 6,
			id: 'T-0.7933720245264542',
			mercados: [],
		},
		{
			plant_type: 'Hidráulica',
			nombre: 'PlantaHD',
			codigo: 'HD001',
			id_area: 2,
			unidades_equiv: '6',
			id: 'T-0.6931176695186163',
			mercados: [],
		},
	],
};

const plantSlice = createSlice({
	name: 'plants',
	initialState,
	reducers: {
		add(state, action) {
			state.items.unshift(action.payload);
		},
		remove(state, action) {
			state.items = state.items.filter(
				item => item.id === action.payload
			);
		},
		update(state, action) {
			const { id } = action.payload;
			const index = state.items.findIndex(item => item.id === id);
			state.items[index] = action.payload;
		},
		setSelected(state, action) {
			state.selected = action.payload;
		},
	},
});

export const plantActions = plantSlice.actions;

export default plantSlice;
