import React, { Fragment, useState } from 'react';

import { Button, CircularProgress } from '@material-ui/core';

import * as Common from '../../../utils/common';
import { DatasetCommentController } from '../../../utils';

export const DatasetCommentRemovalForm = ({ id, onRemove, onCancel }) => {
    const [isSubmitting, setSubmitting] = useState(false);

    const onFormSubmit = async () => {
        setSubmitting(true);
        const res = await DatasetCommentController.remove(id);
        setSubmitting(false);

        if (res.status < 400) {
            Common.fireMiniMessage('Comentario Eliminado!');
            onCancel();
            onRemove();
        }
    };

    return (
        <Fragment>
            <h3 className="alert-title">¿Desea eliminar este comentario?</h3>
            <div className="md-es"></div>
            <div className={Common.rowBetweenMiddle()}>
                <div className={Common.colJoin(3)}>
                    <Button
                        color="default"
                        variant="contained"
                        size="medium"
                        className="ls-custom btn-cancel btn-rmv"
                        onClick={onCancel}
                        disabled={isSubmitting}
                        disableElevation
                    >
                        Cancelar
                    </Button>
                </div>
                <div className={Common.colJoinLg_MdSmXs(4, 5)}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="medium"
                        className="ls-custom fl-right"
                        disabled={isSubmitting}
                        onClick={() => onFormSubmit()}
                        disableElevation
                    >
                        Eliminar &nbsp;
                        {isSubmitting ? <CircularProgress size={20} /> : null}
                    </Button>
                </div>
            </div>
        </Fragment>
    );
};
