import React from 'react';
import classes from './Meta.module.scss';

import { Common } from '../../../utils';

const Meta = ({ title, content, noPadding = false }) => {
	return (
		<div className={Common.rowMiddle() + ' inherit-w zero ' + (!noPadding && 'px-16')}>
			<h4 className={classes.metadata__tittle}>{title}: &nbsp;</h4>
			<p>{content}</p>
		</div>
	);
};

export default Meta;
