import React, { useEffect, useState } from 'react';

import * as Common from '../../../../utils/common';

import { Card, CenteredContainer, NoConnection, NoResults, Spinner } from '../../../layout';
import { OdefController } from '../../../../utils';

/**
 * Componente encargado de renderizar el modulo de históricos.
 */
export const ODEF = () => {
	const [loading, setLoading] = useState(true);
	const [noConnection, setNoConnection] = useState(false);
	const [items, setItems] = useState([]);

	/**
	 * consulta de listado de historicos de odef.
	 */
	const fetch = async () => {
		try {
			setLoading(true);
			setNoConnection(false);
			const res = await OdefController.list();
			if (res.status < 400) {
				setItems(res.data);
			}
		} catch (error) {
			setNoConnection(true);
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	/**
	 * Se genera un archivo en formato .csv para la descarga de los datos de la ODEF.
	 * @param {Object} item ODEF a descargar
	 */
	const handleDownload = async (item) => {
		const response = await OdefController.download(item.id);
		if (response.status < 400) {
			const fileName = `${item.name.split('.').join(' ')}.csv`;
			const href = await URL.createObjectURL(response.data);
			const link = document.createElement('a');
			link.href = href;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	return (
		<CenteredContainer colSize={10}>
			<div className="row inherit-w zero">
				<h2 className="tab-title">Obligaci&oacute;n Diaria de Energ&iacute;a Firme</h2>
			</div>
			<hr />
			<br />
			<div className="inherit-w">
				{noConnection && !loading ? <NoConnection /> : null}
				{loading ? <Spinner /> : null}

				{!loading && !noConnection && items.length === 0 ? (
					<NoResults />
				) : (
					<div className={Common.rowBetween()}>
						<div className={Common.colJoin(12) + ' fixed-scroll'}>
							{items.map((item, index) => {
								const headers = [item.name.split('.').join(' ')];
								const headerSizes = [12];
								return (
									<Card
										key={index}
										headerDist={[8, 4]}
										headers={headers}
										headerSizes={headerSizes}
										onDownload={() => handleDownload(item)}
										canDownload
										canDelete
									/>
								);
							})}
						</div>
					</div>
				)}
			</div>
			<br />
		</CenteredContainer>
	);
};
