import React, { Fragment } from 'react';

import { ArchController, Common, DateHelper } from '../../../utils';

import { FieldArray, Form, Formik } from 'formik';
import * as yup from 'yup';
import { DefaultField } from '../default';
import { Button, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImprovedSelectField } from '../default/select/Improved';
import { useLayerFormatter } from '../../hooks';
import ocurrences from '../../../utils/ocurrences';

const ACTIVATION_FUNCTIONS = ['relu', 'sigmoid', 'softmax', 'tanh'];
const RECURRENT_LAYERS = ['LSTM', 'GRU'];
const LAYER_TYPES = [...RECURRENT_LAYERS, 'SimpleRNN', 'Dense'];
const ALL_LAYERS = ['Dropout', ...LAYER_TYPES].sort((a, b) =>
	a.localeCompare(b, 'es-CO', { sensitivity: 'base' })
);

const SaveArchitectureForm = ({ data: record, onUpdate, onCancel, isCreation = false }) => {
	const recordLayers = useLayerFormatter(record);
	const hasRecordLayers = recordLayers?.length > 0;

	const formInitialValues = {
		name: record ? record.name : DateHelper.formatDate(new Date(), 'yyyy-dd-MM'),
		description: record ? record.description : '',
		layers: hasRecordLayers
			? recordLayers
			: [
					{
						name: '',
						neurons: '1',
						activationFunction: '',
						recurrentActivationFunction: '',
					},
					{
						name: 'Dense',
						neurons: '1',
						activationFunction: 'linear',
						recurrentActivationFunction: '',
					},
			  ],
	};

	const validationSchema = yup.object({
		name: yup
			.string('El nombre de la arquitectura es de tipo texto')
			.required('El nombre de la arquitectura es requerido'),
		description: yup.string('La descripción de la arquitectura es de tipo texto'),
		layers: yup.array().of(
			yup.object({
				name: yup.string().required('El tipo de capa es requerido.'),
				neurons: yup.number().when(['name'], (name, schema) => {
					return name === 'Dropout'
						? yup
								.number()
								.min(0, 'La probabilidad mínima es 0%')
								.max(100, 'La probabilidad máxima es 100%')
								.required('La probabilidad es requerida')
						: yup
								.number()
								.min(1, 'Una capa debe tener como mínimo una neurona')
								.required('Las neuronas de una capa son requeridas');
				}),
				activationFunction: yup.string().when(['name'], (name, schema) => {
					return LAYER_TYPES.includes(name)
						? yup.string().required('La función de activación es requerida.')
						: schema;
				}),
				recurrentActivationFunction: yup.string().when(['name'], (name, schema) => {
					return RECURRENT_LAYERS.includes(name)
						? yup.string().required('Esta capa requiere una función de activación recurrente.')
						: schema;
				}),
			})
		),
	});

	const addNewLayer = ({ arrayHelpers, values }) => {
		const newLayer = {
			name: '',
			neurons: '1',
			activationFunction: '',
			recurrentActivationFunction: '',
		};
		const lastLayer = values.layers.pop();
		arrayHelpers.push(newLayer);
		arrayHelpers.push(lastLayer);
	};

	const onFormSubmit = async (data, setSubmitting, resetForm) => {
		try {
			setSubmitting(true);

			const { name, description, layers } = data;

			const neurons_metadata = layers
				.map(({ name, neurons }) => {
					const isDropout = name === 'Dropout';
					return isDropout ? (neurons / 100).toFixed(2) : neurons;
				})
				.join('-');

			const recurrentString = layers.map((layer) => layer.recurrentActivationFunction).join('-');

			const amountOfLayersWithRecurrentFunction = layers.filter((layer) =>
				RECURRENT_LAYERS.includes(layer.name)
			).length;

			const initialRecurrentActivationMetadata = recurrentString.substring(
				0,
				recurrentString.length - amountOfLayersWithRecurrentFunction
			);
			const dashOcurrences = ocurrences(initialRecurrentActivationMetadata, '-');
			const rec_activation_metadata =
				dashOcurrences === 1
					? initialRecurrentActivationMetadata.replace('-', '')
					: initialRecurrentActivationMetadata;

			const body = {
				name,
				description,
				layers_metadata: layers.map((layer) => layer.name).join('-'),
				neurons_metadata,
				activation_metadata: layers.map((layer) => layer.activationFunction).join('-'),
				rec_activation_metadata,
			};

			const res = isCreation
				? await ArchController.create(body)
				: await ArchController.update(record.id, body);
			const msj = isCreation ? 'Arquitectura registrada!' : 'Arquitectura actualizada!';

			setSubmitting(false);

			if (res.status < 400) {
				resetForm();
				Common.fireMiniMessage(msj);
				onCancel();
				onUpdate();
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Formik
			initialValues={formInitialValues}
			validationSchema={validationSchema}
			onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
		>
			{({ values, isSubmitting }) => (
				<Form className="inherit-w">
					<FieldArray name="layers">
						{(arrayHelpers) => {
							return values.layers.length > 0 ? (
								<Fragment>
									<div className={Common.rowBetweenMiddle()}>
										<div className={Common.colJoinTop(12)}>
											<DefaultField
												name="name"
												label="Nombre de la arquitectura"
												size="small"
											/>
										</div>
									</div>

									<div className="xs-es"></div>
									<div className={Common.rowBetweenMiddle()}>
										<div className={Common.colJoinTop(12)}>
											<DefaultField
												name="description"
												label="Descripción"
												size="small"
												multiline
												rows={2}
											/>
										</div>
									</div>

									<div className={Common.rowBetweenMiddle()}>
										<div className={Common.colJoinTop(8)}>
											<h3 style={{ color: '#173DB8', marginBottom: '0px' }}>Capas</h3>
										</div>
										<div className={Common.colJoinTop(4)}>
											{isCreation && (
												<div
													className={Common.rowCenter() + ' zero clickable'}
													onClick={() => addNewLayer({ arrayHelpers, values })}
												>
													<h3
														style={{
															color: '#747474',
															marginBottom: '0px',
														}}
													>
														Agregar Capa
													</h3>
													<div
														style={{
															height: '23px',
															marginBottom: '0px',
															marginTop: 'auto',
															marginLeft: '5px',
														}}
													>
														<IconButton size="small">
															<FontAwesomeIcon icon={['fas', 'plus']} />
														</IconButton>
													</div>
												</div>
											)}
										</div>
									</div>
									<hr />
									<div className="sm-es"></div>

									{values.layers.map((layer, idx) => {
										const isLastOne = idx === values.layers.length - 1;
										const isReccurent = RECURRENT_LAYERS.includes(layer.name);
										const isNormalLayer = LAYER_TYPES.includes(layer.name);
										const isDropout = layer.name === 'Dropout';

										return (
											<div key={`arch_field_${layer.id || idx}`}>
												<div className={Common.rowMiddle() + ' zero'}>
													<div className={Common.colJoinTop(3) + ' zero-l'}>
														<ImprovedSelectField
															inputProps={{
																opts: ALL_LAYERS,
																label: `Tipo`,
																id: `layer.-${idx}.name`,
																name: `layers.${idx}.name`,
																useValuesAsArray: true,
																disabled: isLastOne || !isCreation,
															}}
														/>
													</div>
													<div
														className={
															Common.colJoinTop(isCreation ? 2 : 3) + ' zero'
														}
													>
														{isNormalLayer && (
															<DefaultField
																name={`layers.${idx}.neurons`}
																label="Neuronas"
																size="small"
																type="number"
																disabled={isLastOne || !isCreation}
															/>
														)}
														{isDropout && (
															<DefaultField
																name={`layers.${idx}.neurons`}
																label="Probabilidad"
																size="small"
																type="number"
																disabled={isLastOne || !isCreation}
															/>
														)}
													</div>

													<div className={Common.colJoinTop(3) + ' zero-r'}>
														{isNormalLayer && (
															<ImprovedSelectField
																inputProps={{
																	opts: isLastOne
																		? [ACTIVATION_FUNCTIONS, 'linear']
																		: ACTIVATION_FUNCTIONS,
																	label: `Activación`,
																	id: `layer.-${idx}.activationFunction`,
																	name: `layers.${idx}.activationFunction`,
																	useValuesAsArray: true,
																	disabled: isLastOne || !isCreation,
																}}
															/>
														)}
													</div>
													<div className={Common.colJoinTop(3) + ' zero-r'}>
														{isReccurent && (
															<ImprovedSelectField
																inputProps={{
																	opts: ACTIVATION_FUNCTIONS,
																	label: `Act. Rec.`,
																	id: `layer.-${idx}.recurrentActivationFunction`,
																	name: `layers.${idx}.recurrentActivationFunction`,
																	useValuesAsArray: true,
																	disabled: isLastOne || !isCreation,
																}}
															/>
														)}
													</div>
													<div className={Common.colJoinTop(1)}>
														{values.layers.length > 1 &&
														!isLastOne &&
														isCreation ? (
															<Tooltip title="Remover" placement="right">
																<IconButton
																	onClick={() => arrayHelpers.remove(idx)}
																>
																	<FontAwesomeIcon
																		icon={['fas', 'minus']}
																	/>
																</IconButton>
															</Tooltip>
														) : null}
													</div>
												</div>
												<div className="sm-es"></div>
											</div>
										);
									})}
								</Fragment>
							) : null;
						}}
					</FieldArray>

					<div className="md-es"></div>
					<div className={Common.rowBetweenMiddle()}>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								color="default"
								variant="contained"
								size="medium"
								className="ls-custom btn-cancel btn-rmv"
								onClick={onCancel}
								disabled={isSubmitting}
								fullWidth
								disableElevation
							>
								Cancelar
							</Button>
						</div>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								size="medium"
								className="ls-custom fl-right"
								disabled={isSubmitting}
								fullWidth
								disableElevation
							>
								{isCreation ? 'Agregar' : 'Editar'} &nbsp;
								{isSubmitting ? <CircularProgress size={20} /> : null}
							</Button>
						</div>
					</div>

					<div className="sm-es"></div>
				</Form>
			)}
		</Formik>
	);
};

export default SaveArchitectureForm;
