import React, { Fragment } from 'react';

// COMPONENTS
import { User } from './user/user';
import { Group } from './group/group';
import { Token } from './token/token';
import { Error404 } from '../../error404/error404';

// REACT ROUTER
import { Route, Redirect, Switch } from 'react-router-dom';
import { ROUTES } from '../../../../utils/routes';
import { ProtectedRolRoute } from '../../../routing';

import { Common } from '../../../../utils';

/**
 * Enrutador que se encarga de hacer el renderizado segun la ruta de navegación
 * actual. Adicionalmente para cada ruta se renderizara la barra de navegación de
 * la applicación. En caso de que el enrutador no logre discernir que vista se ha
 * de renderizar le mostrara al usuario la pantalla de 404.
 */
const AdminRouter = () => {
	return (
		<Fragment>
			<Switch>
				<Route
					exact
					path={ROUTES.CLIENT.ADMIN.BASE}
					render={() => <Redirect to={ROUTES.CLIENT.ADMIN.USERS} />}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.ADMIN.USERS}
					component={User}
					roles={Common.getAdminRights()}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.ADMIN.GROUPS}
					component={Group}
					roles={Common.getAdminRights()}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.ADMIN.TOKENS}
					component={Token}
					roles={Common.getSuperAdminRights()}
				/>
				<ProtectedRolRoute path="/" component={Error404} roles={Common.getDefaultRights()} />
			</Switch>
		</Fragment>
	);
};

export default AdminRouter;
