import { IDS, TYPES as _TYPES } from './renewable';

const RENEWABLE = {
	IDS,
	TYPES: _TYPES,
};

const TYPES = {
	TERMIC: 'TERMIC',
	HIDRAULIC: 'HIDRAULIC',
	RENEWABLE: 'RENEWABLE',
};

export { RENEWABLE, TYPES };
