import React, { Fragment, useState } from 'react';

// PAGES
import AdminRouter from './admin/admin-router';
import DemandRouter from './demand/demand-client';
import EnergyDispatchRouter from './energy-dispatch/EnergyDispatchRouter';
import { ODEF } from './odef/odef';
import { NavBar } from '../../layout/nav-bar/nav-bar';
import { Home } from './home/home';
import { Glosary } from './glosary/glosary';
import { Error404 } from '../error404/error404';

// REACT ROUTER
import { Route, Redirect, Switch } from 'react-router-dom';
import { ROUTES } from '../../../utils/routes';
import { ProtectedRolRoute } from '../../routing';

// COMPONENTS & DEPENDENCIES
import { SideBar } from '../../layout';
import { Common } from '../../../utils';

/**
 * Enrutador que se encarga de hacer el renderizado segun la ruta de navegación
 * actual. Adicionalmente para cada ruta se renderizara la barra de navegación de
 * la applicación. En caso de que el enrutador no logre discernir que vista se ha
 * de renderizar le mostrara al usuario la pantalla de 404.
 */
export const ClientRouter = () => {
	const [isSidebarOpen, setSidebarOpen] = useState(true);

	return (
		<Fragment>
			<NavBar onSidebarToggle={(value) => setSidebarOpen(value)} />
			<SideBar isOpen={isSidebarOpen} />
			<Switch>
				<Route exact path={ROUTES.CLIENT.BASE} render={() => <Redirect to={ROUTES.CLIENT.HOME} />} />
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.HOME}
					component={Home}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.GLOSARY}
					component={Glosary}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute
					path={ROUTES.CLIENT.DEMAND.BASE}
					component={DemandRouter}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.ODEF}
					component={ODEF}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute
					path={ROUTES.CLIENT.ENERGY_DISPATCH.BASE}
					component={EnergyDispatchRouter}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute
					path={ROUTES.CLIENT.ADMIN.BASE}
					component={AdminRouter}
					roles={Common.getAdminRights()}
				/>
				<ProtectedRolRoute path="/" component={Error404} roles={Common.getDefaultRights()} />
			</Switch>
		</Fragment>
	);
};
