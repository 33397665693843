import React, { useEffect, useState } from 'react';

import styles from './controls.module.scss';

import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { Button, CircularProgress, FormControl, FormHelperText } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';

import { DefaultCheckbox, DefaultDateField, DefaultField } from '../../default';
import * as Common from '../../../../utils/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AreaController, ModelController } from '../../../../utils';

import env from '../../../../env.json';
import { ImprovedSelectField } from '../../default/select/Improved';

const validationSchema = yup.object({
	start: yup.date('La fecha de inicio es un campo tipo fecha').required('La fecha de inicio es requerida'),
	end: yup.date('La fecha de fin es un campo tipo fecha').required('La fecha de fin es requerida'),
	areas: yup.array().required('Se requiere mínimo un área'),
	variable_name: yup.string().required('El nombre de la variable es requerido'),
	unit: yup.string().required('La unidad de la variable es requerida'),
});

const today = new Date();

/**
 * Método que convierte a una fecha yyyy-MM-dd hh:mm:ss en yyyy-MM-dd 00:00:00
 * @param {Date} value fecha
 */
const asMinDate = (value) => {
	let yesterday = new Date(value.getTime());
	yesterday.setHours(0);
	yesterday.setMinutes(0);
	yesterday.setSeconds(0);
	yesterday.setMilliseconds(0);
	return yesterday;
};

/**
 * Método que convierte a una fecha yyyy-MM-dd hh:mm:ss en yyyy-MM-dd 23:59:59
 * @param {Date} value fecha
 */
const asMaxDate = (value) => {
	let tomorrow = new Date(value.getTime());
	tomorrow.setHours(23);
	tomorrow.setMinutes(59);
	tomorrow.setSeconds(59);
	tomorrow.setMilliseconds(0);
	return tomorrow;
};

/**
 * API CALLS
 */
/**
 * método que se encarga de listar las areas
 */
const FetchAreas = () => {
	const [areas, setAreas] = useState([]);

	const fetch = async () => {
		try {
			const res = await AreaController.list();
			if (res.status === 200) {
				const _areas = res.data.map((a) => {
					return { id: a.id, value: a.name };
				});
				setAreas(_areas);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	let idPais = null;
	if (areas.length > 0) {
		idPais = areas.find((area) => area.value.toLowerCase() === 'pais').id;
	}

	return { areas, idPais };
};

export const ForecastControls = ({ onUpdate, onLoad, onError }) => {
	const { areas, idPais } = FetchAreas();
	const [loading, setLoading] = useState(false);

	const formInitialValues = {
		start: today,
		end: new Date(today.getTime() + 24 * 60 * 60 * 1000),
		areas: [],
		doOdef: false,
		variable_name: 'Demanda',
		unit: 'MW',
		description: '',
	};

	/**
	 * metodo para ejecturar un pronostico.
	 */
	const onFormSubmit = async (data, setSubmitting, resetForm) => {
		setLoading(true);
		onLoad();
		try {
			const lower = asMinDate(data.start);
			const upper = asMaxDate(data.end);
			console.log(data.areas);
			const areas_id = data.areas;
			const body = {
				date_start: lower.getFullYear() + '-' + (lower.getMonth() + 1) + '-' + lower.getDate(),
				date_end: upper.getFullYear() + '-' + (upper.getMonth() + 1) + '-' + upper.getDate(),
				areas: areas_id,
				variable_name: data.variable_name,
				unit: data.unit,
				description: data.description,
			};
			// console.log(body);
			const exRes = await ModelController.createExecution(body);
			// console.log(exRes);
			if (exRes.status < 400) {
				setLoading(true);
				let prediction = new WebSocket(env.REACT_APP_SOCKET_PREDICTION_URL);
				prediction.onopen = async () => {
					prediction.onmessage = async (event) => {
						console.log('[SOCKET] command processed!');
						const result = JSON.parse(event.data);
						console.log(result);
						if (result.message === 'Success') {
							console.log('---------------------------');
							const hisRes = await ModelController.getHistoryOf(exRes.data.execution_id);

							const colors = Common.getPallete(hisRes.data.data.length);

							const stats = hisRes.data.data.map((pred, index) => {
								return {
									...pred,
									color: colors[index],
									forecast: pred.forecast.map((f) => {
										return { date: new Date(f.date), value: Math.floor(f.value) };
									}),
								};
							});

							let metadata = { ...hisRes.data };
							delete metadata['data'];
							const limits = [lower.getTime(), upper.getTime()];
							console.log({ data: hisRes.data, metadata, limits });
							const _areas = hisRes.data.data.map((pred, index) => {
								return { name: pred.name, id: pred.id, color: colors[index], hidden: false };
							});

							resetForm();
							setLoading(false);
							onUpdate({
								stats,
								metadata,
								limits,
								selectedAreas: _areas,
								odefID: result.odefID || null,
							});
							prediction.close();
						}
					};

					console.log(`Needs odef: ${data.doOdef}`);
					prediction.send(
						JSON.stringify({
							command: 'predict',
							model_execution_id: exRes.data.execution_id,
							doOdef: data.doOdef,
							debug: true,
						})
					);
				};
			}
		} catch (error) {
			onError();
			console.log(error);
		}
	};

	return (
		<Formik
			initialValues={formInitialValues}
			validationSchema={validationSchema}
			onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
		>
			{({ values, isSubmitting, setFieldValue, errors }) => (
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
					<Form className="inherit-w">
						<div className={Common.rowBetweenMiddle()}>
							<div className={Common.colJoinTop(3)}>
								<DefaultDateField
									name="start"
									label="Fecha de inicio"
									size="small"
									value={values.start}
									onChange={(value) => {
										setFieldValue('start', value);
										setFieldValue('end', new Date(value.getTime() + 24 * 60 * 60 * 1000));
									}}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</div>
							<div className={Common.colJoinTop(3)}>
								<DefaultDateField
									name="end"
									label="Fecha de fin"
									size="small"
									value={values.end}
									onChange={(value) => setFieldValue('end', value)}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</div>
							<div className={Common.colJoinTop(3)}>
								<ImprovedSelectField
									inputProps={{
										opts: areas,
										label: `Áreas`,
										id: `areas-selection`,
										name: `areas`,
										useIdAsValue: true,
										multiple: true,
									}}
								/>
							</div>

							<div className={Common.colJoinTop(3)}>
								{/* Comparar, balance-scale */}
								<Button
									type="submit"
									color="primary"
									variant="contained"
									size="small"
									className={styles.btn_text + ' ls-custom'}
									disabled={loading}
									disableElevation
									fullWidth
									style={{ fontSize: '18px' }}
								>
									Pronosticar &nbsp;
									<FontAwesomeIcon icon={['fas', 'chart-bar']} />
									&nbsp;
									{loading ? <CircularProgress size={20} /> : null}
								</Button>
							</div>
						</div>
						<div className="sm-es"></div>
						<div className={Common.rowBetweenMiddle()}>
							<div className={Common.colJoinTop(3)}>
								<DefaultField
									name="variable_name"
									label="Variable"
									size="small"
									type="text"
								/>
							</div>
							<div className={Common.colJoinTop(3)}>
								<DefaultField name="unit" label="Unidad" size="small" type="text" />
							</div>
							<div className={Common.colJoinTop(3)}>
								<DefaultField
									name="description"
									label="Descripción"
									size="small"
									type="text"
								/>
							</div>

							<div className={Common.colJoinTop(3)}>
								{values.areas.includes(idPais) &&
									values.variable_name.toLowerCase() === 'demanda' && (
										<FormControl
											variant="outlined"
											size="small"
											style={{ minWidth: '100%', maxWidth: '100%' }}
											error={errors.hasOwnProperty('doOdef')}
										>
											<Field
												name="doOdef"
												type="checkbox"
												as={DefaultCheckbox}
												hasTooltip={false}
												hasLabel={true}
												label="¿Generar ODEF?"
											></Field>
											{errors.hasOwnProperty('doOdef') ? (
												<FormHelperText error>
													<span>{errors.doOdef}</span>
												</FormHelperText>
											) : null}
										</FormControl>
									)}
							</div>
						</div>
					</Form>
				</MuiPickersUtilsProvider>
			)}
		</Formik>
	);
};
