import { configureStore } from '@reduxjs/toolkit';

import marketSlice from './market-slice';
import plantSlice from './plant-slice';

const store = configureStore({
    reducer: {
        market: marketSlice.reducer,
        plant: plantSlice.reducer,
    },
});

export default store;
