import React, { useState } from 'react';

import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, CircularProgress, Tooltip } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Swal from 'sweetalert2';

import { Common, DateHelper, OptimizationExecutionController } from '../../../utils';
import { ImprovedSelectField } from '../default/select/Improved';
import { useFetchOptimizers } from '../../hooks';
import ClickUploadField from '../default/upload/ClickUploadField';

import env from '../../../env.json';
import { DefaultDateField, DefaultField } from '../default';

const validationSchema = yup.object({
	demand: yup.mixed().test('fileRequired', 'La demanda es requerida', (value) => value.length > 0),
	configuration: yup
		.mixed()
		.test('fileRequired', 'La cofiguración es requerida', (value) => value.length > 0),
	optimizer: yup.number().required('El optimizador es requerido'),
});

const acceptedMimes = [
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-excel.sheet.macroEnabled.12',
];

const TODAY = new Date();
TODAY.setHours(0);
TODAY.setMinutes(0);
TODAY.setSeconds(0);
TODAY.setMilliseconds(0);

const EnergyDispatchControls = ({ onUpdate, onLoad, onError }) => {
	const [loading, setLoading] = useState(false);
	const [demandFiles, setDemandFiles] = useState([]);
	const [configFiles, setConfigFiles] = useState([]);
	const optimizers = useFetchOptimizers();

	const formInitialValues = {
		demand: [],
		configuration: [],
		optimizer: '',
		start: TODAY,
		end: new Date(TODAY.getTime() + 24 * 60 * 60 * 1000),
		comment: '',
	};

	const doDemandValidation = async (data) => {
		const body = new FormData();
		body.append('start_period', DateHelper.formatDate(data.start, 'yyyy-MM-dd'));
		body.append('end_period', DateHelper.formatDate(data.end, 'yyyy-MM-dd'));
		if (data.demand.length > 0) body.append('demand_file', data.demand[0]);

		const res = await OptimizationExecutionController.checkDemandDates(body);
		return {
			isValid: res.status < 400,
			errorPayload: res.data,
		};
	};

	/**
	 * metodo para ejecturar un pronostico.
	 */
	const onFormSubmit = async (data, setSubmitting, resetForm) => {
		setLoading(true);
		onLoad(true);
		setSubmitting(true);
		try {
			const startDate = DateHelper.formatDate(data.start, 'yyyy-MM-dd');
			const endDate = DateHelper.formatDate(data.end, 'yyyy-MM-dd');

			console.clear();
			console.log('Start new optimization process.');
			console.log('>> CHECKING INPUT DATES');
			const { isValid, errorPayload } = await doDemandValidation(data);
			console.log({ demandFiles });
			if (!isValid) {
				const [demandFile] = demandFiles;
				const { file } = demandFile;
				setLoading(false);
				onLoad(false);
				setSubmitting(false);
				Swal.fire({
					title: '¡Error: Rango de fechas no válido!',
					html: `<div style="text-align: left;">El rango de fechas introducido<p style="display: inline-block;">(desde ${startDate} hasta ${endDate}) </p>, <b> no se encuentra incluido en el rango de fechas del archivo de demanda <i>${file.name}</i></b><p style="display: inline-block;">(desde ${errorPayload.valid_range.start_period} hasta ${errorPayload.valid_range.end_period})</p>.</div>`,
					icon: 'error',
					confirmButtonText: 'Ok',
				});
				return;
			}

			const body = new FormData();
			body.append('start_period', startDate);
			body.append('end_period', endDate);
			body.append('optimizer', data.optimizer);
			body.append('description', data.comment);
			if (data.configuration.length > 0) body.append('input_file', data.configuration[0]);
			if (data.demand.length > 0) body.append('demand_file', data.demand[0]);

			console.log('>> CREATING EXECUTION');
			const exRes = await OptimizationExecutionController.execute(body);

			if (exRes.status < 400) {
				console.log('>> >> EXECUTION CREATED!');
				console.log('>> OPENING OPTIMIZATION SOCKET');
				let optimization = new WebSocket(env.REACT_APP_SOCKET_OPTIMIZER_URL);

				optimization.onopen = async () => {
					console.log('>> >> OPTIMIZATION SOCKET HAS OPEN');
					optimization.onmessage = async (event) => {
						const socketResponse = JSON.parse(event.data);

						if (socketResponse.message === 'ERROR') {
							console.log({ socketResponse });
							onLoad(false);
							setLoading(false);
							setSubmitting(false);
							const err = socketResponse.description.error;
							Swal.fire({
								title: '¡Error: Ejecucción del optimizador con errores!',
								html: `<div style="text-align: left;"></p>Error: <b> ${err.exception}</b><br /> En el archivo: <i>${err.filename}</i>. <br /> En la l&iacute;nea #${err.line_number}:<p style="display: inline-block; padding-left: 20px; text-align: left;">${err.line}</p></div>`,
								icon: 'error',
								confirmButtonText: 'Ok',
							});
						} else if (socketResponse.message !== 'COMPLETED') {
							console.log(`>> >> >> MESSAGE RECEIVED: ${socketResponse.message}`);
						} else {
							console.log(`>> >> >> OPTIMIZATION FINISHED`);
							console.log(`>> FETCHING RESULTS`);
							const exDetailRes = await OptimizationExecutionController.detail(
								exRes.data.execution_id
							);

							console.log(`>> >> RESULTS RETRIEVED`);
							if (exDetailRes.status < 400) {
								onLoad(false);
								onUpdate(exDetailRes.data, data.comment);
								setDemandFiles([]);
								setConfigFiles([]);
								resetForm();
								setLoading(false);
								setSubmitting(false);
							}
						}
					};

					console.log('>> >> OPTIMIZATION SOCKET IS SENDING SUBSCRIPTION COMMAND');
					optimization.send(
						JSON.stringify({
							command: 'subscribe',
							id: exRes.data.execution_id,
						})
					);
				};

				optimization.onerror = async () => {
					console.log('>> >> SOCKET ERROR');
					setSubmitting(false);
					onLoad(false);
					setLoading(false);
				};
			}
		} catch (error) {
			onLoad(false);
			setLoading(false);
			setSubmitting(false);
		}
	};

	return (
		<Formik
			initialValues={formInitialValues}
			validationSchema={validationSchema}
			onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
		>
			{({ values, isSubmitting, setFieldValue, errors }) => (
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
					<Form className="inherit-w">
						<div className={Common.rowMiddle()}>
							<div className={Common.colJoinTop(3) + ' zero'}>
								<div className={Common.rowMiddle() + ' zero'}>
									<div className={Common.colJoinTop(6) + ' inherit-h'}>
										<ClickUploadField
											name="demand"
											displayText="icon:lightbulb@fas"
											tooltip="Demanda"
											files={demandFiles}
											setFiles={setDemandFiles}
											// input props
											accept={acceptedMimes}
										/>
									</div>
									<div className={Common.colJoinTop(6)}>
										<ClickUploadField
											name="configuration"
											displayText="icon:cogs@fas"
											tooltip="Configuración"
											files={configFiles}
											setFiles={setConfigFiles}
											// input props
											accept={acceptedMimes}
										/>
									</div>
								</div>
							</div>
							<div className={Common.colJoinTop(3)}>
								<DefaultDateField
									name="start"
									label="Fecha de inicio"
									size="small"
									value={values.start}
									onChange={(value) => {
										setFieldValue('start', value);
										setFieldValue('end', new Date(value.getTime() + 24 * 60 * 60 * 1000));
									}}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</div>
							<div className={Common.colJoinTop(3)}>
								<DefaultDateField
									name="end"
									label="Fecha de fin"
									size="small"
									value={values.end}
									onChange={(value) => setFieldValue('end', value)}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</div>
							<div className={Common.colJoinTop(2)}>
								<ImprovedSelectField
									inputProps={{
										opts: optimizers,
										label: 'Optimizador',
										id: 'optimizer-selection',
										name: 'optimizer',
										useIdAsValue: true,
									}}
								/>
							</div>

							<div className={Common.colJoinTop(1)}>
								{/* Comparar, balance-scale */}
								<Tooltip title="Ejecutar" placement="top">
									<Button
										disableElevation
										fullWidth
										type="submit"
										color="primary"
										variant="contained"
										disabled={loading}
										style={{
											height: '40px',
										}}
									>
										<FontAwesomeIcon icon={['fas', 'play']} />
										&nbsp;
										{loading ? <CircularProgress size={20} /> : null}
									</Button>
								</Tooltip>
							</div>
						</div>
						<div className="sm-es"></div>

						<DefaultField
							multiline
							name="comment"
							label="Comentarios o contexto"
							placeholder="Aproveche esta sección para indicar de forma detallada información sobre está ejecucción"
							size="small"
							rows={2}
						/>
					</Form>
				</MuiPickersUtilsProvider>
			)}
		</Formik>
	);
};

export default EnergyDispatchControls;
