import React from 'react';
import { Slider } from '@material-ui/core';

import SliderLabel from '../common/SliderLabel';

import classes from './StackedBarchart.module.scss';

const StackedBarchartControls = ({ chartName, sliderRange, sliderValue, onSliderChange }) => {
	const valueAsTime = sliderValue.map((date) => new Date(date).getTime());
	const rangeAsTime = sliderRange.map((date) => new Date(date).getTime());
	return (
		<>
			<p className={classes.text}>{chartName} </p>
			<div className="sm-es"></div>
			<p className={classes.text}>Filtrar por fecha: </p>
			<Slider
				value={valueAsTime}
				onChange={onSliderChange}
				min={rangeAsTime[0]}
				max={rangeAsTime[1]}
				step={3600 * 1000}
				ValueLabelComponent={SliderLabel}
			/>
		</>
	);
};

export default React.memo(StackedBarchartControls);
