import React from 'react';
import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';

export const DefaultCheckbox = ({
	tooltipConfig,
	label,
	hasTooltip = true,
	hasLabel = false,
	labelPlacement = 'start',
	...props
}) => {
	const children = hasLabel ? (
		<FormControlLabel
			control={<Checkbox color="secondary" {...props} />}
			label={label}
			labelPlacement={labelPlacement ?? 'start'}
		/>
	) : (
		<Checkbox color="primary" {...props} />
	);
	if (hasTooltip) {
		return (
			<Tooltip
				title={tooltipConfig.title}
				placement={tooltipConfig.placement}
				enterDelay={500}
				leaveDelay={400}
			>
				{children}
			</Tooltip>
		);
	} else {
		return children;
	}
};
