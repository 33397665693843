import React from 'react';
import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Common } from '../../../utils';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from '../../layout';

const keys = [
	{ id: 'rampas_aum_mod_3', value: 'Rampa aumento' },
	{ id: 'rampas_dism_mod_3', value: 'Rampa disminución' },
];

const ABConfig = ({ plant, config, onChanged }) => {
	const renderAB = (key) => {
		const ABObject = config[key.id];
		return (
			<div
				key={`plant-config-${key.id}-${ABObject.id}`}
				className={Common.rowMiddle() + ' inherit-w zero'}
			>
				<div className={Common.colJoin(3)}></div>
				<div className={Common.colJoin(3)}>
					<h4 className="m-0">{key.value}</h4>
				</div>
				<div className={Common.colJoin(1)}>
					<p className="txt-center">{ABObject.a}</p>
				</div>
				<div className={Common.colJoin(1)}>
					<p className="txt-center">{ABObject.b}</p>
				</div>
			</div>
		);
	};

	const renderKeys = () => keys.map((key) => renderAB(key));

	return (
		<StyledAccordion>
			<StyledAccordionSummary
				expandIcon={<FontAwesomeIcon icon={['fas', 'angle-down']} />}
				aria-controls="plant-availability-config-content"
				id="plant-availability-config-header"
			>
				<Typography>Variables A & B </Typography>
			</StyledAccordionSummary>
			<StyledAccordionDetails>
				<div className={Common.colJoin(12) + ' zero'}>
					<div
						key={`plant-${plant.id}-config-headers`}
						className={Common.rowMiddle() + ' inherit-w zero'}
					>
						<div className={Common.colJoin(3)}></div>
						<div className={Common.colJoin(3)}></div>
						<div className={Common.colJoin(1)}>
							<h4 className="txt-center">A</h4>
						</div>
						<div className={Common.colJoin(1)}>
							<h4 className="txt-center">B</h4>
						</div>
					</div>
					{renderKeys()}
				</div>
			</StyledAccordionDetails>
		</StyledAccordion>
	);
};

export default ABConfig;
