import React, { Fragment } from 'react';

import { CenteredContainer } from '../../../../layout';
import { Common } from '../../../../../utils';
// import { PowerBIEmbed } from 'powerbi-client-react';
// import { models } from 'powerbi-client';
// import env from '../../../../../env.json';

export const PowerBIDashboard = () => {
	return (
		<Fragment>
			<CenteredContainer colSize={10}>
				<div className={Common.rowBetweenMiddle() + ' zero inherit-w'}>
					<h2 className="tab-title">Energy Report Dashboard</h2>
				</div>
				<hr />
				<br />
				<div className="inherit-w fixed-h">
					<iframe
						title="TEBSA ENERGY REPORT"
						width="1360"
						height="720"
						style={{ marginLeft: '-200px' }}
						src="https://app.powerbi.com/reportEmbed?reportId=c95b2e27-52cc-45ae-b7b0-07f9c1e5f65b&autoAuth=true&ctid=9591fa9e-29ce-4aeb-9b12-7192e6710500"
						allowFullScreen={true}
					/>
				</div>
				<br />
			</CenteredContainer>
		</Fragment>
	);
};
