const generateServingFields = () => {
	return Array.from({ length: 24 }, (_, i) => {
		return {
			hora: i + 1,
			costo_racion: 0,
			capacidad_racion: 0,
		};
	});
};

export default generateServingFields;
