export class DateHelper {
	static getCurrentTime(offset = false) {
		const d = new Date();
		d.setHours(0);
		d.setMinutes(0);
		d.setSeconds(0);
		d.setMilliseconds(0);
		if (offset) return new Date(d.getTime() + 24 * 60 * 60 * 1000);
		return d;
	}

	static formatDate(date = new Date(), format = 'yyyy-MM-dd HH', getNext = false) {
		try {
			if (getNext) date = new Date(date.getTime() + 24 * 60 * 60 * 1000);
			const years = date.getFullYear().toString();
			const months = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = date.getDate().toString().padStart(2, '0');
			const hours = date.getHours().toString().padStart(2, '0');
			const minutes = date.getMinutes().toString().padStart(2, '0');

			return format
				.replace('yyyy', years)
				.replace('MM', months)
				.replace('dd', day)
				.replace('HH', hours)
				.replace('mm', minutes);
		} catch (error) {
			return 'Sin seleccionar';
		}
	}

	static formatStrDate(strDate, format = 'yyyy-MM-dd HH', getNext = false) {
		try {
			if (!strDate) {
				return '0001-01-01 01:01:01';
			}

			let date = this.strToDate(strDate);
			if (getNext) date = new Date(date.getTime() + 24 * 60 * 60 * 1000);
			const years = date.getFullYear().toString();
			const months = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = date.getDate().toString().padStart(2, '0');
			const hours = date.getHours().toString().padStart(2, '0');
			const minutes = date.getMinutes().toString().padStart(2, '0');

			return format
				.replace('yyyy', years)
				.replace('MM', months)
				.replace('dd', day)
				.replace('HH', hours)
				.replace('mm', minutes);
		} catch (error) {
			return 'Sin seleccionar';
		}
	}

	static d3Format(date) {
		const years = date.getFullYear().toString();
		const months = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		const hours = date.getHours().toString().padStart(2, '0');
		return `${years}-${months}-${day} ${hours}`;
	}

	static locale() {
		const months = [
			'Enero',
			'Febrero',
			'Marzo',
			'Abril',
			'Mayo',
			'Junio',
			'Julio',
			'Agosto',
			'Septiembre',
			'Octubre',
			'Noviembre',
			'Diciembre',
		];
		const days = ['Dom.', 'Lun.', 'Mar.', 'Mie.', 'Jue.', 'Vie.', 'Sab.'];

		return {
			localize: {
				month: (n) => months[n],
				day: (n) => days[n],
			},
			formatLong: {},
		};
	}

	static strToDate(input) {
		const hasZ = input.endsWith('Z');
		const asDate = hasZ ? new Date(input) : new Date(input + 'Z');
		const offset = new Date().getTimezoneOffset() * 60 * 1000;
		return new Date(asDate.getTime() + offset);
	}

	static strToDateTime(input) {
		const hasZ = input.endsWith('Z');
		const asDate = hasZ ? new Date(input) : new Date(input + 'Z');
		const offset = new Date().getTimezoneOffset() * 60 * 1000;
		return new Date(asDate.getTime() + offset);
	}
}
