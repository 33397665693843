import React from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { Field, useField } from 'formik';
import { DefaultSelectField } from './select';

export const ImprovedSelectField = React.memo(({ inputProps }) => {
	const { name, multiple = false } = inputProps;
	const [field, meta] = useField(name);
	const errorText = meta.error && meta.touched ? meta.error : '';
	const hasError = !!errorText;

	return (
		<FormControl
			variant="outlined"
			size="small"
			style={{ minWidth: '100%', maxWidth: '100%' }}
			error={hasError}
		>
			<Field
				type="text"
				component={DefaultSelectField}
				name={name}
				inputProps={inputProps}
				multiple={multiple}
				{...field}
			/>
			{hasError && <FormHelperText>{errorText}</FormHelperText>}
		</FormControl>
	);
});
