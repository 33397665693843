import React from 'react';

import styles from './backdrop.module.scss';

/**
 * Componente que se encarga de renderizar un fondo negro con opacidad de 0.5
 */
export const Backdrop = ({ show, onClick }) => {
    const scrollHeight = document.documentElement.scrollHeight;
    return show ? (
        <div className={styles.backdrop} style={{ height: `${scrollHeight}px` }} onClick={onClick}></div>
    ) : null;
};
