import React, { useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress, FormControl, FormHelperText } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';

import { Common, DatasetController, ArchController, ModelController, DateHelper } from '../../../../utils';

import { NoResults } from '../../../layout';
import { DefaultCheckbox, DefaultField, DefaultSelectField } from '../../default';
import { ImprovedSelectField } from '../../default/select/Improved';
import styles from './model-save.module.scss';

import env from '../../../../env.json';

const optimizers = ['Adadelta', 'Adagrad', 'Adam', 'Adamax', 'Ftrl', 'Nadam', 'RMSprop', 'SGD'];
const optimizer_opts = [
	{ id: 'Adadelta', value: 'Adadelta' },
	{ id: 'Adagrad', value: 'Adagrad' },
	{ id: 'Adam', value: 'Adam' },
	{ id: 'Adamax', value: 'Adamax' },
	{ id: 'Ftrl', value: 'Ftrl' },
	{ id: 'Nadam', value: 'Nadam' },
	{ id: 'RMSprop', value: 'RMSprop' },
	{ id: 'SGD', value: 'SGD' },
];
const batch_sizes = [128, 256, 512];
const batch_size_opts = [
	{ id: 128, value: 128 },
	{ id: 256, value: 256 },
	{ id: 512, value: 512 },
];
const loss_functions = [
	'mean_squared_error',
	'mean_absolute_error',
	'mean_absolute_percentage_error',
	'mean_squared_logarithmic_error',
	'log_cosh',
];
const loss_function_opts = [
	{ id: 'mean_squared_error', value: 'MSE' },
	{ id: 'mean_absolute_error', value: 'MAE' },
	{ id: 'mean_absolute_percentage_error', value: 'MAPE' },
	{ id: 'mean_squared_logarithmic_error', value: 'MSLE' },
	{ id: 'log_cosh', value: 'Log cosh' },
];
const metrics = [
	'mean_absolute_error',
	'mean_absolute_percentage_error',
	'mean_squared_error',
	'mean_squared_logarithmic_error',
	'RootMeanSquaredError',
	'CosineSimilarity',
	'LogCoshError',
];
const metrics_opts = [
	{ id: 'mean_absolute_error', value: 'MAE' },
	{ id: 'mean_absolute_percentage_error', value: 'MAPE' },
	{ id: 'mean_squared_error', value: 'MSE' },
	{ id: 'mean_squared_logarithmic_error', value: 'MSLE' },
	{ id: 'RootMeanSquaredError', value: 'RMSE' },
	{ id: 'CosineSimilarity', value: 'Cosine similarity' },
	{ id: 'LogCoshError', value: 'Log cosh error' },
];
const isInMetrics = (list) => list.every((el) => metrics.includes(el));

export const ModelSaveForm = ({ data: record, socket, onUpdate, close, isCreation = false }) => {
	const [datasets, setDatasets] = useState([]);
	const [archs, setArchs] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetch = useCallback(async () => {
		try {
			const [datasetRes, archRes] = await Promise.all([
				DatasetController.list(),
				ArchController.list(),
			]);

			if (datasetRes.status === 200) {
				setDatasets(
					datasetRes.data.map((dataset) => {
						return { id: dataset.id, value: dataset.name };
					})
				);
			}
			if (archRes.status === 200) {
				setArchs(
					archRes.data
						.filter(({ created_at }) => {
							const itemDate = DateHelper.strToDate(created_at);
							return itemDate.getTime() > DateHelper.strToDate('2022-07-07 00:00:01');
						})
						.map((arch) => {
							return { id: arch.id, value: arch.name };
						})
				);
			}
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		fetch();
	}, [fetch]);

	const formInitialValues = {
		name: record ? record.name : '',
		optimizer: record ? record.optimizer : '',
		learning_rate: record ? record.learning_rate : 0.01,
		epochs: record ? record.epochs : 80,
		batch_size: record ? record.batch_size : 256,
		loss_function: record ? record.loss_function : '',
		metrics: record ? record.metrics : [],
		dataset_id: record ? record.dataset_id : '',
		arch_id: record ? record.arch_id : '',
		description: record ? record.description : '',
		weights: [],
		architecture: [],
		dataset_val_data: record ? record.dataset_params.val_data : false,
		dataset_split_ratio: record ? record.dataset_params.val_data : 0.95,
		regressor_objective: record ? record.regressor_objective.val_data : 'reg:squarederror',
		regressor_colsample_bytree: record ? record.regressor_colsample_bytree : 0.8,
		regressor_learning_rate: record ? record.regressor_learning_rate : 0.1,
		regressor_max_depth: record ? record.regressor_max_depth : 75,
		regressor_alpha: record ? record.regressor_alpha : 1,
		regressor_n_estimators: record ? record.regressor_n_estimators : 150,
		regressor_n_jobs: record ? record.regressor_n_jobs : 4,
		regressor_verbosity: record ? record.regressor_verbosity : 2,
		regressor_random_state: record ? record.regressor_random_state : 500,
	};

	const validationSchema = yup.object({
		name: yup.string('El nombre es de tipo texto').required('El nombre es requerido'),
		optimizer: yup
			.mixed()
			.oneOf(optimizers, "El campo 'Optimizer' no se encuentra en la lista de valores permitidos")
			.required("El campo 'Optimizer' es requerido"),
		learning_rate: yup
			.number("El campo 'Learning rate' ha de ser numerico")
			.min(0.001, "El campo 'Learning rate' tiene como valor mínimo 0.001")
			.max(0.1, "El campo 'Learning rate' tiene como valor máximo 0.1")
			.required("El campo 'Learning rate' es requerido"),
		epochs: yup
			.number("El campo 'Epochs' ha de ser numerico")
			.min(1, "El campo 'Epochs' tiene como valor mínimo 1")
			.max(200, "El campo 'Epochs' tiene como valor máximo 200")
			.required("El campo 'Epochs' es requerido"),
		batch_size: yup
			.mixed()
			.oneOf(batch_sizes, "El campo 'Batch size' no se encuentra en la lista de valores permitidos")
			.required("El campo 'Batch size' es requerido"),
		loss_function: yup
			.mixed()
			.oneOf(
				loss_functions,
				"El campo 'Loss function' no se encuentra en la lista de valores permitidos"
			)
			.required("El campo 'Loss function' es requerido"),
		metrics: yup
			.array()
			.of(yup.mixed())
			.test(
				'atLeastOne',
				"El campo 'Metrics' requiere por lo menos un valor",
				(list) => list.length > 0
			)
			.test('inList', "El campo 'Metrics' no se encuentra en la lista de valores permitidos", (list) =>
				isInMetrics(list)
			),
		dataset_id: yup
			.number("El campo 'Dataset' ha de ser numerico")
			.min(1, "El campo 'Dataset' es requerido")
			.required("El campo 'Dataset' es requerido"),
		arch_id: yup
			.number("El campo 'Arquitectura' ha de ser numerico")
			.min(1, "El campo 'Arquitectura' es requerido")
			.required("El campo 'Arquitectura' es requerido"),
		description: yup.string('La descripción es de tipo texto'),
		dataset_split_ratio: yup
			.number("El campo 'Split ratio' ha de ser numerico")
			.min(0, "El campo 'Split ratio' tiene como valor mínimo 0")
			.max(1, "El campo 'Split ratio' tiene como valor máximo 1"),
		architecture: yup
			.mixed()
			.test('fileQuantity', 'La arquitectura solo pueden ser cargada desde un archivo', (value) => {
				if (value.length === 0) return true;
				return value.length === 1;
			})
			.test('fileType', 'La arquitectura deben de estar en el formato .h5', (value) => {
				if (value.length === 0) return true;
				return value[0].name.slice(-4) === 'json';
			}),
	});

	/**
	 * método para crear y entrenar un modelo.
	 */
	const onFormSubmit = async (data, setSubmitting, resetForm) => {
		setLoading(true);

		const dataset = {
			id: data.dataset_id,
			test_data: false,
			val_data: data.dataset_val_data,
			split_ratio: data.dataset_split_ratio,
		};

		const params = {
			optimizer: data.optimizer,
			learning_rate: data.learning_rate,
			epochs: data.epochs,
			batch_size: data.batch_size,
			loss_function: data.loss_function,
			metrics: data.metrics,
		};

		const model = { arch_id: data.arch_id, name: data.name, parameters: params };

		try {
			// STEP 01: CREATE MODEL
			console.log('[FORMULARY]@1 --> model/create');
			const modelCreation = await ModelController.create({ model, dataset });
			if (modelCreation.status < 400) {
				// console.log(modelCreation);
				Common.fireMiniMessage('Modelo inicializado satisfactoriamente!');

				// STEP 02: START TRAINING MODEL
				console.log('[FORMULARY]@2 --> model/traning');
				if (Common.isSocketOpen(socket)) {
					console.log('socket is open!');
					socket.onmessage = (event) => {
						console.log('[SOCKET] command processed!');
						const result = JSON.parse(event.data);
						console.log(result);
						if (result.message === 'Success') {
							Common.fireMiniMessage(
								'El modelo ha iniciado su entrenamiento! Este proceso puede tardar varias horas...'
							);
							setLoading(false);
							close();
						}
					};
					socket.send(JSON.stringify({ command: 'train', model_id: modelCreation.data.model_id }));
				} else {
					console.log('socket is not open, attempting reconnection');
					let reconnection = new WebSocket(env.REACT_APP_SOCKET_TRAINING_URL);
					reconnection.onopen = () => {
						console.log('[SOCKET] successfully connected!');
						reconnection.onmessage = (event) => {
							console.log('[SOCKET]> train: command processed!');
							const result = JSON.parse(event.data);
							console.log(result);
							if (result.message === 'Success') {
								Common.fireMiniMessage(
									'El modelo ha iniciado su entrenamiento! Este proceso puede tardar varias horas...'
								);
								setLoading(false);
								close();
							}
						};

						console.log('[SOCKET] seding train command!');
						reconnection.send(
							JSON.stringify({ command: 'train', model_id: modelCreation.data.model_id })
						);
					};
				}
			}
		} catch (error) {
			setLoading(false);
			console.error(error);
		}
	};

	if (datasets.length === 0) {
		return <NoResults resource="Datasets" />;
	}

	if (archs.length === 0) {
		return <NoResults resource="Arquitecturas" />;
	}

	return (
		<Formik
			initialValues={formInitialValues}
			validationSchema={validationSchema}
			onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
		>
			{({ isSubmitting, errors, touched, setFieldValue }) => (
				<Form className="inherit-w">
					{/* NAME, DATASET && DESCRIPTION */}
					<div className={Common.rowBetween() + ' zero'}>
						<div className={Common.colJoin(6) + ' zero-l'}>
							<DefaultField name="name" label="Nombre del modelo" size="small" />
							<div className="sm-es-desc"></div>
							{/* DATASET */}
							<ImprovedSelectField
								inputProps={{
									opts: datasets,
									label: 'Dataset',
									id: 'dataset_id-selection',
									name: 'dataset_id',
									useIdAsValue: true,
								}}
							/>
							<div className="sm-es-desc"></div>

							{/* ARCHITECTURE */}
							<ImprovedSelectField
								inputProps={{
									opts: archs,
									label: 'Arquitectura',
									id: 'arch_id-selection',
									name: 'arch_id',
									useIdAsValue: true,
								}}
							/>
							<div className="sm-es-desc"></div>
						</div>
						<div className={Common.colJoin(6) + ' zero-r'}>
							<div className={Common.rowBetween()}>
								<div className={Common.colJoinTop(7)}>
									<Field
										name="dataset_val_data"
										type="checkbox"
										as={DefaultCheckbox}
										hasLabel={true}
										label="¿Validar durante entrenamiento?"
										labelPlacement="start"
										tooltipConfig={{
											title: '¿Validar modelo durante entrenamiento?',
											placement: 'bottom',
										}}
									/>
								</div>
								<div className={Common.colJoinTop(5)}>
									<DefaultField
										name="dataset_split_ratio"
										label="Split ratio"
										size="small"
										type="number"
									/>
								</div>
							</div>

							<div className="sm-es-desc"></div>
							<div className="xs-es"></div>
							<div className="tiny-es"></div>
							{/* DESCRIPTION */}
							<DefaultField
								name="description"
								label="Descripción"
								size="small"
								multiline
								rows={3}
							/>
						</div>
					</div>
					<div className="xs-es"></div>
					<div className={Common.rowBetween() + ' zero'}>
						<div className={Common.colJoin(4) + ' zero-l'}>
							<hr className={styles.dis_hr} />
						</div>
						<div className={Common.colJoin(4)}>
							<div className={Common.rowCenterMiddle() + ' zero'}>
								<span className={styles.span_txt}>Hiper-parámetreos</span>
							</div>
						</div>
						<div className={Common.colJoin(4) + ' zero-r'}>
							<hr className={styles.dis_hr} />
						</div>
					</div>
					<div className="sm-es-desc"></div>
					<div className="sm-es-desc"></div>

					{/* LEARNING RATE && EPOCHS && BATCH SIZE && LOSS FUNCTION*/}
					<div className={Common.rowBetween() + ' zero'}>
						<div className={Common.colJoin(3) + ' zero'}>
							<DefaultField
								name="learning_rate"
								label="Learning rate"
								size="small"
								type="number"
							/>
						</div>
						<div className={Common.colJoin(3)}>
							<DefaultField name="epochs" label="Epochs" size="small" type="number" />
						</div>
						<div className={Common.colJoin(3)}>
							<FormControl
								variant="outlined"
								size="small"
								style={{ minWidth: '100%', maxWidth: '100%' }}
								error={
									touched.hasOwnProperty('batch_size') &&
									errors.hasOwnProperty('batch_size')
								}
							>
								<Field
									type="text"
									component={DefaultSelectField}
									name="batch_size"
									inputProps={{
										opts: batch_size_opts,
										label: 'Batch size',
										id: 'batch_size-selection',
										name: 'batch_size',
										useIdAsValue: true,
									}}
								></Field>
								{touched.hasOwnProperty('batch_size') &&
								errors.hasOwnProperty('batch_size') ? (
									<FormHelperText>{errors.batch_size}</FormHelperText>
								) : null}
							</FormControl>
						</div>
						<div className={Common.colJoin(3) + ' zero'}>
							<FormControl
								variant="outlined"
								size="small"
								style={{ minWidth: '100%', maxWidth: '100%' }}
								error={
									touched.hasOwnProperty('loss_function') &&
									errors.hasOwnProperty('loss_function')
								}
							>
								<Field
									type="text"
									component={DefaultSelectField}
									name="loss_function"
									inputProps={{
										opts: loss_function_opts,
										label: 'Loss function',
										id: 'loss_function-selection',
										name: 'loss_function',
										useIdAsValue: true,
									}}
								></Field>
								{touched.hasOwnProperty('loss_function') &&
								errors.hasOwnProperty('loss_function') ? (
									<FormHelperText>{errors.loss_function}</FormHelperText>
								) : null}
							</FormControl>
						</div>
					</div>
					<div className="sm-es"></div>
					{/* OPTIMIZER && METRICS */}
					<div className={Common.rowBetween() + ' zero'}>
						<div className={Common.colJoin(6) + ' zero-l'}>
							<FormControl
								variant="outlined"
								size="small"
								style={{ minWidth: '100%', maxWidth: '100%' }}
								error={
									touched.hasOwnProperty('optimizer') && errors.hasOwnProperty('optimizer')
								}
							>
								<Field
									type="text"
									component={DefaultSelectField}
									name="optimizer"
									inputProps={{
										opts: optimizer_opts,
										label: 'Optimizer',
										id: 'optimizer-selection',
										name: 'optimizer',
										useIdAsValue: true,
									}}
								></Field>
								{touched.hasOwnProperty('optimizer') && errors.hasOwnProperty('optimizer') ? (
									<FormHelperText>{errors.optimizer}</FormHelperText>
								) : null}
							</FormControl>
						</div>
						<div className={Common.colJoin(6) + ' zero-r'}>
							<FormControl
								variant="outlined"
								size="small"
								style={{ minWidth: '100%', maxWidth: '100%' }}
								error={touched.hasOwnProperty('metrics') && errors.hasOwnProperty('metrics')}
							>
								<Field
									type="text"
									component={DefaultSelectField}
									name="metrics"
									multiple={true}
									inputProps={{
										opts: metrics_opts,
										label: 'Metrics',
										id: 'metrics-selection',
										name: 'metrics',
										useIdAsValue: true,
									}}
								></Field>
								{touched.hasOwnProperty('metrics') && errors.hasOwnProperty('metrics') ? (
									<FormHelperText>{errors.metrics}</FormHelperText>
								) : null}
							</FormControl>
						</div>
					</div>

					{/* REGRESSOR PARAMS */}

					<div className="xs-es"></div>
					<div className="xs-es"></div>
					<div className={Common.rowBetween() + ' zero'}>
						<div className={Common.colJoin(4) + ' zero-l'}>
							<hr className={styles.dis_hr} />
						</div>
						<div className={Common.colJoin(4)}>
							<div className={Common.rowCenterMiddle() + ' zero'}>
								<span className={styles.span_txt}>Par&aacute;metros del regresor</span>
							</div>
						</div>
						<div className={Common.colJoin(4) + ' zero-r'}>
							<hr className={styles.dis_hr} />
						</div>
					</div>
					<div className="xs-es"></div>
					<div className="xs-es"></div>

					{/* OBJECTIVE */}
					<DefaultField name="regressor_objective" label="Objective" size="small" />
					<div className="sm-es"></div>

					{/* colsample_bytree && learning_rate && max_depth && alpha*/}
					<div className={Common.rowBetween() + ' zero'}>
						<div className={Common.colJoin(3) + ' zero'}>
							<DefaultField
								name="regressor_colsample_bytree"
								label="Col sample by tree"
								size="small"
								type="number"
							/>
						</div>
						<div className={Common.colJoin(3)}>
							<DefaultField
								name="regressor_learning_rate"
								label="Learning rate"
								size="small"
								type="number"
							/>
						</div>
						<div className={Common.colJoin(3)}>
							<DefaultField
								name="regressor_max_depth"
								label="Max depth"
								size="small"
								type="number"
							/>
						</div>
						<div className={Common.colJoin(3) + ' zero'}>
							<DefaultField name="regressor_alpha" label="Aplha" size="small" type="number" />
						</div>
					</div>
					<div className="sm-es"></div>

					{/* n_estimators && n_jobs && verbosity && random_state*/}
					<div className={Common.rowBetween() + ' zero'}>
						<div className={Common.colJoin(3) + ' zero'}>
							<DefaultField
								name="regressor_n_estimators"
								label="# Estimators"
								size="small"
								type="number"
							/>
						</div>
						<div className={Common.colJoin(3)}>
							<DefaultField name="regressor_n_jobs" label="# Jobs" size="small" type="number" />
						</div>
						<div className={Common.colJoin(3)}>
							<DefaultField
								name="regressor_verbosity"
								label="Verbosity"
								size="small"
								type="number"
							/>
						</div>
						<div className={Common.colJoin(3) + ' zero'}>
							<DefaultField
								name="regressor_random_state"
								label="Random state"
								size="small"
								type="number"
							/>
						</div>
					</div>

					<div className="xs-es"></div>
					<hr className={styles.dis_hr} />
					<div className="xs-es"></div>

					<div className={Common.rowBetweenMiddle()}>
						<div className={Common.colJoin(3)}></div>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								size="medium"
								className="ls-custom fl-right"
								disabled={loading}
								fullWidth
								disableElevation
							>
								{isCreation ? 'Entrenar' : 'Editar'} &nbsp;
								{loading ? <CircularProgress size={20} /> : null}
							</Button>
						</div>
					</div>

					<div className="sm-es"></div>
				</Form>
			)}
		</Formik>
	);
};
