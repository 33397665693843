import env from '../../../env.json';
import { api } from '../axios';

const controller = env.REACT_APP_API_VERSION + 'sync';

/**
 * Realiza una petición con el método POST a la API bajo el controlador /sync, en la
 * ruta /new con el fin de sincronizar los datos del optimizador.
 */
export const sync = (body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/new`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
