import env from '../../../../env.json';
import { SyncController } from '..';
import { api } from '../../axios';

const controller = env.REACT_APP_API_VERSION + 'planta_renovable';

/**
 * Realiza una petición con el método GET a la API bajo el controlador /planta_renovable, en la
 * ruta /list con el fin de listar las plantas de energía renovable existentes en la base de datos.
 */
export const list = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller}/list`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /planta_renovable, en la
 * ruta /create con el fin de crear una planta de energía renovable.
 * @param {Object} body Cuerpo de la petición con la planta de energía renovable a crear.
 */
export const create = (body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/create`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador
 * /planta_renovable, en la ruta /:id/modify_multiple_mercado_equiv con el fin de agregar una
 * configuración de unidades equivalentes a una planta de energía renovable.
 * @param {number} id Id de la planta de energía renovable en cuestión.
 * @param {Object} body Datos de la configuración a agregar.
 */
export const saveEquivalentUnits = (id, body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/${id}/modify_multiple_mercado_equiv`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador
 * /planta_renovable, en la ruta /add_disponibilidad con el fin de crear
 * una configuración de disponibilidad para una planta de energía renovable.
 * @param {number} id Id de la planta de energía renovable en cuestión.
 * @param {Object} body Datos de la configuración a agregar.
 */
export const addAvailability = (id, body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/${id}/add_disponibilidad`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método PUT a la API bajo el controlador /planta_renovable, en la
 * ruta /:id/update con el fin de actualizar la información de una planta de energía renovable.
 * @param {number} id id correspondiente a la planta de energía renovable a editar.
 * @param {Object} body Cuerpo de la petición con los cambios de la planta de energía renovable correspondiente.
 */
export const update = (id, body) =>
	new Promise((resolve, reject) => {
		api.put(`${controller}/${id}/update`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método PUT a la API bajo el controlador
 * /planta_renovable, en la ruta /update_disponibilidad con el fin de actualizar una
 * configuración de disponibilidad para una planta de energía renovable.
 * @param {number} id Id de la planta de energía renovable en cuestión.
 * @param {Object} body Datos de la configuración a actualizar.
 */
export const updateAvailability = (id, body) =>
	new Promise((resolve, reject) => {
		api.put(`${controller}/${id}/update_disponibilidad`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador /planta_renovable, en
 * la ruta /:id/delete con el fin de eliminar una planta de energía renovable de la base de datos de forma permanente.
 * @param {number} id id correspondiente de la planta de energía renovable a eliminar.
 */
export const remove = (id) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller}/${id}/delete`)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador
 * /planta_renovable, en la ruta /delete_disponibilidad con el fin de eliminar
 * una configuración de disponibilidad para una planta de energía renovable.
 * @param {number} id Id de la planta de energía renovable en cuestión.
 */
export const removeAvailability = (id) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller}/${id}/delete_disponibilidad`)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});
