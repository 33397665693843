import React from 'react';

import ExportExcel from 'react-export-excel';

import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Common, DateHelper } from '../../../utils';

const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelFile.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelFile.ExcelColumn;

const ChartActions = ({ chartName, xlsx, xlsxCols, onDownloadSVG, onDownloadPNG }) => {
	return (
		<div>
			<div className="md-es"></div>
			<div className="xl-es"></div>
			<div className={Common.rowBetweenMiddle()}>
				<div className={Common.colJoinTop(4)}>
					<ExcelFile
						filename={`${chartName} ${DateHelper.formatDate(new Date(), 'yyyy-MM-dd')}`}
						element={
							<Button
								color="primary"
								variant="contained"
								size="small"
								disableElevation
								fullWidth
							>
								Descargar datos &nbsp;
								<FontAwesomeIcon icon={['fas', 'file-excel']} />
							</Button>
						}
					>
						<ExcelSheet data={xlsx} name="pronostico por areas">
							{xlsxCols.map((col, index) => (
								<ExcelColumn key={index} label={col} value={col} />
							))}
						</ExcelSheet>
					</ExcelFile>
				</div>

				<div className={Common.colJoinTop(4)}>
					<Button
						color="primary"
						variant="contained"
						size="small"
						onClick={onDownloadSVG}
						disableElevation
						fullWidth
					>
						Descargar SVG &nbsp;
						<FontAwesomeIcon icon={['fas', 'file-download']} />
					</Button>
				</div>

				<div className={Common.colJoinTop(4)}>
					<Button
						color="primary"
						variant="contained"
						size="small"
						onClick={onDownloadPNG}
						disableElevation
						fullWidth
					>
						Descargar PNG &nbsp;
						<FontAwesomeIcon icon={['fas', 'file-image']} />
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ChartActions;
