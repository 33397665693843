import React, { Fragment, useState } from 'react';
import { AccordionActions, Button, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Common } from '../../utils';
import { formatWithPreviousHour } from '../../utils/pipes';
import { Modal, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from '../layout';
import { ServingConfigForm } from '../forms/constants';

const ServingView = ({ items, onChanged }) => {
	const [showModal, setShowModal] = useState(false);

	const handleEdit = () => {
		setShowModal(true);
	};

	const handleClose = () => {
		setShowModal(false);
	};

	const renderServing = () => {
		return items.map((item) => {
			const hourRange = formatWithPreviousHour(item.hora);
			return (
				<div
					key={`plant-serving-planta de racionamiento-${item.id}`}
					className={Common.rowCenterMiddle() + ' inherit-w zero'}
				>
					<div className={Common.colJoin(4)}>
						<p className="txt-center">{hourRange}</p>
					</div>
					<div className={Common.colJoin(4)}>
						<p className="txt-center">{item.costo_racion}</p>
					</div>
					<div className={Common.colJoin(4)}>
						<p className="txt-center">{item.capacidad_racion}</p>
					</div>
				</div>
			);
		});
	};

	const renderModal = () => {
		return (
			<Modal show={showModal} onClose={handleClose}>
				<ServingConfigForm data={items} onUpdate={onChanged} onCancel={handleClose} />
			</Modal>
		);
	};

	return (
		<Fragment>
			<StyledAccordion>
				<StyledAccordionSummary
					expandIcon={<FontAwesomeIcon icon={['fas', 'angle-down']} />}
					aria-controls="plant-serving-config-title"
					id="plant-serving-config-title"
				>
					<Typography>Planta de racionamiento</Typography>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					<div className={Common.colJoin(12) + ' zero'}>
						<div
							key={`plant-serving-config-headers`}
							className={Common.rowMiddle() + ' inherit-w zero'}
						>
							<div className={Common.colJoin(4)}>
								<h4 className="txt-center my-4">Hora</h4>
							</div>
							<div className={Common.colJoin(4)}>
								<h4 className="txt-center my-4">Costo</h4>
							</div>
							<div className={Common.colJoin(4)}>
								<h4 className="txt-center my-4">Capacidad</h4>
							</div>
						</div>
						{renderServing()}
					</div>
				</StyledAccordionDetails>

				<hr className="gray" />
				<AccordionActions>
					<div className={Common.rowBetween() + ' inherit-w zero'}>
						<div className={Common.colJoin(6) + ' zero'}></div>
						<div className={Common.colJoin(6) + ' zero flex-end'}>
							<div className="row zero flex-end">
								<Button
									disableElevation
									variant="contained"
									size="small"
									color="secondary"
									onClick={handleEdit}
								>
									<div className={Common.rowCenterMiddle() + ' inherit-w zero'}>
										<FontAwesomeIcon icon={['far', 'edit']} />
										&nbsp; Editar
									</div>
								</Button>
							</div>
						</div>
					</div>
				</AccordionActions>
			</StyledAccordion>

			{showModal && renderModal()}
		</Fragment>
	);
};

export default ServingView;
