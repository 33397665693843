import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Common } from '../../../utils';

/**
 * Componente que se encarga de renderizar el mensaje de que la petición que se
 * realizó falló debido a fallos de conexión o en el servidor.
 */
export const NoConnection = () => {
    return (
        <div className={Common.rowCenterMiddle() + ' inherit-w zero'}>
            <FontAwesomeIcon className="err-text" icon={['fas', 'exclamation-circle']} />
            &nbsp;
            <span className="err-text">Sin conexión con el servidor, por favor intente más tarde...</span>
            <div className="xs-es"></div>
        </div>
    );
};
