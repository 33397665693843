import React from 'react';

/**
 * Contexto de react que se usa para compartir la instancia
 * del socket a lo largo del componente de modelos.
 *
 * @deprecated este contexto se dejo usar debido a los ciclos de vida de los WebSockets.
 */
const SocketContext = React.createContext();

export default SocketContext;
