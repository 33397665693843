import { useEffect, useState } from 'react';
import { AreaController } from '../../utils';

/**
 * Método para obtener el listado de áreas.
 */
export const useFetchAreas = () => {
	const [areas, setAreas] = useState([]);

	useEffect(() => {
		const fetch = async () => {
			try {
				const res = await AreaController.list();

				if (res.status === 200) {
					const _areas = res.data.map(a => {
						return { id: a.id, value: a.name };
					});
					setAreas(_areas);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetch();
	}, []);

	return areas;
};
