import React, { Fragment, useEffect, useState } from 'react';

import styles from './dataset.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	AddButton,
	Card,
	CenteredContainer,
	DatasetComment,
	Meta,
	Modal,
	NoConnection,
	NoResults,
	Spinner,
} from '../../../../layout';
import { DatasetSaveForm, DatasetCommentSaveForm } from '../../../../forms';
import { IconButton, Tooltip } from '@material-ui/core';
import { Common, DatasetController } from '../../../../../utils';

/**
 * Componente para el renderizado del titulo de los comentarios.
 */
const commentary_title = (
	<div className={Common.rowBetween()}>
		<div className={Common.colJoinTop(5)}>
			<hr className="light" />
		</div>
		<div className={Common.colJoinTop(2)}>
			<div className={Common.rowCenter()}>
				<span className="txt-center" style={{ lineHeight: '15px', color: '#1c3545' }}>
					&laquo; &raquo;
				</span>
			</div>
		</div>
		<div className={Common.colJoinTop(5)}>
			<hr className="light" />
		</div>
	</div>
);

/**
 * Componente que se encarga del renderizado del modulo de datasets.
 */
export const Dataset = () => {
	const [datasetCreation, setDatasetCreation] = useState(false);
	const [datasetComment, setDatasetComment] = useState(false);
	const [datasetId, setDatasetId] = useState(0);
	const [datasets, setDatasets] = useState([]);
	const [loading, setLoading] = useState(true);
	const [noConnection, setNoConnection] = useState(false);

	/**
	 * Método que se encarga de consultar la lista de datasets.
	 */
	const fetch = async () => {
		try {
			setLoading(true);
			setNoConnection(false);
			const res = await DatasetController.list();
			if (res.status === 200) {
				// console.log(res.data);
				setDatasets(res.data);
			}
		} catch (error) {
			setNoConnection(true);
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	/**
	 * metodo que actualiza la informacion del modal.
	 * @param {number} datasetId id del dataset
	 * @param {boolean} showModal switch para ver el modal
	 */
	const setModalData = (datasetId, showModal) => {
		setDatasetId(datasetId);
		setDatasetComment(showModal);
	};

	return (
		<Fragment>
			<CenteredContainer colSize={10}>
				<div className={Common.rowBetweenMiddle() + ' zero inherit-w'}>
					<h2 className="tab-title">Datasets</h2>
					<a
						className={styles.doc}
						target="_blank"
						rel="noopener noreferrer"
						href="../../../../Manual de Ingeniería.pdf"
					>
						Ing. de Atributos &nbsp;
						<FontAwesomeIcon icon={['fas', 'file-download']} />
					</a>
				</div>
				<hr />
				<br />
				<div className="inherit-w">
					{noConnection && !loading ? <NoConnection /> : null}
					{loading ? <Spinner /> : null}

					{!loading && !noConnection && datasets.length === 0 ? (
						<NoResults />
					) : (
						datasets.map((dataset) => {
							const formattedName =
								dataset.name.length > 15
									? dataset.name.substring(0, 15) + '...'
									: dataset.name;
							const headers = [
								formattedName,
								dataset.user.email,
								Common.formatDate(dataset.created_at),
								dataset.current_version,
							];
							const headerSizes = [2, 5, 4, 1];
							return (
								<Card
									key={dataset.id}
									headers={headers}
									headerSizes={headerSizes}
									cardType={Common.CardType.DATASET}
									data={dataset}
									canEdit
									canRemoveFromBody
									canExpand
									onUpdate={() => fetch()}
									onRemove={() => fetch()}
									hasRights={Common.isInManager()}
								>
									<Meta title="Nombre" content={dataset.name} />
									<br />
									<p>{dataset.description}</p>
									<br />
									{dataset.comment.length > 0 ? (
										<Fragment>
											{commentary_title}
											<div className={Common.rowBetween()}>
												<div className={Common.colJoinTop(12)}>
													{dataset.comment.map((comment) => (
														<DatasetComment
															key={comment.id}
															data={comment}
															onUpdate={() => {
																setModalData(0, false);
																fetch();
															}}
															onRemove={() => {
																setModalData(0, false);
																fetch();
															}}
														/>
													))}
												</div>
											</div>
										</Fragment>
									) : null}
									<br />

									<div className={Common.rowBetween()}>
										<div></div>
										<Tooltip title="Añadir comentario" placement="left">
											<IconButton
												className="ls-custom fl-right"
												onClick={() => setModalData(dataset.id, true)}
											>
												<FontAwesomeIcon
													className="primary"
													icon={['fas', 'comment']}
												/>
											</IconButton>
										</Tooltip>
									</div>
								</Card>
							);
						})
					)}
				</div>
				<br />
				<AddButton onClick={() => setDatasetCreation(true)} />

				{Common.isInManager() && <AddButton onClick={() => setDatasetCreation(true)} />}
			</CenteredContainer>

			{datasetCreation ? (
				<Modal show={datasetCreation} onClose={() => setDatasetCreation(false)}>
					<DatasetSaveForm
						onCancel={() => setDatasetCreation(false)}
						onUpdate={() => fetch()}
						isCreation
					/>
				</Modal>
			) : null}
			{datasetComment ? (
				<Modal show={datasetComment} onClose={() => setModalData(0, false)}>
					<DatasetCommentSaveForm
						datasetId={datasetId}
						onCancel={() => setModalData(0, false)}
						onUpdate={() => fetch()}
						isCreation
					/>
				</Modal>
			) : null}
		</Fragment>
	);
};
