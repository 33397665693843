import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Common } from '../../../utils';

const MissingConfigurationWarning = ({ configurationName, onClick }) => {
	return (
		<div className={Common.rowMiddle() + ' inherit-w zero px-16'}>
			<FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
			<p>&nbsp; {configurationName}: Sin configurar &nbsp;</p>
			<Tooltip placement="right" title="Añadir configuración">
				<IconButton size="small" color="secondary" onClick={onClick}>
					<FontAwesomeIcon icon={['fas', 'plus']} />
				</IconButton>
			</Tooltip>
		</div>
	);
};

export default MissingConfigurationWarning;
