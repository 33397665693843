import React, { Fragment, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Button, Chip, CircularProgress, Typography } from '@material-ui/core';

import { Common, TermicPlantController, Methods } from '../../../utils';
import { toTitleCase, formatWithPreviousHour } from '../../../utils/pipes';
import { DefaultField } from '../default';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from '../../layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const validationSchema = yup.object({
	num_config: yup
		.number()
		.required('El número de configuración es requerido.')
		.moreThan(0, 'El número de configuración debe ser mayor a 0.'),
	fieldsModifer: yup.number().min(0, 'El valor no puede ser menor a 0.'),
	disp_data: yup.array().of(
		yup.object({
			disponibilidad: yup
				.number()
				.required('La disponbilidad es requerida.')
				.min(0, 'La disponbilidad no puede ser menor a 0.'),
		})
	),
	config_data: yup.object({
		min_tec: yup.number().required('Min. Tec es requerido.').min(0, 'Min. Tec debe ser mayor a 0.'),
		pap: yup.number().required('PAP es requerido.').min(0, 'PAP debe ser mayor a 0.'),
		ur_prima: yup.number().required('UR prima es requerido.').min(0, 'UR prima debe ser mayor a 0.'),
		dr_prima: yup.number().required('DR prima es requerido.').min(0, 'DR prima debe ser mayor a 0.'),
		ur_mod_3: yup.number().required('UR mod 3 es requerido.').min(0, 'UR mod 3 debe ser mayor a 0.'),
		dr_mod_3: yup.number().required('DR mod 3 es requerido.').min(0, 'DR mod 3 debe ser mayor a 0.'),
		rsgtmmt: yup.number().required('RSGTMMT es requerido.').min(0, 'RSGTMMT debe ser mayor a 0.'),
	}),
	arranque_caliente: yup.object({
		r1: yup.number().required('R1 es requerido.').min(0, 'R1 debe ser mayor a 0.'),
		r2: yup.number().required('R2 es requerido.').min(0, 'R2 debe ser mayor a 0.'),
		r3: yup.number().required('R3 es requerido.').min(0, 'R3 debe ser mayor a 0.'),
		r4: yup.number().required('R4 es requerido.').min(0, 'R4 debe ser mayor a 0.'),
		r5: yup.number().required('R5 es requerido.').min(0, 'R5 debe ser mayor a 0.'),
	}),
	arranque_tibio: yup.object({
		r1: yup.number().required('R1 es requerido.').min(0, 'R1 debe ser mayor a 0.'),
		r2: yup.number().required('R2 es requerido.').min(0, 'R2 debe ser mayor a 0.'),
		r3: yup.number().required('R3 es requerido.').min(0, 'R3 debe ser mayor a 0.'),
		r4: yup.number().required('R4 es requerido.').min(0, 'R4 debe ser mayor a 0.'),
		r5: yup.number().required('R5 es requerido.').min(0, 'R5 debe ser mayor a 0.'),
	}),
	arranque_frio: yup.object({
		r1: yup.number().required('R1 es requerido.').min(0, 'R1 debe ser mayor a 0.'),
		r2: yup.number().required('R2 es requerido.').min(0, 'R2 debe ser mayor a 0.'),
		r3: yup.number().required('R3 es requerido.').min(0, 'R3 debe ser mayor a 0.'),
		r4: yup.number().required('R4 es requerido.').min(0, 'R4 debe ser mayor a 0.'),
		r5: yup.number().required('R5 es requerido.').min(0, 'R5 debe ser mayor a 0.'),
	}),
	ur: yup.object({
		r1: yup.number().required('R1 es requerido.').min(0, 'R1 debe ser mayor a 0.'),
		r2: yup.number().required('R2 es requerido.').min(0, 'R2 debe ser mayor a 0.'),
		r3: yup.number().required('R3 es requerido.').min(0, 'R3 debe ser mayor a 0.'),
		r4: yup.number().required('R4 es requerido.').min(0, 'R4 debe ser mayor a 0.'),
		r5: yup.number().required('R5 es requerido.').min(0, 'R5 debe ser mayor a 0.'),
	}),
	min_ur: yup.object({
		r1: yup.number().required('R1 es requerido.').min(0, 'R1 debe ser mayor a 0.'),
		r2: yup.number().required('R2 es requerido.').min(0, 'R2 debe ser mayor a 0.'),
		r3: yup.number().required('R3 es requerido.').min(0, 'R3 debe ser mayor a 0.'),
		r4: yup.number().required('R4 es requerido.').min(0, 'R4 debe ser mayor a 0.'),
		r5: yup.number().required('R5 es requerido.').min(0, 'R5 debe ser mayor a 0.'),
	}),
	max_ur: yup.object({
		r1: yup.number().required('R1 es requerido.').min(0, 'R1 debe ser mayor a 0.'),
		r2: yup.number().required('R2 es requerido.').min(0, 'R2 debe ser mayor a 0.'),
		r3: yup.number().required('R3 es requerido.').min(0, 'R3 debe ser mayor a 0.'),
		r4: yup.number().required('R4 es requerido.').min(0, 'R4 debe ser mayor a 0.'),
		r5: yup.number().required('R5 es requerido.').min(0, 'R5 debe ser mayor a 0.'),
	}),
	dr: yup.object({
		r1: yup.number().required('R1 es requerido.').min(0, 'R1 debe ser mayor a 0.'),
		r2: yup.number().required('R2 es requerido.').min(0, 'R2 debe ser mayor a 0.'),
		r3: yup.number().required('R3 es requerido.').min(0, 'R3 debe ser mayor a 0.'),
		r4: yup.number().required('R4 es requerido.').min(0, 'R4 debe ser mayor a 0.'),
		r5: yup.number().required('R5 es requerido.').min(0, 'R5 debe ser mayor a 0.'),
	}),
	min_dr: yup.object({
		r1: yup.number().required('R1 es requerido.').min(0, 'R1 debe ser mayor a 0.'),
		r2: yup.number().required('R2 es requerido.').min(0, 'R2 debe ser mayor a 0.'),
		r3: yup.number().required('R3 es requerido.').min(0, 'R3 debe ser mayor a 0.'),
		r4: yup.number().required('R4 es requerido.').min(0, 'R4 debe ser mayor a 0.'),
		r5: yup.number().required('R5 es requerido.').min(0, 'R5 debe ser mayor a 0.'),
	}),
	max_dr: yup.object({
		r1: yup.number().required('R1 es requerido.').min(0, 'R1 debe ser mayor a 0.'),
		r2: yup.number().required('R2 es requerido.').min(0, 'R2 debe ser mayor a 0.'),
		r3: yup.number().required('R3 es requerido.').min(0, 'R3 debe ser mayor a 0.'),
		r4: yup.number().required('R4 es requerido.').min(0, 'R4 debe ser mayor a 0.'),
		r5: yup.number().required('R5 es requerido.').min(0, 'R5 debe ser mayor a 0.'),
	}),
	rampa_salida: yup.object({
		r1: yup.number().required('R1 es requerido.').min(0, 'R1 debe ser mayor a 0.'),
		r2: yup.number().required('R2 es requerido.').min(0, 'R2 debe ser mayor a 0.'),
		r3: yup.number().required('R3 es requerido.').min(0, 'R3 debe ser mayor a 0.'),
		r4: yup.number().required('R4 es requerido.').min(0, 'R4 debe ser mayor a 0.'),
		r5: yup.number().required('R5 es requerido.').min(0, 'R5 debe ser mayor a 0.'),
	}),
	rampa_aumento_modelo_3: yup.object({
		a: yup.number().required('A es requerido.').min(0, 'A debe ser mayor a 0.'),
		b: yup.number().required('B es requerido.').min(0, 'B debe ser mayor a 0.'),
	}),
	rampa_disminucion_modelo_3: yup.object({
		a: yup.number().required('A es requerido.').min(0, 'A debe ser mayor a 0.'),
		b: yup.number().required('B es requerido.').min(0, 'B debe ser mayor a 0.'),
	}),
});

const defaultConfigData = {
	min_tec: 0,
	pap: 0,
	ur_prima: 0,
	dr_prima: 0,
	ur_mod_3: 0,
	dr_mod_3: 0,
	rsgtmmt: 0,
};

const defaultRData = {
	r1: 0,
	r2: 0,
	r3: 0,
	r4: 0,
	r5: 0,
};

const defaultABData = {
	a: 0,
	b: 0,
};

const AllPurposeConfigForm = ({
	plant,
	data: record,
	isCreation = false,
	onUpdate = () => {},
	onCancel = () => {},
}) => {
	const [disableButton, setDisableButton] = useState(false);

	const formInitialValues = {
		num_config: record ? record.num_config : 0,
		fieldsModifer: 0,
		disp_data: record ? record.disp_data : Methods.generateAvailabilityFields(),
		config_data: record ? record.config_data : defaultConfigData,
		arranque_caliente: record ? record.arranque_caliente : { ...defaultRData },
		arranque_tibio: record ? record.arranque_tibio : { ...defaultRData },
		arranque_frio: record ? record.arranque_frio : { ...defaultRData },
		ur: record ? record.ur : { ...defaultRData },
		min_ur: record ? record.min_ur : { ...defaultRData },
		max_ur: record ? record.max_ur : { ...defaultRData },
		dr: record ? record.dr : { ...defaultRData },
		min_dr: record ? record.min_dr : { ...defaultRData },
		max_dr: record ? record.max_dr : { ...defaultRData },
		rampa_salida: record ? record.rampa_salida : { ...defaultRData },
		rampa_aumento_modelo_3: record ? record.rampa_aumento_modelo_3 : { ...defaultABData },
		rampa_disminucion_modelo_3: record ? record.rampa_disminucion_modelo_3 : { ...defaultABData },
	};

	const renderAvailabilityFields = (disp_data, setFieldValue, setFieldTouched) => {
		const availabilityFields = disp_data.map((availability, idx) => {
			const label = formatWithPreviousHour(availability.hora);
			return (
				<div key={`availabity-hour-${availability.hora}`}>
					<div className="sm-es"></div>
					<div className={Common.rowBetween() + ' zero'}>
						<div className={Common.colJoinTopPT4(3)}>
							<Chip variant="outlined" color="secondary" label={label} />
						</div>
						<div className={Common.colJoinTop(9)}>
							<DefaultField
								name={`disp_data.${idx}.disponibilidad`}
								label="Disponibilidad"
								size="small"
								type="number"
							/>
						</div>
					</div>
				</div>
			);
		});

		return (
			<StyledAccordion>
				<StyledAccordionSummary
					expandIcon={<FontAwesomeIcon icon={['fas', 'angle-down']} />}
					aria-controls="plant-availability-config-content"
					id="plant-availability-config-header"
				>
					<Typography>Disponibilidades </Typography>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					<div className={Common.colJoin(12) + ' zero'}>
						<div key="availability-fields-modifier-spacer" className="sm-es"></div>
						<DefaultField
							key="availability-fields-modifier"
							name="fieldsModifer"
							label="Modificar horario de disponibilidad"
							size="small"
							type="number"
							onBlurWrapper={(e, handler) => {
								setDisableButton(true);
								handler(e);

								for (let index = 0; index < 24; index++) {
									setFieldValue(`disp_data.${index}.disponibilidad`, e.target.value);
									setFieldTouched(`disp_data.${index}.disponibilidad`);
								}
								setDisableButton(false);
							}}
						/>
						{availabilityFields}
					</div>
				</StyledAccordionDetails>
			</StyledAccordion>
		);
	};

	const renderConfigFields = () => {
		return (
			<StyledAccordion>
				<StyledAccordionSummary
					expandIcon={<FontAwesomeIcon icon={['fas', 'angle-down']} />}
					aria-controls="plant-availability-config-content"
					id="plant-availability-config-header"
				>
					<Typography>Configuraci&oacute;n </Typography>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					<div className={Common.colJoin(12) + ' zero'}>
						<div className="sm-es"></div>
						<div className={Common.rowBetween() + ' zero'}>
							<div className={Common.colJoinTop(4)}>
								<DefaultField
									name="config_data.min_tec"
									label="Mínimo técnico"
									size="small"
									type="number"
								/>
							</div>
							<div className={Common.colJoinTop(4)}>
								<DefaultField name="config_data.pap" label="PAP" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(4)}>
								<DefaultField
									name="config_data.rsgtmmt"
									label="RSGTMMT"
									size="small"
									type="number"
								/>
							</div>
						</div>
						<div className="sm-es"></div>
						<div className={Common.rowBetween() + ' zero'}>
							<div className={Common.colJoinTop(6)}>
								<DefaultField
									name="config_data.ur_prima"
									label="UR Prima"
									size="small"
									type="number"
								/>
							</div>
							<div className={Common.colJoinTop(6)}>
								<DefaultField
									name="config_data.dr_prima"
									label="DR Prima"
									size="small"
									type="number"
								/>
							</div>
						</div>
						<div className="sm-es"></div>
						<div className={Common.rowBetween() + ' zero'}>
							<div className={Common.colJoinTop(6)}>
								<DefaultField
									name="config_data.ur_mod_3"
									label="UR Mod 3"
									size="small"
									type="number"
								/>
							</div>
							<div className={Common.colJoinTop(6)}>
								<DefaultField
									name="config_data.dr_mod_3"
									label="DR Mod 3"
									size="small"
									type="number"
								/>
							</div>
						</div>
					</div>
				</StyledAccordionDetails>
			</StyledAccordion>
		);
	};

	const renderIgnitionFields = () => {
		return (
			<StyledAccordion>
				<StyledAccordionSummary
					expandIcon={<FontAwesomeIcon icon={['fas', 'angle-down']} />}
					aria-controls="plant-multi-purpose-config-content"
					id="plant-multi-purpose-config-header"
				>
					<Typography>Arranque </Typography>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					<div className={Common.colJoin(12) + ' zero'}>
						<div className={Common.rowBetween() + ' zero'}>
							<h4>Caliente:</h4>
						</div>
						<div className={Common.rowBetween() + ' zero'}>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField
									name="arranque_caliente.r1"
									label="R1"
									size="small"
									type="number"
								/>
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField
									name="arranque_caliente.r2"
									label="R2"
									size="small"
									type="number"
								/>
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField
									name="arranque_caliente.r3"
									label="R3"
									size="small"
									type="number"
								/>
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField
									name="arranque_caliente.r4"
									label="R4"
									size="small"
									type="number"
								/>
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField
									name="arranque_caliente.r5"
									label="R5"
									size="small"
									type="number"
								/>
							</div>
						</div>
						<div className="sm-es"></div>
						<div className={Common.rowBetween() + ' zero'}>
							<h4>Tibio:</h4>
						</div>
						<div className={Common.rowBetween() + ' zero'}>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField
									name="arranque_tibio.r1"
									label="R1"
									size="small"
									type="number"
								/>
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField
									name="arranque_tibio.r2"
									label="R2"
									size="small"
									type="number"
								/>
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField
									name="arranque_tibio.r3"
									label="R3"
									size="small"
									type="number"
								/>
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField
									name="arranque_tibio.r4"
									label="R4"
									size="small"
									type="number"
								/>
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField
									name="arranque_tibio.r5"
									label="R5"
									size="small"
									type="number"
								/>
							</div>
						</div>
						<div className="sm-es"></div>
						<div className={Common.rowBetween() + ' zero'}>
							<h4>Fr&iacute;o:</h4>
						</div>
						<div className={Common.rowBetween() + ' zero'}>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="arranque_frio.r1" label="R1" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="arranque_frio.r2" label="R2" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="arranque_frio.r3" label="R3" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="arranque_frio.r4" label="R4" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="arranque_frio.r5" label="R5" size="small" type="number" />
							</div>
						</div>
					</div>
				</StyledAccordionDetails>
			</StyledAccordion>
		);
	};

	const renderURFields = () => {
		return (
			<StyledAccordion>
				<StyledAccordionSummary
					expandIcon={<FontAwesomeIcon icon={['fas', 'angle-down']} />}
					aria-controls="plant-multi-purpose-config-content"
					id="plant-multi-purpose-config-header"
				>
					<Typography>UR </Typography>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					<div className={Common.colJoin(12) + ' zero'}>
						<div className={Common.rowBetween() + ' zero'}>
							<h4>Normal:</h4>
						</div>
						<div className={Common.rowBetween() + ' zero'}>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="ur.r1" label="R1" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="ur.r2" label="R2" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="ur.r3" label="R3" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="ur.r4" label="R4" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="ur.r5" label="R5" size="small" type="number" />
							</div>
						</div>
						<div className="sm-es"></div>
						<div className={Common.rowBetween() + ' zero'}>
							<h4>M&iacute;nimo:</h4>
						</div>
						<div className={Common.rowBetween() + ' zero'}>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="min_ur.r1" label="R1" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="min_ur.r2" label="R2" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="min_ur.r3" label="R3" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="min_ur.r4" label="R4" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="min_ur.r5" label="R5" size="small" type="number" />
							</div>
						</div>
						<div className="sm-es"></div>
						<div className={Common.rowBetween() + ' zero'}>
							<h4>M&aacute;ximo:</h4>
						</div>
						<div className={Common.rowBetween() + ' zero'}>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="max_ur.r1" label="R1" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="max_ur.r2" label="R2" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="max_ur.r3" label="R3" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="max_ur.r4" label="R4" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="max_ur.r5" label="R5" size="small" type="number" />
							</div>
						</div>
					</div>
				</StyledAccordionDetails>
			</StyledAccordion>
		);
	};

	const renderDRFields = () => {
		return (
			<StyledAccordion>
				<StyledAccordionSummary
					expandIcon={<FontAwesomeIcon icon={['fas', 'angle-down']} />}
					aria-controls="plant-multi-purpose-config-content"
					id="plant-multi-purpose-config-header"
				>
					<Typography>DR </Typography>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					<div className={Common.colJoin(12) + ' zero'}>
						<div className={Common.rowBetween() + ' zero'}>
							<h4>Normal:</h4>
						</div>
						<div className={Common.rowBetween() + ' zero'}>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="dr.r1" label="R1" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="dr.r2" label="R2" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="dr.r3" label="R3" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="dr.r4" label="R4" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="dr.r5" label="R5" size="small" type="number" />
							</div>
						</div>
						<div className="sm-es"></div>
						<div className={Common.rowBetween() + ' zero'}>
							<h4>M&iacute;nimo:</h4>
						</div>
						<div className={Common.rowBetween() + ' zero'}>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="min_dr.r1" label="R1" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="min_dr.r2" label="R2" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="min_dr.r3" label="R3" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="min_dr.r4" label="R4" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="min_dr.r5" label="R5" size="small" type="number" />
							</div>
						</div>
						<div className="sm-es"></div>
						<div className={Common.rowBetween() + ' zero'}>
							<h4>M&aacute;ximo:</h4>
						</div>
						<div className={Common.rowBetween() + ' zero'}>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="max_dr.r1" label="R1" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="max_dr.r2" label="R2" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="max_dr.r3" label="R3" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="max_dr.r4" label="R4" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="max_dr.r5" label="R5" size="small" type="number" />
							</div>
						</div>
					</div>
				</StyledAccordionDetails>
			</StyledAccordion>
		);
	};

	const renderRampFields = () => {
		return (
			<StyledAccordion>
				<StyledAccordionSummary
					expandIcon={<FontAwesomeIcon icon={['fas', 'angle-down']} />}
					aria-controls="plant-multi-purpose-config-content"
					id="plant-multi-purpose-config-header"
				>
					<Typography>Rampa </Typography>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					<div className={Common.colJoin(12) + ' zero'}>
						<div className={Common.rowBetween() + ' zero'}>
							<h4>Salida:</h4>
						</div>
						<div className={Common.rowBetween() + ' zero'}>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="rampa_salida.r1" label="R1" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="rampa_salida.r2" label="R2" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="rampa_salida.r3" label="R3" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="rampa_salida.r4" label="R4" size="small" type="number" />
							</div>
							<div className={Common.colJoinTop(2) + ' zero'}>
								<DefaultField name="rampa_salida.r5" label="R5" size="small" type="number" />
							</div>
						</div>
						<div className="sm-es"></div>
						<div className={Common.rowBetween() + ' zero'}>
							<h4>Aumento:</h4>
						</div>
						<div className={Common.rowBetween() + ' zero'}>
							<div className={Common.colJoinTop(6) + ' no-pl'}>
								<DefaultField
									name="rampa_aumento_modelo_3.a"
									label="A"
									size="small"
									type="number"
								/>
							</div>
							<div className={Common.colJoinTop(6) + ' no-pr'}>
								<DefaultField
									name="rampa_aumento_modelo_3.b"
									label="B"
									size="small"
									type="number"
								/>
							</div>
						</div>
						<div className="sm-es"></div>
						<div className={Common.rowBetween() + ' zero'}>
							<h4>Disminuci&oacute;n:</h4>
						</div>
						<div className={Common.rowBetween() + ' zero'}>
							<div className={Common.colJoinTop(6) + ' no-pl'}>
								<DefaultField
									name="rampa_disminucion_modelo_3.a"
									label="A"
									size="small"
									type="number"
								/>
							</div>
							<div className={Common.colJoinTop(6) + ' no-pr'}>
								<DefaultField
									name="rampa_disminucion_modelo_3.b"
									label="B"
									size="small"
									type="number"
								/>
							</div>
						</div>
					</div>
				</StyledAccordionDetails>
			</StyledAccordion>
		);
	};

	const onFormSubmit = async (data, setSubmitting, resetForm) => {
		setSubmitting(true);
		try {
			let body = { ...data };
			delete body['fieldsModifer'];

			let res;
			if (isCreation) {
				res = await TermicPlantController.addConfiguration(plant.id, body);
			} else {
				const configNumber = formInitialValues.num_config;
				res = await TermicPlantController.updateConfiguration(plant.id, configNumber, body);
			}

			if (res.status < 400) {
				const action = isCreation ? 'agregado' : 'actualizado';
				Common.fireMiniMessage(
					`Se ha ${action} la configuración multipropósito de la planta ${plant.name}!`
				);
			}

			setSubmitting(false);
			resetForm();
			onUpdate();
			onCancel();
		} catch (error) {
			console.error(error);
			setSubmitting(false);
		}
	};

	return (
		<Formik
			initialValues={formInitialValues}
			validationSchema={validationSchema}
			onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
		>
			{({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
				<Form className="inherit-w">
					<div className={Common.rowMiddle() + ' zero'}>
						<h3
							style={{
								color: '#173DB8',
							}}
						>
							{toTitleCase(plant.name)}: Config. Multipropósito &nbsp;
						</h3>
					</div>
					<hr />
					<div className="sm-es"></div>
					<DefaultField
						name="num_config"
						label="Número de configuración"
						size="small"
						type="number"
						disabled={!isCreation}
					/>

					<FieldArray name="disp_data">
						{(_arrayHelpers) => (
							<Fragment>
								{renderAvailabilityFields(values.disp_data, setFieldValue, setFieldTouched)}
							</Fragment>
						)}
					</FieldArray>
					{renderConfigFields()}
					{renderIgnitionFields()}
					{renderURFields()}
					{renderDRFields()}
					{renderRampFields()}
					<div className="md-es"></div>
					<div className={Common.rowBetweenMiddle()}>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								color="default"
								variant="contained"
								size="medium"
								className="ls-custom btn-cancel btn-rmv"
								onClick={onCancel}
								disabled={isSubmitting}
								fullWidth
								disableElevation
							>
								Cancelar
							</Button>
						</div>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								size="medium"
								className="ls-custom fl-right"
								disabled={isSubmitting || disableButton}
								fullWidth
								disableElevation
							>
								{isCreation ? 'Agregar' : 'Editar'} &nbsp;
								{isSubmitting ? <CircularProgress size={20} /> : null}
							</Button>
						</div>
					</div>
					<div className="sm-es"></div>
				</Form>
			)}
		</Formik>
	);
};

export default AllPurposeConfigForm;
