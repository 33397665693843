import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SidebarLink } from './link/link';

import styles from './side-bar.module.scss';

import { LocalStoreService, NAV_BAR_ROUTES, ROUTES } from '../../../utils';
import { useMediaQuery } from '@material-ui/core';

const localStorage = new LocalStoreService();

/**
 * Componente que se encarga de renderizar el modal.
 * @param {Object} param0 props del modal
 */
export const SideBar = ({ isOpen }) => {
	const matches = useMediaQuery('(max-width:1001px)');

	const [isExpanded, setExpanded] = useState(() => false);
	// eslint-disable-next-line
	const [user, _setUser] = useState(() => localStorage.getUserInfo());
	// eslint-disable-next-line
	const [userRole, _setUserRole] = useState(() => localStorage.getUserRol());
	const [currentLink, setCurrentLink] = useState(() => '');
	const history = useHistory();

	const closedSpace = matches ? '50px' : '100px';

	const expandSpring = useSpring({
		from: {
			width: isOpen || isExpanded ? closedSpace : '350px',
			opacity: isOpen || isExpanded ? 0 : 1,
		},
		width: isOpen || isExpanded ? '350px' : closedSpace,
		opacity: isOpen || isExpanded ? 1 : 0,
	});

	function renderLinks() {
		return NAV_BAR_ROUTES.filter((link) => link.rights.includes(userRole)).map((link) => (
			<SidebarLink
				key={`sb-lnk-${link.name}`}
				current={currentLink}
				onActiveChanged={(link) => setCurrentLink(link)}
				content={link.content}
				route={link.route}
				sublinks={link.sublinks}
				role={userRole}
			/>
		));
	}

	function handleLogoutClick() {
		localStorage.removeAccessToken();
		history.push(ROUTES.AUTH.LOGIN);
	}

	function handleGlosaryClick() {
		history.push(ROUTES.CLIENT.GLOSARY);
	}

	return ReactDOM.createPortal(
		<nav
			className={styles.wrapper}
			onMouseEnter={() => setExpanded(true)}
			onMouseLeave={() => setExpanded(false)}
		>
			<animated.div className={styles.sideBar} style={expandSpring}>
				<div className={styles.user_info}>
					<span className={styles.user_heading}>{user.name}</span>
					<hr className={styles.line} />
					<span className={styles.user_subheading}>{user.email}</span>
					<span className={styles.user_subheading}>{user.role}</span>
				</div>
				<div className={styles.usefull_links}>
					<span className={styles.special_red} onClick={() => handleLogoutClick()}>
						<FontAwesomeIcon icon={['fas', 'power-off']} /> &nbsp; Cerrar sesi&oacute;n
					</span>
					<span className={styles.default} onClick={() => handleGlosaryClick()}>
						<FontAwesomeIcon icon={['fas', 'book']} /> &nbsp; Ver glosario
					</span>
				</div>

				<div className={styles.links}>{renderLinks()}</div>
			</animated.div>
		</nav>,
		document.getElementById('side-bar-portal')
	);
};
