import React, { Fragment, useEffect, useState } from 'react';
import { Common, MarketController, Methods, Schemas } from '../../utils';
import { toTitleCase } from '../../utils/pipes';
import MissingConfigurationWarning from '../layout/missing-configuration/MissingConfigurationWarning';
import { Card, Meta, Modal, NoConnection, NoResults, Spinner } from '../layout';
import { RangeConfig } from '../configuration';
import { useListRequest } from '../hooks';
import { MarketRangeSaveForm } from '../forms';

const MarketTab = ({ refresh, fetchCallback }) => {
	const { items, loading, error, fetch } = useListRequest(MarketController, fetchCallback);
	const [showModal, setShowModal] = useState(false);
	const [selectedMarket, setSelectedMarket] = useState();

	useEffect(() => {
		if (refresh) fetch();
	}, [refresh, fetch]);

	const showCreateMarketRangesModalHandler = (market) => {
		setSelectedMarket(market);
		setShowModal(true);
	};

	const renderModal = () => {
		return (
			<Modal show={showModal} onClose={() => setShowModal(false)}>
				<MarketRangeSaveForm
					isCreation
					data={selectedMarket}
					onUpdate={fetch}
					onCancel={() => setShowModal(false)}
				/>
			</Modal>
		);
	};

	const renderMarkets = () =>
		items.map((market) => {
			const titleCaseName = toTitleCase(market.name);
			const headers = [titleCaseName, market.num_mercado.toString()];
			const headerSizes = [4, 4, 2, 2];
			let configurations = [];
			let warnings = [];

			const showRangeWarning = market.rangos.length === 0;
			if (showRangeWarning) {
				warnings.push(
					<MissingConfigurationWarning
						key={`market-${market.id}-range-config`}
						configurationName="Rangos"
						onClick={() => showCreateMarketRangesModalHandler(market)}
					/>
				);
			} else {
				configurations.push(
					<RangeConfig key={`market-${market.id}-range-config`} market={market} onChanged={fetch} />
				);
			}

			if (warnings.length > 0) {
				warnings.unshift(<div key={`market-${market.id}-spacer`} className="sm-es"></div>);
			}

			return (
				<Card
					key={market.id}
					headers={headers}
					headerSizes={headerSizes}
					cardType={Common.CardType.MARKET}
					data={market}
					canEdit
					canRemove
					canExpand
					removalProps={{
						entity: 'mercado',
						prefix: 'este',
						id: market.id,
						validationSchema: Schemas.stringBasic('name', 'El nombre del mercado es requerido'),
						formInitialValues: { name: '' },
						formFields: [{ key: 'name', display: 'Nombre' }],
						validationMethod: Methods.compare(market.name),
						removeEndpoint: MarketController.remove,
						removeMessage: `El mercado (${market.name}) ha sido eliminado!`,
					}}
					onUpdate={fetch}
					onRemove={fetch}
				>
					<div className={Common.colJoin(12) + ' zero'}>
						<div key={`market-${market.id}-metadata`} className="row zero">
							<div className={Common.colJoin(4) + ' zero'}>
								<Meta title="Nombre" content={titleCaseName} />
							</div>
							<div className={Common.colJoin(4) + ' zero'}>
								<Meta title="Número del mercado" content={market.num_mercado} />
							</div>
							<div className={Common.colJoin(4) + ' zero'}>
								<Meta title="Área" content={market.area.name} />
							</div>
						</div>
						{configurations}
						{warnings}
					</div>
				</Card>
			);
		});

	return (
		<Fragment>
			{error && !loading && <NoConnection />}
			{loading && <Spinner />}

			{!loading && !error && items.length === 0 ? (
				<NoResults />
			) : (
				<div className={Common.rowBetween() + ' inherit-w'}>
					<div className={Common.colJoin(12) + ' fixed-scroll-md'}>{renderMarkets()}</div>
				</div>
			)}

			{showModal && renderModal()}
		</Fragment>
	);
};

export default MarketTab;
