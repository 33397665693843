import env from '../../../env.json';
import { api } from '../axios';

const controller = env.REACT_APP_API_VERSION + 'user';

/**
 * Realiza una petición con el método GET a la API bajo el controlador /user, en la
 * ruta /list con el fin de listar los usuarios existentes en la base de datos.
 */
export const list = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller}/list`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método PUT a la API bajo el controlador /user, en la
 * ruta /:id/update con el fin de actualizar la información de un usuario.
 * @param {number} id id correspondiente del usuario a editar.
 * @param {Object} body Cuerpo de la petición con los cambios del usuario correspondiente.
 */
export const update = (id, body) =>
	new Promise((resolve, reject) => {
		api.put(`${controller}/${id}/update`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador /user, en
 * la ruta /delete con el fin de eliminar un usuario de la base de datos de forma
 * permanente.
 * @param {number} id id correspondiente del usuario a eliminar.
 */
export const remove = (id) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller}/${id}/delete`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
