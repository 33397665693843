import React from 'react';
import { Common, ConstantController, Methods, Schemas } from '../../utils';
import { Card, Meta } from '../layout';

const ConstantList = ({ items, onItemUpdate }) => {
	return items.map((item) => {
		const upperName = item.name.toUpperCase();
		const headers = [upperName, item.value];
		const headerSizes = [6, 6];

		return (
			<Card
				key={item.id}
				headers={headers}
				headerSizes={headerSizes}
				cardType={Common.CardType.CONSTANT}
				data={item}
				canExpand
				canEdit
				canRemove
				removalProps={{
					entity: 'constante',
					prefix: 'esta',
					id: item.id,
					validationSchema: Schemas.stringBasic('name', 'El nombre de la constante es requerido'),
					formInitialValues: { name: '' },
					formFields: [{ key: 'name', display: 'Nombre' }],
					validationMethod: Methods.compare(item.name),
					removeEndpoint: ConstantController.remove,
					removeMessage: `La constante (${item.name}) ha sido eliminada!`,
				}}
				onRemove={onItemUpdate}
				onUpdate={onItemUpdate}
			>
				<div className="row zero">
					<div className={Common.colJoin(12)}>
						<Meta title="Descripción" content={item.description} />
					</div>
				</div>
			</Card>
		);
	});
};

export default ConstantList;
