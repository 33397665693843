import React, { useEffect, useState } from 'react';

import ExportExcel from 'react-export-excel';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Common, DateHelper, OptimizerHelper } from '../../../../../utils';
import { EnergyDispatchControls } from '../../../../forms';
import { CenteredContainer, Spinner } from '../../../../layout';
import { ImprovedLinechart, StackedBarchart } from '../../../../charts';

const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelFile.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelFile.ExcelColumn;

/**
 * Componente encargado de renderizar el modulo del optimizador.
 */
const ExecuteEnergyDispatch = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [description, setDescription] = useState(null);
	const [parsedMap, setParsedMap] = useState(null);
	const [XLSXDoc, setXLSXDoc] = useState(null);

	useEffect(() => {
		if (!data) return;
		let map = {};
		const keys = Object.keys(data);
		for (const key of keys) {
			const [parsedColumn, columns] = OptimizerHelper.parse(data, key);
			map[key] = {
				data: parsedColumn,
				columns: columns,
			};
		}
		setParsedMap(map);
		setLoading(false);
	}, [data]);

	const renderCharts = () => {
		if (!parsedMap) return null;
		if (!XLSXDoc) return null;

		const downloadSummary = (
			<div className={Common.rowBetweenMiddle()} key={`chart-summary`}>
				<div className={Common.colJoinTop(12)}>
					<ExcelFile
						filename={XLSXDoc.name}
						element={
							<Button
								color="secondary"
								variant="outlined"
								size="small"
								disableElevation
								fullWidth
							>
								Descargar consolidado &nbsp;
								<FontAwesomeIcon icon={['fas', 'file-excel']} />
							</Button>
						}
					>
						{XLSXDoc.sheets.map((sheet) => (
							<ExcelSheet data={sheet.rows} name={sheet.name} key={sheet.name}>
								{sheet.cols.map((col, index) => (
									<ExcelColumn key={index} label={col} value={col} />
								))}
							</ExcelSheet>
						))}
					</ExcelFile>
				</div>
			</div>
		);

		const descriptionNode = (
			<div key={`chart-description`} className="row zero">
				<div key={`chart-description-top-spacer`} className="sm-es"></div>

				<div className={Common.colJoin(12)}>
					<p className="txt">Descripción </p>
					<div key={`chart-DEMANDA-spacer`} className="xs-es"></div>
					<p>{description || 'N/A'} </p>
				</div>

				<div key={`chart-description-bottom-spacer`} className="sm-es"></div>
			</div>
		);

		const DEMANDA = parsedMap['DEMANDA'].data;
		const demandaCols = parsedMap['DEMANDA'].columns;
		const barchart = (
			<div key={`chart-DEMANDA-container`} className={Common.colJoin(12)}>
				<StackedBarchart
					key="DEMANDA"
					chartName="DEMANDA"
					x={DEMANDA.dates}
					y={demandaCols.filter((col) => col !== 'UNIDADESEQ').map((col) => DEMANDA[col])}
					barIds={demandaCols.filter((col) => col !== 'UNIDADESEQ')}
					lines={demandaCols.filter((col) => col === 'UNIDADESEQ').map((col) => DEMANDA[col])}
					lineIds={['UNIDADESEQ']}
					disableBezier
				/>
			</div>
		);

		const linecharts = Object.keys(parsedMap)
			.filter((key) => key !== 'DEMANDA')
			.map((key) => {
				return (
					<div key={`chart-${key}-container`} className={Common.colJoin(12)}>
						<div key={`chart-DEMANDA-spacer`} className="md-es"></div>
						<ImprovedLinechart
							key={key}
							chartName={key}
							x={parsedMap[key].data.dates}
							y={parsedMap[key].columns.map((col) => parsedMap[key].data[col])}
							lineIds={parsedMap[key].columns}
						/>
					</div>
				);
			});

		return [downloadSummary, descriptionNode, barchart, ...linecharts];
	};

	const buildXLSX = (data = null, description = '') => {
		if (!data) return;
		let map = {};
		const keys = Object.keys(data);
		const sheets = [];
		for (const key of keys) {
			const [parsedColumn, columns] = OptimizerHelper.parse(data, key);
			map[key] = {
				data: parsedColumn,
				columns: columns,
			};

			let sheet = {
				name: key,
				rows: [],
				cols: [],
			};

			sheet.rows = data[key].map((row) => {
				const otherCols = Object.keys(row).filter((header) => header !== 'date');
				const date = DateHelper.strToDateTime(row['date']);
				const fecha = DateHelper.formatDate(date, 'yyyy-MM-dd');
				const hora = +DateHelper.formatDate(date, 'HH') + 1;

				let t = {};
				for (const col of otherCols) {
					t[col] = row[col];
				}
				t['fecha'] = fecha;
				t['hora'] = hora;
				return t;
			});

			sheet.cols = Object.keys(sheet.rows[0]).filter((header) => !['fecha', 'hora'].includes(header));
			sheet.cols.unshift('hora');
			sheet.cols.unshift('fecha');
			sheets.push(sheet);
		}

		sheets.unshift({
			name: 'Descripción',
			rows: [{ contexto: description }],
			cols: ['contexto'],
		});

		const todayFormatted = DateHelper.formatDate(new Date(), 'yyyy-MM-dd HH mm');

		setXLSXDoc({ sheets, name: `CONSOLIDADO ${todayFormatted}` });
	};

	const onOptimizerFetch = (data, description = '') => {
		buildXLSX(data, description);
		setDescription(description);
		setData(data);
	};

	return (
		<CenteredContainer colSize={10}>
			<div className="row inherit-w zero">
				<h2 className="tab-title">Optimizar</h2>
			</div>
			<hr />
			<br />
			<div className="inherit-w">
				<div className={Common.colJoin(12) + ' zero'}>
					<div className={Common.colJoin(12)}>
						<EnergyDispatchControls onUpdate={onOptimizerFetch} onLoad={setLoading} />
						<div className="sm-es"></div>
					</div>
					<hr />

					<div key="opitimizer-pre-chart-spacer" className="sm-es"></div>
					{loading && <Spinner />}
					{renderCharts()}
					<div key="opitimizer-post-chart-spacer" className="sm-es"></div>
				</div>
			</div>
			<br />
		</CenteredContainer>
	);
};

export default ExecuteEnergyDispatch;
