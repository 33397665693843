import React from 'react';
import { Tooltip } from '@material-ui/core';

const parseValue = (value) => {
	const date = new Date(+value);
	const y = date.getFullYear();
	const M = (date.getMonth() + 1).toString().padStart(2, '0');
	const d = date.getDate().toString().padStart(2, '0');
	const h = date.getHours().toString().padStart(2, '0');
	const m = date.getMinutes().toString().padStart(2, '0');
	const s = date.getSeconds().toString().padStart(2, '0');
	return `${y}-${M}-${d} ${h}:${m}:${s}`;
};

const LinechartSliderLabel = (props) => {
	const { children, open, value } = props;

	return (
		<Tooltip open={open} enterTouchDelay={0} placement="top" title={parseValue(value)}>
			{children}
		</Tooltip>
	);
};

export default LinechartSliderLabel;
