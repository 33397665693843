import React, { Fragment } from 'react';
import { NoConnection } from '../no-connection/no-connection';
import { NoResults } from '../no-results/no-results';
import { Spinner } from '../spinner/spinner';

const RequestMeta = ({ error, loading, items, children }) => {
	const requestFailed = error && !loading;
	const noResults = !loading && !error && items.length === 0;
	const showResults = !loading && !error && items?.length > 0;
	return (
		<Fragment>
			{requestFailed && <NoConnection />}
			{loading && <Spinner />}

			{noResults && <NoResults />}
			{showResults && children}
		</Fragment>
	);
};

export default RequestMeta;
