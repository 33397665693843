import React from 'react';

import { Common, DatasetController, DatasetCommentController } from '../../../utils';

import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { DefaultField } from '../default';
import { Button, CircularProgress } from '@material-ui/core';

const validationSchema = yup.object({
    comment: yup.string('El contenido es de tipo texto').required('El contenido es requerido'),
});

export const DatasetCommentSaveForm = ({ data: record, datasetId, onUpdate, onCancel, isCreation = false }) => {
    const formInitialValues = {
        comment: record ? record.comment : '',
    };

    /**
     * Metodo para crear o editar un comentario de dataset.
     */
    const onFormSubmit = async (data, setSubmitting, resetForm) => {
        try {
            setSubmitting(true);

            const body = { ...data };

            const res = isCreation
                ? await DatasetController.comment(datasetId, body)
                : await DatasetCommentController.update(record.id, body);
            const msj = isCreation ? 'Comentario creado!' : 'Comentario actualizado!';

            setSubmitting(false);

            if (res.status < 400) {
                resetForm();
                Common.fireMiniMessage(msj);

                onCancel();
                onUpdate();
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
        >
            {({ isSubmitting }) => (
                <Form className="inherit-w">
                    <DefaultField name="comment" label="Comentario" size="small" multiline rows={4} />
                    <div className="sm-es"></div>

                    <div className="md-es"></div>
                    <div className={Common.rowBetweenMiddle()}>
                        <div className={Common.colJoin(3)} />
                        <div className={Common.colJoinLg_MdSmXs(4, 5)}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                size="medium"
                                className="ls-custom fl-right"
                                disabled={isSubmitting}
                                disableElevation
                            >
                                {isCreation ? 'Comentar' : 'Editar'} &nbsp;
                                {isSubmitting ? <CircularProgress size={20} /> : null}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
