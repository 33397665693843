import React, { Fragment, useState } from 'react';
import { Common } from '../../../../../utils';
import { LineChart } from '../../../../charts';
import { ReportControls } from '../../../../forms';
import { CenteredContainer, NoConnection, Spinner } from '../../../../layout';

/**
 * Métodos utilizados para el renderizado del GeoJson y del diagrama de torta.
 */
// const filter = (data, total) => {
//     return data.features.map((feature) => {
//         return {
//             subarea: feature.properties.DPTO_CNMBR,
//             value: +feature.properties.INTENSITY / total,
//         };
//     });
// };
// const top5 = (data) => {
//     const sorted = data.sort((a, b) => (a.value > b.value ? -1 : a.value < b.value ? 1 : 0));
//     return sorted.slice(0, 5).map((record) => record.subarea);
// };

export const Report = () => {
	const [data, setData] = useState([]);
	//const [total, setTotal] = useState([]);
	//const [wholeData, setWholeData] = useState([]);
	//const [wholeDataFiltered, setWholeDataFiltered] = useState([]);
	const [hidden, setHidden] = useState([]);
	const [filterLimits, setFilterLimits] = useState([]);
	const [areas, setAreas] = useState([]);
	const [loading, setLoading] = useState(false);
	const [noConnection, setNoConnection] = useState(false);

	/**
	 * Este metodo actualiza la informacion renderizada por el grafico de reporte.
	 * @param {number[][]} stats datos del grafico
	 * @param {number[]} limits limites del grafico
	 * @param {string[]} selectedAreas areas seleccionadas
	 */
	const fetch = async (stats, limits, selectedAreas) => {
		try {
			setAreas(selectedAreas);
			setFilterLimits(limits);
			setData(stats);
			setLoading(false);

			/**
			 * Comandos correspondientes al GeoJson y diagrama de torta.
			 */
			// const res = await GraphController.getGEOJson();
			// if (res.status === 200) {
			//     setWholeData(res.data.geojson);
			//     setTotal(res.data.total);
			//     setWholeDataFiltered(filter(res.data.geojson, res.data.total));
			// }
		} catch (error) {
			console.log(error);
		} finally {
			//setLoading(false);
		}
	};

	return (
		<CenteredContainer colSize={10}>
			<div className='row inherit-w zero'>
				<h2 className='tab-title'>Informe</h2>
			</div>
			<hr />
			<br />
			<div className='inherit-w'>
				<div className={Common.colJoin(12) + ' zero'}>
					<div className={Common.colJoin(12)}>
						<ReportControls
							onUpdate={(stats, limits, selectedAreas) =>
								fetch(stats, limits, selectedAreas)
							}
							onSlideBarChange={setFilterLimits}
							setHidden={setHidden}
							onLoad={value => setLoading(value)}
							onError={() => setNoConnection(true)}
						/>
					</div>
					<div className='sm-es'></div>
					<hr />

					{noConnection && !loading ? <NoConnection /> : null}
					{loading ? <Spinner /> : null}

					{data.length > 0 && !loading ? (
						<Fragment>
							<LineChart
								areas={areas}
								range={filterLimits}
								data={data}
								hidden={hidden}
							/>
							<div className='xl-es'></div>
							<div className='md-es'></div>

							{/* HERE GOES 'HEAT MAP' */}
							{/*<p className="tab-subtitle">Distribuci&oacute;n de la Demanada Nivel País: </p>
                            <div className="sm-es"></div>
                            <div className="row">
                                <div className={Common.colJoin(8)}>
                                    <GeoChart data={wholeData} total={total} />
                                </div>
                                <div className={Common.colJoin(4)}>
                                    <CardList list={top5(wholeDataFiltered)} />
                                    <div className="sm-es"></div>
                                    <PieChart data={wholeDataFiltered} />
                                </div>
                            </div> */}
						</Fragment>
					) : null}
				</div>
			</div>
			<br />
		</CenteredContainer>
	);
};
