import React, { Fragment, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Button, Chip, CircularProgress } from '@material-ui/core';

import { Common, ExchangeController, Methods } from '../../../utils';
import { toTitleCase, formatWithPreviousHour } from '../../../utils/pipes';
import { DefaultField } from '../default';

const validationSchema = yup.object({
	fieldsModifer: yup.number().min(0, 'El valor no puede ser menor a 0.'),
	formArray: yup.array().of(
		yup.object({
			value: yup.number().required('El valor es requerido.').min(0, 'El valor no puede ser menor a 0.'),
		})
	),
});

const ExchangeScheduleSaveForm = ({
	exchange,
	data: record,
	isCreation = false,
	onUpdate = () => {},
	onCancel = () => {},
}) => {
	const [disableButton, setDisableButton] = useState(false);

	const formInitialValues = {
		fieldsModifer: 0,
		formArray: record.length > 0 ? record : Methods.generateScheduleFields(),
	};

	const renderScheduleFields = (fields) => {
		return fields.map((field, idx) => {
			const label = formatWithPreviousHour(field.hora);
			return (
				<div key={`schedule-hour-${field.hora}`}>
					<div className="sm-es"></div>
					<div className={Common.rowBetween() + ' zero'}>
						<div className={Common.colJoinTopPT4(3)}>
							<Chip variant="outlined" color="secondary" label={label} />
						</div>
						<div className={Common.colJoinTop(9)}>
							<DefaultField
								name={`formArray.${idx}.value`}
								label="Valor"
								size="small"
								type="number"
							/>
						</div>
					</div>
					<div className="sm-es"></div>
				</div>
			);
		});
	};

	const onFormSubmit = async (data, setSubmitting, resetForm) => {
		setSubmitting(true);
		try {
			const body = data.formArray;
			const request = isCreation
				? ExchangeController.addSchedule(exchange.id, body)
				: ExchangeController.updateSchedule(exchange.id, body);
			const response = await request;

			if (response.status < 400) {
				const action = isCreation ? 'agregado' : 'actualizado';
				Common.fireMiniMessage(`Se ha ${action} el horario del intercambio ${exchange.name}!`);
			}

			setSubmitting(false);
			resetForm();
			onUpdate();
			onCancel();
		} catch (error) {
			console.error(error);
			setSubmitting(false);
		}
	};

	return (
		<Formik
			initialValues={formInitialValues}
			validationSchema={validationSchema}
			onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
		>
			{({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
				<Form className="inherit-w">
					<div className={Common.rowMiddle() + ' zero'}>
						<h3
							style={{
								color: '#173DB8',
							}}
						>
							{toTitleCase(exchange.name)}: Horario &nbsp;
						</h3>
					</div>

					<hr />
					<div className="sm-es"></div>
					<div className={Common.colJoinTop(12)}>
						<DefaultField
							name="fieldsModifer"
							label="Modificar horario"
							size="small"
							type="number"
							onBlurWrapper={(e, handler) => {
								setDisableButton(true);
								handler(e);

								for (let index = 0; index < 24; index++) {
									setFieldValue(`formArray.${index}.value`, e.target.value);
									setFieldTouched(`formArray.${index}.value`);
								}
								setDisableButton(false);
							}}
						/>
					</div>

					<FieldArray name="formArray">
						{(_arrayHelpers) => <Fragment>{renderScheduleFields(values.formArray)}</Fragment>}
					</FieldArray>

					<div className="md-es"></div>
					<div className={Common.rowBetweenMiddle()}>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								color="default"
								variant="contained"
								size="medium"
								className="ls-custom btn-cancel btn-rmv"
								onClick={onCancel}
								disabled={isSubmitting}
								fullWidth
								disableElevation
							>
								Cancelar
							</Button>
						</div>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								size="medium"
								className="ls-custom fl-right"
								disabled={isSubmitting || disableButton}
								fullWidth
								disableElevation
							>
								{isCreation ? 'Agregar' : 'Editar'} &nbsp;
								{isSubmitting ? <CircularProgress size={20} /> : null}
							</Button>
						</div>
					</div>

					<div className="sm-es"></div>
				</Form>
			)}
		</Formik>
	);
};

export default ExchangeScheduleSaveForm;
