import React from 'react';
import { Common, Methods, Schemas, SubareaController } from '../../utils';
import { Card } from '../layout';

const SubareaList = ({ items, selected, onItemUpdate, onSelected }) => {
	return items?.map((item) => {
		const { name, is_great_consumer } = item;
		const headers = [name, is_great_consumer ? 'Gran consumidor' : ''];
		const headerSizes = [6, 6];

		return (
			<Card
				key={`area_${item.id}`}
				isHighlighted={selected?.id === item.id}
				headers={headers}
				headerSizes={headerSizes}
				cardType={Common.CardType.SUBAREA}
				data={item}
				canEdit
				canRemove
				onEdit={onSelected}
				onUpdate={onItemUpdate}
				onRemove={onItemUpdate}
				removalProps={{
					entity: 'subárea',
					prefix: 'esta',
					id: item.id,
					validationSchema: Schemas.stringBasic('name', 'El nombre de la subárea es requerido'),
					formInitialValues: { name: '' },
					formFields: [{ key: 'name', display: 'Nombre' }],
					validationMethod: Methods.compare(item.name),
					removeEndpoint: SubareaController.remove,
					removeMessage: `La subárea (${item.name}) ha sido eliminada!`,
				}}
			/>
		);
	});
};

export default SubareaList;
