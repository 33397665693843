import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { Common } from '../../../utils';

/**
 * Componente que se encarga de renderizar el spinner de carga de datos cuando
 * una petición esta a la espera de respuesta.
 */
export const Spinner = () => {
    return (
        <div className={Common.rowCenterMiddle() + ' inherit-w zero'}>
            <div className={Common.colJoin(12)}>
                <br />
                <CircularProgress color="secondary" />
                <div className="xs-es"></div>
                <span className="spinner-text">Cargando</span>
            </div>
        </div>
    );
};
