import React from 'react';

import styles from './centered-container.module.scss';
import { animated, useSpring } from 'react-spring';

/**
 * Componente que se encarga de generar un contenedor centrado con respecto al viewport.
 * @param {Object} param0 Props de Contenedor centrado.
 */
export const CenteredContainer = ({ colSize = 6, lgSize = 6, deltaY = 40, className, children }) => {
	const spring = useSpring({
		from: { transform: `translateY(${deltaY}%)` },
		transform: 'translateY(0px)',
	});

	return (
		<animated.div className={styles.center} style={{ ...spring }}>
			<div className={'row  ' + styles.center + ' zero'}>
				<div className={`${styles.center} zero ${styles.container} ${className}`}>{children}</div>
			</div>
		</animated.div>
	);
};
