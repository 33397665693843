import { Common } from '..';
import { HidraulicPlantController, RenewablePlantController, TermicPlantController } from '../api';

const [RENEWABLE, HIDRAULIC, TERMIC] = Common.PLANT_TYPES;

const plantTypeToController = (plantType) => {
	switch (plantType) {
		case RENEWABLE:
			return RenewablePlantController;

		case HIDRAULIC:
			return HidraulicPlantController;

		case TERMIC:
			return TermicPlantController;

		default:
			return undefined;
	}
};

export default plantTypeToController;
