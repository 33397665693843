import React, { Fragment, useEffect, useState } from 'react';

import styles from './controls.module.scss';

import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { Button, CircularProgress, FormControl, FormHelperText, Tooltip } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';

import { DefaultDateField, DefaultSelectField } from '../../default';
import * as Common from '../../../../utils/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatasetController, ModelController } from '../../../../utils';

import Swal from 'sweetalert2';

const validationSchema = yup.object({
    start: yup.date('La fecha de inicio es un campo tipo fecha').required('La fecha de inicio es requerida'),
    end: yup.date('La fecha de fin es un campo tipo fecha').required('La fecha de fin es requerida'),
    dataset: yup
        .number('El dataset es un campo númerico')
        .min(1, "El valor minimo para el dataset es '1'")
        .required('El dataset es requerido'),
    execution: yup
        .number('La ejecución es un campo númerico')
        .min(1, "El valor minimo para la ejecución es '1'")
        .required('La ejecución es requerida'),
});

const today = new Date();

/**
 * Método que convierte a una fecha yyyy-MM-dd hh:mm:ss en yyyy-MM-dd 00:00:00
 * @param {Date} value fecha
 */
const asMinDate = (value) => {
    let yesterday = new Date(value.getTime());
    yesterday.setHours(0);
    yesterday.setMinutes(0);
    yesterday.setSeconds(0);
    yesterday.setMilliseconds(0);
    return yesterday;
};

/**
 * Método que convierte a una fecha yyyy-MM-dd hh:mm:ss en yyyy-MM-dd 23:59:59
 * @param {Date} value fecha
 */
const asMaxDate = (value) => {
    let tomorrow = new Date(value.getTime());
    tomorrow.setHours(23);
    tomorrow.setMinutes(59);
    tomorrow.setSeconds(59);
    tomorrow.setMilliseconds(0);
    return tomorrow;
};

/**
 * API CALLS
 */
/**
 * Método que lista todos los datasets.
 */
const FetchDatasets = () => {
    const [datasets, setDatasets] = useState([]);

    const fetch = async () => {
        try {
            const res = await DatasetController.list_all();
            if (res.status === 200) {
                const datasetsFromRes = res.data.map((a) => {
                    return { id: a.id, value: a.name };
                });
                setDatasets(datasetsFromRes);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    return datasets;
};

/**
 * Metodo que lista los pronosticos ejecutados previamente.
 */
const FetchExecutions = () => {
    const [executions, setExecutions] = useState([]);

    const fetch = async () => {
        try {
            const res = await ModelController.getHistory();
            if (res.status === 200) {
                const executionsFromRes = res.data.map((a) => {
                    return { id: a.id, value: a.name };
                });
                setExecutions(executionsFromRes);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    return executions;
};

/**
 * Método que le da el formato a una fecha como: yyyy-MM-dd
 * @param {Date} date fecha
 */
const formatDate_YYY_MM_DD = (date) => {
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const d = date.getDate();
    return `${y}-${m.toString().padStart(2, '0')}-${d.toString().padStart(2, '0')}`;
};

export const ReportControls = ({ onUpdate, onLoad, onError }) => {
    const datasets = FetchDatasets();
    const executions = FetchExecutions();
    const [loading, setLoading] = useState(false);

    const formInitialValues = {
        start: today,
        end: new Date(today.getTime() + 24 * 60 * 60 * 1000),
        dataset: '',
        execution: '',
    };

    /**
     * Método que se encarga de conseguir los datos del pronostico y el historico para realizar el grafico de linea.
     */
    const onFormSubmit = async (data, setSubmitting, resetForm) => {
        setLoading(true);
        onLoad(true);
        // console.log(data);
        const hisRes = await ModelController.getHistoryOf(data.execution);
        // console.log(hisRes);
        if (hisRes.status < 400) {
            const lower = asMinDate(data.start);
            const upper = asMaxDate(data.end);
            const body = {
                start_date: formatDate_YYY_MM_DD(lower),
                end_date: formatDate_YYY_MM_DD(upper),
                areas: hisRes.data.data.map((r) => r.name),
            };
            const dataRes = await DatasetController.getDatasetHistoryByAreas(data.dataset, body);

            if (dataRes.status < 400) {
                const formatHistory = hisRes.data.data.map((el) => {
                    return { ...el, id: `h-${el.id}`, name: `h-${el.name}` };
                });

                const formatDataset = dataRes.data.data.map((el) => {
                    return { ...el, id: `d-${el.id}`, name: `d-${el.name}` };
                });

                const historySize = formatHistory[0].forecast.length;
                const sizesMatch = formatDataset.every((area) => area.forecast.length === historySize);
                if (!sizesMatch) {
                    setLoading(false);
                    onLoad(false);
                    const sizeData = formatDataset[0].forecast.length;
                    Swal.fire({
                        title: 'Error!',
                        text: `El histórico (${historySize}) y el dataset (${sizeData}) no cuentan con la misma cantidad de registros...`,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    });
                    return;
                }

                const formatted = [...formatHistory, ...formatDataset];
                console.log('---- formatting ----');
                // console.log(formatted);

                const colors = Common.getPallete(formatted.length);
                const stats = formatted.map((pred, index) => {
                    return {
                        ...pred,
                        color: colors[index],
                        forecast: pred.forecast.map((f) => {
                            return { date: new Date(f.date), value: Math.floor(f.value) };
                        }),
                    };
                });

                const _areas = formatted.map((pred, index) => {
                    return { name: pred.name, id: pred.id, color: colors[index], hidden: false };
                });

                resetForm();
                setLoading(false);
                onUpdate(stats, [lower.getTime(), upper.getTime()], _areas);
            }
        }
    };

    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
        >
            {({ touched, values, errors, isSubmitting, setFieldValue }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <Form className="inherit-w">
                        <div className={Common.rowBetweenMiddle()}>
                            <div className={Common.colJoinTop(5)}>
                                <div className={Common.rowBetweenMiddle()}>
                                    <div className={Common.colJoinTop(12)}>
                                        <DefaultDateField
                                            name="start"
                                            label="Fecha de inicio"
                                            size="small"
                                            value={values.start}
                                            onChange={(value) => {
                                                setFieldValue('start', value);
                                                setFieldValue('end', new Date(value.getTime() + 24 * 60 * 60 * 1000));
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="xs-es"></div>
                                <div className={Common.rowBetweenMiddle()}>
                                    <div className={Common.colJoinTop(12)}>
                                        <FormControl
                                            variant="outlined"
                                            size="small"
                                            style={{ minWidth: '100%', maxWidth: '100%' }}
                                            error={
                                                touched.hasOwnProperty('dataset') && errors.hasOwnProperty('dataset')
                                            }
                                        >
                                            <Field
                                                type="text"
                                                component={DefaultSelectField}
                                                name="dataset"
                                                inputProps={{
                                                    opts: datasets,
                                                    useIdAsValue: true,
                                                    label: 'Dataset',
                                                    id: 'dataset-selection',
                                                    name: 'dataset',
                                                }}
                                            ></Field>
                                            {touched.hasOwnProperty('dataset') && errors.hasOwnProperty('dataset') ? (
                                                <FormHelperText error>
                                                    <span>{errors.dataset}</span>
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className={Common.colJoinTop(5)}>
                                <div className={Common.rowBetweenMiddle()}>
                                    <div className={Common.colJoinTop(12)}>
                                        <DefaultDateField
                                            name="end"
                                            label="Fecha de fin"
                                            size="small"
                                            value={values.end}
                                            onChange={(value) => setFieldValue('end', value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="xs-es"></div>
                                <div className={Common.rowBetweenMiddle()}>
                                    <div className={Common.colJoinTop(12)}>
                                        <FormControl
                                            variant="outlined"
                                            size="small"
                                            style={{ minWidth: '100%', maxWidth: '100%' }}
                                            error={
                                                touched.hasOwnProperty('execution') &&
                                                errors.hasOwnProperty('execution')
                                            }
                                        >
                                            <Field
                                                type="text"
                                                component={DefaultSelectField}
                                                name="execution"
                                                inputProps={{
                                                    opts: executions,
                                                    useIdAsValue: true,
                                                    label: 'Ejecución',
                                                    id: 'execution-selection',
                                                    name: 'execution',
                                                }}
                                            ></Field>
                                            {touched.hasOwnProperty('execution') &&
                                            errors.hasOwnProperty('execution') ? (
                                                <FormHelperText error>
                                                    <span>{errors.execution}</span>
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>
                                    </div>
                                </div>
                            </div>

                            <div className={Common.colJoinTop(2)}>
                                <div className={Common.rowBetweenMiddle()}>
                                    <div className={Common.colJoinTop(8)}>
                                        <Tooltip title="Comparar" placement="right" arrow>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                className={styles.btn_icon + ' ls-custom'}
                                                disabled={loading}
                                                disableElevation
                                                fullWidth
                                            >
                                                <FontAwesomeIcon icon={['fas', 'balance-scale']} />
                                                {loading ? (
                                                    <Fragment>
                                                        &nbsp; <CircularProgress size={20} />
                                                    </Fragment>
                                                ) : null}
                                            </Button>
                                        </Tooltip>
                                    </div>
                                    <div className={Common.colJoinTop(2)}></div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </MuiPickersUtilsProvider>
            )}
        </Formik>
    );
};
