import React, { useCallback, useState } from 'react';
import { AppBar, Tab } from '@material-ui/core';

import { Common, DATA } from '../../../../../utils';
import { AddButton, CenteredContainer, Modal, StyledTabPanel } from '../../../../layout';
import { ExchangeSaveForm, MarketSaveForm } from '../../../../forms';
import { TabContext, TabList } from '@material-ui/lab';
import MarketTab from '../../../../market/MarketTab';
import ExchangeTab from '../../../../market/ExchangeTab';

/**
 * Componente encargado de renderizar el modulo de mercados de energia.
 */
const Market = () => {
	const [tabValue, setTabValue] = useState(DATA.MARKET.TABS.MARKETS);
	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState(DATA.MARKET.TABS.MARKETS);
	const [refresh, setRefresh] = useState(false);

	const tabHandler = (_e, val) => {
		setTabValue(val);
		setModalContent(val);
	};

	const showCreateMarketModalHandler = () => {
		setShowModal(true);
	};

	const itemsFetched = useCallback(() => {
		setRefresh(false);
	}, []);

	const itemSavedHandler = useCallback(() => {
		setRefresh(true);
	}, []);

	const getModalContent = () => {
		switch (modalContent) {
			case DATA.MARKET.TABS.MARKETS:
				return (
					<MarketSaveForm
						isCreation
						onUpdate={itemSavedHandler}
						onCancel={() => setShowModal(false)}
					/>
				);

			case DATA.MARKET.TABS.EXCHANGES:
				return (
					<ExchangeSaveForm
						isCreation
						onUpdate={itemSavedHandler}
						onCancel={() => setShowModal(false)}
					/>
				);

			default:
				return null;
		}
	};

	const renderModal = () => {
		const content = getModalContent();

		return (
			<Modal show={showModal} onClose={() => setShowModal(false)}>
				{content}
			</Modal>
		);
	};

	return (
		<>
			<CenteredContainer colSize={10}>
				<div className="row inherit-w zero">
					<h2 className="tab-title">Mercados e Intercambios</h2>
				</div>
				<hr className="m-0" />
				<TabContext value={tabValue}>
					<div className="row inherit-w zero">
						<AppBar
							position="static"
							elevation={0}
							style={{ background: '#fafafa', color: '#1c3545' }}
						>
							<TabList onChange={tabHandler}>
								<Tab label="Mercados" value={DATA.MARKET.TABS.MARKETS} />
								<Tab label="Intercambios" value={DATA.MARKET.TABS.EXCHANGES} />
							</TabList>
						</AppBar>
					</div>
					<br />
					<div className="row inherit-w zero">
						<StyledTabPanel value={DATA.MARKET.TABS.MARKETS} className="inherit-w">
							<MarketTab refresh={refresh} fetchCallback={itemsFetched} />
						</StyledTabPanel>
						<StyledTabPanel value={DATA.MARKET.TABS.EXCHANGES} className="inherit-w">
							<ExchangeTab refresh={refresh} fetchCallback={itemsFetched} />
						</StyledTabPanel>
					</div>
				</TabContext>

				{Common.isInAdmin() && <AddButton onClick={showCreateMarketModalHandler} />}
			</CenteredContainer>

			{showModal && renderModal()}
		</>
	);
};

export default Market;
