import React, { Fragment, useState } from 'react';

import logo from '../../../../assets/logo.svg';
import * as Common from '../../../../utils/common.js';

import styles from './login.module.scss';

import { CenteredContainer } from '../../../layout/centered-container/centered-container.jsx';

import { Modal } from '../../../layout';
import { LoginForm, ResetForm } from '../../../forms';
import env from '../../../../env.json';

/**
 * Componente de inicio de sesión.
 */
export const Login = () => {
    const [showPWDRecovery, setShowPWDRecovery] = useState(false);

    return (
        <Fragment>
            <div className="xl-es"></div>
            <CenteredContainer colSize={6} deltaY={200}>
                <div className={Common.rowCenterMiddle() + ' inherit-w zero'}>
                    <img src={logo} className="App-logo" alt="logo" />
                    &nbsp; &nbsp;
                    <h1 className={styles.App_Title}>{env.REACT_APP_APP_NAME}</h1>
                </div>
                <br />
                <br />
                <br />
                <div className={Common.rowCenterMiddle() + ' inherit-w zero'}>
                    <div className={Common.colJoinLg_MdSmXs(6, 12)}>
                        <LoginForm recovery={setShowPWDRecovery} />
                    </div>
                </div>
            </CenteredContainer>
            <Modal show={showPWDRecovery} onClose={() => setShowPWDRecovery(false)}>
                <ResetForm close={() => setShowPWDRecovery(false)} />
            </Modal>
        </Fragment>
    );
};
