const generateAvailabilityFields = () => {
	return Array.from({ length: 24 }, (_, i) => {
		return {
			hora: i + 1,
			disponibilidad: 0,
		};
	});
};

export default generateAvailabilityFields;
