import React, { Fragment, useState } from 'react';
import { Common } from '../../../utils';
import { DatasetCommentSaveForm } from '../../forms';
import { DatasetCommentRemovalForm } from '../../forms/dataset-comment/dataset-comment-removal.form';
import { Modal } from '../modal/modal';
import styles from './dataset-comment.module.scss';

/**
 * Componenten que se encarga de renderizar un comentario de dataset.
 * @param {Object} param0  propos del comentario
 */
export const DatasetComment = ({ data, ...props }) => {
    const [showEdit, setShowEdit] = useState(false);
    const [showRemove, setShowRemove] = useState(false);

    return (
        <Fragment>
            <div className={styles.comment + ' ' + Common.rowBetween()}>
                <div className={Common.colJoinTop(12)}>
                    <p>
                        <strong>{data.user.email}</strong>
                    </p>
                    <div className="x-xs-es"></div>
                    <p>{data.comment}</p>
                    <div className="x-xs-es"></div>
                    <span className="mini-link" onClick={() => setShowEdit(true)}>
                        Editar
                    </span>
                    <span>&nbsp;&nbsp;</span>
                    <span className="mini-link" onClick={() => setShowRemove(true)}>
                        Eliminar
                    </span>
                </div>
            </div>

            {showEdit ? (
                <Modal show={showEdit} includeHeader onClose={() => setShowEdit(false)}>
                    <DatasetCommentSaveForm
                        data={data}
                        onUpdate={() => props.onUpdate()}
                        onCancel={() => setShowEdit(false)}
                    />
                </Modal>
            ) : null}

            {showRemove ? (
                <Modal show={showRemove} onClose={() => setShowRemove(false)}>
                    <DatasetCommentRemovalForm
                        id={data.id}
                        onRemove={() => props.onRemove()}
                        onCancel={() => setShowRemove(false)}
                    />
                </Modal>
            ) : null}
        </Fragment>
    );
};
