import React, { useEffect, useState } from 'react';

import * as Common from '../../../../../utils/common';

import styles from './history.module.scss';

import { Card, CenteredContainer, NoConnection, NoResults, Spinner } from '../../../../layout';
import { LineChart } from '../../../../charts';
import { ModelController } from '../../../../../utils';

/**
 * Componente encargado de renderizar el modulo de históricos.
 */
export const History = () => {
	const [showLineChart, setShowLinechart] = useState(false);
	const [selectedId, setSelectedId] = useState(0);
	const [chartMetadata, setChartMetadata] = useState({});
	const [data, setData] = useState([]);
	const [filterLimits, setFilterLimits] = useState([]);
	const [range, setRange] = useState([]);
	const [areas, setAreas] = useState([]);
	const [filename, setFilename] = useState('');
	const [loading, setLoading] = useState(true);
	const [noConnection, setNoConnection] = useState(false);
	const [executions, setExecutions] = useState([]);

	/**
	 * consulta de listado de historicos.
	 */
	const fetch = async () => {
		try {
			setLoading(true);
			setNoConnection(false);
			const res = await ModelController.getHistory();
			if (res.status < 400) {
				setExecutions(res.data);
				console.log(res.data);
			}
		} catch (error) {
			setNoConnection(true);
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	/**
	 * Se consultan los datos de un pronostico en particular, de igual forma se
	 * actualizan los datos del grafico de linea.
	 * @param {Object} exec ejecucion a consultar
	 */
	const fetchExecution = async (exec) => {
		try {
			setShowLinechart(false);
			const hisRes = await ModelController.getHistoryOf(exec.id);

			const colors = Common.getPallete(hisRes.data.data.length);

			let metadata = { ...hisRes.data };
			delete metadata['data'];

			const stats = hisRes.data.data.map((pred, index) => {
				return {
					...pred,
					color: colors[index],
					forecast: pred.forecast.map((f) => {
						return {
							date: new Date(f.date),
							value: Math.floor(f.value),
						};
					}),
				};
			});

			const areas = hisRes.data.data.map((pred, index) => {
				return {
					name: pred.name,
					id: pred.id,
					color: colors[index],
					hidden: false,
				};
			});

			const lower = new Date(exec.date_start);
			lower.setHours(0);
			lower.setMinutes(0);
			lower.setSeconds(0);
			lower.setMilliseconds(0);
			lower.setDate(lower.getDate() + 1);
			const upper = new Date(exec.date_end);
			upper.setHours(23);
			upper.setMinutes(59);
			upper.setSeconds(59);
			upper.setMilliseconds(0);
			upper.setDate(upper.getDate() + 1);

			const limits = [lower.getTime(), upper.getTime()];
			const formatted = [Common.formatDate(lower), Common.formatDate(upper)];
			// console.log(lower, upper);

			setAreas(areas);
			setRange(formatted);
			setFilterLimits(limits);
			setChartMetadata(metadata);
			setData(stats);
			setFilename(exec.name);
			setShowLinechart(true);
			setSelectedId(exec.id);
		} catch (error) {
			console.log(error);
		} finally {
			//setLoading(false);
		}
	};

	/**
	 * Se genera un archivo en formato .json para la descarga de los datos del
	 * pronostico.
	 * @param {Object} execution ejecucion a descargar
	 */
	const downloadExecution = async (execution) => {
		const hisRes = await ModelController.getHistoryOf(execution.id);
		// console.log(hisRes);
		if (hisRes.status < 400) {
			const fileName = `${execution.name}.json`;
			const json = JSON.stringify(hisRes.data.data);
			const blob = new Blob([json], { type: 'application/json' });
			const href = await URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = href;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	return (
		<CenteredContainer colSize={10}>
			<div className="row inherit-w zero">
				<h2 className="tab-title">Hist&oacute;rico</h2>
			</div>
			<hr />
			<br />
			<div className="inherit-w">
				{noConnection && !loading ? <NoConnection /> : null}
				{loading ? <Spinner /> : null}

				{!loading && !noConnection && executions.length === 0 ? (
					<NoResults />
				) : (
					<div className={Common.rowBetween()}>
						<div className={Common.colJoin(showLineChart ? 4 : 12) + ' fixed-scroll'}>
							{executions.map((exec, index) => {
								const headers = [exec.name.split('.').join(' '), exec.variable_name];
								const headerSizes = [9, 3];
								return (
									<Card
										key={index}
										headerDist={[8, 4]}
										headers={headers}
										headerSizes={headerSizes}
										onViewToggled={() => fetchExecution(exec)}
										onDownload={() => downloadExecution(exec)}
										isHighlighted={selectedId === exec.id}
										hasStatistics
									/>
								);
							})}
						</div>
						{showLineChart ? (
							<div className={Common.colJoin(7)}>
								<p className={styles.text}>{filename}</p>
								<p className={styles.text}>Inicio: {range[0]}</p>
								<p className={styles.text}>Fin: {range[1]}</p>
								<div className="xs-es"></div>
								<LineChart
									areas={areas}
									range={filterLimits}
									data={data}
									hidden={[]}
									metadata={chartMetadata}
								/>
							</div>
						) : null}
					</div>
				)}
			</div>
			<br />
		</CenteredContainer>
	);
};
