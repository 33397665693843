import React, { Fragment, useEffect, useRef, useState } from 'react';
import styles from './line-chart.module.scss';
import {
	select,
	scaleLinear,
	line,
	max,
	curveCardinal,
	axisBottom,
	axisLeft,
	min,
	scaleTime,
	brushX,
} from 'd3';
import { usePrevious, useResizeObserver } from '../../hooks';
import { Common, OdefController } from '../../../utils';
import { Button, Chip, Slider, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ExportExcel from 'react-export-excel';

/**
 * XLSX FORMAT
 */
const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelFile.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelFile.ExcelColumn;

const formatTickDate = (date) => {
	const h = date.getHours() + 1;
	const monthDay = date.getDate();
	const weekday = Common.WEEKDAYS[date.getDay()];
	const str = h > 12 ? `${weekday} (${monthDay}) ${h - 12} PM` : `${weekday} (${monthDay}) ${h} AM`;
	return str;
};

const parseValue = (value) => {
	const date = new Date(+value);
	const y = date.getFullYear();
	const M = (date.getMonth() + 1).toString().padStart(2, '0');
	const d = date.getDate().toString().padStart(2, '0');
	const h = date.getHours().toString().padStart(2, '0');
	const m = date.getMinutes().toString().padStart(2, '0');
	const s = date.getSeconds().toString().padStart(2, '0');
	return `${y}-${M}-${d} ${h}:${m}:${s}`;
};

function ValueLabelComponent(props) {
	const { children, open, value } = props;

	return (
		<Tooltip open={open} enterTouchDelay={0} placement="top" title={parseValue(value)}>
			{children}
		</Tooltip>
	);
}

export const LineChart = ({ areas, range, data, odefId, metadata }) => {
	const svgRef = useRef();
	const wrapperRef = useRef();
	const dimensions = useResizeObserver(wrapperRef);
	const [delta, setDelta] = useState(10);
	const [selection, setSelection] = useState([]);
	const [limits, setLimits] = React.useState(range);
	const [selectedAreas, setSelectedAreas] = useState([]);
	const [hidden, setHidden] = React.useState([]);
	const previousSelection = usePrevious(selection);
	const previousData = usePrevious(data);
	const previousHidden = usePrevious(hidden);
	const [xlsx, setXlsx] = React.useState([]);
	const [xlsxCols, setXlsxCols] = React.useState([]);

	const { variable_name = 'Demanda', unit = 'MW', description = '' } = metadata ?? {};

	console.log({ variable_name, unit, description });

	/**
	 * UPDATE AREAS
	 */
	useEffect(() => {
		setSelectedAreas(areas);
	}, [areas]);

	/**
	 * FORMAT DATA FOR EXCEL FILE
	 */
	useEffect(() => {
		const areaMapper = data.map((area, index) => {
			return {
				area_name: area.name,
				area_index: index,
			};
		});

		const excelCols = ['fecha', 'hora', ...areaMapper.map((area) => area.area_name)];

		const excelData = [];

		for (let index = 0; index < data[0].forecast.length; index++) {
			const date = data[0].forecast[index].date;
			const dateString = `${date.getFullYear()}-${(date.getMonth() + 1)
				.toString()
				.padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
			const hour = date.getHours() + 1;
			let row = {
				fecha: dateString,
				hora: hour,
			};
			for (const map of areaMapper) {
				row[map.area_name] = data[map.area_index].forecast[index].value;
			}

			excelData.push(row);
		}

		setXlsxCols(excelCols);
		setXlsx(excelData);
	}, [data]);

	/**
	 * HANDLE D3 RENDER
	 */
	useEffect(() => {
		if (!data) return;
		if (data.length === 0) return;
		const svg = select(svgRef.current);
		const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();

		// DATA UPDATE i.e: area removed
		if ((hidden !== previousHidden && previousHidden) || (data !== previousData && previousData)) {
			for (const entry of previousData) {
				svg.selectAll(`.linechart-line-${entry.id}`).remove();
				svg.selectAll(`.linechart-dot-${entry.id}`).remove();
			}
		}

		const filtered = data
			.filter((area) => !hidden.includes(area.id))
			.map((area) => {
				const forecast = area.forecast.filter(
					(record) => record.date.getTime() >= limits[0] && record.date.getTime() <= limits[1]
				);
				return { id: area.id, name: area.name, forecast: forecast, color: area.color };
			});

		const minDate = min(filtered, (area) => min(area.forecast, (record) => record.date));
		const maxDate = max(filtered, (area) => max(area.forecast, (record) => record.date));

		const minValue = min(filtered, (area) => min(area.forecast, (record) => record.value));
		const maxValue = max(filtered, (area) => max(area.forecast, (record) => record.value));

		const _delta = maxValue - minValue + Common.getNearestTenPow(maxValue) * 2;
		setDelta(_delta);

		// scales and line generator
		const xScale = scaleTime().domain([minDate, maxDate]).range([0, width]);

		const yScale = scaleLinear()
			.domain([
				minValue - Common.getNearestTenPow(maxValue),
				maxValue + Common.getNearestTenPow(maxValue),
			])
			.range([height, 0]);

		const lineGenerator = line()
			.x((record, index) => xScale(record.date))
			.y((record) => yScale(record.value))
			.curve(curveCardinal);

		const lines = svg.select('#sisprover-linechart-lines');
		for (const entry of filtered) {
			// render the line
			lines
				.selectAll(`.linechart-line-${entry.id}`)
				.data([entry.forecast])
				.join('path')
				.attr('class', `linechart-line-${entry.id}`)
				.attr('stroke', (record, index) => entry.color)
				.attr('fill', 'none')
				.attr('d', lineGenerator);

			// render dots
			lines
				.selectAll(`.linechart-dot-${entry.id}`)
				.data(entry.forecast)
				.join('circle')
				.attr('class', `clickable linechart-dot-${entry.id}`)
				.attr('stroke', (record, index) => entry.color)
				.attr('r', (record, index) =>
					record.date >= selection[0] && record.date <= selection[1] ? 4 : 2
				)
				.attr('fill', (record, index) =>
					record.date >= selection[0] && record.date <= selection[1]
						? Common.brightenColor(entry.color, 2)
						: entry.color
				)
				.attr('cx', (record, index) => xScale(record.date))
				.attr('cy', (record, index) => yScale(record.value))
				.on('mouseover', (event, { date, value }) => {
					select(event.target).attr('r', 4).attr('fill', Common.brightenColor(entry.color, 2));
					svg.selectAll('.d3-tooltip-bg')
						.data([event])
						.join((enter) => enter.append('rect').attr('y', yScale(value) - 120))
						.attr('class', 'd3-tooltip-bg')
						.attr('x', xScale(date) - 5)
						.attr('text-anchor', 'middle')
						.attr('width', 200)
						.attr('height', '60px')
						.attr('fill', 'black')
						.transition()
						.attr('y', yScale(value) - 70)
						.attr('fill-opacity', 0.5);

					svg.selectAll('.d3-tooltip-title')
						.data([event])
						.join((enter) => enter.append('text').attr('y', yScale(value) - 110))
						.attr('class', 'd3-tooltip-title')
						.text(`${formatTickDate(date)}:`)
						.attr('font-family', "'Lato', sans-serif")
						.attr('x', xScale(date) + 95)
						.attr('text-anchor', 'middle')
						.attr('width', 200)
						.attr('height', 30)
						.style('fill', 'white')
						.transition()
						.attr('y', yScale(value) - 50)
						.attr('opacity', 1);

					svg.selectAll('.d3-tooltip-text')
						.data([event])
						.join((enter) => enter.append('text').attr('y', yScale(value) - 110))
						.attr('class', 'd3-tooltip-text')
						.text(`${value} ${unit}`)
						.attr('font-family', "'Lato', sans-serif")
						.attr('x', xScale(date) + 95)
						.attr('text-anchor', 'middle')
						.attr('width', 200)
						.attr('height', 30)
						.style('fill', 'white')
						.transition()
						.attr('y', yScale(value) - 20)
						.attr('opacity', 1);
				})
				.on('mouseleave', (event, { date, value }) => {
					if (date < selection[0] || date > selection[1])
						select(event.target).attr('r', 2).attr('fill', entry.color);
					svg.select('.d3-tooltip-bg').remove();
					svg.select('.d3-tooltip-title').remove();
					svg.select('.d3-tooltip-text').remove();
				});
		}

		// axes
		const xAxis = axisBottom(xScale).ticks(12).tickFormat(formatTickDate);
		svg.select('.linechart-x-axis')
			.attr('transform', `translate(0, ${height})`)
			.call(xAxis)
			.selectAll('text')
			.attr('y', 10)
			.attr('x', -10)
			.attr('transform', 'rotate(-45)')
			.style('text-anchor', 'end');

		const yAxis = axisLeft(yScale).ticks(_delta / Common.getNearestTenPow(_delta));
		svg.select('.linechart-y-axis').call(yAxis);

		const margin = {
			top: 100,
			left: 60,
		};
		// Add X axis label:
		svg.select('.linechart-x-axis-label')
			.join('text')
			.attr('class', 'linechart-x-axis-label')
			.attr('text-anchor', 'end')
			.attr('x', width)
			.attr('y', height + margin.top + 20)
			.text('Tiempo (horas)');

		// Y axis label:
		svg.select('.linechart-y-axis-label')
			.join('text')
			.attr('class', 'linechart-y-axis-label')
			.attr('text-anchor', 'end')
			.attr('transform', 'rotate(-90)')
			.attr('y', -margin.left + 20)
			.attr('x', 0)
			.text(`${variable_name} (${unit}/h)`);

		// brush
		const brush = brushX()
			.extent([
				[0, 0],
				[width, height],
			])
			.on('start brush end', (event) => {
				if (event.selection) {
					const dateSelection = event.selection.map(xScale.invert);
					setSelection(dateSelection);
				}
			});

		if (selection === previousSelection) {
			svg.select('.linechart-brush')
				.call(brush)
				.call(brush.move, [minDate, new Date(minDate.getTime() + 3 * 60 * 60 * 1000)].map(xScale));
		}
	}, [
		dimensions,
		limits,
		data,
		hidden,
		selection,
		previousSelection,
		previousData,
		previousHidden,
		areas,
		variable_name,
		unit,
	]);

	//const tickCount = Math.ceil((delta || 10) / Common.getNearestTenPow(delta || 10) + 1);
	const tickCountH = (delta || 10) / Common.getNearestTenPow(delta || 10);
	const tickCountW = (delta || 2) / Common.getNearestTenPow(delta || 10);
	const heightP = (1 / tickCountH) * 100;
	const widthP = ((1 / tickCountW) * 100) / 5;

	/**
	 * HANDLE SLIDER
	 */
	const handleChange = (event, newValue) => {
		if (newValue[0] < newValue[1]) setLimits(newValue);
	};

	/**
	 * HANDLE LEGEND
	 */
	const legendToggle = (id, hidden) => {
		const toToggle = selectedAreas.findIndex((a) => a.id === id);
		const _areas = [...selectedAreas];
		let visible_count = 0;
		for (const area of _areas) if (!area.hidden) visible_count++;
		if (visible_count > 1 || hidden) {
			_areas[toToggle].hidden = !_areas[toToggle].hidden;
			setSelectedAreas(_areas);
			setHidden(_areas.filter((el) => el.hidden).map((el) => el.id));
		}
	};

	const svg2str = () => {
		const { width, height } = dimensions;

		const svg = document.getElementById('sisprover-linechart-container').cloneNode(true);
		svg.setAttribute('height', height + 130);
		svg.setAttribute('width', width + 100);

		for (const child of svg.children) {
			child.setAttribute('style', 'transform: translateX(70px)');
		}

		let svgAsXML = new XMLSerializer().serializeToString(svg);
		svgAsXML = svgAsXML.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
		svgAsXML = svgAsXML.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix
		return svgAsXML;
	};

	/**
	 * DOWNLOAD AS SVG
	 */
	const downloadAsSVG = () => {
		const svgStr = svg2str();
		let a = document.createElement('a');
		a.download = 'grafico.svg';
		a.href = 'data:image/svg+xml,' + encodeURIComponent(svgStr);
		a.target = '_blank';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	/**
	 * DOWNLOAD AS PNG
	 */
	const downloadAsPNG = () => {
		const { width, height } = dimensions;
		const svgStr = svg2str();
		const src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgStr)));

		let canvas = document.createElement('canvas');
		let context = canvas.getContext('2d');

		canvas.width = width + 100;
		canvas.height = height + 130;

		let image = new Image();
		image.onload = function () {
			context.clearRect(0, 0, width + 100, height + 130);
			context.drawImage(image, 0, 0, width + 100, height + 130);

			canvas.toBlob(function (blob) {
				let a = document.createElement('a');
				a.download = 'grafico.png';
				a.href = URL.createObjectURL(blob);
				a.target = '_blank';
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			}, 'image/png');
		};

		image.src = src;
	};

	/**
	 * Se genera un archivo en formato .csv para la descarga de los datos de la ODEF.
	 * @param {Object} item ODEF a descargar
	 */
	const handleODEFDownload = async () => {
		const response = await OdefController.download(odefId);
		const detail = await OdefController.detail(odefId);
		if (response.status < 400) {
			const fileName = `${detail.data.name}.csv`;
			const href = await URL.createObjectURL(response.data);
			const link = document.createElement('a');
			link.href = href;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const buttonsSize = odefId ? 3 : 4;

	return (
		<Fragment>
			{selectedAreas.length > 0 ? <p className={styles.text}>Areas seleccionadas: </p> : null}
			{/*< <p>{JSON.stringify(selectedAreas)}</p> */}
			{selectedAreas.map((item) => {
				let chipStyle = {
					margin: '5px',
					color: '#FFFFFF',
				};
				if (item.hidden) {
					chipStyle['border'] = `1px solid ${item.color}`;
					chipStyle['backgroundColor'] = 'transparent';
					chipStyle['color'] = item.color;
				} else {
					chipStyle['backgroundColor'] = item.color;
				}

				let iconStyle = {
					paddingRight: '5px',
					color: item.hidden ? item.color : '#FFFFFF',
				};
				const iconText = item.hidden ? 'eye-slash' : 'eye';
				return (
					<Chip
						key={item.id}
						label={item.name}
						variant={'outlined'}
						style={chipStyle}
						deleteIcon={<FontAwesomeIcon icon={['far', iconText]} style={iconStyle} />}
						onDelete={() => legendToggle(item.id, item.hidden)}
						onClick={() => legendToggle(item.id, item.hidden)}
					/>
				);
			})}

			<div className="sm-es"></div>
			<p className={styles.text}>Filtrar por fecha: </p>
			<Slider
				value={limits}
				onChange={handleChange}
				min={range[0]}
				max={range[1]}
				step={3600 * 1000}
				ValueLabelComponent={ValueLabelComponent}
			/>

			<div id="sisprover-linechart-wrapper" ref={wrapperRef} className={styles.root}>
				<svg
					id="sisprover-linechart-container"
					ref={svgRef}
					className={styles.svg}
					style={{ backgroundSize: `${widthP}% ${heightP}%` }}
				>
					<g id="sisprover-linechart-info" className={styles.svg_container}>
						<g className="linechart-x-axis"></g>
						<text className="linechart-x-axis-label"></text>
						<g className="linechart-y-axis"></g>
						<text className="linechart-y-axis-label"></text>
						<g className="linechart-brush"></g>
					</g>
					<g id="sisprover-linechart-lines" className={styles.svg_container}>
						<g className="linechart-x-axis"></g>
						<text className="linechart-x-axis-label"></text>
						<g className="linechart-y-axis"></g>
						<text className="linechart-y-axis-label"></text>
						<g className="linechart-brush"></g>
					</g>
				</svg>
			</div>
			{xlsxCols.length > 0 && xlsx.length > 0 ? (
				<div>
					<div className="md-es"></div>
					<div className="xl-es"></div>
					<div className={Common.rowBetweenMiddle()}>
						<div className={Common.colJoinTop(buttonsSize)}>
							<ExcelFile
								filename="datos"
								element={
									<Button
										color="primary"
										variant="contained"
										size="small"
										className={styles.btn_text}
										disableElevation
										fullWidth
									>
										Descargar datos &nbsp;
										<FontAwesomeIcon icon={['fas', 'file-excel']} />
									</Button>
								}
							>
								<ExcelSheet data={xlsx} name="pronostico por areas">
									{xlsxCols.map((col, index) => (
										<ExcelColumn key={index} label={col} value={col} />
									))}
								</ExcelSheet>
							</ExcelFile>
						</div>

						<div className={Common.colJoinTop(buttonsSize)}>
							<Button
								color="primary"
								variant="contained"
								size="small"
								className={styles.btn_text}
								onClick={() => {
									downloadAsSVG();
								}}
								disableElevation
								fullWidth
							>
								Descargar SVG &nbsp;
								<FontAwesomeIcon icon={['fas', 'file-download']} />
							</Button>
						</div>

						<div className={Common.colJoinTop(buttonsSize)}>
							<Button
								color="primary"
								variant="contained"
								size="small"
								className={styles.btn_text}
								onClick={() => {
									downloadAsPNG();
								}}
								disableElevation
								fullWidth
							>
								Descargar PNG &nbsp;
								<FontAwesomeIcon icon={['fas', 'file-image']} />
							</Button>
						</div>
						{odefId ? (
							<div className={Common.colJoinTop(buttonsSize)}>
								<Button
									color="secondary"
									variant="contained"
									size="small"
									className={styles.btn_text}
									onClick={() => {
										handleODEFDownload();
									}}
									disableElevation
									fullWidth
								>
									Descargar ODEF &nbsp;
									<FontAwesomeIcon icon={['fas', 'file-csv']} />
								</Button>
							</div>
						) : null}
					</div>
				</div>
			) : null}
		</Fragment>
	);
};
