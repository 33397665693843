import { Common } from '..';
const [RENEWABLE, HIDRAULIC, TERMIC] = Common.PLANT_TYPES;

const getExchanges = (plantType, plant) => {
	switch (plantType) {
		case RENEWABLE:
			return plant.mercados_renovables;

		case HIDRAULIC:
			return plant.mercados_hidraulicas;

		case TERMIC:
			return plant.mercados_termicas;

		default:
			return undefined;
	}
};

const normalizeExchanges = (plantType, plant) => {
	const exchanges = getExchanges(plantType, plant);
	return exchanges.map((exchange) => {
		return {
			id: exchange.id,
			mercado: exchange.mercado,
			unidades_equiv: exchange.unidades_equiv,
		};
	});
};

export default normalizeExchanges;
