import React, { Fragment } from 'react';

// COMPONENTS
import { Login } from './login/login';
import { Error404 } from '../error404/error404';

// REACT ROUTER
import { Route, Redirect, Switch } from 'react-router-dom';

//ROUTES
import { ROUTES } from '../../../utils/routes';
import { SessionRoute } from '../../routing';

/**
 * Enrutador que se encarga de hacer el renderizado segun la ruta de navegación
 * actual. En caso de que el enrutador no logre discernir que vista se ha
 * de renderizar le mostrara al usuario la pantalla de 404.
 */
export const AuthRouter = () => {
    return (
        <Fragment>
            <Switch>
                <Route exact path={ROUTES.AUTH.BASE} render={() => <Redirect to={ROUTES.AUTH.LOGIN} />} />
                <SessionRoute exact path={ROUTES.AUTH.LOGIN} component={Login} />
                <Route path="/" component={Error404} />
            </Switch>
        </Fragment>
    );
};
