import { toast } from 'react-toastify';
import chroma, { bezier } from 'chroma-js';
import { LocalStoreService } from './api';

/**
 * Instancia del almacenamiento local.
 */
const locaStorage = new LocalStoreService();

/**
 * Vector que contiene los días de la semana.
 */
export const WEEKDAYS = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];

/**
 * Vector que contiene los diferentes tamaños soportados por flexbox-grid.
 */
const sizes = ['lg', 'md', 'sm', 'xs'];

/**
 * Métodos que aportan facilidad a la hora de definir estilos y clases
 * para la disposicón de las columnas con respecto al viewport.
 */
export const colLg = (size) => `col-${sizes[0]}-${size}`;
export const colMd = (size) => `col-${sizes[1]}-${size}`;
export const colSm = (size) => `col-${sizes[2]}-${size}`;
export const colXs = (size) => `col-${sizes[3]}-${size}`;
export const colJoin = (size) => sizes.map((v) => `col-${v}-${size}`).join(' ');
export const colJoinLg_MdSmXs = (lg, rest) => `${colLg(lg)} ${colMd(rest)} ${colSm(rest)} ${colXs(rest)}`;
export const colJoinLgMd_SmXs = (size) => sizes.map((v) => `col-${v}-${size}`).join(' ');
export const colJoinLgMdSm_Xs = (size) => sizes.map((v) => `col-${v}-${size}`).join(' ');
export const colJoinTop = (size) => sizes.map((v) => `col-${v}-${size}`).join(' ') + ' flex-start';
export const colJoinTopPT4 = (size) => sizes.map((v) => `col-${v}-${size}`).join(' ') + ' pt-4';
export const colJoinMiddle = (size) => sizes.map((v) => `col-${v}-${size}`).join(' ') + ' flex-center';

export const colXSOffset = (size) => `col-xs-offset-${size} col-xs-${12 - size}`;

/**
 * Métodos que aportan facilidad a la hora de definir estilos y clases
 * para la disposicón de las filas con respecto al viewport.
 */

/**
 * Este método se usa como bloque de construccion para definir metodos más
 * complejos.
 * @param {string} scssClass la clase de scss correspondiente.
 */
const rowJoin = (scssClass) => sizes.map((v) => `${scssClass}-${v}`).join(' ');

/**
 * abreviación para la clase scss 'around' de flexbox-grid.
 */
export const around = () => rowJoin('around');
export const rowAround = () => 'row ' + around();

/**
 * abreviación para la clase scss 'between' de flexbox-grid
 */
export const between = () => rowJoin('between');
export const rowBetween = () => 'row ' + between();

/**
 * abreviación para la clase scss 'center' de flexbox-grid.
 */
export const center = () => rowJoin('center');
export const rowCenter = () => 'row ' + center();

/**
 * abreviación para la clase scss 'middle' de flexbox-grid.
 */
export const middle = () => rowJoin('middle');
export const rowMiddle = () => 'row ' + middle();

/**
 * Combinaciones entre las diferentes abreviaciones de filas.
 */
export const aroundCenter = () => around() + ' ' + center();
export const rowAroundCenter = () => 'row ' + aroundCenter();
export const aroundMiddle = () => around() + ' ' + middle();
export const rowAroundMiddle = () => 'row ' + aroundMiddle();

export const betweenCenter = () => between() + ' ' + center();
export const rowBetweenCenter = () => 'row ' + betweenCenter();
export const betweenMiddle = () => between() + ' ' + middle();
export const rowBetweenMiddle = () => 'row ' + betweenMiddle();

export const centerMiddle = () => center() + ' ' + middle();
export const rowCenterMiddle = () => 'row ' + centerMiddle();

/**
 * Mapa que contiene los roles de usuario dentro de la aplicación.
 */
export const RoleEnum = {
	SUPER_ADMINISTRADOR: 'SA',
	ADMINISTRADOR: 'AD',
	GERENTE: 'MA',
	USUARIO: 'US',
};

/**
 * Mapa que contiene los grupos de la aplicación.
 * @deprecated este mapa solo fue usado con motivos de demostración.
 */
export const GroupEnum = {
	Development: 'Desarrollo',
};

/**
 * Mapa que contiene los diferentes tipos de tarjetas que maneja la aplicación.
 */
export const CardType = {
	USER: 'CARD.TYPE.USER',
	TOKEN: 'CARD.TYPE.TOKEN',
	GROUP: 'CARD.TYPE.GROUP',
	AREA: 'CARD.TYPE.AREA',
	SUB_AREA: 'CARD.TYPE.SUB_AREA',
	DATASET: 'CARD.TYPE.DATASET',
	ARCHITECTURE: 'CARD.TYPE.ARCHITECTURE',
	MODEL: 'CARD.TYPE.MODEL',
	GLOSSARY: 'CARD.TYPE.GLOSSARY',
	PLANT: 'CARD.TYPE.PLANT',
	MARKET: 'CARD.TYPE.MARKET',
	EXCHANGE: 'CARD.TYPE.EXCHANGE',
	SERVING_PLANT: 'CARD.TYPE.SERVING_PLANT',
	CONSTANT: 'CARD.TYPE.CONSTANT',
	OPTIMIZER: 'CARD.TYPE.OPTIMIZER',
};

/**
 * Mapa que contiene los roles de usuario dentro de la aplicación de forma inversa.
 */
export const USER_ROLES = {
	SA: 'Super administrador',
	AD: 'Administrador',
	MA: 'Gerente',
	US: 'Usuario',
};

/**
 * Mapa que contiene los roles de usuario dentro de la aplicación de forma inversa.
 */
export const PLANT_TYPES = ['Energía Renovable', 'Hidráulica', 'Térmica'];
export const PLANT_ENERGY = ['solar', 'eolica', 'pch'];

/**
 * Mapa que contiene los diferentes tipos de servicio.
 * @deprecated este mapa no ha sido implementado.
 */
export const DATA_SERVICE = {
	ACUEDUCTO_SUSCRIPTORES: 'AS',
	ACUEDUCTO_CONSUMO: 'AC',
	ELECTRICIDAD_SUSCRIPTORES: 'ES',
	ELECTRICIDAD_CONSUMO: 'EC',
	GAS_SUSCRIPTORES: 'GS',
};

/**
 * Mapa que contiene los diferentes estados de un modelo.
 * @deprecated este mapa no ha sido implementado.
 */
export const MODEL_STAGES = {
	TRAINING: 'TR',
	VALIDATION: 'VA',
	TESTING: 'TE',
};

/**
 * Método que genera una lista de tuplas que contiene los roles de usuario.
 */
export const GET_USER_ROLES_AS_KEY_PAIR = () => {
	return Object.keys(USER_ROLES).map((k) => {
		return { id: k, value: USER_ROLES[k] };
	});
};

/**
 * Servicio que se encarga de disparar mensajes en la posicion inferior derecha de
 * la pantalla, implementación de react-toastify.
 */
export const fireMiniMessage = (message) => {
	toast.info(message, {
		position: 'bottom-right',
		autoClose: 5000,
		delay: 350,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: false,
		className: 'success-toast',
	});
};

/**
 * Método que convierte una fecha del formato ISO a un formato 'local' y en
 * español.
 */
export const formatDate = (dateString) => {
	const date = new Date(dateString);
	const y = date.getFullYear();
	const M = (date.getMonth() + 1).toString().padStart(2, '0');
	const d = date.getDate().toString().padStart(2, '0');
	const _h = date.getHours();
	const mer = _h >= 12 ? 'P.M.' : 'A.M.';
	const h = (_h >= 12 ? _h - 12 : _h).toString().padStart(2, '0');
	const m = date.getMinutes().toString().padStart(2, '0');
	const formatted = `${d}/${M}/${y} ${h}:${m} ${mer}`;
	return formatted;
};

/**
 * Método que calcula la potencia de 10 más cercana al número de entrada.
 * @param {number} input número de entrada.
 */
export const getNearestTenPow = (input) => Math.pow(10, input.toString().length - 1);

/**
 * Lista de metodos que se encarga de generar y validar los roles para decidir el
 * acceso a módulos de la aplicación.
 */
export const getDefaultRights = () => ['SA', 'AD', 'MA', 'US'];
export const getManagerRights = () => ['SA', 'AD', 'MA'];
export const getAdminRights = () => ['SA', 'AD'];
export const getSuperAdminRights = () => ['SA'];
export const isInManager = () => getManagerRights().includes(locaStorage.getUserRol());
export const isInAdmin = () => getAdminRights().includes(locaStorage.getUserRol());
export const isInSuperAdmin = () => getSuperAdminRights().includes(locaStorage.getUserRol());

/**
 * A continuación se describen métodos que se usan para generar una paleta de
 * colores a partir de unos colores base y adicionalmente se encargan de aclarar u
 * oscurecer un color, estos métodos dependen de la libreria chroma.js
 */
const BASE_COLORS = ['#173db8', '#ff593e', '#f0a71f'];
export const getPallete = (colorsRequired) =>
	bezier(BASE_COLORS).scale().correctLightness().colors(colorsRequired);
export const brightenColor = (color, brightness) => {
	try {
		const _color = chroma(color).brighten(brightness);
		return _color;
	} catch (error) {
		console.error(error);
		return '#222222';
	}
};
export const darkenColor = (color, darkness) => chroma(color).darken(darkness);

/**
 * Vector de subareas
 */
export const SUBAREAS = [
	'',
	'Antioquia',
	'Arauca',
	'Atlantico',
	'BajoPutumayo',
	'Bolivar',
	'Boyaca',
	'Caldas',
	'Cali',
	'Caqueta',
	'Cartago',
	'Casanare',
	'Cauca',
	'Cerromatoso',
	'Choco',
	'CiraInfanta',
	'Codensa',
	'CordobaSucre',
	'Drummond',
	'GCM',
	'Guaviare',
	'Huila',
	'Intercor',
	'Meta',
	'Nariño',
	'NorSantander',
	'Oxy',
	'Pereira',
	'Planeta',
	'Putumayo',
	'Quindio',
	'Rubiales',
	'Santander',
	'Tolima',
	'Tulua',
];

/**
 * Este metodo valida si el web socket proveeido se encuentra abierto.
 * @param {WebSocket} ws websocket a validar
 */
export const isSocketOpen = (ws) => {
	console.log('checking socket connection!');
	if (ws) return ws.readyState === WebSocket.OPEN;
	return false;
};

/**
 * este metodo genera una espera en la aplicacion en milisegundos.
 * @param {number} ms milisegundos a esperar
 */
export const delay = (ms) =>
	new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve(true);
		}, ms);
	});
