import React, { useCallback, useState } from 'react';
import { AppBar, Tab } from '@material-ui/core';
import { TabContext, TabList } from '@material-ui/lab';

import AreaTab from '../../../../Area/AreaTab';
import { Common } from '../../../../../utils';
import { AddButton, CenteredContainer, Modal, StyledTabPanel } from '../../../../layout';
import { AreaSaveForm } from '../../../../forms';
import SubareaTab from '../../../../Area/SubareaTab';

const tabs = {
	area: 'Áreas',
	subArea: 'Subáreas',
};

/**
 * Componente encargado de renderizar el modulo de mercados de energia.
 */
const AreaView = () => {
	const [tabValue, setTabValue] = useState(tabs.area);
	const [showModal, toggleShowModal] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const isInArea = tabValue === tabs.area;

	const tabHandler = (_e, val) => {
		setTabValue(val);
	};

	const showCreateMarketModalHandler = () => {
		toggleShowModal(true);
	};

	const itemsFetched = useCallback(() => {
		setRefresh(false);
	}, []);

	const itemSavedHandler = useCallback(() => {
		setRefresh(true);
	}, []);

	return (
		<>
			<CenteredContainer colSize={10}>
				<div className="row inherit-w zero">
					<h2 className="tab-title">Áreas</h2>
				</div>
				<hr className="m-0" />
				<TabContext value={tabValue}>
					<div className="row inherit-w zero">
						<AppBar
							position="static"
							elevation={0}
							style={{ background: '#fafafa', color: '#1c3545' }}
						>
							<TabList onChange={tabHandler}>
								<Tab label="Áreas" value={tabs.area} />
								<Tab label="Subáreas" value={tabs.subArea} />
							</TabList>
						</AppBar>
					</div>
					<br />
					<div className="row inherit-w zero">
						<StyledTabPanel value={tabs.area} className="inherit-w">
							<AreaTab refresh={refresh} fetchCallback={itemsFetched} />
						</StyledTabPanel>
						<StyledTabPanel value={tabs.subArea} className="inherit-w">
							<SubareaTab refresh={refresh} fetchCallback={itemsFetched} />
						</StyledTabPanel>
					</div>
				</TabContext>

				{Common.isInAdmin() && isInArea && <AddButton onClick={showCreateMarketModalHandler} />}
			</CenteredContainer>

			{showModal && (
				<Modal show={showModal} onClose={() => toggleShowModal(false)} closeDisabled>
					<AreaSaveForm
						onUpdate={() => itemSavedHandler()}
						onCancel={() => toggleShowModal(false)}
						isCreation
					/>
				</Modal>
			)}
		</>
	);
};

export default AreaView;
