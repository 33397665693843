import React from 'react';

import { Common, ConstantController } from '../../../utils';

import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { DefaultField } from '../default';
import { Button, CircularProgress } from '@material-ui/core';

const validationSchema = yup.object({
	name: yup.string('El nombre es de tipo texto').required('El nombre es requerido'),
	value: yup.number('El valor es de tipo númerico').required('El valor es requerido'),
});

const ConstantSaveForm = ({ data: record, onUpdate, onCancel, isCreation = false }) => {
	const formInitialValues = {
		name: record ? record.name : '',
		value: record ? record.value : '',
		description: record ? record.description : '',
	};

	/**
	 * metodo crear o editar una entrada de glosario.
	 */
	const onFormSubmit = async (data, setSubmitting, resetForm) => {
		try {
			setSubmitting(true);

			const body = { ...data };

			const res = isCreation
				? await ConstantController.create(body)
				: await ConstantController.update(record.id, body);
			const action = isCreation ? 'creado' : 'actualizado';
			const name = isCreation ? body.name : record.name;

			setSubmitting(false);

			if (res.status < 400) {
				resetForm();
				Common.fireMiniMessage(`Se ha ${action} la constante ${name}`);

				onCancel();
				onUpdate();
			}
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<Formik
			initialValues={formInitialValues}
			validationSchema={validationSchema}
			onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
		>
			{({ isSubmitting }) => (
				<Form className="inherit-w">
					<DefaultField name="name" label="Nombre" size="small" />
					<div className="sm-es"></div>

					<DefaultField name="value" label="Valor" size="small" />
					<div className="sm-es"></div>
					<div className="sm-es"></div>

					<DefaultField multiline name="description" label="Descripción" size="small" rows={3} />

					<div className="md-es"></div>
					<div className={Common.rowBetweenMiddle()}>
						<div className={Common.colJoin(3)} />
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								size="medium"
								className="ls-custom fl-right"
								disabled={isSubmitting}
								fullWidth
								disableElevation
							>
								{isCreation ? 'Agregar' : 'Editar'} &nbsp;
								{isSubmitting ? <CircularProgress size={20} /> : null}
							</Button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default ConstantSaveForm;
