const normalizeAvailability = (plant, isFromTermicConfig = false) => {
	if (isFromTermicConfig) {
		return normalizeAvailabilityForTermic(plant);
	} else {
		return normalizeAvailabilityForHidraulic(plant);
	}
};

const normalizeAvailabilityForHidraulic = (plant) => {
	const { disponibilidades: availabilities } = plant;
	const reducedSubunits = Array.from(new Set(availabilities.map((a) => a.num_subunidad))).sort();
	const normalizedAvailabilities = reducedSubunits.map((subunit) => {
		const subunitAvailability = availabilities.filter((a) => a.num_subunidad === subunit);
		return {
			subunit,
			availabilities: subunitAvailability.map((sa) => {
				return {
					id: sa.id,
					hora: sa.hora,
					disponibilidad: sa.disponibilidad,
				};
			}),
		};
	});
	return normalizedAvailabilities;
};

const normalizeAvailabilityForTermic = (plant) => {
	const { disponibilidades: availabilities } = plant;
	const reducedConfigNumber = Array.from(new Set(availabilities.map((a) => a.num_config))).sort();
	const normalizedAvailabilities = reducedConfigNumber.map((configNumber) => {
		const configAvailability = availabilities.filter((a) => a.num_config === configNumber);
		return {
			configNumber,
			availabilities: configAvailability.map((sa) => {
				return {
					id: sa.id,
					hora: sa.hora,
					disponibilidad: sa.disponibilidad,
				};
			}),
		};
	});
	return normalizedAvailabilities;
};

export default normalizeAvailability;
