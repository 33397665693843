import React from 'react';

import styles from './home.module.scss';
import logo from '../../../../assets/logo.svg';
import { Common } from '../../../../utils';

import { CenteredContainer } from '../../../layout';
import env from '../../../../env.json';

/**
 * Vista que se encarga de renderizar todo lo correspondiente al módulo de
 * inicio.
 */
export const Home = () => {
    return (
        <CenteredContainer colSize={10}>
            <div className={Common.rowCenterMiddle() + ' inherit-w zero'}>
                <img src={logo} className="App-logo" alt="logo" />
                &nbsp; &nbsp;
                <h2 className={styles.app_title}>{env.REACT_APP_APP_NAME}</h2>
            </div>
            <br />
            <br />
            <br />
            <div className="row zero">
                <p className={styles.app_description}>
                    SISPROVER es una herramienta desarrollada para dar soporte a los procesos de la Gerencia Comercial
                    de TEBSA, donde se involucra la toma de decisión bajo incertidumbre, como en los procesos de oferta
                    y nominación, y compra y venta de energía en contratos, entre otros. En cada uno de estos procesos
                    es importante acertar tanto en la tendencia como en la magnitud de la variable que se desea
                    pronosticar, para lo cual SISPROVER juega un papel importante.
                </p>
            </div>
        </CenteredContainer>
    );
};
