const formatWithPreviousHour = (hour) => {
	if (!hour) return null;

	const invalidType = typeof hour !== 'number' && typeof hour !== 'string';
	if (invalidType) return null;

	let asNumber = hour;
	if (typeof hour === 'string') {
		asNumber = parseInt(hour);
	}

	const previous = `${(asNumber - 1).toString().padStart(2, '0')}`;
	const current = `${asNumber.toString().padStart(2, '0')}`;
	return `${previous}:00 - ${current}:00`;
};

export default formatWithPreviousHour;
