import env from '../../../env.json';
import { api } from '../axios';
import { SyncController } from '.';

const controller = env.REACT_APP_API_VERSION + 'intercambio';

/**
 * Realiza una petición con el método GET a la API bajo el controlador /intercambio, en la
 * ruta /list con el fin de listar los intercambios existentes en la base de datos.
 */
export const list = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller}/list`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /intercambio, en la
 * ruta /create con el fin de crear un intercambio.
 * @param {Object} body Cuerpo de la petición con el intercambio a crear.
 */
export const create = (body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/create`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /intercambio, en la
 * ruta /:id/add_values con el fin de aggregar el listado de valores a un intercambio.
 * @param {number} id Id del intercambio en cuestión.
 * @param {Object} body Cuerpo de la petición con el intercambio a crear.
 */
export const addSchedule = (id, body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/${id}/add_values`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método PUT a la API bajo el controlador /intercambio,
 * en la ruta /:id/update con el fin de actualizar la información de un intercambio.
 * @param {number} id id correspondiente al intercambio a editar.
 * @param {Object} body Cuerpo de la petición con los cambios del intercambio correspondiente.
 */
export const update = (id, body) =>
	new Promise((resolve, reject) => {
		api.put(`${controller}/${id}/update`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método PUT a la API bajo el controlador /intercambio,
 * en la ruta /:id/update_values con el fin de actualizar la configuración de valores
 * de un intercambio.
 * @param {number} id id correspondiente del intercambio en cuestión.
 * @param {Object} body Cuerpo de la petición con los cambios del intercambio correspondiente.
 */
export const updateSchedule = (id, body) =>
	new Promise((resolve, reject) => {
		api.put(`${controller}/${id}/update_values`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador /intercambio, en
 * la ruta /:id/delete con el fin de eliminar un intercambio de la base de datos de forma permanente.
 * @param {number} id id correspondiente del intercambio a eliminar.
 */
export const remove = (id) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller}/${id}/delete`)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador /intercambio, en
 * la ruta /:id/remove_values con el fin de eliminar la configuracion de
 * valores de un intercambio de la base de datos de forma permanente.
 * @param {number} id id correspondiente del intercambio en cuestión.
 */
export const removeSchedule = (id) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller}/${id}/remove_values`)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});
