import React from 'react';
import { Common } from '../../utils';
import { Card } from '../layout';

const AreaList = ({ items, onItemUpdate }) => {
	return items?.map((item) => {
		const headers = [item.name];
		const headerSizes = [4, 4, 3, 1];
		return (
			<Card
				key={`area_${item.id}`}
				headers={headers}
				headerSizes={headerSizes}
				cardType={Common.CardType.AREA}
				data={item}
				canEdit
				canRemove
				canExpand
				onUpdate={onItemUpdate}
				onRemove={onItemUpdate}
			>
				<div className="row zero">
					{item.subareas.map((subarea) => (
						<div className={Common.colJoin(3)} key={subarea.id}>
							<p>{subarea.name}</p>
						</div>
					))}
				</div>
			</Card>
		);
	});
};

export default AreaList;
