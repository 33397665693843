import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Common } from '../../../utils';

/**
 * Componente que se encarga de renderizar el mensaje de que la petición que se
 * realizó no contiene datos para mostrar.
 */
export const NoResults = ({ resource = null }) => {
	return (
		<div className={Common.rowCenterMiddle() + ' inherit-w zero'}>
			<div className={Common.colJoin(12)}>
				<FontAwesomeIcon className="secondary-text" icon={['fas', 'binoculars']} />
				&nbsp;
				<span className="spinner-text">
					No se encontraron resultados {resource && ` (${resource})`}
				</span>
				<div className="xs-es"></div>
			</div>
		</div>
	);
};
