import React, { useEffect } from 'react';
import { Common, DATA, Methods, RenewablePlantController, Schemas } from '../../utils';
import { formatEnergyType, toTitleCase } from '../../utils/pipes';
import MissingConfigurationWarning from '../layout/missing-configuration/MissingConfigurationWarning';
import { Card, Meta, NoConnection, NoResults, Spinner } from '../layout';
import { AvailabilityConfig, ExchangesConfig } from '../configuration';
import { useListRequest } from '../hooks';

const RenewablePlantTab = ({ onShowModal, refresh = undefined, fetchCallback = undefined }) => {
	const { items, loading, error, fetch } = useListRequest(RenewablePlantController, fetchCallback);

	useEffect(() => {
		if (refresh) fetch();
	}, [refresh, fetch]);

	const renderPlants = () =>
		items.map((plant) => {
			const titleCaseName = toTitleCase(plant.name);
			const headers = [titleCaseName, plant.code];
			const headerSizes = [4, 4, 3, 1];
			let warnings = [];
			let configurations = [];

			const showUnitWarning = plant.mercados_renovables.length === 0;
			if (showUnitWarning) {
				warnings.push(
					<MissingConfigurationWarning
						key={`renewable-${plant.id}-ex-config`}
						configurationName="Unidades Equivalentes"
						onClick={() => onShowModal(plant, DATA.MODAL.CONTENT.PLANT.EXCHANGE.CREATE)}
					/>
				);
			} else {
				configurations.push(
					<ExchangesConfig
						key={`renewable-${plant.id}-ex-config`}
						plant={plant}
						plantType={Common.PLANT_TYPES[0]}
						onChanged={fetch}
					/>
				);
			}

			const showAvailabilityWarning = plant.disponibilidades_renovables.length === 0;
			if (showAvailabilityWarning) {
				warnings.push(
					<MissingConfigurationWarning
						key={`renewable-${plant.id}-availability-config`}
						configurationName="Disponibilidad"
						onClick={() => onShowModal(plant, DATA.MODAL.CONTENT.PLANT.AVAILABILITY.CREATE)}
					/>
				);
			} else {
				configurations.push(
					<AvailabilityConfig
						singular
						key={`renewable-${plant.id}-availability-config-`}
						plant={{ id: plant.id, name: plant.name, _type: DATA.PLANTS.TYPES.RENEWABLE }}
						config={{ availabilities: plant.disponibilidades_renovables }}
						onChanged={fetch}
					/>
				);
			}

			if (warnings.length > 0) {
				warnings.unshift(<div key={`renewable-${plant.id}-warning-spacer`} className="sm-es"></div>);
			}

			return (
				<Card
					key={plant.id}
					headers={headers}
					headerSizes={headerSizes}
					cardType={Common.CardType.PLANT}
					data={plant}
					plantType={Common.PLANT_TYPES[0]}
					canEdit
					canRemove
					canExpand
					removalProps={{
						entity: 'planta',
						prefix: 'esta',
						id: plant.id,
						validationSchema: Schemas.stringBasic('name', 'El nombre de la planta es requerida'),
						formInitialValues: { name: '' },
						formFields: [{ key: 'name', display: 'Nombre' }],
						validationMethod: Methods.compare(plant.name),
						removeEndpoint: RenewablePlantController.remove,
						removeMessage: `La planta (${plant.name}) ha sido eliminada!`,
					}}
					onUpdate={() => fetch()}
					onRemove={() => fetch()}
				>
					<div className={Common.colJoin(12)}>
						<div key={`renewable-${plant.id}-meta-1`} className="row zero">
							<div className={Common.colJoin(6) + ' zero'}>
								<Meta title="Nombre" content={titleCaseName} />
							</div>
							<div className={Common.colJoin(6) + ' zero'}>
								<Meta title="Unidades equivalentes" content={plant.unidades_equiv} />
							</div>
						</div>

						<div key={`renewable-${plant.id}-meta-2`} className="row zero">
							<div className={Common.colJoin(3) + ' zero'}>
								<Meta title="Código" content={plant.code} />
							</div>
							<div className={Common.colJoin(3) + ' zero'}>
								<Meta title="Área" content={plant.area.name} />
							</div>
							<div className={Common.colJoin(3) + ' zero'}>
								<Meta title="Tipo" content={formatEnergyType(plant.type_planta)} />
							</div>
							<div className={Common.colJoin(3) + ' zero'}>
								<Meta title="Capacidad" content={plant.capacidad} />
							</div>
						</div>

						{warnings}

						{configurations}
					</div>
				</Card>
			);
		});

	const requestFailed = error && !loading;
	const noResults = !loading && !error && items.length === 0;

	return (
		<div className="inherit-w">
			{requestFailed && <NoConnection />}
			{loading && <Spinner />}

			{noResults ? (
				<NoResults />
			) : (
				<div className={Common.rowBetween() + ' inherit-w'}>
					<div className={Common.colJoin(12) + ' fixed-sc roll-md'}>{renderPlants()}</div>
				</div>
			)}
		</div>
	);
};

export default React.memo(RenewablePlantTab);
