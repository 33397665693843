import env from '../../../../env.json';
import { api } from '../../axios';
import { SyncController } from '..';

const controller = env.REACT_APP_API_VERSION + 'basic_data';

/**
 * Realiza una petición con el método GET a la API bajo el controlador /basic_data, en la
 * ruta /list con el fin de listar las plantas de racionamiento existentes en la base de datos.
 */
export const list = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller}/list`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /basic_data, en la
 * ruta /create con el fin de crear una planta de racionamiento.
 * @param {Object} body Cuerpo de la petición con la planta de racionamiento a crear.
 */
export const create = (body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/create`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador
 * /basic_data, en la ruta /create_multiple con el fin de crear varios
 * registros de planta de racionamiento.
 * @param {Object} body Datos de la configuración a agregar.
 */
export const createMultiple = (body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/create_multiple`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método PUT a la API bajo el controlador /basic_data, en la
 * ruta /update_multiple con el fin de actualizar varios registros de planta de racionamiento.
 * @param {Object} body Cuerpo de la petición con los cambios de las planta de racionamiento.
 */
export const updateMultiple = (body) =>
	new Promise((resolve, reject) => {
		api.put(`${controller}/update_multiple`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador /basic_data, en
 * la ruta /:id/delete con el fin de eliminar una planta de racionamiento de la base de datos de forma permanente.
 * @param {number} id id correspondiente de la planta de racionamiento a eliminar.
 */
export const remove = (id) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller}/${id}/delete`)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador
 * /basic_data, en la ruta /delete_all con el fin de eliminar
 * una configuración de disponibilidad para una planta de racionamiento.
 */
export const removeAll = () =>
	new Promise((resolve, reject) => {
		api.delete(`${controller}/delete_all`)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});
