import React, { Fragment } from 'react';

// COMPONENTS
import ExecuteEnergyDispatch from './execute/ExecuteEnergyDispatch';
import EnergyDispatchHistory from './history/EnergyDispatchHistory';
import Market from './market/market';
import Plant from './plant/plant';
import Constants from './constants/constants';
import { Error404 } from '../../error404/error404';

// REACT ROUTER
import { Route, Redirect, Switch } from 'react-router-dom';
import { ROUTES } from '../../../../utils/routes';
import { ProtectedRolRoute } from '../../../routing';

import { Common } from '../../../../utils';

/**
 * Enrutador que se encarga de hacer el renderizado segun la ruta de navegación
 * actual. Adicionalmente para cada ruta se renderizara la barra de navegación de
 * la applicación. En caso de que el enrutador no logre discernir que vista se ha
 * de renderizar le mostrara al usuario la pantalla de 404.
 */
const EnergyDispatchRouter = () => {
	return (
		<Fragment>
			<Switch>
				<Route
					exact
					path={ROUTES.CLIENT.ENERGY_DISPATCH.BASE}
					render={() => <Redirect to={ROUTES.CLIENT.ENERGY_DISPATCH.EXECUTE} />}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.ENERGY_DISPATCH.EXECUTE}
					component={ExecuteEnergyDispatch}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.ENERGY_DISPATCH.HISTORY}
					component={EnergyDispatchHistory}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.ENERGY_DISPATCH.MARKETS}
					component={Market}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.ENERGY_DISPATCH.PLANTS}
					component={Plant}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.ENERGY_DISPATCH.CONSTANTS}
					component={Constants}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute path="/" component={Error404} roles={Common.getDefaultRights()} />
			</Switch>
		</Fragment>
	);
};

export default EnergyDispatchRouter;
