import React, { useCallback, useState } from 'react';
import { AppBar, Tab } from '@material-ui/core';
import { TabContext, TabList } from '@material-ui/lab';

import { Common, DATA } from '../../../../../utils';
import { AddButton, CenteredContainer, Modal, StyledTabPanel } from '../../../../layout';
import ConstantTab from '../../../../constants/ConstantTab';
import OptimizersTab from '../../../../constants/OptimizersTab';
import { ConstantSaveForm, ServingConfigForm } from '../../../../forms/constants';
import ServingPlantTab from '../../../../constants/ServingPlantTab';

/**
 * Componente encargado de renderizar el modulo de mercados de energia.
 */
const Constants = () => {
	const hasRights = Common.isInAdmin();

	const startTab = hasRights ? DATA.CONSTANTS.TABS.SERVING_PLANTS : DATA.CONSTANTS.TABS.CONSTANTS;

	const [tabValue, setTabValue] = useState(startTab);
	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState(startTab);
	const [refresh, setRefresh] = useState(false);
	const [servings, setServings] = useState([]);

	const tabHandler = (_e, val) => {
		setTabValue(val);
		setModalContent(val);
	};

	const onShowModal = () => {
		setShowModal(true);
	};

	const itemsFetched = useCallback(() => {
		setRefresh(false);
	}, []);

	const itemSavedHandler = useCallback(() => {
		setRefresh(true);
	}, []);

	const getModalContent = () => {
		switch (modalContent) {
			case DATA.CONSTANTS.TABS.SERVING_PLANTS:
				return (
					<ServingConfigForm
						isCreation
						onUpdate={itemSavedHandler}
						onCancel={() => setShowModal(false)}
					/>
				);

			case DATA.CONSTANTS.TABS.CONSTANTS:
				return (
					<ConstantSaveForm
						isCreation
						onUpdate={itemSavedHandler}
						onCancel={() => setShowModal(false)}
					/>
				);

			default:
				return <p>{modalContent}</p>;
		}
	};

	const renderModal = () => {
		const content = getModalContent();

		return (
			<Modal show={showModal} onClose={() => setShowModal(false)}>
				{content}
			</Modal>
		);
	};

	const showAddForServing =
		tabValue === DATA.CONSTANTS.TABS.SERVING_PLANTS && servings.length === 0 && Common.isInAdmin();
	const showAddForConstants = tabValue === DATA.CONSTANTS.TABS.CONSTANTS && Common.isInManager();
	const showAddButton = showAddForServing || showAddForConstants;

	const renderTabHeaders = () => {
		const servingPlant = (
			<Tab
				label="Plantas de racionamiento"
				value={DATA.CONSTANTS.TABS.SERVING_PLANTS}
				key={DATA.CONSTANTS.TABS.SERVING_PLANTS}
			/>
		);
		const constants = (
			<Tab
				label="Constantes"
				value={DATA.CONSTANTS.TABS.CONSTANTS}
				key={DATA.CONSTANTS.TABS.CONSTANTS}
			/>
		);
		const optimizers = (
			<Tab
				label="Optimizadores"
				value={DATA.CONSTANTS.TABS.OPTIMIZERS}
				key={DATA.CONSTANTS.TABS.OPTIMIZERS}
			/>
		);
		const tabs = [];

		if (hasRights) tabs.push(servingPlant);
		tabs.push(constants);
		tabs.push(optimizers);

		return tabs;
	};

	return (
		<>
			<CenteredContainer colSize={10}>
				<div className="row inherit-w zero">
					<h2 className="tab-title">Informaci&oacute;n Constante</h2>
				</div>
				<hr className="m-0" />
				<TabContext value={tabValue}>
					<div className="row inherit-w zero">
						<AppBar
							position="static"
							elevation={0}
							style={{ background: '#fafafa', color: '#1c3545' }}
						>
							<TabList onChange={tabHandler}>{renderTabHeaders()}</TabList>
						</AppBar>
					</div>
					<br />
					<div className="row inherit-w zero">
						{hasRights && (
							<StyledTabPanel value={DATA.CONSTANTS.TABS.SERVING_PLANTS} className="inherit-w">
								<ServingPlantTab
									refresh={refresh}
									fetchCallback={itemsFetched}
									shareItems={setServings}
								/>
							</StyledTabPanel>
						)}
						<StyledTabPanel value={DATA.CONSTANTS.TABS.CONSTANTS} className="inherit-w">
							<ConstantTab refresh={refresh} fetchCallback={itemsFetched} />
						</StyledTabPanel>
						<StyledTabPanel value={DATA.CONSTANTS.TABS.OPTIMIZERS} className="inherit-w">
							<OptimizersTab />
						</StyledTabPanel>
					</div>
				</TabContext>

				{showAddButton && <AddButton onClick={onShowModal} />}
			</CenteredContainer>

			{showModal && renderModal()}
		</>
	);
};

export default Constants;
