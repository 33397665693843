import { useEffect, useState } from 'react';
import { ChromaHelper } from '../../utils';

/**
 * Hook que se encarga generar una tupla en base a dos vectores de entrada.
 * @param {Array} x
 * @param {Array} y
 * @returns {Array}
 */
const useBarGenerator = (x, y, barIds) => {
	const [bars, setBars] = useState([]);
	const [xLimits, setXlimits] = useState({ min: 0, max: 0 });
	const [yLimits, setYlimits] = useState({ min: 0, max: 0 });
	const [colors, setColors] = useState({});

	useEffect(() => {
		const transformToDate = (input) => {
			const hasZ = input.endsWith('Z');
			const asDate = hasZ ? new Date(input) : new Date(input + 'Z');
			const offset = new Date().getTimezoneOffset() * 60 * 1000;
			return new Date(asDate.getTime() + offset);
		};

		const mapToBarEntries = (xValues, yValues, yKeys) => {
			const isXValid = xValues && xValues.length > 0;
			const isYValid = yValues && yValues.length > 0;
			const areAxisValid = isXValid && isYValid && xValues.length === yValues[0].length;

			if (areAxisValid) {
				const newPoints = xValues.map((xElement, index) => {
					const isNan = isNaN(xElement);
					let obj = {
						xAxis: null,
					};
					if (!isNan) {
						obj.xAxis = xElement;
					} else {
						const asDate = transformToDate(xElement);
						obj.xAxis = asDate;
					}

					yKeys.forEach((yKey, yIndex) => {
						obj[yKey] = yValues[yIndex][index];
					});
					return obj;
				});

				let minX = Math.min(...xValues);
				let maxX = Math.max(...xValues);
				let xIsTime = false;

				if (isNaN(minX) && isNaN(maxX)) {
					const asDates = newPoints.map((point) => point.xAxis);
					minX = new Date(Math.min.apply(null, asDates));
					maxX = new Date(Math.max.apply(null, asDates));
					xIsTime = true;
				}

				const minY = Math.min(...yValues.flat());
				const maxY = Math.max(...yValues.flat());

				const newDelta = maxY - minY > 0 ? 10 ** Math.floor(Math.log10(maxY - minY)) : 0;

				return {
					xIsTime,
					delta: newDelta,
					data: newPoints,
					limits: {
						x: { min: minX, max: maxX },
						y: { min: minY, max: maxY },
					},
				};
			} else {
				return {
					xIsTime: false,
					delta: 0,
					data: [],
					limits: {
						x: {
							min: 0,
							max: 0,
						},
						y: {
							min: 0,
							max: 0,
						},
					},
				};
			}
		};

		const newBars = mapToBarEntries(x, y, barIds);
		const newBarColors = ChromaHelper.getPallete(barIds.length);
		let dict = {};
		barIds.forEach((barId, index) => {
			dict[barId] = newBarColors[index];
		});

		setXlimits(newBars.limits.x);
		setYlimits(newBars.limits.y);
		setColors(dict);

		setBars(newBars);
	}, [x, y, barIds]);

	return {
		bars,
		colors,
		limits: {
			x: xLimits,
			y: yLimits,
		},
	};
};

export default useBarGenerator;
