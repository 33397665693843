import React, { useEffect } from 'react';
import { Common, DATA, HidraulicPlantController, Methods, Schemas } from '../../utils';
import { toTitleCase } from '../../utils/pipes';
import MissingConfigurationWarning from '../layout/missing-configuration/MissingConfigurationWarning';
import { Card, Meta, NoConnection, NoResults, Spinner } from '../layout';
import { ExchangesConfig } from '../configuration';
import { useListRequest } from '../hooks';
import AvailabilityConfig from '../configuration/plant/AvailabilityConfig';
import normalizeAvailability from '../../utils/transformations/normalize-availaibilty';

const HidraulicPlantTab = ({ onShowModal, refresh, fetchCallback }) => {
	const { items, loading, error, fetch } = useListRequest(HidraulicPlantController, fetchCallback);

	useEffect(() => {
		if (refresh) fetch();
	}, [refresh, fetch]);

	const renderPlants = () =>
		items.map((plant) => {
			const titleCaseName = toTitleCase(plant.name);
			const headers = [titleCaseName, plant.code];
			const headerSizes = [4, 4, 3, 1];
			let warnings = [];
			let configurations = [];

			const showUnitWarning = plant.mercados_hidraulicas.length === 0;
			if (showUnitWarning) {
				warnings.push(
					<MissingConfigurationWarning
						key={`hidro-${plant.id}-ex-config`}
						configurationName="Unidades Equivalentes"
						onClick={() => onShowModal(plant, DATA.MODAL.CONTENT.PLANT.EXCHANGE.CREATE)}
					/>
				);
			} else {
				configurations.push(
					<ExchangesConfig
						key={`hidro-${plant.id}-ex-config`}
						plant={plant}
						plantType={Common.PLANT_TYPES[1]}
						onChanged={fetch}
					/>
				);
			}

			const showAvailabilityWarning = plant.disponibilidades.length === 0;
			let maxCapacity = 0;
			if (showAvailabilityWarning) {
				warnings.push(
					<MissingConfigurationWarning
						key={`hidro-${plant.id}-ex-config-warning`}
						configurationName="Disponibilidades"
						onClick={() => onShowModal(plant, DATA.MODAL.CONTENT.PLANT.AVAILABILITY.CREATE)}
					/>
				);
			} else {
				const availabilities = normalizeAvailability(plant);
				const flattened = availabilities.map((config) =>
					Math.max(...config.availabilities.map((a) => a.disponibilidad))
				);
				maxCapacity = flattened.reduce((a, b) => a + b, 0);

				for (const availability of availabilities) {
					configurations.push(
						<AvailabilityConfig
							key={`hidro-${plant.id}-availability-config-${availability.subunit}`}
							plant={{ id: plant.id, name: plant.name, _type: DATA.PLANTS.TYPES.HIDRAULIC }}
							config={availability}
							onChanged={fetch}
						/>
					);
				}
			}

			if (warnings.length > 0) {
				warnings.unshift(
					<div key={`hidro-${plant.id}-availability-config-warning`} className="sm-es"></div>
				);
			}

			return (
				<Card
					key={plant.id}
					headers={headers}
					headerSizes={headerSizes}
					cardType={Common.CardType.PLANT}
					data={plant}
					plantType={Common.PLANT_TYPES[1]}
					canEdit
					canRemove
					canExpand
					removalProps={{
						entity: 'planta',
						prefix: 'esta',
						id: plant.id,
						validationSchema: Schemas.stringBasic('name', 'El nombre de la planta es requerida'),
						formInitialValues: { name: '' },
						formFields: [{ key: 'name', display: 'Nombre' }],
						validationMethod: Methods.compare(plant.name),
						removeEndpoint: HidraulicPlantController.remove,
						removeMessage: `La planta (${plant.name}) ha sido eliminada!`,
					}}
					onUpdate={() => fetch()}
					onRemove={() => fetch()}
				>
					<div className={Common.colJoin(12)}>
						<div key={`hidro-${plant.id}-meta-1`} className="row zero">
							<div className={Common.colJoin(6) + ' zero'}>
								<Meta title="Nombre" content={titleCaseName} />
							</div>
							<div className={Common.colJoin(3) + ' zero'}>
								<Meta title="Código" content={plant.code} />
							</div>
							<div className={Common.colJoin(3) + ' zero'}>
								<Meta title="Área" content={plant.area.name} />
							</div>
						</div>
						<div key={`hidro-${plant.id}-meta-2`} className="row zero">
							<div className={Common.colJoin(6) + ' zero'}>
								<Meta title="Capacidad máxima" content={maxCapacity} />
							</div>
							<div className={Common.colJoin(6) + ' zero'}>
								<Meta title="Unidades equivalentes" content={plant.unidades_equiv} />
							</div>
						</div>

						{warnings}

						{configurations}
					</div>
				</Card>
			);
		});

	return (
		<div className="inherit-w">
			{error && !loading && <NoConnection />}
			{loading && <Spinner />}

			{!loading && !error && items.length === 0 ? (
				<NoResults />
			) : (
				<div className={Common.rowBetween() + ' inherit-w'}>
					<div className={Common.colJoin(12) + ' fixed-scroll-md'}>{renderPlants()}</div>
				</div>
			)}
		</div>
	);
};

export default HidraulicPlantTab;
