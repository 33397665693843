import env from '../../../env.json';
import { api } from '../axios';

const controller = env.REACT_APP_API_VERSION + 'auth';

/**
 * Realiza una petición con el método POST a la API bajo el controlador /auth, en la ruta /login con el fin de iniciar sesión en la aplicación.
 * @param {Object} body Cuerpo de la petición con las credenciales de acceso.
 */
export const login = (body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/login`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /auth, en la ruta /register con el fin de registrar un usuario en la base de datos.
 * @param {Object} body Cuerpo de la petición con el usuario a registrar.
 */
export const register = (body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/register`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /auth, en la ruta /restore_password con el fin de restaurar la contraseña de un usuario.
 * @param {Object} body Cuerpo de la petición con el correo al cual enviar la nueva contraseña.
 */
export const restorePassword = (body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/restore_password`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
