import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useField } from 'formik';

export const DefaultDateField = ({ label, placeholder, type, ...props }) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';

    return (
        <KeyboardDatePicker
            inputVariant="outlined"
            color="primary"
            fullWidth
            {...field}
            label={label}
            format="yyyy-MM-dd"
            helperText={errorText}
            error={!!errorText}
            {...props}
        />
    );
};
