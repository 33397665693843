import { useEffect, useRef } from 'react';

/**
 * Hook que se encarga de almacenar el estado previo de una variable.
 * @param {any} value valor a observar.
 */
export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
