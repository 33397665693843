import React, { useEffect, useState } from 'react';

import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { Button, CircularProgress, FormControl, FormHelperText } from '@material-ui/core';

import { DefaultField, DefaultSelectField } from '../default';
import { AuthController, Common, GroupController, UserController } from '../../../utils';

const validationSchema = yup.object({
    first_name: yup.string('El primer nombre es de tipo texto').required('El primer nombre es requerido'),
    middle_name: yup.string('El segundo nombre es de tipo texto'),
    last_name: yup.string('El primer apellido es de tipo texto').required('El primer apellido es requerido'),
    sur_name: yup.string('El segundo apellido es de tipo texto'),
    username: yup.string('El usuario es de tipo texto').required('El usuario es requerido'),
    email: yup
        .string('El correo electrónico es de tipo texto')
        .required('El correo electrónico es requerido')
        .email('El correo electronico es invalido'),
    groups_id: yup.array(),
    rol: yup.string().required('El rol es requerido'),
});

/**
 * API CALLS
 */
/**
 * Método para obtener el listado de grupos.
 */
const FetchGroups = () => {
    const [groups, setGroups] = useState([]);

    const fetch = async () => {
        try {
            const res = await GroupController.list();
            if (res.status === 200) {
                const _groups = res.data.map((g) => {
                    return { id: g.id, value: g.name };
                });
                setGroups(_groups);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    return groups;
};

export const UserSaveForm = ({ data: record, onCancel, isCreation = false, onUpdate }) => {
    const groups = FetchGroups();

    const formInitialValues = {
        first_name: record ? record.first_name || '' : '',
        middle_name: record ? record.middle_name || '' : '',
        last_name: record ? record.last_name || '' : '',
        sur_name: record ? record.sur_name || '' : '',
        username: record ? record.username || '' : '',
        email: record ? record.email || '' : '',
        groups_id: record ? record.tgroup.map((g) => g.name) : [],
        rol: record ? Common.USER_ROLES[record.rol] || 0 : '',
    };

    /**
     * Método para registrar o actualizar la información de un usuario.
     */
    const onFormSubmit = async (data, setSubmitting, resetForm) => {
        try {
            setSubmitting(true);

            let body = { ...data };
            delete body.group;

            body.rol = Common.RoleEnum[body.rol.trim().replace(/ /g, '_').toUpperCase()];

            const res = isCreation ? await AuthController.register(body) : await UserController.update(record.id, body);
            const msj = isCreation ? 'Usuario registrado!' : 'Usuario actualizado!';

            if (res.status < 400) {
                resetForm();
                Common.fireMiniMessage(msj);
                const ids = data.groups_id.map((id) => groups.find((g) => g.value === id).id);

                if (ids.length > 0) {
                    const group_res = await GroupController.addUser(res.data.id, ids);
                    if (group_res.status < 400) Common.fireMiniMessage(group_res.data.message);
                }

                onCancel();
                onUpdate();
            }
            setSubmitting(false);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
        >
            {({ touched, errors, isSubmitting }) => (
                <Form className="inherit-w">
                    <div className={Common.rowBetween() + ' zero'}>
                        <div className={Common.colJoin(6) + ' zero-l'}>
                            <DefaultField name="first_name" label="Primer nombre" size="small" />
                        </div>
                        <div className={Common.colJoin(6) + ' zero-r'}>
                            <DefaultField name="middle_name" label="Segundo nombre" size="small" />
                        </div>
                    </div>

                    <div className="sm-es"></div>

                    <div className={Common.rowBetween() + ' zero'}>
                        <div className={Common.colJoin(6) + ' zero-l'}>
                            <DefaultField name="last_name" label="Primer apellido" size="small" />
                        </div>
                        <div className={Common.colJoin(6) + ' zero-r'}>
                            <DefaultField name="sur_name" label="Segundo apellido" size="small" />
                        </div>
                    </div>
                    <div className="sm-es"></div>

                    <DefaultField name="username" label="Usuario" size="small" />
                    <div className="sm-es"></div>

                    <DefaultField name="email" label="Correo electrónico" placeholder="name@example.com" size="small" />
                    <div className="sm-es"></div>

                    <div className={Common.rowBetween() + ' zero'}>
                        <div className={Common.colJoin(6) + ' zero-l'}>
                            <FormControl variant="outlined" size="small" style={{ minWidth: '100%', maxWidth: '100%' }}>
                                <Field
                                    type="text"
                                    component={DefaultSelectField}
                                    name="groups_id"
                                    multiple={true}
                                    inputProps={{
                                        opts: groups,
                                        label: 'Grupos',
                                        id: 'groups-selection',
                                        name: 'groups_id',
                                    }}
                                ></Field>
                            </FormControl>
                        </div>
                        <div className={Common.colJoin(6) + ' zero-r'}>
                            <FormControl
                                variant="outlined"
                                size="small"
                                style={{ minWidth: '100%', maxWidth: '100%' }}
                                error={touched.hasOwnProperty('rol') && errors.hasOwnProperty('rol')}
                            >
                                <Field
                                    type="text"
                                    component={DefaultSelectField}
                                    name="rol"
                                    inputProps={{
                                        opts: Common.GET_USER_ROLES_AS_KEY_PAIR(),
                                        label: 'Rol',
                                        id: 'rol-selection',
                                        name: 'rol',
                                    }}
                                ></Field>
                                {touched.hasOwnProperty('rol') && errors.hasOwnProperty('rol') ? (
                                    <FormHelperText>{errors.rol}</FormHelperText>
                                ) : null}
                            </FormControl>
                        </div>
                    </div>
                    <div className="md-es"></div>
                    <div className={Common.rowBetweenMiddle()}>
                        <div className={Common.colJoinLg_MdSmXs(4, 5)}>
                            <Button
                                color="default"
                                variant="contained"
                                size="medium"
                                className="ls-custom btn-cancel btn-rmv"
                                onClick={onCancel}
                                disabled={isSubmitting}
                                fullWidth
                                disableElevation
                            >
                                Cancelar
                            </Button>
                        </div>
                        <div className={Common.colJoinLg_MdSmXs(4, 5)}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                size="medium"
                                className="ls-custom fl-right"
                                disabled={isSubmitting}
                                fullWidth
                                disableElevation
                            >
                                {isCreation ? 'Agregar' : 'Editar'} &nbsp;
                                {isSubmitting ? <CircularProgress size={20} /> : null}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
