import React, { useEffect, useRef, useState } from 'react';
import { axisBottom, axisLeft, curveCardinal, line, scaleLinear, scaleTime, select } from 'd3';

import classes from './line-chart.module.scss';

import { ChromaHelper, DateHelper } from '../../../utils';
import { useChartDataGenerator, useResizeObserver } from '../../hooks';
import LinechartControls from './LinechartControls';
import LinechartActions from './LinechartActions';

const parseX = (values) => {
	return values.map((value, index) => (isNaN(value) ? DateHelper.strToDateTime(value) : value));
};

const ImprovedLinechart = ({ chartName, x, y, lineIds, disableBezier = false }) => {
	const [hiddenLines, setHiddenLines] = useState(lineIds.slice(1));
	const [xlsx, setXlsx] = React.useState([]);
	const [xlsxCols, setXlsxCols] = React.useState([]);
	const svgRef = useRef();
	const wrapperRef = useRef();
	const dimensions = useResizeObserver(wrapperRef);
	const chartData = useChartDataGenerator(x, y, lineIds);

	const xAsDates = parseX(x);
	const range = [xAsDates[0], xAsDates[xAsDates.length - 1]];
	const [limits, setLimits] = useState(range);
	const { lines: chartLines } = chartData;
	const colors = chartData.lines.map((line) => line.color);

	/**
	 * Crear el formato para el archivo de excel.
	 */
	useEffect(() => {
		if (chartLines.length === 0) return;

		const lineMapper = chartLines.map((line, index) => {
			return {
				id: line.id,
				index: index,
			};
		});

		const excelCols = ['fecha', 'hora', ...lineMapper.map((line) => line.id)];

		const excelData = [];

		for (let index = 0; index < chartLines[0].points.length; index++) {
			const date = chartLines[0].points[index].xAxis;
			const dateString = `${date.getFullYear()}-${(date.getMonth() + 1)
				.toString()
				.padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
			const hour = date.getHours() + 1;
			let row = {
				fecha: dateString,
				hora: hour,
			};

			for (const line of lineMapper) {
				row[line.id] = chartLines[line.index].points[index].yAxis;
			}

			excelData.push(row);
		}

		setXlsxCols(excelCols);
		setXlsx(excelData);
	}, [chartLines]);

	/**
	 * Generar grafico usando la libreria D3
	 */
	useEffect(() => {
		if (chartData.lines.length === 0) return;

		const filtered = {
			...chartData,
			lines: chartData.lines
				.filter((line) => !hiddenLines.includes(line.id))
				.map((line) => {
					const points = line.points.filter(
						(point) => point.xAxis.getTime() >= limits[0] && point.xAxis.getTime() <= limits[1]
					);

					return { ...line, points };
				}),
		};

		const svg = select(svgRef.current);

		// DATA UPDATE i.e: variable hidden
		for (const hidden of hiddenLines) {
			svg.selectAll(`.linechart-line-${hidden}`).remove();
			svg.selectAll(`.linechart-dot-${hidden}`).remove();
		}

		const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();

		const xScale = scaleTime().domain([limits[0], limits[1]]).range([0, width]);

		const delta = filtered.delta > 0 ? filtered.delta : 1;

		const yScale = scaleLinear()
			.domain([filtered.limits.y.min - delta, filtered.limits.y.max + delta])
			.range([height, 0]);

		const lineGenerator = line()
			.x((record, index) => xScale(record.xAxis))
			.y((record) => yScale(record.yAxis));

		if (!disableBezier) {
			lineGenerator.curve(curveCardinal);
		}

		const lines = svg.select(`#sisprover-linechart-lines-${chartName}`);

		// render the line
		for (const line of filtered.lines) {
			const lineColor = line.color;

			lines
				.selectAll(`.linechart-line-${line.id}`)
				.data([line.points])
				.join('path')
				.attr('class', `linechart-line-${line.id}`)
				.attr('stroke', (record, index) => lineColor)
				.attr('stroke-width', '2px')
				.attr('fill', 'none')
				.attr('d', lineGenerator);

			// render dots
			const dotColor = line.color;
			lines
				.selectAll(`.linechart-dot-${line.id}`)
				.data(line.points)
				.join('circle')
				.attr('class', `clickable linechart-dot-${line.id}`)
				.attr('stroke', dotColor)
				.attr('r', 2)
				.attr('fill', dotColor)
				.attr('cx', (point, index) => xScale(new Date(point.xAxis)))
				.attr('cy', (point, index) => yScale(point.yAxis))
				.on('mouseover', (event, { xAxis, yAxis }) => {
					select(event.target).attr('r', 4).attr('fill', ChromaHelper.brighten(dotColor, 2));

					svg.selectAll('.d3-tooltip-bg')
						.data([event])
						.join((enter) => enter.append('rect').attr('y', yScale(yAxis) - 120))
						.attr('class', 'd3-tooltip-bg')
						.attr('x', xScale(xAxis) - 80)
						.attr('text-anchor', 'middle')
						.attr('width', 165)
						.attr('height', '60px')
						.attr('fill', '#1A202C')
						.transition()
						.attr('y', yScale(yAxis) - 70)
						.attr('fill-opacity', 0.5);

					svg.selectAll('.d3-tooltip-title')
						.data([event])
						.join((enter) => enter.append('text').attr('y', yScale(yAxis) - 110))
						.attr('class', 'd3-tooltip-title')
						.text(`${DateHelper.formatDate(xAxis)}:`)
						.attr('font-family', "'Lato', sans-serif")
						.attr('x', xScale(xAxis))
						.attr('text-anchor', 'middle')
						.attr('width', 200)
						.attr('height', 30)
						.style('fill', 'white')
						.transition()
						.attr('y', yScale(yAxis) - 50)
						.attr('opacity', 1);

					svg.selectAll('.d3-tooltip-text')
						.data([event])
						.join((enter) => enter.append('text').attr('y', yScale(yAxis) - 110))
						.attr('class', 'd3-tooltip-text')
						.text(`${yAxis} MW/h`)
						.attr('font-family', "'Lato', sans-serif")
						.attr('x', xScale(xAxis))
						.attr('text-anchor', 'middle')
						.attr('width', 200)
						.attr('height', 30)
						.style('fill', 'white')
						.transition()
						.attr('y', yScale(yAxis) - 20)
						.attr('opacity', 1);
				})
				.on('mouseleave', (event, { date, value }) => {
					// if (date < selection[0] || date > selection[1])
					select(event.target).attr('r', 2).attr('fill', dotColor);
					svg.select('.d3-tooltip-bg').remove();
					svg.select('.d3-tooltip-title').remove();
					svg.select('.d3-tooltip-text').remove();
				});
		}

		const xTicks = filtered.lines[0].points.length > 10 ? 16 : filtered.lines[0].points.length;
		// axes
		const xAxis = axisBottom(xScale).ticks(xTicks).tickFormat(DateHelper.d3Format);
		svg.select('.linechart-x-axis')
			.attr('transform', `translate(0, ${height})`)
			.call(xAxis)
			.selectAll('text')
			.attr('y', 10)
			.attr('x', -10)
			.attr('transform', 'rotate(-45)')
			.style('text-anchor', 'end');

		const yAxis = axisLeft(yScale).ticks(10);
		svg.select('.linechart-y-axis').call(yAxis);

		const margin = {
			top: 100,
			left: 60,
		};

		// Add X axis label:
		svg.select('.linechart-x-axis-label')
			.join('text')
			.attr('class', 'linechart-x-axis-label')
			.attr('text-anchor', 'end')
			.attr('x', width)
			.attr('y', height + margin.top)
			.text('Tiempo (Horas)');

		// Y axis label:
		svg.select('.linechart-y-axis-label')
			.join('text')
			.attr('class', 'linechart-y-axis-label')
			.attr('text-anchor', 'end')
			.attr('transform', 'rotate(-90)')
			.attr('y', -margin.left + 10)
			.attr('x', 0)
			.text('MW/h');
	}, [chartName, chartData, dimensions, disableBezier, hiddenLines, limits]);

	/**
	 * Este metodo se encarga de actualizar el estado de las lineas ocultas del
	 * diagrama tomando en cuenta el estado al momento de intentar hacer la
	 * actualización
	 * @param {string} line line to toggle
	 * @param {boolean} isHidden currentState
	 */
	const toggleHiddenLine = (line, isHidden) => {
		setHiddenLines((previousHiddenLines) => {
			if (isHidden) {
				// we want it to be visible
				const hiddenIndex = previousHiddenLines.indexOf(line);
				const hiddenLinesCopy = [...previousHiddenLines];
				hiddenLinesCopy.splice(hiddenIndex, 1);
				return hiddenLinesCopy;
			} else {
				// we want it to be hidden
				if (previousHiddenLines.length === lineIds.length - 1) {
					return previousHiddenLines;
				}
				return [...previousHiddenLines, line];
			}
		});
	};

	/**
	 * Actualizar los valores del slider que controla el filtro de fechas
	 */
	const handleSliderChange = (event, newValue) => {
		if (newValue[0] < newValue[1]) setLimits(newValue);
	};

	/**
	 * Este metodo genera un vector en formato de base 64
	 * @returns {string} cadena de texto en base 64
	 */
	const svg2str = () => {
		const { width, height } = dimensions;

		const svg = document.getElementById(`sisprover-linechart-container-${chartName}`).cloneNode(true);
		svg.setAttribute('height', height + 130);
		svg.setAttribute('width', width + 100);

		for (const child of svg.children) {
			child.setAttribute('style', 'transform: translateX(70px)');
		}

		let svgAsXML = new XMLSerializer().serializeToString(svg);
		svgAsXML = svgAsXML.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
		svgAsXML = svgAsXML.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix
		return svgAsXML;
	};

	/**
	 * Descargar diagrama como .svg
	 */
	const downloadAsSVG = () => {
		const svgStr = svg2str();
		let a = document.createElement('a');
		a.download = `${chartName} ${DateHelper.formatDate(new Date(), 'yyyy-MM-dd')}.svg`;
		a.href = 'data:image/svg+xml,' + encodeURIComponent(svgStr);
		a.target = '_blank';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	/**
	 * Descargar diagrama como .png
	 */
	const downloadAsPNG = () => {
		const { width, height } = dimensions;
		const svgStr = svg2str();
		const src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgStr)));

		let canvas = document.createElement('canvas');
		let context = canvas.getContext('2d');

		canvas.width = width + 100;
		canvas.height = height + 130;

		let image = new Image();
		image.onload = function () {
			context.clearRect(0, 0, width + 100, height + 130);
			context.drawImage(image, 0, 0, width + 100, height + 130);

			canvas.toBlob(function (blob) {
				let a = document.createElement('a');
				a.download = `${chartName} ${DateHelper.formatDate(new Date(), 'yyyy-MM-dd')}.png`;
				a.href = URL.createObjectURL(blob);
				a.target = '_blank';
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			}, 'image/png');
		};

		image.src = src;
	};

	return (
		<>
			{lineIds.length > 1 && (
				<LinechartControls
					chartName={chartName}
					lines={lineIds}
					colors={colors}
					hidden={hiddenLines}
					onToggle={toggleHiddenLine}
					sliderValue={limits}
					sliderRange={range}
					onSliderChange={handleSliderChange}
				/>
			)}
			<div id={`sisprover-linechart-wrapper-${chartName}`} ref={wrapperRef} className={classes.root}>
				<svg
					id={`sisprover-linechart-container-${chartName}`}
					ref={svgRef}
					className={classes.svg}
					style={{ backgroundSize: '5% 5%' }}
				>
					<g id={`sisprover-linechart-info-${chartName}`} className={classes.svg_container}>
						<g className="linechart-x-axis"></g>
						<text className="linechart-x-axis-label"></text>
						<g className="linechart-y-axis"></g>
						<text className="linechart-y-axis-label"></text>
						<g className="linechart-brush"></g>
					</g>
					<g id={`sisprover-linechart-lines-${chartName}`} className={classes.svg_container}>
						<g className="linechart-x-axis"></g>
						<text className="linechart-x-axis-label"></text>
						<g className="linechart-y-axis"></g>
						<text className="linechart-y-axis-label"></text>
						<g className="linechart-brush"></g>
					</g>
				</svg>
			</div>
			{xlsxCols.length > 0 && xlsx.length > 0 && (
				<LinechartActions
					chartName={chartName}
					xlsx={xlsx}
					xlsxCols={xlsxCols}
					onDownloadSVG={downloadAsSVG}
					onDownloadPNG={downloadAsPNG}
				/>
			)}
		</>
	);
};

export default ImprovedLinechart;
