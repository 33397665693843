import React from 'react';

import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, CircularProgress } from '@material-ui/core';

import { DefaultField } from '../default';
import * as Common from '../../../utils/common';
import { UserController } from '../../../utils';

/**
 * Default props.
 */
const demoFormInitialValues = {
	email: '',
};

const demoValidationSchema = yup.object({
	email: yup
		.string('El correo electrónico es de tipo texto')
		.email('El campo es de tipo correo electrónico')
		.required('El correo electrónico es requerido'),
});

const demoValidationMethod = (value) => 'developer@dev.com'.toLowerCase() === value?.trim().toLowerCase();

const RemovalForm = ({
	entity = 'usuario',
	prefix = 'este',
	id = undefined,
	validationSchema = demoValidationSchema,
	formInitialValues = demoFormInitialValues,
	formFields = [{ key: 'email', display: 'Email' }],
	validationMethod = demoValidationMethod, // should return true if validation is succedeed.
	removeEndpoint = UserController.remove,
	removeMessage = 'El usuario (developer@dev.com) ha sido eliminado',
	onRemove,
	onCancel,
}) => {
	const onFormSubmit = async (setSubmitting, resetForm) => {
		if (!id) return;
		setSubmitting(true);
		const res = await removeEndpoint(id);
		setSubmitting(false);

		if (res.status < 400) {
			resetForm();
			Common.fireMiniMessage(removeMessage);
			onCancel();
			onRemove();
		}
	};

	return (
		<Formik
			initialValues={formInitialValues}
			validationSchema={validationSchema}
			onSubmit={(_data, { setSubmitting, resetForm }) => onFormSubmit(setSubmitting, resetForm)}
		>
			{({ values, isSubmitting }) => (
				<Form className="inherit-w">
					<h3 className="alert-title">
						¿Desea eliminar {prefix} {entity}?
					</h3>
					<div className="sm-es"></div>
					<p>
						Para eliminar {prefix} {entity}, por favor ingrese el{' '}
						{formFields[0].display.toLowerCase()}:
					</p>
					<br />
					<DefaultField name={formFields[0].key} label={formFields[0].display} size="small" />
					<div className="md-es"></div>
					<div className={Common.rowBetweenMiddle()}>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								color="default"
								variant="contained"
								size="medium"
								className="ls-custom btn-cancel btn-rmv"
								onClick={onCancel}
								disabled={isSubmitting}
								fullWidth
								disableElevation
							>
								Cancelar
							</Button>
						</div>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								size="medium"
								className="ls-custom fl-right"
								disabled={
									validationMethod
										? isSubmitting || !validationMethod(values[formFields[0].key])
										: isSubmitting
								}
								fullWidth
								disableElevation
							>
								Eliminar&nbsp;
								{isSubmitting ? <CircularProgress size={20} /> : null}
							</Button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default RemovalForm;
