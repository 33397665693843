import React from 'react';
import { Common } from '../../utils';
import { Card, Meta } from '../layout';

const OptimizerList = ({ items, onItemUpdate }) => {
	return items.map((item) => {
		const upperName = item.name.toUpperCase();
		const headers = [upperName];
		const headerSizes = [12];

		return (
			<Card
				key={item.id}
				headers={headers}
				headerSizes={headerSizes}
				cardType={Common.CardType.OPTIMIZER}
				data={item}
				canEdit
				canExpand
				onUpdate={onItemUpdate}
			>
				<div className={Common.colJoin(12) + ' zero'}>
					<div key={`market-${item.id}-metadata`} className="row zero">
						<div className={Common.colJoin(3) + ' zero'}>
							<Meta title="Nombre" content={upperName} />
						</div>
						<div className={Common.colJoin(3) + ' zero'}>
							<Meta title="Tolerancia" content={item.tolerancia} />
						</div>
						<div className={Common.colJoin(3) + ' zero'}>
							<Meta title="T. MAX. ESPERA" content={item.t_max_espera} />
						</div>
						<div className={Common.colJoin(3) + ' zero'}>
							<Meta title="TEE" content={item.tee} />
						</div>
					</div>
				</div>
			</Card>
		);
	});
};

export default OptimizerList;
