import React, { useEffect } from 'react';
import { useListRequest } from '../hooks';
import { Card, Meta, NoConnection, NoResults, Spinner } from '../layout';
import { Common, DATA, Methods, Schemas, TermicPlantController } from '../../utils';
import { toTitleCase } from '../../utils/pipes';
import MissingConfigurationWarning from '../layout/missing-configuration/MissingConfigurationWarning';
import { AllPurposeConfig, ExchangesConfig } from '../configuration';
import normalizeAllPurpose from '../../utils/transformations/normalize-all-purpose-config';

const TermicPlantTab = ({ onShowModal, refresh, fetchCallback }) => {
	const { items, loading, error, fetch } = useListRequest(TermicPlantController, fetchCallback);

	useEffect(() => {
		if (refresh) fetch();
	}, [refresh, fetch]);

	const renderPlants = () =>
		items.map((plant) => {
			const titleCaseName = toTitleCase(plant.name);
			const headers = [titleCaseName, plant.code];
			const headerSizes = [4, 4, 3, 1];

			let warnings = [];
			let configurations = [];

			const showUnitWarning = plant.mercados_termicas.length === 0;
			if (showUnitWarning) {
				warnings.push(
					<MissingConfigurationWarning
						key={`termic-${plant.id}-ex-config`}
						configurationName="Unidades Equivalentes"
						onClick={() => onShowModal(plant, DATA.MODAL.CONTENT.PLANT.EXCHANGE.CREATE)}
					/>
				);
			} else {
				configurations.push(
					<ExchangesConfig
						key={`termic-${plant.id}-ex-config`}
						plant={plant}
						plantType={Common.PLANT_TYPES[2]}
						onChanged={fetch}
					/>
				);
			}

			const showMultipurposeWarning =
				plant.configuraciones.length === 0 &&
				plant.disponibilidades.length === 0 &&
				plant.arranques_calientes.length === 0 &&
				plant.arranques_tibios.length === 0 &&
				plant.arranques_frios.length === 0 &&
				plant.urs.length === 0 &&
				plant.min_urs.length === 0 &&
				plant.max_urs.length === 0 &&
				plant.drs.length === 0 &&
				plant.min_drs.length === 0 &&
				plant.max_drs.length === 0 &&
				plant.rampas_salida.length === 0 &&
				plant.rampas_aum_mod_3.length === 0 &&
				plant.rampas_dism_mod_3.length === 0;

			if (showMultipurposeWarning) {
				warnings.push(
					<MissingConfigurationWarning
						key={`termic-${plant.id}-config`}
						configurationName="Configuración multipropósito"
						onClick={() => onShowModal(plant, DATA.MODAL.CONTENT.PLANT.ALL_PURPOSE.CREATE)}
					/>
				);
			} else {
				const configs = normalizeAllPurpose(plant);
				for (const config of configs) {
					configurations.push(
						<AllPurposeConfig
							key={`termic-${plant.id}-config-${config.num_config}`}
							plant={plant}
							config={config}
							onChanged={fetch}
						/>
					);
				}
			}

			if (warnings.length > 0) {
				warnings.unshift(<div key={`hidro-${plant.id}-warning-spacer`} className="sm-es"></div>);
			}

			return (
				<Card
					key={plant.id}
					headers={headers}
					headerSizes={headerSizes}
					cardType={Common.CardType.PLANT}
					data={plant}
					plantType={Common.PLANT_TYPES[2]}
					canEdit
					canRemove
					canExpand
					removalProps={{
						entity: 'planta',
						prefix: 'esta',
						id: plant.id,
						validationSchema: Schemas.stringBasic('name', 'El nombre de la planta es requerida'),
						formInitialValues: { name: '' },
						formFields: [{ key: 'name', display: 'Nombre' }],
						validationMethod: Methods.compare(plant.name),
						removeEndpoint: TermicPlantController.remove,
						removeMessage: `La planta (${plant.name}) ha sido eliminada!`,
					}}
					onUpdate={() => fetch()}
					onRemove={() => fetch()}
				>
					<div className={Common.colJoin(12)}>
						<div key={`termic-${plant.id}-meta-1`} className="row zero">
							<div className={Common.colJoin(6) + ' zero'}>
								<Meta title="Nombre" content={titleCaseName} />
							</div>
							<div className={Common.colJoin(3) + ' zero'}>
								<Meta title="Código" content={plant.code} />
							</div>
							<div className={Common.colJoin(3) + ' zero'}>
								<Meta title="Área" content={plant.area.name} />
							</div>
						</div>
						<div key={`termic-${plant.id}-meta-2`} className="row zero">
							<div className={Common.colJoin(6) + ' zero'}>
								<Meta title="Unidades equivalentes" content={plant.unidades_equiv} />
							</div>
							<div className={Common.colJoin(6) + ' zero'}>
								<Meta title="Número de arranques por día" content={plant.no_arranques_dias} />
							</div>
						</div>
						<div key={`termic-${plant.id}-meta-3`} className="row zero">
							<div className={Common.colJoin(6) + ' zero'}>
								<Meta title="Tiempo mínimo en Línea" content={plant.tml} />
							</div>
							<div className={Common.colJoin(6) + ' zero'}>
								<Meta title="Tiempo mínimo Fuera de Línea" content={plant.tmfl} />
							</div>
						</div>
						<div key={`termic-${plant.id}-meta-4`} className="row zero">
							<div className={Common.colJoin(6) + ' zero'}>
								<Meta title="Tiempo de espera para arranque caliente" content={plant.te1} />
							</div>
							<div className={Common.colJoin(6) + ' zero'}>
								<Meta title="Tiempo de espera para arranque tibio" content={plant.te2} />
							</div>
							<div className={Common.colJoin(6) + ' zero'}>
								<Meta title="Tiempo de espera para arranque frío" content={plant.te3} />
							</div>
						</div>

						{warnings}
						{configurations}
					</div>
				</Card>
			);
		});

	return (
		<div className="inherit-w">
			{error && !loading && <NoConnection />}
			{loading && <Spinner />}

			{!loading && !error && items.length === 0 ? (
				<NoResults />
			) : (
				<div className={Common.rowBetween() + ' inherit-w'}>
					<div className={Common.colJoin(12) + ' fixed-scroll-md'}>{renderPlants()}</div>
				</div>
			)}
		</div>
	);
};

export default TermicPlantTab;
