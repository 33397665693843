import React, { useRef } from 'react';
import { Button, FormControl, FormHelperText, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import { Fragment } from 'react';

const MAXIMUM_CHARACTERS = 15;

const formatLargeFileName = (filename) => {
	if (filename.length > MAXIMUM_CHARACTERS) return filename.substring(0, MAXIMUM_CHARACTERS - 3) + '...';

	return filename;
};

const ClickUploadField = ({ name, displayText, files, setFiles, tooltip, ...rest }) => {
	const fileRef = useRef();
	// eslint-disable-next-line
	const [_, meta, helpers] = useField(name);
	const hasError = Boolean(meta.error && meta.touched);

	const buttonClickedHandler = () => fileRef.current.click();

	const handleFileChange = (files) => {
		helpers.setValue(files);

		const _files = Array.from(files).map((file, index) => {
			return { file: file, index: index };
		});

		setFiles(_files);
	};

	const changeHanlder = (event) => {
		const { target } = event;
		if (target.files) {
			handleFileChange(target.files);
		}
	};

	const renderButtonText = () => {
		if (displayText.includes('icon:')) {
			// eslint-disable-next-line
			const [_, iconFormat] = displayText.split(':');
			const [id, family] = iconFormat.split('@');

			return <FontAwesomeIcon icon={[family, id]} />;
		}

		return (
			<Fragment>
				<FontAwesomeIcon icon={['fas', 'upload']} />
				&nbsp; {displayText} &nbsp;
			</Fragment>
		);
	};

	const renderButton = () => {
		const buttonContent = renderButtonText();

		const button = (
			<Button
				fullWidth
				disableElevation
				color="secondary"
				variant="contained"
				onClick={buttonClickedHandler}
				style={{
					height: '40px',
				}}
			>
				{buttonContent}
			</Button>
		);

		if (Boolean(tooltip)) {
			return (
				<Tooltip title={tooltip} placement="top">
					{button}
				</Tooltip>
			);
		}

		return button;
	};

	return (
		<FormControl fullWidth variant="outlined" error={hasError}>
			{renderButton()}
			<input
				type="file"
				className="inherit-w"
				ref={fileRef}
				style={{ display: 'none' }}
				onChange={changeHanlder}
				{...rest}
			/>
			{files.length > 0 &&
				files.map((fileItem) => {
					const { file } = fileItem;
					return (
						<p key={`${name}-file-${fileItem.index}`} title={file.name}>
							{formatLargeFileName(file.name)}
						</p>
					);
				})}
			{hasError && <FormHelperText margin="dense">{meta.error}</FormHelperText>}
		</FormControl>
	);
};

export default ClickUploadField;
