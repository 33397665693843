import React from 'react';
import { TextField } from '@material-ui/core';
import { useField } from 'formik';

export const DefaultField = ({
	label,
	placeholder,
	type,
	onChangeWrapper = undefined,
	onBlurWrapper = undefined,
	...props
}) => {
	const { name } = props;
	const [field, meta] = useField(name);
	const errorText = meta.error && meta.touched ? meta.error : '';
	const hasError = !!errorText;

	return (
		<TextField
			variant='outlined'
			color='primary'
			fullWidth
			{...field}
			label={label}
			placeholder={placeholder}
			type={type ? type : 'input'}
			helperText={errorText}
			error={hasError}
			onChange={
				onChangeWrapper
					? e => onChangeWrapper(e, field.onChange)
					: field.onChange
			}
			onBlur={
				onBlurWrapper
					? e => onBlurWrapper(e, field.onBlur)
					: field.onBlur
			}
			{...props}
		/>
	);
};
