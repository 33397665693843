import env from '../../../env.json';
import { api } from '../axios';

const controller = env.REACT_APP_API_VERSION + 'token';

/**
 * Realiza una petición con el método GET a la API bajo el controlador /token, en la
 * ruta /list con el fin de listar los tokens existentes en la base de datos.
 */
export const list = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller}/list`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /token, en la
 * ruta /create con el fin de crear un token.
 * @param {Object} body Cuerpo de la petición con el token a crear.
 */
export const create = (body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/create`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método PUT a la API bajo el controlador /token, en la
 * ruta /:id/update con el fin de actualizar la información de un token así mismo
 * como refrescar la expiración de este.
 * @param {number} id id correspondiente al token a editar.
 * @param {Object} body Cuerpo de la petición con los cambios del token correspondiente.
 */
export const update = (id, body) =>
	new Promise((resolve, reject) => {
		api.put(`${controller}/${id}/update`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador /token,
 * en la ruta /delete con el fin de eliminar un token de la base de datos de forma
 * permanente.
 * @param {number} id id correspondiente del token a eliminar.
 */
export const remove = (id) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller}/${id}/delete`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
