import React, { Fragment, useEffect, useState } from 'react';

import {
	CenteredContainer,
	Card,
	Modal,
	NoResults,
	Spinner,
	NoConnection,
	AddButton,
} from '../../../../layout';
import { UserSaveForm } from '../../../../forms';

import { Common, UserController } from '../../../../../utils';

/**
 * Componente que se encarga del renderizado del modulo de usuarios.
 */
export const User = () => {
	/**
	 * HOOKS
	 */
	const [userCreation, setUserCreation] = useState(false);
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [noConnection, setNoConnection] = useState(false);

	/**
	 * Método que se encarga de consultar la lista de usuarios.
	 */
	const fetch = async () => {
		try {
			setLoading(true);
			setNoConnection(false);
			const res = await UserController.list();
			if (res.status === 200) {
				setUsers(res.data);
			}
		} catch (error) {
			setNoConnection(true);
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	return (
		<Fragment>
			<CenteredContainer colSize={10}>
				<div className="row inherit-w zero">
					<h2 className="tab-title">Usuarios</h2>
				</div>
				<hr />
				<br />
				<div className="inherit-w">
					{noConnection && !loading ? <NoConnection /> : null}
					{loading ? <Spinner /> : null}

					{!loading && !noConnection && users.length === 0 ? (
						<NoResults />
					) : (
						users.map((user) => {
							const headers = [
								`${user.first_name} ${user.last_name}`,
								user.email,
								Common.USER_ROLES[user.rol],
								user.tgroup[0] ? user.tgroup[0].name : '',
							];
							const headerSizes = [4, 4, 3, 1];

							return (
								<Card
									key={user.id}
									headers={headers}
									headerSizes={headerSizes}
									cardType={Common.CardType.USER}
									data={user}
									canEdit
									canRemove
									onUpdate={() => fetch()}
									onRemove={() => fetch()}
								/>
							);
						})
					)}
				</div>
				<br />
				<AddButton icon="user-plus" onClick={() => setUserCreation(true)} />
			</CenteredContainer>
			{userCreation ? (
				<Modal show={userCreation} onClose={() => setUserCreation(false)}>
					<UserSaveForm
						onCancel={() => setUserCreation(false)}
						onUpdate={() => fetch()}
						isCreation
					/>
				</Modal>
			) : null}
		</Fragment>
	);
};
