import React from 'react';

import { Common, OptimizerController } from '../../../utils';

import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { DefaultField } from '../default';
import { Button, CircularProgress } from '@material-ui/core';

const validationSchema = yup.object({
	name: yup.string('El nombre es de tipo texto').required('El nombre es requerido'),
	tolerancia: yup
		.number('La tolerancia es de tipo númerico')
		.required('La tolerancia es requerida')
		.min(0, 'La Tolerancia ha de ser mayor o igual a 0'),
	t_max_espera: yup
		.number('El T. Max. Espera es de tipo númerico')
		.required('El T. Max. Espera es requerido')
		.moreThan(0, 'El T. Max. Espera ha de ser mayor a 0'),
	tee: yup
		.number('El TEE es de tipo númerico')
		.required('El TEE es requerido')
		.min(0, 'El TEE ha de ser mayor a 0'),
});

const OptimizerUpdateForm = ({ data: record, onUpdate, onCancel }) => {
	const formInitialValues = {
		name: record.name,
		tolerancia: record.tolerancia,
		t_max_espera: record.t_max_espera,
		tee: record.tee,
	};

	/**
	 * metodo crear o editar una entrada de glosario.
	 */
	const onFormSubmit = async (data, setSubmitting, resetForm) => {
		try {
			setSubmitting(true);

			const body = { ...data };

			const res = await OptimizerController.update(record.id, body);

			setSubmitting(false);

			if (res.status < 400) {
				resetForm();
				Common.fireMiniMessage(`Se ha actualizado el optimizador ${record.name.toUpperCase()}`);

				onCancel();
				onUpdate();
			}
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<Formik
			initialValues={formInitialValues}
			validationSchema={validationSchema}
			onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
		>
			{({ isSubmitting }) => (
				<Form className="inherit-w">
					<DefaultField name="name" label="Nombre del optimizador" size="small" disabled />
					<div className="sm-es"></div>

					<DefaultField name="tolerancia" label="Tolerancia" size="small" />
					<div className="sm-es"></div>

					<DefaultField name="t_max_espera" label="T. Max. Espera" size="small" />
					<div className="sm-es"></div>

					<DefaultField name="tee" label="TEE" size="small" />
					<div className="sm-es"></div>

					<div className="md-es"></div>
					<div className={Common.rowBetweenMiddle()}>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								color="default"
								variant="contained"
								size="medium"
								className="ls-custom btn-cancel btn-rmv"
								onClick={onCancel}
								disabled={isSubmitting}
								fullWidth
								disableElevation
							>
								Cancelar
							</Button>
						</div>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								size="medium"
								className="ls-custom fl-right"
								disabled={isSubmitting}
								fullWidth
								disableElevation
							>
								Editar &nbsp;
								{isSubmitting ? <CircularProgress size={20} /> : null}
							</Button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default OptimizerUpdateForm;
