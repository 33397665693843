import React, { Fragment, useState } from 'react';
import { AccordionActions, Button, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Common,
	DATA,
	HidraulicPlantController,
	Methods,
	RenewablePlantController,
	Schemas,
} from '../../../utils';
import { formatWithPreviousHour } from '../../../utils/pipes';
import { AvailabilityConfigForm, RemovalForm } from '../../forms';
import { Modal, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from '../../layout';

const AvailabilityConfig = ({ singular = false, hideActions = false, plant, config, onChanged }) => {
	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState(undefined);

	const handleNew = () => {
		setShowModal(true);
		setModalContent(DATA.MODAL.CONTENT.PLANT.AVAILABILITY.CREATE);
	};

	const handleEdit = () => {
		setShowModal(true);
		setModalContent(DATA.MODAL.CONTENT.PLANT.AVAILABILITY.UPDATE);
	};

	const handleRemove = () => {
		setShowModal(true);
		setModalContent(DATA.MODAL.CONTENT.PLANT.AVAILABILITY.REMOVE);
	};

	const handleClose = () => {
		setShowModal(false);
		setModalContent(undefined);
	};

	const renderAvailabilities = () => {
		return config.availabilities.map((availability) => {
			const hourRange = formatWithPreviousHour(availability.hora);
			return (
				<div
					key={`plant-availability-config-${availability.id}`}
					className={Common.rowCenterMiddle() + ' inherit-w zero'}
				>
					<div className={Common.colJoin(6)}>
						<p className="txt-center">{hourRange}</p>
					</div>
					<div className={Common.colJoin(6)}>
						<p className="txt-center">{availability.disponibilidad}</p>
					</div>
				</div>
			);
		});
	};

	const getModalContent = () => {
		switch (modalContent) {
			case DATA.MODAL.CONTENT.PLANT.AVAILABILITY.CREATE:
				return (
					<AvailabilityConfigForm
						isCreation
						plant={plant}
						onUpdate={onChanged}
						onCancel={handleClose}
					/>
				);

			case DATA.MODAL.CONTENT.PLANT.AVAILABILITY.UPDATE:
				const formattedPlant = {
					num_subunidad: config.subunit,
					disp_data: config.availabilities,
				};
				return (
					<AvailabilityConfigForm
						hideSubunit={singular}
						data={formattedPlant}
						plant={plant}
						onUpdate={onChanged}
						onCancel={handleClose}
					/>
				);

			case DATA.MODAL.CONTENT.PLANT.AVAILABILITY.REMOVE:
				return (
					<RemovalForm
						id={plant.id}
						entity={`config. disponibilidad, de la planta ${plant.name}`}
						prefix="la"
						validationSchema={Schemas.stringBasic('name', 'El nombre de la planta es requerido')}
						formInitialValues={{ name: '' }}
						formFields={[{ key: 'name', display: 'Nombre de la planta' }]}
						validationMethod={Methods.compare(plant.name)}
						removeEndpoint={async () => {
							const { _type: plantType } = plant;
							let controller = HidraulicPlantController;
							if (plantType === DATA.PLANTS.TYPES.RENEWABLE) {
								controller = RenewablePlantController;
							}

							const args = singular ? [plant.id] : [plant.id, config.subunit];

							return await controller.removeAvailability(...args);
						}}
						removeMessage={`La config. de disponiblidad de la planta (${plant.name}) ha sido eliminada!`}
						onRemove={onChanged}
						onCancel={handleClose}
					/>
				);

			default:
				return undefined;
		}
	};

	const renderModal = () => {
		const content = getModalContent();

		return (
			<Modal show={showModal} onClose={handleClose}>
				{content}
			</Modal>
		);
	};

	const subunit = config.subunit;
	console.log(config.availabilities);
	const maxValue = Math.max(...config.availabilities.map((a) => a.disponibilidad));

	const renderSubunit = () => (
		<Fragment>
			- <i>(subunidad = {subunit})</i>
		</Fragment>
	);

	const renderMax = () => (
		<Fragment>
			- <i>(Max = {maxValue})</i>
		</Fragment>
	);

	return (
		<Fragment>
			<StyledAccordion>
				<StyledAccordionSummary
					expandIcon={<FontAwesomeIcon icon={['fas', 'angle-down']} />}
					aria-controls="plant-availability-config-content"
					id="plant-availability-config-header"
				>
					<Typography>
						Disponibilidad{singular ? '' : 'es'} {subunit && renderSubunit()} {renderMax()}
					</Typography>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					<div className={Common.colJoin(12) + ' zero'}>
						<div
							key={`plant-${plant.id}-availability-config-headers`}
							className={Common.rowMiddle() + ' inherit-w zero'}
						>
							<div className={Common.colJoin(6)}>
								<h4 className="txt-center">Hora</h4>
							</div>
							<div className={Common.colJoin(6)}>
								<h4 className="txt-center">Disponibilidad</h4>
							</div>
						</div>
						{renderAvailabilities()}
					</div>
				</StyledAccordionDetails>

				{!hideActions && (
					<Fragment>
						<hr className="gray" />
						<AccordionActions>
							<div className={Common.rowBetween() + ' inherit-w zero'}>
								<div className={Common.colJoin(6) + ' zero'}>
									{!singular && (
										<Button
											disableElevation
											variant="contained"
											size="small"
											color="primary"
											onClick={handleNew}
										>
											<FontAwesomeIcon icon={['fas', 'plus']} />
											&nbsp; Agregar nueva subunidad
										</Button>
									)}
								</div>
								<div className={Common.colJoin(6) + ' zero flex-end'}>
									<div className="row zero flex-end">
										<Button variant="outlined" size="small" onClick={handleRemove}>
											<FontAwesomeIcon icon={['far', 'trash-alt']} />
											&nbsp; Eliminar
										</Button>
										<div className="vspace-xs"></div>
										<Button
											disableElevation
											variant="contained"
											size="small"
											color="secondary"
											onClick={handleEdit}
										>
											<div className={Common.rowCenterMiddle() + ' inherit-w zero'}>
												<FontAwesomeIcon icon={['far', 'edit']} />
												&nbsp; Editar
											</div>
										</Button>
									</div>
								</div>
							</div>
						</AccordionActions>
					</Fragment>
				)}
			</StyledAccordion>

			{showModal && renderModal()}
		</Fragment>
	);
};

export default AvailabilityConfig;
