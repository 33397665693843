import { useMemo, useRef } from 'react';

const RECURRENT_LAYERS = ['LSTM', 'GRU'];

/**
 * Método para darle formato a las capas de una arquitectura.
 */
const useFetchOptimizers = (architecture = {}) => {
	const memoizedArchitecture = useMemo(() => architecture, [architecture]);
	const counter = useRef(0);

	const {
		layers_metadata,
		neurons_metadata,
		activation_metadata,
		rec_activation_metadata,
	} = memoizedArchitecture;

	if (
		!Boolean(layers_metadata) &&
		!Boolean(neurons_metadata) &&
		!Boolean(activation_metadata) &&
		!Boolean(rec_activation_metadata)
	) {
		return;
	}

	const neuronData = neurons_metadata.split('-');
	const activationFunctions = activation_metadata.split('-');
	const recurrentActivationFunctions = rec_activation_metadata.split('-');

	const formatted = layers_metadata.split('-').map((layer, index) => {
		const isDropout = layer === 'Dropout';
		const neurons = isDropout ? (neuronData[index] * 100) | 0 : neuronData[index];
		let format = {
			name: layer,
			neurons,
			activationFunction: activationFunctions[index],
			recurrentActivationFunction: '',
		};

		if (RECURRENT_LAYERS.includes(layer)) {
			format.recurrentActivationFunction = recurrentActivationFunctions[counter.current];
			counter.current += 1;
		}

		return format;
	});

	return formatted;
};

export default useFetchOptimizers;
