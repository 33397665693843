import React, { Fragment, useEffect, useState } from 'react';
import { Common, GlossaryController } from '../../../../utils';
import { GlossarySaveForm } from '../../../forms';
import { AddButton, Card, CenteredContainer, Modal, NoConnection, NoResults, Spinner } from '../../../layout';

/**
 * Vista que se encarga de renderizar todo lo correspondiente al módulo de
 * glosario.
 */
export const Glosary = () => {
	/**
	 * HOOKS
	 */
	const [glossaryCreation, setGlossaryCreation] = useState(false);
	const [glossary, setGlossary] = useState([]);
	const [loading, setLoading] = useState(true);
	const [noConnection, setNoConnection] = useState(false);

	/**
	 * Cargue inicial de la lista de registros del glosario.
	 */
	const fetch = async () => {
		try {
			setLoading(true);
			setNoConnection(false);
			const res = await GlossaryController.list();
			if (res.status === 200) {
				// console.log(res.data);
				setGlossary(res.data);
			}
		} catch (error) {
			setNoConnection(true);
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	return (
		<Fragment>
			<CenteredContainer colSize={10}>
				<div className="row inherit-w zero">
					<h2 className="tab-title">Glosario</h2>
				</div>
				<hr />
				<br />
				<div className="inherit-w">
					{noConnection && !loading ? <NoConnection /> : null}
					{loading ? <Spinner /> : null}

					{!loading && !noConnection && glossary.length === 0 ? (
						<NoResults />
					) : (
						glossary.map((entry) => {
							const headers = [entry.name];
							const headerSizes = [4, 4, 3, 1];
							return (
								<Card
									key={`area_${entry.id}`}
									headers={headers}
									headerSizes={headerSizes}
									cardType={Common.CardType.GLOSSARY}
									data={entry}
									canEdit
									canRemove
									canExpand
									onUpdate={() => fetch()}
									onRemove={() => fetch()}
									hasRights={Common.isInManager()}
								>
									<div className="row zero">
										<p>{entry.description}</p>
									</div>
								</Card>
							);
						})
					)}
				</div>
				<br />

				{Common.isInManager() && <AddButton onClick={() => setGlossaryCreation(true)} />}
			</CenteredContainer>

			{glossaryCreation ? (
				<Modal show={glossaryCreation} onClose={() => setGlossaryCreation(false)}>
					<GlossarySaveForm
						onUpdate={() => fetch()}
						onCancel={() => setGlossaryCreation(false)}
						isCreation
					/>
				</Modal>
			) : null}
		</Fragment>
	);
};
