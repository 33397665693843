import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CenteredContainer } from '../../layout';

/**
 * Componente para visualizar un error 404.
 */
export const Error404 = () => {
	return (
		<CenteredContainer colSize={6} className="items-center">
			<h2 className="txt-center secondary">
				<FontAwesomeIcon icon={['fas', 'unlink']} /> &nbsp; Est&aacute; p&aacute;gina no se encuentra
				disponible
			</h2>
			<p>
				El link puede estar inhabilitado o puede que la p&aacute;gina haya sido removida. Verifica si
				el link al que estas tratando de acceder es el correcto.
			</p>
		</CenteredContainer>
	);
};
