import React, { useEffect } from 'react';

// COMPONENTS
import { AuthRouter } from './components/navigation/auth/auth-router';
import { ClientRouter } from './components/navigation/client/client-router';
import { Error404 } from './components/navigation/error404/error404';

// REACT ROUTER
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

//ROUTES
import { ROUTES } from './utils/routes';
import { ProtectedRoute } from './components/routing';

// ADD ICONS FROM FONT-AWESOME
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

// TOASTS
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//SOCKET CLIENT
import SocketContext from './utils/contexts/socket.context';

//ENV
import env from './env.json';

library.add(fab, fas, far);

// const opts = {
//     protocolVersion: 8,
//     origin: 'https://localhost:9000',
//     rejectUnauthorized: false,
// };
let socket;
// if (env.REACT_APP_RUNNING_ENV !== 'demo') socket = new WebSocket(env.REACT_APP_SOCKET_TRAINING_URL);

export const history = createBrowserHistory();

const App = () => {
    useEffect(() => {
        try {
            socket = new WebSocket(env.REACT_APP_SOCKET_TEST_URL);
            socket.onopen = (evt) => {
                console.log(evt);
                console.log('[SOCKET] connection established!');

                socket.onclose = (evt) => console.log('[SOCKET] closing connection');

                console.log('[SOCKET] sending "ping" command');
                socket.onmessage = (evt) => {
                    console.log('[SOCKET] message recevided, showing payload: ');
                    console.log(evt);
                    socket.close();
                };

                socket.send(JSON.stringify({ command: 'ping' }));
            };
        } catch (error) {
            console.log(error);
            console.error(error);
        }
    }, []);

    return (
        <SocketContext.Provider value={null}>
            <Router history={history}>
                <Switch>
                    <Route exact path="/" render={() => <Redirect to={ROUTES.AUTH.BASE} />} />
                    <Route path={ROUTES.AUTH.BASE} component={AuthRouter} />
                    <ProtectedRoute path={ROUTES.CLIENT.BASE} component={ClientRouter} />
                    <Route path="/" component={Error404} />
                </Switch>
                <ToastContainer />
            </Router>
        </SocketContext.Provider>
    );
};

export default App;
