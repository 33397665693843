import React, { Fragment, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Button, Chip, CircularProgress } from '@material-ui/core';

import { Common, Methods, ServingPlantController } from '../../../utils';
import { formatWithPreviousHour } from '../../../utils/pipes';
import { DefaultField } from '../default';

const validationSchema = yup.object({
	costoModifer: yup.number().min(0, 'El valor no puede ser menor a 0.'),
	racionModifer: yup.number().min(0, 'El valor no puede ser menor a 0.'),
	array: yup.array().of(
		yup.object({
			costo_racion: yup
				.number()
				.required('El costo es requerido.')
				.min(0, 'El costo no puede ser menor a 0.'),
			capacidad_racion: yup
				.number()
				.required('La capacidad es requerida.')
				.min(0, 'La capacidad no puede ser menor a 0.'),
		})
	),
});

const ServingConfigForm = ({
	data: record,
	isCreation = false,
	onUpdate = () => {},
	onCancel = () => {},
}) => {
	const [disableButton, setDisableButton] = useState(false);

	const formInitialValues = {
		costoModifer: 0,
		racionModifer: 0,
		array: record?.length > 0 ? record : Methods.generateServingFields(),
	};

	const renderAvailabilityFields = (fields) => {
		return fields.map((field, idx) => {
			const label = formatWithPreviousHour(field.hora);
			return (
				<div key={`serving-hour-${field.hora}`}>
					<div className="sm-es"></div>
					<div className={Common.rowBetween() + ' zero'}>
						<div className={Common.colJoinTopPT4(3)}>
							<Chip variant="outlined" color="secondary" label={label} />
						</div>
						<div className={Common.colJoinTop(4)}>
							<DefaultField
								name={`array.${idx}.costo_racion`}
								label="Costo"
								size="small"
								type="number"
							/>
						</div>
						<div className={Common.colJoinTop(4)}>
							<DefaultField
								name={`array.${idx}.capacidad_racion`}
								label="Capacidad"
								size="small"
								type="number"
							/>
						</div>
					</div>
					<div className="sm-es"></div>
				</div>
			);
		});
	};

	const onFormSubmit = async (data, setSubmitting, resetForm) => {
		setSubmitting(true);
		try {
			const body = [...data.array];

			let res = isCreation
				? await ServingPlantController.createMultiple(body)
				: await ServingPlantController.updateMultiple(body);

			if (res.status < 400) {
				const action = isCreation ? 'agregado' : 'actualizado';
				Common.fireMiniMessage(`Se ha ${action} la configuración de la planta de racionamiento!`);
			}

			setSubmitting(false);
			resetForm();
			onUpdate();
			onCancel();
		} catch (error) {
			console.error(error);
			setSubmitting(false);
		}
	};

	return (
		<Formik
			initialValues={formInitialValues}
			validationSchema={validationSchema}
			onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
		>
			{({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
				<Form className="inherit-w">
					<div className={Common.rowMiddle() + ' zero'}>
						<h3
							style={{
								color: '#173DB8',
							}}
						>
							Planta de Racionamiento
						</h3>
					</div>
					<hr />
					<div className="sm-es"></div>

					<div className={Common.rowBetween() + ' zero'}>
						<div className={Common.colJoinTop(6)}>
							<DefaultField
								name="costoModifer"
								label="Modificar costo"
								size="small"
								type="number"
								onBlurWrapper={(e, handler) => {
									setDisableButton(true);
									handler(e);

									for (let index = 0; index < 24; index++) {
										setFieldValue(`array.${index}.costo_racion`, e.target.value);
										setFieldTouched(`array.${index}.costo_racion`);
									}
									setDisableButton(false);
								}}
							/>
						</div>
						<div className={Common.colJoinTop(6)}>
							<DefaultField
								name="racionModifer"
								label="Modificar capacidad"
								size="small"
								type="number"
								onBlurWrapper={(e, handler) => {
									setDisableButton(true);
									handler(e);

									for (let index = 0; index < 24; index++) {
										setFieldValue(`array.${index}.capacidad_racion`, e.target.value);
										setFieldTouched(`array.${index}.capacidad_racion`);
									}
									setDisableButton(false);
								}}
							/>
						</div>
					</div>
					<div className="sm-es"></div>
					<FieldArray name="array">
						{(_arrayHelpers) => <Fragment>{renderAvailabilityFields(values.array)}</Fragment>}
					</FieldArray>
					<div className="md-es"></div>
					<div className={Common.rowBetweenMiddle()}>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								color="default"
								variant="contained"
								size="medium"
								className="ls-custom btn-cancel btn-rmv"
								onClick={onCancel}
								disabled={isSubmitting}
								fullWidth
								disableElevation
							>
								Cancelar
							</Button>
						</div>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								size="medium"
								className="ls-custom fl-right"
								disabled={isSubmitting || disableButton}
								fullWidth
								disableElevation
							>
								{isCreation ? 'Agregar' : 'Editar'} &nbsp;
								{isSubmitting ? <CircularProgress size={20} /> : null}
							</Button>
						</div>
					</div>
					<div className="sm-es"></div>
				</Form>
			)}
		</Formik>
	);
};

export default ServingConfigForm;
