import React, { useState } from 'react';

import logo from '../../../assets/logo.svg';
import styles from './nav-bar.module.scss';

import { useHistory } from 'react-router-dom';

import { LocalStoreService, ROUTES, Common } from '../../../utils';

import env from '../../../env.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const localStorage = new LocalStoreService();

/**
 * Componente que se encarga del renderizado de la barra de navegación.
 */
export const NavBar = ({ onSidebarToggle }) => {
    const [showSidebar, setShowSidebar] = useState(() => true);
    const [showNavbarMiniMenu, setShowNavbarMiniMenu] = useState(() => false);
    // eslint-disable-next-line
    const [user, _setUser] = useState(() => localStorage.getUserInfo());
    const history = useHistory();

    const renderUsername = () => {
        const first = user.name.split(' ')[0];
        const supposedSecond = user.name.split(' ')[1];

        if (first.length <= 3) return `${first} ${supposedSecond}`;
        return first;
    };

    /**
     * nagevación cuando se cliquea el logo
     */
    const handleLogoClick = () => {
        history.push(ROUTES.CLIENT.HOME);
    };

    /**
     * Mostrar el menu de acciones.
     */
    function handleSidebarToggle() {
        const value = showSidebar;
        setShowSidebar(!value);
        onSidebarToggle(!value);
    }

    /**
     * Controlar el cerrado de sesion.
     */
    function handleLogoutClick() {
        setShowSidebar(false);
        localStorage.removeAccessToken();
        history.push(ROUTES.AUTH.LOGIN);
    }

    return (
        <>
            <nav className={'row ' + styles.nav_bar}>
                <div className={Common.colJoin(6)}>
                    <div className={Common.rowMiddle() + ' inherit-h  clickable ' + styles.app_title}>
                        <div className={'col ml-8'}>
                            <div
                                className={Common.rowCenterMiddle() + ' inherit-h zero ' + styles.navbar_toggle}
                                style={{ backgroundColor: showSidebar ? '#EDEDED' : '#FFFFFF' }}
                                onClick={() => handleSidebarToggle()}
                            >
                                <FontAwesomeIcon icon={['fas', showSidebar ? 'angle-double-right' : 'bars']} />
                            </div>
                        </div>
                        <div className={'col'}>
                            <div
                                className={Common.rowMiddle() + ' inherit-h zero clickable ' + styles.app_title}
                                onClick={handleLogoClick}
                            >
                                <div className={Common.colJoin(1)}></div>
                                <img src={logo} className={styles.logo} alt="logo" />
                                <div className={Common.colJoin(3)}>
                                    <h2>{env.REACT_APP_APP_NAME}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={Common.colJoin(6)}>
                    <div className={Common.rowMiddle() + ' reverse inherit-h'}>
                        <div className={styles.userWrapper} onClick={() => setShowNavbarMiniMenu(!showNavbarMiniMenu)}>
                            <div className={'col mr-4'}>
                                <p className={styles.user__heading}>{renderUsername()}</p>
                                <p className={styles.user__subheading}>({user.role})</p>
                            </div>
                            <FontAwesomeIcon icon={['far', showNavbarMiniMenu ? 'minus-square' : 'plus-square']} />
                        </div>
                    </div>
                </div>
            </nav>
            {showNavbarMiniMenu ? (
                <div className={styles.miniMenu}>
                    <span className={styles.special_red} onClick={() => handleLogoutClick()}>
                        <FontAwesomeIcon icon={['fas', 'power-off']} /> &nbsp; Cerrar sesi&oacute;n
                    </span>
                </div>
            ) : null}
        </>
    );
};
