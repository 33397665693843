import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './link.module.scss';

const SidebarSublink = ({ parent, content, route, onClick }) => {
    const history = useHistory();

    function handleOnClick() {
        onClick(parent);
        history.push(route);
    }

    return (
        <span className={styles.link + ' ml-4'} onClick={() => handleOnClick()}>
            <FontAwesomeIcon icon={['fas', 'caret-right']} /> &nbsp; {content}
        </span>
    );
};

export const SidebarLink = ({ current, onActiveChanged, content, route, sublinks = [], role = 'US' }) => {
    const [isActive, setActive] = useState(false);
    const [showSublinks, setShowSublinks] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setActive(current === content);
    }, [current, content]);

    function handleOnClick() {
        if (sublinks.length > 0) {
            setShowSublinks(!showSublinks);
        } else {
            onActiveChanged(content);
            history.push(route);
        }
    }

    function renderSublinks() {
        return sublinks
            .filter((sublink) => sublink.rights.includes(role))
            .map((sublink) => (
                <SidebarSublink
                    key={`${sublink.parent}-sb-lnk-${sublink.name}`}
                    parent={sublink.parent}
                    content={sublink.content}
                    route={sublink.route}
                    onClick={(changed) => {
                        onActiveChanged(changed);
                    }}
                />
            ));
    }

    return (
        <>
            <span
                className={styles.link}
                style={{ backgroundColor: isActive ? '#EDEDED' : '#FFFFFF' }}
                onClick={() => handleOnClick()}
            >
                <FontAwesomeIcon icon={['fas', showSublinks ? 'caret-down' : 'caret-right']} /> &nbsp; {content}
            </span>
            {showSublinks ? <>{renderSublinks()}</> : null}
        </>
    );
};
