import React, { Fragment } from 'react';
import * as yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import { Button, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Common, MarketController } from '../../../utils';
import { DefaultField } from '../default';
import { toTitleCase } from '../../../utils/pipes';

const validationSchema = yup.object({
	rangos: yup.array().of(
		yup.object({
			rango_inf: yup
				.number()
				.required('El límite inferior es requerido.')
				.moreThan(-1, 'El límite inferior es un número positivo.'),
			rango_sup: yup
				.number()
				.required('El límite superior es requerido.')
				.moreThan(yup.ref('rango_inf'), 'El límite superior ha de ser mayor al límite inferior.'),
			unid_equiv: yup
				.number()
				.required('Las unidades equivalentes son requeridas.')
				.moreThan(0, 'Las unidades equivalentes deben ser mayor que 0.'),
		})
	),
});

export const MarketRangeSaveForm = ({ isCreation = false, data: record = null, onCancel, onUpdate }) => {
	const formInitialValues = {
		rangos:
			record && record.rangos && record.rangos.length > 0
				? record.rangos.map((rango) => {
						return {
							id: rango.id,
							rango_inf: rango.rango_inf,
							rango_sup: rango.rango_sup,
							unid_equiv: rango.unid_equiv,
						};
				  })
				: [
						{
							id: `ranges_field_${Date.now()}`,
							rango_inf: 0,
							rango_sup: 0,
							unid_equiv: '',
						},
				  ],
	};

	const addItemHandler = (arrayHelpers) => {
		const rows = [...arrayHelpers.form.values[arrayHelpers.name]];
		const lastItem = rows[rows.length - 1];

		arrayHelpers.push({
			id: `ranges_field_${Date.now()}`,
			rango_inf: +lastItem.rango_sup || '',
			rango_sup: '',
			unid_equiv: '',
		});
	};

	const formatData = (data) => {
		return data.rangos.map((row, index) => {
			let formatted = {
				...row,
				num_rango: index + 1,
			};
			if (isCreation) delete formatted['id'];
			return formatted;
		});
	};

	const onFormSubmit = async (data, setSubmitting, resetForm) => {
		try {
			setSubmitting(true);
			const body = formatData(data);
			console.log({ body });

			const res = isCreation
				? await MarketController.addRanges(record.id, body)
				: await MarketController.updateRanges(record.id, body);

			if (res.status < 400) {
				const action = isCreation ? 'agregado' : 'editado';
				Common.fireMiniMessage(`Se han ${action} los rangos en el mercado ${record.name}!`);
			}

			setSubmitting(false);
			resetForm();
			onUpdate();
			onCancel();
		} catch (error) {
			setSubmitting(false);
			console.log(error);
		}
	};

	const renderRangos = (rangos, setFieldValue, setFieldTouched, arrayHelpers) => {
		return rangos.map((rango, idx) => {
			return (
				<div key={rango.id}>
					<div className={Common.rowBetween() + ' zero'}>
						{/* LOWER INPUT */}
						<div className={Common.colJoinTop(3)}>
							<DefaultField
								name={`rangos.${idx}.rango_inf`}
								label="Límite Inf."
								size="small"
								type="number"
								readOnly={idx > 0}
								disabled={idx > 0}
							/>
						</div>
						{/* UPPER INPUT */}
						<div className={Common.colJoinTop(3)}>
							<DefaultField
								name={`rangos.${idx}.rango_sup`}
								label="Límite Sup."
								size="small"
								type="number"
								onChangeWrapper={(e, handler) => {
									handler(e);

									const isFromLastRow = idx + 1 === rangos.length;

									if (!isFromLastRow) {
										setFieldValue(
											`rangos.${idx + 1}.rango_inf`,
											(+e.target.value + 1).toString()
										);
									}
								}}
								onBlurWrapper={(e, handler) => {
									handler(e);

									const isFromLastRow = idx + 1 === rangos.length;

									if (!isFromLastRow) {
										setFieldTouched(`rangos.${idx + 1}.rango_inf`);
									}
								}}
							/>
						</div>
						{/* VALUE INPUT */}
						<div className={Common.colJoinTop(4)}>
							<DefaultField
								name={`rangos.${idx}.unid_equiv`}
								label="Unidades Equiv."
								size="small"
								type="number"
							/>
						</div>
						<div className={Common.colJoinTop(2)}>
							{rangos.length > 1 ? (
								<Tooltip title="Remover" placement="right">
									<IconButton onClick={() => arrayHelpers.remove(idx)}>
										<FontAwesomeIcon icon={['fas', 'minus']} />
									</IconButton>
								</Tooltip>
							) : null}
						</div>
					</div>
					<div className="sm-es"></div>
				</div>
			);
		});
	};

	return (
		<Formik
			initialValues={formInitialValues}
			validationSchema={validationSchema}
			onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
		>
			{({ values, setFieldValue, setFieldTouched, isSubmitting }) => (
				<Form className="inherit-w">
					<FieldArray name="rangos">
						{(arrayHelpers) => (
							<Fragment>
								<div className={Common.rowMiddle() + ' zero'}>
									<h3
										style={{
											color: '#173DB8',
										}}
									>
										{toTitleCase(record.name)}: Config. Rangos &nbsp;
									</h3>
									<Tooltip size="small" title="Agregar rango" placement="right">
										<IconButton onClick={addItemHandler.bind(null, arrayHelpers)}>
											<FontAwesomeIcon icon={['fas', 'plus']} />
										</IconButton>
									</Tooltip>
								</div>

								<hr />
								<div className="sm-es"></div>
								{renderRangos(values.rangos, setFieldValue, setFieldTouched, arrayHelpers)}
							</Fragment>
						)}
					</FieldArray>

					<div className="md-es"></div>
					<div className={Common.rowBetweenMiddle()}>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								color="default"
								variant="contained"
								size="medium"
								className="ls-custom btn-cancel btn-rmv"
								onClick={onCancel}
								disabled={isSubmitting}
								fullWidth
								disableElevation
							>
								Cancelar
							</Button>
						</div>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								size="medium"
								className="ls-custom fl-right"
								disabled={isSubmitting}
								fullWidth
								disableElevation
							>
								{isCreation ? 'Agregar' : 'Editar'} &nbsp;
								{isSubmitting ? <CircularProgress size={20} /> : null}
							</Button>
						</div>
					</div>

					<div className="sm-es"></div>
				</Form>
			)}
		</Formik>
	);
};
