/**
 * Este archivo se encarga de agrupar los controladores para que sean
 * facilmente exportados desde un solo archivo.
 */

import * as _ArchController from './arch.controller';
import * as _AreaController from './area.controller';
import * as _AuthController from './auth.controller';
import * as _DatasetCommentController from './dataset-comment.controller';
import * as _DatasetController from './dataset.controller';
import * as _GraphController from './graph.controller';
import * as _ExchangeController from './exchange.controller';
import * as _GlossaryController from './glossary.controller';
import * as _GroupController from './group.controller';
import * as _MarketController from './market.controller';
import * as _ModelController from './model.controller';
import * as _OdefController from './odef.controller';
import * as _OptimizationExecutionController from './optimization-execution.controller';
import * as _SubareaController from './subarea.controller';
import * as _SyncController from './sync.controller';
import * as _TokenController from './token.controller';
import * as _UserController from './user.controller';

import * as _RenewablePlantController from './plant/renewable.controller';
import * as _HidraulicPlantController from './plant/hidraulic.controller';
import * as _TermicPlantController from './plant/termic.controller';

import * as _ConstantController from './constants/constant.controller';
import * as _OptimizerController from './constants/optimizer.controller';
import * as _ServingPlantController from './constants/serving.controller';

const ArchController = _ArchController;
const AreaController = _AreaController;
const AuthController = _AuthController;
const DatasetCommentController = _DatasetCommentController;
const DatasetController = _DatasetController;
const GraphController = _GraphController;
const ExchangeController = _ExchangeController;
const GlossaryController = _GlossaryController;
const GroupController = _GroupController;
const MarketController = _MarketController;
const ModelController = _ModelController;
const OdefController = _OdefController;
const OptimizationExecutionController = _OptimizationExecutionController;
const SubareaController = _SubareaController;
const SyncController = _SyncController;
const TokenController = _TokenController;
const UserController = _UserController;

const RenewablePlantController = _RenewablePlantController;
const HidraulicPlantController = _HidraulicPlantController;
const TermicPlantController = _TermicPlantController;

const ConstantController = _ConstantController;
const OptimizerController = _OptimizerController;
const ServingPlantController = _ServingPlantController;

export {
	ArchController,
	AreaController,
	AuthController,
	DatasetCommentController,
	DatasetController,
	GraphController,
	ExchangeController,
	GlossaryController,
	GroupController,
	MarketController,
	ModelController,
	OdefController,
	OptimizationExecutionController,
	SubareaController,
	SyncController,
	TokenController,
	UserController,
	// plants
	RenewablePlantController,
	HidraulicPlantController,
	TermicPlantController,
	// constant data
	ConstantController,
	OptimizerController,
	ServingPlantController,
};
