import env from '../../../../env.json';
import { SyncController } from '..';
import { api } from '../../axios';

const controller = env.REACT_APP_API_VERSION + 'planta_termica';

/**
 * Realiza una petición con el método GET a la API bajo el controlador /planta_termica, en la
 * ruta /list con el fin de listar las plantas térmicas existentes en la base de datos.
 */
export const list = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller}/list`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /planta_termica, en la
 * ruta /create con el fin de crear una planta térmica.
 * @param {Object} body Cuerpo de la petición con la planta térmica a crear.
 */
export const create = (body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/create`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador
 * /planta_termica, en la ruta /:id/modify_multiple_mercado_equiv con el fin de agregar una
 * configuración de unidades equivalentes a una planta térmica.
 * @param {number} id Id de la planta térmica en cuestión.
 * @param {Object} body Datos de la configuración a agregar.
 */
export const saveEquivalentUnits = (id, body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/${id}/modify_multiple_mercado_equiv`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador
 * /planta_termica, en la ruta /:id/add_configuration con el fin de agregar una
 * configuración multipropósito a una planta térmica.
 * @param {number} id Id de la planta térmica en cuestión.
 * @param {Object} body Datos de la configuración a agregar.
 */
export const addConfiguration = (id, body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/${id}/add_configuration`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método PUT a la API bajo el controlador /planta_termica, en la
 * ruta /:id/update con el fin de actualizar la información de una planta térmica.
 * @param {number} id id correspondiente a la planta térmica a editar.
 * @param {Object} body Cuerpo de la petición con los cambios de la planta térmica correspondiente.
 */
export const update = (id, body) =>
	new Promise((resolve, reject) => {
		api.put(`${controller}/${id}/update`, body)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método PUT a la API bajo el controlador
 * /planta_termica, en la ruta /:id/add_configuration con el fin de actualizar la
 * configuración multipropósito de una planta térmica.
 * @param {number} id id correspondiente a la planta térmica a editar.
 * @param {Object} body Datos de la configuración a editar.
 */
export const updateConfiguration = (id, configNumber, body) =>
	new Promise((resolve, reject) => {
		api.put(`${controller}/${id}/update_configuration`, body, {
			params: {
				config: configNumber,
			},
		})
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador /planta_termica, en
 * la ruta /:id/delete con el fin de eliminar una planta térmica de la base de datos de forma permanente.
 * @param {number} id id correspondiente de la planta térmica a eliminar.
 */
export const remove = (id) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller}/${id}/delete`)
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador
 * /planta_termica, en la ruta /delete_configuration con el fin de eliminar
 * una configuración multipropósito para una planta térmica.
 * @param {number} id Id de la planta térmica en cuestión.
 * @param {number} configNumber Número de la configuración.
 */
export const removeConfiguration = (id, configNumber) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller}/${id}/delete_configuration`, {
			params: {
				config: configNumber,
			},
		})
			.then((res) =>
				SyncController.sync()
					.then((synced) => resolve(res))
					.catch((err) => reject(err))
			)
			.catch((err) => reject(err));
	});
