import { useEffect, useState } from 'react';
import { ChromaHelper } from '../../utils';

/**
 * Hook que se encarga generar una tupla en base a dos vectores de entrada.
 * @param {Array} x
 * @param {Array} y
 * @returns {Array}
 */
const useChartDataGenerator = (x, y, lineIds, isAlternate = false) => {
	const [lines, setLines] = useState([]);
	const [xLimits, setXlimits] = useState({ min: 0, max: 0 });
	const [yLimits, setYlimits] = useState({ min: 0, max: 0 });
	const [delta, setDelta] = useState(0);
	const [isMultiline, setMultiline] = useState(false);

	useEffect(() => {
		const yIsMatrix = y[0]?.length > 0;

		const transformToDate = (input) => {
			const hasZ = input.endsWith('Z');
			const asDate = hasZ ? new Date(input) : new Date(input + 'Z');
			const offset = new Date().getTimezoneOffset() * 60 * 1000;
			return new Date(asDate.getTime() + offset);
		};

		const mapToLineDots = (xValues, yValues) => {
			const isXValid = xValues && xValues.length > 0;
			const isYValid = yValues && yValues.length > 0;
			const areAxisValid = isXValid && isYValid && xValues.length === yValues.length;

			if (areAxisValid) {
				const newPoints = xValues.map((xElement, index) => {
					const isNan = isNaN(xElement);
					if (!isNan) {
						return {
							xAxis: xElement,
							yAxis: yValues[index],
						};
					} else {
						const asDate = transformToDate(xElement);
						return {
							xAxis: asDate,
							yAxis: yValues[index],
						};
					}
				});

				let minX = Math.min(...xValues);
				let maxX = Math.max(...xValues);
				let xIsTime = false;

				if (isNaN(minX) && isNaN(maxX)) {
					const asDates = newPoints.map((point) => point.xAxis);
					minX = new Date(Math.min.apply(null, asDates));
					maxX = new Date(Math.max.apply(null, asDates));
					xIsTime = true;
				}

				const minY = Math.min(...yValues);
				const maxY = Math.max(...yValues);

				const newDelta = maxY - minY > 0 ? 10 ** Math.floor(Math.log10(maxY - minY)) : 0;

				return {
					xIsTime,
					delta: newDelta,
					points: newPoints,
					limits: {
						x: { min: minX, max: maxX },
						y: { min: minY, max: maxY },
					},
				};
			} else {
				return {
					xIsTime: false,
					delta: 0,
					points: [],
					limits: {
						x: {
							min: 0,
							max: 0,
						},
						y: {
							min: 0,
							max: 0,
						},
					},
				};
			}
		};

		const newLines = yIsMatrix
			? y.map((yEntryValues) => mapToLineDots(x, yEntryValues))
			: [mapToLineDots(x, y)];

		if (yIsMatrix) {
			setMultiline(true);

			const xMins = newLines.map((line) => line.limits.x.min);
			const xMaxs = newLines.map((line) => line.limits.x.max);
			let xMin;
			let xMax;

			if (newLines[0].xIsTime) {
				xMin = new Date(Math.min.apply(null, xMins));
				xMax = new Date(Math.max.apply(null, xMaxs));
			} else {
				xMin = Math.min(...xMins);
				xMax = Math.max(...xMaxs);
			}

			setXlimits({ min: xMin, max: xMax });

			const yMins = newLines.map((line) => line.limits.y.min);
			const yMaxs = newLines.map((line) => line.limits.y.max);
			let yMin = Math.min(...yMins);
			let yMax = Math.max(...yMaxs);

			setYlimits({ min: yMin, max: yMax });

			let globalDelta = yMax - yMin > 0 ? 10 ** Math.floor(Math.log10(yMax - yMin)) : 0;
			if (globalDelta * 3 >= yMax) {
				globalDelta /= 5;
			}
			setDelta(globalDelta);
		} else {
			setMultiline(false);
			setXlimits(newLines[0].limits.x);
			setYlimits(newLines[0].limits.y);
			setDelta(newLines[0].delta);
		}

		const colors = isAlternate
			? ChromaHelper.getAlternatePallete(newLines.length)
			: ChromaHelper.getPallete(newLines.length);

		const newLinesWithColor = newLines.map((line, index) => {
			return { ...line, color: colors[index], id: lineIds[index] };
		});

		setLines(newLinesWithColor);
	}, [x, y, lineIds, isAlternate]);

	return {
		isMultiline,
		lines,
		limits: {
			x: xLimits,
			y: yLimits,
		},
		delta,
	};
};

export default useChartDataGenerator;
