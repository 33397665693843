import React, { Fragment, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Button, Chip, CircularProgress } from '@material-ui/core';

import { Common, DATA, HidraulicPlantController, Methods, RenewablePlantController } from '../../../utils';
import { toTitleCase, formatWithPreviousHour } from '../../../utils/pipes';
import { DefaultField } from '../default';

const validationSchema = yup.object({
	num_subunidad: yup
		.number()
		.required('El número de subunidad es requerido.')
		.moreThan(0, 'El número de subunidad debe ser mayor a 0.'),
	fieldsModifer: yup.number().min(0, 'El valor no puede ser menor a 0.'),
	disp_data: yup.array().of(
		yup.object({
			disponibilidad: yup
				.number()
				.required('La disponbilidad es requerida.')
				.min(0, 'La disponbilidad no puede ser menor a 0.'),
		})
	),
});

const AvailabilityConfigForm = ({
	plant,
	data: record,
	isCreation = false,
	hideSubunit = false,
	onUpdate = () => {},
	onCancel = () => {},
}) => {
	const [disableButton, setDisableButton] = useState(false);

	const subunitHiddenValue = hideSubunit ? 1 : 0;
	const formInitialValues = {
		num_subunidad: record && record.num_subunidad ? record.num_subunidad : subunitHiddenValue,
		fieldsModifer: 0,
		disp_data: record ? record.disp_data : Methods.generateAvailabilityFields(),
	};

	console.log({ formInitialValues });

	const renderAvailabilityFields = (disp_data) => {
		return disp_data.map((availability, idx) => {
			const label = formatWithPreviousHour(availability.hora);
			return (
				<div key={`availabity-hour-${availability.hora}`}>
					<div className="sm-es"></div>
					<div className={Common.rowBetween() + ' zero'}>
						<div className={Common.colJoinTopPT4(3)}>
							<Chip variant="outlined" color="secondary" label={label} />
						</div>
						<div className={Common.colJoinTop(9)}>
							<DefaultField
								name={`disp_data.${idx}.disponibilidad`}
								label="Disponibilidad"
								size="small"
								type="number"
							/>
						</div>
					</div>
					<div className="sm-es"></div>
				</div>
			);
		});
	};

	const onFormSubmit = async (data, setSubmitting, resetForm) => {
		setSubmitting(true);
		try {
			let body = { ...data };

			delete body['fieldsModifer'];
			if (hideSubunit) delete body['num_subunidad'];

			const { id: plantId, _type: plantType } = plant;

			let controller = HidraulicPlantController;
			if (plantType === DATA.PLANTS.TYPES.RENEWABLE) {
				controller = RenewablePlantController;
			}

			console.log({ plantType });

			let res;
			if (isCreation) {
				res = await controller.addAvailability(plantId, body);
			} else {
				const subunit = formInitialValues.num_subunidad;
				const args = hideSubunit ? [plantId, body] : [plantId, subunit, body];
				res = await controller.updateAvailability(...args);
			}

			if (res.status < 400) {
				const action = isCreation ? 'agregado' : 'actualizado';
				Common.fireMiniMessage(
					`Se ha ${action} la configuración de disponibilidad de la planta ${plant.name}!`
				);
			}

			setSubmitting(false);
			resetForm();
			onUpdate();
			onCancel();
		} catch (error) {
			console.error(error);
			setSubmitting(false);
		}
	};

	return (
		<Formik
			initialValues={formInitialValues}
			validationSchema={validationSchema}
			onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
		>
			{({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
				<Form className="inherit-w">
					<div className={Common.rowMiddle() + ' zero'}>
						<h3
							style={{
								color: '#173DB8',
							}}
						>
							{toTitleCase(plant.name)}: Config. Disponibilidad &nbsp;
						</h3>
					</div>

					<hr />
					{!hideSubunit && (
						<>
							<div className="sm-es"></div>
							<DefaultField
								name="num_subunidad"
								label="Número de subunidad"
								size="small"
								type="number"
								disabled={!isCreation}
							/>
						</>
					)}

					<div className="sm-es"></div>
					<DefaultField
						name="fieldsModifer"
						label="Modificar horario de disponibilidad"
						size="small"
						type="number"
						onBlurWrapper={(e, handler) => {
							setDisableButton(true);
							handler(e);

							for (let index = 0; index < 24; index++) {
								setFieldValue(`disp_data.${index}.disponibilidad`, e.target.value);
								setFieldTouched(`disp_data.${index}.disponibilidad`);
							}
							setDisableButton(false);
						}}
					/>

					<FieldArray name="disp_data">
						{(_arrayHelpers) => <Fragment>{renderAvailabilityFields(values.disp_data)}</Fragment>}
					</FieldArray>

					<div className="md-es"></div>
					<div className={Common.rowBetweenMiddle()}>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								color="default"
								variant="contained"
								size="medium"
								className="ls-custom btn-cancel btn-rmv"
								onClick={onCancel}
								disabled={isSubmitting}
								fullWidth
								disableElevation
							>
								Cancelar
							</Button>
						</div>
						<div className={Common.colJoinLg_MdSmXs(4, 5)}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								size="medium"
								className="ls-custom fl-right"
								disabled={isSubmitting || disableButton}
								fullWidth
								disableElevation
							>
								{isCreation ? 'Agregar' : 'Editar'} &nbsp;
								{isSubmitting ? <CircularProgress size={20} /> : null}
							</Button>
						</div>
					</div>

					<div className="sm-es"></div>
				</Form>
			)}
		</Formik>
	);
};

export default AvailabilityConfigForm;
