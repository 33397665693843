import React, { useCallback, useState } from 'react';
import { AppBar, Tab } from '@material-ui/core';

import { Common, DATA } from '../../../../../utils';
import { CenteredContainer, Modal, StyledTabPanel, AddButton } from '../../../../layout';
import {
	AllPurposeConfigForm,
	AvailabilityConfigForm,
	EquivalentUnitsConfigForm,
	PlantSaveForm,
} from '../../../../forms';
import { TabContext, TabList } from '@material-ui/lab';
import { HidraulicPlantTab, RenewablePlantTab, TermicPlantTab } from '../../../../plants';

const [RENEWABLE, HIDRAULIC, TERMIC] = Common.PLANT_TYPES;

/**
 * Componente encargado de renderizar el modulo de plantas energeticas.
 */
const Plant = () => {
	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState(DATA.MODAL.CONTENT.EMPTY);
	const [tabValue, setTabValue] = useState(RENEWABLE);
	const [selectedPlant, setSelectedPlant] = useState(RENEWABLE);
	const [refresh, setRefresh] = useState(false);

	const tabHandler = (_e, val) => {
		setTabValue(val);
	};

	const plantSavedHandler = useCallback(() => {
		setRefresh(true);
	}, []);

	const showPlantModalHandler = () => {
		setShowModal(true);
		setModalContent(DATA.MODAL.CONTENT.PLANT.CREATE);
	};

	const showRenewableConfigModalHandler = useCallback((plant, config) => {
		setShowModal(true);
		setModalContent(config);
		setSelectedPlant({ ...plant, _type: DATA.PLANTS.TYPES.RENEWABLE });
	}, []);

	const showHidraulicConfigModalHandler = useCallback((plant, config) => {
		setShowModal(true);
		setModalContent(config);
		setSelectedPlant({ ...plant, _type: DATA.PLANTS.TYPES.HIDRAULIC });
	}, []);

	const showTermicConfigModalHandler = useCallback((plant, config) => {
		setShowModal(true);
		setModalContent(config);
		setSelectedPlant({ ...plant, _type: DATA.PLANTS.TYPES.TERMIC });
	}, []);

	const closeModalHandler = () => {
		setShowModal(false);
		setModalContent(undefined);
	};

	const getModalContent = () => {
		const { _type: plantType } = selectedPlant;
		// console.log({ msg: `opening ${modalContent} for ${plantType} plant` });
		switch (modalContent) {
			case DATA.MODAL.CONTENT.PLANT.CREATE:
				return (
					<PlantSaveForm
						isCreation
						tab={tabValue}
						onUpdate={plantSavedHandler}
						onCancel={closeModalHandler}
					/>
				);

			case DATA.MODAL.CONTENT.PLANT.EXCHANGE.CREATE:
				return (
					<EquivalentUnitsConfigForm
						isCreation
						plant={selectedPlant}
						plantType={tabValue}
						onUpdate={plantSavedHandler}
						onCancel={closeModalHandler}
					/>
				);

			case DATA.MODAL.CONTENT.PLANT.AVAILABILITY.CREATE:
				return (
					<AvailabilityConfigForm
						isCreation
						hideSubunit={plantType !== DATA.PLANTS.TYPES.HIDRAULIC}
						plant={selectedPlant}
						onUpdate={plantSavedHandler}
						onCancel={closeModalHandler}
					/>
				);

			case DATA.MODAL.CONTENT.PLANT.ALL_PURPOSE.CREATE:
				return (
					<AllPurposeConfigForm
						isCreation
						plant={selectedPlant}
						onUpdate={plantSavedHandler}
						onCancel={closeModalHandler}
					/>
				);

			default:
				return undefined;
		}
	};

	const renderModal = () => {
		const modalContent = getModalContent();

		return (
			<Modal show={showModal} onClose={closeModalHandler} width={700}>
				{modalContent}
			</Modal>
		);
	};

	const fetchCallback = useCallback(() => {
		setRefresh(false);
	}, []);

	return (
		<>
			<CenteredContainer colSize={10}>
				<div className="row inherit-w zero">
					<h2 className="tab-title">Plantas de Energ&iacute;a</h2>
				</div>
				<hr className="m-0" />
				<TabContext value={tabValue}>
					<div className="row inherit-w zero">
						<AppBar
							position="static"
							elevation={0}
							style={{ background: '#fafafa', color: '#1c3545' }}
						>
							<TabList onChange={tabHandler}>
								<Tab label="Energía Renovable" value={RENEWABLE} />
								<Tab label="Hidráulica" value={HIDRAULIC} />
								<Tab label="Térmica" value={TERMIC} />
							</TabList>
						</AppBar>
					</div>
					<br />
					<div className="row inherit-w zero">
						<StyledTabPanel value={RENEWABLE} className="inherit-w">
							<RenewablePlantTab
								onShowModal={showRenewableConfigModalHandler}
								refresh={refresh}
								fetchCallback={fetchCallback}
							/>
						</StyledTabPanel>
						<StyledTabPanel value={HIDRAULIC} className="inherit-w">
							<HidraulicPlantTab
								onShowModal={showHidraulicConfigModalHandler}
								refresh={refresh}
								fetchCallback={fetchCallback}
							/>
						</StyledTabPanel>
						<StyledTabPanel value={TERMIC} className="inherit-w">
							<TermicPlantTab
								onShowModal={showTermicConfigModalHandler}
								refresh={refresh}
								fetchCallback={fetchCallback}
							/>
						</StyledTabPanel>
					</div>
				</TabContext>
				<br />

				{Common.isInAdmin() && <AddButton onClick={showPlantModalHandler} />}
			</CenteredContainer>

			{showModal && renderModal()}
		</>
	);
};

export default Plant;
