import React from 'react';
import ReactDOM from 'react-dom';
import { IconButton, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddButton = ({ onClick, family = 'fas', icon = 'plus', tooltip = 'Añadir' }) => {
	return ReactDOM.createPortal(
		<Tooltip placement="bottom" title={tooltip}>
			<IconButton className="btn-add" onClick={onClick}>
				<FontAwesomeIcon icon={[family, icon]} />
			</IconButton>
		</Tooltip>,
		document.getElementById('add-btn')
	);
};

export default AddButton;
