import React from 'react';
import { useListRequest } from '../hooks';
import { Common, OptimizerController } from '../../utils';
import { RequestMeta } from '../layout';
import OptimizerList from './OptimizerList';

const OptimizersTab = () => {
	const { items, loading, error, fetch } = useListRequest(OptimizerController);

	return (
		<RequestMeta error={error} loading={loading} items={items}>
			<div className={Common.rowBetween() + ' inherit-w'}>
				<div className={Common.colJoin(12) + ' fixed-scroll-md'}>
					<OptimizerList items={items} onItemUpdate={fetch} />
				</div>
			</div>
		</RequestMeta>
	);
};

export default OptimizersTab;
