import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import styles from './modal.module.scss';
import { useSpring, animated } from 'react-spring';
import { Backdrop } from '../backdrop/backdrop';
import { useWindowDimensions } from '../../hooks';

const HIDDEN_SPACER = '50%';

/**
 * Componente que se encarga de renderizar el modal.
 * @param {Object} param0 props del modal
 */
export const Modal = ({ children, show, onClose, width: widthPx = 600, closeDisabled = false }) => {
	const dimensions = useWindowDimensions();
	const widthPercentage = `${(widthPx / dimensions.width) * 100}`;
	const width = `${widthPercentage}%`;
	const leftPercentage = (100 - widthPercentage) / 2;
	const left = `${leftPercentage}%`;

	const expandSpring = useSpring({
		from: {
			width: show ? '0%' : width,
			left: show ? HIDDEN_SPACER : left,
			opacity: show ? 0 : 1,
		},
		width: show ? width : '0%',
		left: show ? left : HIDDEN_SPACER,
		opacity: show ? 1 : 0,
	});

	const opacitySpring = useSpring({
		delay: 250,
		from: { opacity: 0 },
		opacity: show ? 1 : 0,
	});

	if (!show) return null;

	const style = {
		visibility: show ? 'visible' : 'hidden',
		maxWidth: '960px',
	};

	return ReactDOM.createPortal(
		<Fragment>
			<Backdrop
				show={show}
				onClick={() => {
					if (!closeDisabled) onClose();
				}}
			/>
			<animated.div className={styles.modal} style={{ ...style, ...expandSpring }}>
				<animated.div style={opacitySpring}>{children}</animated.div>
			</animated.div>
		</Fragment>,
		document.getElementById('modal-portal')
	);
};
