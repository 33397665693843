import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { DefaultField } from '../default';
import { Button, CircularProgress } from '@material-ui/core';
import { AuthController, Common } from '../../../utils';

const formInitialValues = {
    email: '',
};

const validationSchema = yup.object({
    email: yup
        .string('El correo electrónico es de tipo texto')
        .required('El correo electrónico es requerido')
        .email('El campo no cumple con el formato de correo electrónico'),
});

export const ResetForm = ({ close }) => {
    /**
     * método para enviar correo de restauración.
     */
    const onFormSubmit = async (data, setSubmitting, resetForm) => {
        let hasError = false;
        try {
            setSubmitting(true);
            const res = await AuthController.restorePassword(data);
            if (res && res.status === 200) {
                Common.fireMiniMessage('Correo de restauración enviado!');
                resetForm();
            }
        } catch (error) {
            hasError = true;
            console.log(error);
        } finally {
            setSubmitting(false);
            if (!hasError) close();
        }
    };
    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
        >
            {({ isSubmitting }) => (
                <Form className="inherit-w">
                    <p>Las instrucciones serán enviadas al correo electrónico del usuario registrado</p>
                    <div className="sm-es"></div>
                    <DefaultField name="email" label="Correo electrónico" size="small" />
                    <div className="sm-es"></div>
                    <div className={Common.rowBetweenMiddle() + ' zero flx-end'}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            size="medium"
                            className="ls-custom"
                            disabled={isSubmitting}
                            disableElevation
                        >
                            Reestablecer contraseña &nbsp;
                            {isSubmitting ? <CircularProgress size={20} /> : null}
                        </Button>
                    </div>
                    <div className="xs-es"></div>
                </Form>
            )}
        </Formik>
    );
};
