import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    items: [
        {
            num_mercado: '2',
            id_area: 2,
            id: 'TM-FIXED-0.5517901758193398',
        },
        {
            num_mercado: '1',
            id_area: 2,
            id: 'TM-FIXED-0.28904504563917',
        },
    ],
};

const marketSlice = createSlice({
    name: 'markets',
    initialState,
    reducers: {
        add(state, action) {
            state.items.unshift(action.payload);
        },
        remove(state, action) {
            state.items = state.items.filter((item) => item.id === action.payload);
        },
        update(state, action) {
            const { id } = action.payload;
            const index = state.items.findIndex((item) => item.id === id);
            state.items[index] = action.payload;
        },
    },
});

export const marketActions = marketSlice.actions;

export default marketSlice;
