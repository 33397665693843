import env from '../../../env.json';
import { api } from '../axios';

const controller_v1 = env.REACT_APP_API_VERSION + 'model';
// const controller = env.REACT_APP_API_VERSION_2 + 'model';

/**
 * Realiza una petición con el método GET a la API bajo el controlador /model, en
 * la ruta /list con el fin de listar los modelos existentes en la base de datos.
 */
export const list = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller_v1}/list`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método GET a la API bajo el controlador /model, en
 * la ruta /list/training` con el fin de listar los modelos en estado de 'ENTRENAMIENTO'
 * existentes en la base de datos.
 */
export const listTraining = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller_v1}/list/training`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método GET a la API bajo el controlador /model, en
 * la ruta /execution/list con el fin de listar las ejecuciones de pronóstico
 * existentes en la base de datos.
 */
export const getHistory = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller_v1}/execution/list`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método GET a la API bajo el controlador /model, en
 * la ruta /execution/:id/history con el fin de obtener los datos de un pronóstico
 * existente en la base de datos en especifico.
 * @param {number} id id correspondiente al pronóstico a obtener.
 */
export const getHistoryOf = (id) =>
	new Promise((resolve, reject) => {
		api.get(`${controller_v1}/execution/${id}/history`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /model, en la
 * ruta /create con el fin de crear un modelo.
 * @param {Object} body Cuerpo de la petición con el modelo a crear.
 */
export const create = (body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller_v1}/create`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /model, en la
 * ruta /execution/create con el fin de crear una ejecucion  modelo.
 * @param {Object} body Cuerpo de la petición con la ejecución de pronóstico a crear.
 */
export const createExecution = (body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller_v1}/execution/create`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método PUT a la API bajo el controlador /model, en la
 * ruta /:id/update con el fin de actualizar que modelo se encuentra como predeterminado.
 * @param {number} id id correspondiente al modelo a editar.
 */
export const setAsDefault = (id) =>
	new Promise((resolve, reject) => {
		api.put(`${controller_v1}/${id}/set_default`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador /model, en
 * la ruta /delete con el fin de eliminar un modelo de la base de datos de forma permanente.
 * @param {number} id id correspondiente del modelo a eliminar.
 */
export const remove = (id) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller_v1}/${id}/delete`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método GET a la API bajo el controlador /model, en
 * la ruta /metrics/{id}/download con el fin de descargar las métricas de dicho modelo en formato csv.
 * @param {number} id id del modelo correspondiente.
 */
export const download_metrics = (id) =>
	new Promise((resolve, reject) => {
		api.get(`${controller_v1}/metrics/${id}/download`, {
			responseType: 'blob',
		})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
