const generateScheduleFields = () => {
	return Array.from({ length: 24 }, (_, i) => {
		return {
			hora: i + 1,
			value: 0,
		};
	});
};

export default generateScheduleFields;
