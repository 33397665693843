import React, { Fragment, useRef, useState } from 'react';

import styles from './card.module.scss';
import * as Common from '../../../utils/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSpring, animated, useTransition } from 'react-spring';
import { Button, Tooltip } from '@material-ui/core';
import { Modal } from '../modal/modal';
import {
	DatasetRemovalForm,
	DatasetSaveForm,
	ModelRemovalForm,
	TokenRemovalForm,
	TokenSaveForm,
	UserSaveForm,
	UserRemovalForm,
	GroupRemovalForm,
	GroupSaveForm,
	AreaRemovalForm,
	AreaSaveForm,
	GlossarySaveForm,
	GlossaryRemovalForm,
	PlantSaveForm,
	MarketSaveForm,
	RemovalForm,
	ExchangeSaveForm,
	SaveArchitectureForm,
} from '../../forms';
import useComponentSize from '@rehooks/component-size';
import { ConstantSaveForm, OptimizerUpdateForm } from '../../forms/constants';

/**
 * CARD COMPONENT
 * PROPS:
 * @param children, herencia del DOM de react.
 * @param headerDist, Modifica el como se distribuye el conteido del encabezado.
 * @param headers, el contenido del encabezado.
 * @param headerSizes, el tamaño de cada elemento del encabezado.
 * @param cardType, tipo de contenido a mostrar.
 * @param data, información relacionada al contenido.
 * @param canEdit, indica si el contenido se puede editar o no.
 * @param canRemove, indica si el contenido se puede remover o no.
 * @param canRemoveFromBody, indica si el contenido se puede remover desde si mismo o no.
 * @param canExpand, indica si el contenido es expandible o no.
 * @param canDownload, indica si el contenido se puede descargar o no.
 * @param hasStatistics, indica si el contenido consulta datos estadisticos o no.
 * @param onViewToggled, controlador de eventos para observar información estadistica.
 * @param onDownload, controlador de eventos para descargar información relacionada al contenido.
 */
export const Card = ({
	children,
	headerDist = [10, 2],
	headers,
	headerSizes = [3, 3, 3, 3],
	cardType = '',
	plantType = undefined,
	data,
	isHighlighted = false,
	canEdit = false,
	canRemove = false,
	canRemoveFromBody = false,
	canExpand = false,
	canDownload = false,
	canShowChart = false,
	hasStatistics = false,
	removalProps = undefined,
	onViewToggled,
	onDownload,
	onEdit,
	hasRights = Common.isInAdmin(),
	...props
}) => {
	const [contentIsShown, setContentIsShown] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [showRemove, setShowRemove] = useState(false);

	const heightRef = useRef(null);
	const { height } = useComponentSize(heightRef);

	const headerSpring = useSpring({
		from: {
			borderBottomRightRadius: contentIsShown ? '5px' : '0px',
			borderBottomLeftRadius: contentIsShown ? '5px' : '0px',
		},
		borderBottomRightRadius: contentIsShown ? '0px' : '5px',
		borderBottomLeftRadius: contentIsShown ? '0px' : '5px',
	});

	const transitions = useTransition(contentIsShown, null, {
		from: { height: 0 },
		enter: { height },
		leave: { height: 0 },
		update: { height },
	});

	const opacitySpring = useSpring({
		delay: 250,
		from: { opacity: 0 },
		opacity: contentIsShown ? 1 : 0,
	});

	const editHandler = Boolean(onEdit) ? () => onEdit(data) : () => setShowEdit(true);

	const editHeader = (
		<div className="col px-2">
			<Tooltip placement="right" title="Editar">
				<div>
					<FontAwesomeIcon
						icon={['far', 'edit']}
						className={styles.header_icon + ' clickable'}
						onClick={editHandler}
					/>
				</div>
			</Tooltip>
		</div>
	);

	const removeHeader = (
		<div className="col px-2">
			<Tooltip placement="right" title="Remover">
				<div>
					<FontAwesomeIcon
						icon={['far', 'trash-alt']}
						className={styles.header_icon + ' clickable'}
						onClick={() => setShowRemove(true)}
					/>
				</div>
			</Tooltip>
		</div>
	);

	const expandHeader = (
		<div className="col px-2">
			<Tooltip placement="right" title={contentIsShown ? 'Minimizar' : 'Expandir'}>
				<div>
					<FontAwesomeIcon
						icon={['fas', contentIsShown ? 'minus' : 'plus']}
						className={styles.header_icon + ' clickable'}
						onClick={() => setContentIsShown(!contentIsShown)}
					/>
				</div>
			</Tooltip>
		</div>
	);

	const statisticsHeader = (
		<>
			<div className="col px-2">
				<Tooltip placement="right" title="Descargar histórico">
					<div>
						<FontAwesomeIcon
							icon={['fas', 'file-download']}
							className={styles.header_icon + ' clickable'}
							onClick={onDownload}
						/>
					</div>
				</Tooltip>
			</div>

			<div className="col px-2">
				<Tooltip placement="right" title="Visualizar histórico">
					<div>
						<FontAwesomeIcon
							icon={['far', 'eye']}
							className={styles.header_icon + ' clickable'}
							onClick={onViewToggled}
						/>
					</div>
				</Tooltip>
			</div>
		</>
	);

	const viewChartHeader = (
		<div className="col px-2">
			<Tooltip placement="right" title="Visualizar histórico">
				<div>
					<FontAwesomeIcon
						icon={['far', 'eye']}
						className={styles.header_icon + ' clickable'}
						onClick={onViewToggled}
					/>
				</div>
			</Tooltip>
		</div>
	);

	const editProps = {
		data: { ...data },
		onUpdate: () => props.onUpdate(),
		onCancel: () => setShowEdit(false),
		tab: plantType,
	};

	// console.log({ editProps });

	const removeProps = {
		id: data?.id || null,
		onRemove: () => props.onRemove(),
		onCancel: () => setShowRemove(false),
	};

	const downloadHeader = (
		<Fragment>
			<div className="col px-2">
				<Tooltip
					placement="right"
					title={`Descargar ${cardType === Common.CardType.MODEL ? 'métricas' : 'histórico'}`}
				>
					<div>
						<FontAwesomeIcon
							icon={['fas', 'file-download']}
							className={styles.header_icon + ' clickable'}
							onClick={onDownload}
						/>
					</div>
				</Tooltip>
			</div>
		</Fragment>
	);

	const editModalShouldClose = () => cardType === Common.CardType.AREA;

	const renderEditModal = () => {
		switch (cardType) {
			case Common.CardType.USER:
				return <UserSaveForm {...editProps} />;
			case Common.CardType.GROUP:
				return <GroupSaveForm {...editProps} />;
			case Common.CardType.AREA:
				return <AreaSaveForm {...editProps} />;
			case Common.CardType.TOKEN:
				return <TokenSaveForm {...editProps} />;
			case Common.CardType.GLOSSARY:
				return <GlossarySaveForm {...editProps} />;
			case Common.CardType.DATASET:
				return <DatasetSaveForm {...editProps} />;
			case Common.CardType.ARCHITECTURE:
				return <SaveArchitectureForm {...editProps} />;
			case Common.CardType.PLANT:
				return <PlantSaveForm {...editProps} />;
			case Common.CardType.MARKET:
				return <MarketSaveForm {...editProps} />;
			case Common.CardType.EXCHANGE:
				return <ExchangeSaveForm {...editProps} />;
			case Common.CardType.OPTIMIZER:
				return <OptimizerUpdateForm {...editProps} />;
			case Common.CardType.CONSTANT:
				return <ConstantSaveForm {...editProps} />;

			default:
				return null;
		}
	};

	const renderRemoveModal = () => {
		switch (cardType) {
			case Common.CardType.USER:
				return <UserRemovalForm {...removeProps} validation={data.email} />;
			case Common.CardType.GROUP:
				return <GroupRemovalForm {...removeProps} validation={data.name} />;
			case Common.CardType.AREA:
				return <AreaRemovalForm {...removeProps} validation={data.name} />;
			case Common.CardType.TOKEN:
				return <TokenRemovalForm {...removeProps} />;
			case Common.CardType.GLOSSARY:
				return <GlossaryRemovalForm {...removeProps} />;
			case Common.CardType.DATASET:
				return <DatasetRemovalForm {...removeProps} validation={data.name} />;
			case Common.CardType.MODEL:
				return <ModelRemovalForm {...removeProps} validation={data.name} />;

			default:
				return <RemovalForm {...removeProps} {...removalProps} />;
		}
	};

	return (
		<Fragment>
			<div className="inhreit-w">
				<div className="col inherit-w zero">
					<animated.div
						className={
							styles[isHighlighted ? 'card_header_highlighted' : 'card_header'] + ' inhreit-w'
						}
						style={headerSpring}
					>
						<div className={Common.rowBetweenMiddle() + ' inherit-w zero'}>
							<div className={Common.colJoin(headerDist[0])}>
								<div className="row inherit-w zero">
									{headers.map((header, index) =>
										index === 0 ? (
											<div key={index} className={Common.colJoin(headerSizes[index])}>
												{typeof header === 'string' && header.includes('icon:') ? (
													<Tooltip placement="left" title={header.split(':')[3]}>
														<div>
															<FontAwesomeIcon
																icon={[
																	header.split(':')[1],
																	header.split(':')[2],
																]}
																className={styles.header_icon}
																onClick={editHandler}
															/>
														</div>
													</Tooltip>
												) : (
													<h5 className={styles.header}>{header}</h5>
												)}
											</div>
										) : (
											<div key={index} className={Common.colJoin(headerSizes[index])}>
												<h5 className={styles.header_secondary}>{header}</h5>
											</div>
										)
									)}
								</div>
							</div>
							<div className={Common.colJoin(headerDist[1])}>
								<div className="row reverse inherit-w zero">
									{/* {!canExpand ? <div className="col"></div> : null} */}
									{canDownload && downloadHeader}
									{hasStatistics && statisticsHeader}
									{canShowChart && viewChartHeader}
									{canExpand && expandHeader}
									{canRemove && hasRights && removeHeader}
									{canEdit && hasRights && editHeader}
								</div>
							</div>
						</div>
					</animated.div>
					{transitions.map(
						({ item, props, key }) =>
							item && (
								<animated.div
									style={{
										...props,
										overflow: 'hidden',
										position: 'relative',
									}}
									className={styles.card_content}
									key={key}
								>
									<animated.div
										className={styles.content}
										style={opacitySpring}
										ref={heightRef}
									>
										{children}
										{canRemoveFromBody ? (
											<div className={styles.delete_container}>
												<Button
													color="primary"
													variant="contained"
													size="small"
													disableElevation
													className={styles.delete_btn}
													onClick={() => setShowRemove(true)}
												>
													Eliminar
												</Button>
											</div>
										) : null}
									</animated.div>
								</animated.div>
							)
					)}
					<br />
				</div>
			</div>
			{showEdit && (
				<Modal
					width={
						[Common.CardType.ARCHITECTURE, Common.CardType.MODEL].includes(cardType) ? 700 : 600
					}
					show={showEdit}
					onClose={() => setShowEdit(false)}
					closeDisabled={editModalShouldClose()}
				>
					{renderEditModal()}
				</Modal>
			)}
			{showRemove && (
				<Modal show={showRemove} onClose={() => setShowRemove(false)}>
					{renderRemoveModal()}
				</Modal>
			)}
		</Fragment>
	);
};
