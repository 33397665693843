const ENERGY_TYPE = {
	SOL: 'Solar',
	EOL: 'Eólica ',
	PCH: 'PCH',
};

export const formatEnergyType = plantType => {
	const mapped = ENERGY_TYPE[plantType];
	return mapped || 'N/A';
};
