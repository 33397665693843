import env from '../../../env.json';
import { api } from '../axios';

const controller = env.REACT_APP_API_VERSION + 'glossary';

/**
 * Realiza una petición con el método GET a la API bajo el controlador /glossary, en
 * la ruta /list con el fin de listar los registros del glosario existentes en la base de
 * datos.
 */
export const list = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller}/list`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /glossary,
 * en la ruta /create con el fin de crear un registro en el glosario.
 * @param {Object} body Cuerpo de la petición con el registro del glosario a crear.
 */
export const create = (body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/create`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método PUT a la API bajo el controlador /glossary, en
 * la ruta /:id/update con el fin de actualizar la información de un registro del glosario.
 * @param {number} id id correspondiente al registro del glosario a editar.
 * @param {Object} body Cuerpo de la petición con los cambios del registro del glosario correspondiente.
 */
export const update = (id, body) =>
	new Promise((resolve, reject) => {
		api.put(`${controller}/${id}/update`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador /glossary,
 * en la ruta /delete con el fin de eliminar un registro del glosario de la base de
 * datos de forma permanente.
 * @param {number} id id correspondiente al registro del glosario a eliminar.
 */
export const remove = (id) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller}/${id}/delete`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
