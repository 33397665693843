import React from 'react';

import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, CircularProgress } from '@material-ui/core';

import { DefaultField } from '../default';
import * as Common from '../../../utils/common';
import { UserController } from '../../../utils';

const formInitialValues = {
    email: '',
};

const validationSchema = yup.object({
    email: yup
        .string('El correo electrónico es de tipo texto')
        .email('El campo es de tipo correo electrónico')
        .required('El correo electrónico es requerido'),
});

export const UserRemovalForm = ({ id, validation, onRemove, onCancel }) => {
    /**
     * método de confirmación.
     */
    const canSend = (value) => validation.toLowerCase() === value.toLowerCase();

    /**
     * método para remover el usuario de la base de datos.
     */
    const onFormSubmit = async (setSubmitting, resetForm) => {
        setSubmitting(true);
        const res = await UserController.remove(id);
        console.log(res);
        setSubmitting(false);

        if (res.status < 400) {
            resetForm();
            Common.fireMiniMessage('Usuario Eliminado!');
            onCancel();
            onRemove();
        }
    };

    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(setSubmitting, resetForm)}
        >
            {({ values, isSubmitting }) => (
                <Form className="inherit-w">
                    <h3 className="alert-title">¿Desea eliminar este usuario?</h3>
                    <div className="sm-es"></div>
                    <p>Para eliminar este usuario, por favor ingrese su correo electrónico</p>
                    <br />
                    <DefaultField name="email" label="correo electrónico" size="small" />
                    <div className="md-es"></div>
                    <div className={Common.rowBetweenMiddle()}>
                        <div className={Common.colJoinLg_MdSmXs(4, 5)}>
                            <Button
                                color="default"
                                variant="contained"
                                size="medium"
                                className="ls-custom btn-cancel btn-rmv"
                                onClick={onCancel}
                                disabled={isSubmitting}
                                fullWidth
                                disableElevation
                            >
                                Cancelar
                            </Button>
                        </div>
                        <div className={Common.colJoinLg_MdSmXs(4, 5)}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                size="medium"
                                className="ls-custom fl-right"
                                disabled={isSubmitting || !canSend(values.email)}
                                fullWidth
                                disableElevation
                            >
                                Eliminar &nbsp;
                                {isSubmitting ? <CircularProgress size={20} /> : null}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
