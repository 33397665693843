import React, { Fragment } from 'react';
import { AccordionActions, Button, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from '../../layout';
import { Common, DATA, MarketController, Methods, Schemas } from '../../../utils';
import { useState } from 'react';
import { MarketRangeSaveForm, RemovalForm } from '../../forms';

const RangeConfig = ({ market, onChanged }) => {
	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState(undefined);

	const handleEdit = () => {
		setShowModal(true);
		setModalContent(DATA.MODAL.CONTENT.MARKET.RANGE.UPDATE);
	};

	const handleRemove = () => {
		setShowModal(true);
		setModalContent(DATA.MODAL.CONTENT.MARKET.RANGE.REMOVE);
	};

	const handleClose = () => {
		setShowModal(false);
		setModalContent(undefined);
	};

	const renderRanges = () => {
		return market.rangos.map((range, index) => {
			return (
				<div
					key={`market-range-config-${range.id}`}
					className={Common.rowCenterMiddle() + ' inherit-w zero'}
				>
					<div className={Common.colJoin(4)}>
						<p className="txt-center">{range.rango_inf}</p>
					</div>
					<div className={Common.colJoin(4)}>
						<p className="txt-center">{range.rango_sup}</p>
					</div>
					<div className={Common.colJoin(4)}>
						<p className="txt-center">{range.unid_equiv}</p>
					</div>
				</div>
			);
		});
	};

	const getModalContent = () => {
		switch (modalContent) {
			case DATA.MODAL.CONTENT.MARKET.RANGE.UPDATE:
				return <MarketRangeSaveForm data={market} onUpdate={onChanged} onCancel={handleClose} />;

			case DATA.MODAL.CONTENT.MARKET.RANGE.REMOVE:
				return (
					<RemovalForm
						id={market.id}
						entity={`config. rangos, del mercado ${market.name}`}
						prefix="la"
						validationSchema={Schemas.stringBasic('name', 'El nombre del mercado es requerido')}
						formInitialValues={{ name: '' }}
						formFields={[{ key: 'name', display: 'Nombre del mercado' }]}
						validationMethod={Methods.compare(market.name)}
						removeEndpoint={MarketController.removeRanges}
						removeMessage={`La config. de rangos del mercado (${market.name}) ha sido eliminada!`}
						onRemove={onChanged}
						onCancel={handleClose}
					/>
				);

			default:
				return undefined;
		}
	};

	const renderModal = () => {
		const content = getModalContent();

		return (
			<Modal show={showModal} onClose={handleClose}>
				{content}
			</Modal>
		);
	};

	return (
		<Fragment>
			<StyledAccordion>
				<StyledAccordionSummary
					expandIcon={<FontAwesomeIcon icon={['fas', 'angle-down']} />}
					aria-controls="market-range-config-content"
					id="market-range-config-header"
				>
					<Typography>Rangos</Typography>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					<div className={Common.colJoin(12) + ' zero'}>
						<div
							key={`market-${market.id}-range-config-headers`}
							className={Common.rowMiddle() + ' inherit-w zero'}
						>
							<div className={Common.colJoin(4)}>
								<h4 className="txt-center">L&iacute;mite inferior</h4>
							</div>
							<div className={Common.colJoin(4)}>
								<h4 className="txt-center">L&iacute;mite superior</h4>
							</div>
							<div className={Common.colJoin(4)}>
								<h4 className="txt-center">Unidades equivalentes</h4>
							</div>
						</div>
						{renderRanges()}
					</div>
				</StyledAccordionDetails>
				<hr className="gray" />
				<AccordionActions>
					<Button variant="outlined" size="small" onClick={handleRemove}>
						<FontAwesomeIcon icon={['far', 'trash-alt']} />
						&nbsp; Eliminar
					</Button>
					<Button
						disableElevation
						variant="contained"
						size="small"
						color="secondary"
						onClick={handleEdit}
					>
						<div className={Common.rowCenterMiddle() + ' inherit-w zero'}>
							<FontAwesomeIcon icon={['far', 'edit']} />
							&nbsp; Editar
						</div>
					</Button>
				</AccordionActions>
			</StyledAccordion>

			{showModal && renderModal()}
		</Fragment>
	);
};

export default RangeConfig;
