import { USER_ROLES } from '../../common';

/**
 * Esta clase se encarga de administrar la informacion que se guarda en el
 * almacamiento local (local storage), del explorador web. Esta informacion puede
 * ser accedida desde application -> local storage en cualquier navegador web.
 */
export class LocalStoreService {
    /**
     * Se almacena la key 'electric_demand_access_token' con el valor del token de acceso en el almacenamiento local.
     * @param {string} access_token token de acceso a almacenar.
     */
    setAccessToken(access_token) {
        localStorage.setItem('electric_demand_access_token', access_token);
        this.setIsLoggedIn(true);
    }

    /**
     * Se obtiene la key 'electric_demand_access_token' del almacenamiento local.
     */
    getAccessToken() {
        return localStorage.getItem('electric_demand_access_token');
    }

    /**
     * Se eliminan las keys 'electric_demand_access_token', 'electric_demand_user_rol' y
     * 'electric_demand_auth' toma el valor de falso indicado que el usuario ha cerrado
     * la sesión en la aplicación.
     */
    removeAccessToken() {
        localStorage.removeItem('electric_demand_access_token');
        this.setIsLoggedIn(false);
        this.removeUserRol();
        this.removeUserInfo();
    }

    /**
     * Se almacena en el almacenamiento local la key 'electric_demand_user_rol' con el
     * valor del rol del usuario que haya iniciado sesión en la aplicación.
     * @param {string} rol rol de usuario en la sesión actual.
     */
    setUserRol(rol) {
        localStorage.setItem('electric_demand_user_rol', rol);
    }

    /**
     * Se obtiene la key 'electric_demand_user_rol' del almacenamiento local.
     */
    getUserRol() {
        return localStorage.getItem('electric_demand_user_rol');
    }
    /**
     * Se elimina la key 'electric_demand_user_rol' del almacenamiento local.
     */
    removeUserRol() {
        localStorage.removeItem('electric_demand_user_rol');
    }

    /**
     * Se almacena en el almacenamiento local la key 'electric_demand_auth' un
     * booleano que indica si se encuentra una sesión activa o no dentro de la
     * aplicación.
     * @param {boolean} state estado de la sesión actual.
     */
    setIsLoggedIn(state) {
        localStorage.setItem('electric_demand_auth', state);
    }

    /**
     * Se obtiene la key 'electric_demand_auth' del almacenamiento local.
     */
    getIsLoggedIn() {
        return localStorage.getItem('electric_demand_auth');
    }

    /**
     * Se almacena la key 'electric_demand_user_info' del almacenamiento local.
     */
    setUserInfo(data) {
        console.log(data);
        const toSave = {
            name: [data.first_name, data.middle_name, data.last_name, data.sur_name]
                .filter((el) => el.length > 0)
                .join(' '),
            role: USER_ROLES[data.rol],
            email: data.email,
        };
        localStorage.setItem('electric_demand_user_info', JSON.stringify(toSave));
    }

    /**
     * Se obtiene la key 'electric_demand_user_info' del almacenamiento local.
     */
    getUserInfo() {
        const json = localStorage.getItem('electric_demand_user_info');
        return JSON.parse(json);
    }

    /**
     * Se elimina la key 'electric_demand_user_info' del almacenamiento local.
     */
    removeUserInfo() {
        localStorage.removeItem('electric_demand_user_info');
    }
}
