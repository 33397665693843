import env from '../../../env.json';
import { api } from '../axios';

const controller_v1 = env.REACT_APP_API_VERSION + 'dataset';
const controller = env.REACT_APP_API_VERSION_2 + 'dataset';

/**
 * Realiza una petición con el método GET a la API bajo el controlador /dataset, en
 * la ruta /list con el fin de listar los datasets existentes en la base de datos.
 */
export const list = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller_v1}/list`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método GET a la API bajo el controlador /dataset, en
 * la ruta /list_all con el fin de los nombres de los datasets existentes en la base de
 * datos.
 */
export const list_all = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller_v1}/list_all`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /dataset,
 * en la ruta /create con el fin de crear un dataset.
 * @param {Object} body Cuerpo de la petición con el dataset a crear.
 */
export const create = (formData) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/create`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /dataset,
 * en la ruta /comment con el fin de crear un comentario sobre un dataset.
 * @param {number} id id correspondiente al dataset sobre el que se comentara.
 * @param {Object} body Cuerpo de la petición con el comentario a crear.
 */
export const comment = (id, body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller_v1}/${id}/comment`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método POST a la API bajo el controlador /dataset,
 * en la ruta /history/area con el fin de obtener el histórico de las áreas que se
 * encuentran en el cuerpo de la petición.
 * @param {number} id id correspondiente al dataset sobre del que se obtendra el histórico.
 * @param {Object} body Cuerpo de la petición con las áreas de las cuales se quiere obtener el histórico.
 */
export const getDatasetHistoryByAreas = (id, body) =>
	new Promise((resolve, reject) => {
		api.post(`${controller}/${id}/history/area`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método PUT a la API bajo el controlador /dataset, en
 * la ruta /:id/update con el fin de actualizar la información de un dataset.
 * @param {number} id id correspondiente al dataset a editar.
 * @param {Object} body Cuerpo de la petición con los cambios del dataset correspondiente.
 */
export const update = (id, formData) =>
	new Promise((resolve, reject) => {
		api.put(`${controller_v1}/${id}/update`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador /dataset,
 * en la ruta /delete con el fin de eliminar un dataset de la base de datos de forma
 * permanente.
 * @param {number} id id correspondiente del área a eliminar.
 */
export const remove = (id) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller_v1}/${id}/delete`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
