import { withStyles } from '@material-ui/core';
import { TabPanel } from '@material-ui/lab';

const StyledTabPanel = withStyles({
	root: {
		padding: '0px',
		'padding-top': '10px',
	},
})(TabPanel);

export default StyledTabPanel;
