import env from '../../../env.json';
import { api } from '../axios';

const controller = env.REACT_APP_API_VERSION + 'comment';

/**
 * Realiza una petición con el método PUT a la API bajo el controlador /comment,
 * en la ruta /:id/update con el fin de actualizar la información de un área.
 * @param {number} id id correspondiente al comentario a editar.
 * @param {Object} body Cuerpo de la petición con los cambios del comentario correspondiente.
 */
export const update = (id, body) =>
	new Promise((resolve, reject) => {
		api.put(`${controller}/${id}/update`, body)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});

/**
 * Realiza una petición con el método DELETE a la API bajo el controlador
 * /comment, en la ruta /delete con el fin de eliminar un área de la base de datos
 * de forma permanente.
 * @param {number} id id correspondiente del comentario a eliminar.
 */
export const remove = (id) =>
	new Promise((resolve, reject) => {
		api.delete(`${controller}/${id}/delete`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
