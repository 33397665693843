import React, { Fragment } from 'react';

// COMPONENTS
import ArchitectureView from './ArchitectureView';
import AreaView from './AreaView';
import { Forecast } from './forecast/forecast';
import { History } from './history/history';
import { Report } from './report/report';
import { Dataset } from './dataset/dataset';
import { Model } from './model/model';
import { PowerBIDashboard } from './PowerBIDashboard/PowerBIDashboard';
import { Error404 } from '../../error404/error404';

// REACT ROUTER
import { Route, Redirect, Switch } from 'react-router-dom';
import { ROUTES } from '../../../../utils/routes';
import { ProtectedRolRoute } from '../../../routing';

import { Common } from '../../../../utils';

/**
 * Enrutador que se encarga de hacer el renderizado segun la ruta de navegación
 * actual. Adicionalmente para cada ruta se renderizara la barra de navegación de
 * la applicación. En caso de que el enrutador no logre discernir que vista se ha
 * de renderizar le mostrara al usuario la pantalla de 404.
 */
const DemandRouter = () => {
	return (
		<Fragment>
			<Switch>
				<Route
					exact
					path={ROUTES.CLIENT.DEMAND.BASE}
					render={() => <Redirect to={ROUTES.CLIENT.DEMAND.FORECAST} />}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.DEMAND.FORECAST}
					component={Forecast}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.DEMAND.HISTORY}
					component={History}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.DEMAND.REPORT}
					component={Report}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.DEMAND.AREAS}
					component={AreaView}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.DEMAND.DATASETS}
					component={Dataset}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.DEMAND.ARCHITECTURE}
					component={ArchitectureView}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.DEMAND.MODELS}
					component={Model}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute
					exact
					path={ROUTES.CLIENT.DEMAND.DASHBOARD}
					component={PowerBIDashboard}
					roles={Common.getDefaultRights()}
				/>
				<ProtectedRolRoute path="/" component={Error404} roles={Common.getDefaultRights()} />
			</Switch>
		</Fragment>
	);
};

export default DemandRouter;
