import React, { useEffect } from 'react';
import { AreaController, Common } from '../../utils';
import { RequestMeta } from '../layout';
import { useListRequest } from '../hooks';
import AreaList from './AreaList';

const AreaTab = ({ refresh, fetchCallback }) => {
	const { items, loading, error, fetch } = useListRequest(AreaController, fetchCallback);
	useEffect(() => {
		if (refresh) {
			fetch();
		}
	}, [refresh, fetch]);

	return (
		<RequestMeta error={error} loading={loading} items={items}>
			<div className={Common.rowBetween() + ' inherit-w'}>
				<div className={Common.colJoin(12) + ' fixed-scroll-md'}>
					<AreaList items={items} onItemUpdate={fetch} />
				</div>
			</div>
		</RequestMeta>
	);
};

export default AreaTab;
