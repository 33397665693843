import React, { Fragment, useEffect, useState } from 'react';

import {
	CenteredContainer,
	Card,
	Modal,
	Spinner,
	NoConnection,
	NoResults,
	AddButton,
} from '../../../../layout';
import { GroupSaveForm } from '../../../../forms';

import { Common, GroupController } from '../../../../../utils';

/**
 * Componente que se encarga de renderizar el modulo de grupos.
 */
export const Group = () => {
	const [groupCreation, setGroupCreation] = useState(false);
	const [groups, setGroups] = useState([]);
	const [loading, setLoading] = useState(true);
	const [noConnection, setNoConnection] = useState(false);

	/**
	 * Método que se encarga de consultar la lista de grupos.
	 */
	const fetch = async () => {
		try {
			setLoading(true);
			setNoConnection(false);
			const res = await GroupController.list();
			setLoading(false);
			if (res.status === 200) {
				// console.log(res.data);
				setGroups(res.data);
			}
		} catch (error) {
			setNoConnection(true);
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	return (
		<Fragment>
			<CenteredContainer colSize={10}>
				<div className="row inherit-w zero">
					<h2 className="tab-title">Grupos</h2>
				</div>
				<hr />
				<br />
				<div className="inherit-w">
					{noConnection && !loading ? <NoConnection /> : null}
					{loading ? <Spinner /> : null}

					{!loading && !noConnection && groups.length === 0 ? (
						<NoResults />
					) : (
						groups.map((group) => {
							const headers = [group.name, Common.formatDate(group.created_at)];
							const headerSizes = [4, 4, 3, 1];
							return (
								<Card
									key={group.id}
									headers={headers}
									headerSizes={headerSizes}
									cardType={Common.CardType.GROUP}
									data={group}
									canEdit
									canRemove
									onUpdate={() => fetch()}
									onRemove={() => fetch()}
								/>
							);
						})
					)}
				</div>
				<br />
				<AddButton onClick={() => setGroupCreation(true)} />
			</CenteredContainer>
			{groupCreation ? (
				<Modal show={groupCreation} onClose={() => setGroupCreation(false)}>
					<GroupSaveForm
						onCancel={() => setGroupCreation(false)}
						onUpdate={() => fetch()}
						isCreation
					/>
				</Modal>
			) : null}
		</Fragment>
	);
};
