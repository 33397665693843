import React, { useRef, useState } from 'react';

import styles from './dataset-save.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Common, DatasetController } from '../../../../utils';

import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { DefaultField } from '../../default';
import { Button, Chip, CircularProgress } from '@material-ui/core';

export const DatasetSaveForm = ({ data: record, onUpdate, onCancel, isCreation = false }) => {
    const [files, setFiles] = useState([]);
    const fileUploadRef = useRef(null);

    const formInitialValues = {
        name: record ? record.name : '',
        description: record ? record.description : '',
        dataset: [],
    };

    const validationSchema = yup.object({
        name: yup.string('El nombre es de tipo texto').required('El nombre es requerido'),
        description: yup.string('La descripción es de tipo texto'),
        dataset: yup
            .mixed()
            .test('fileRequired', 'El dataset es requerido', (value) => {
                if (isCreation) {
                    return value.length > 0;
                } else {
                    return true;
                }
            })
            .test('fileQuantity', 'El dataset solo puede contener hasta un archivo', (value) => {
                if (isCreation) {
                    return value.length === 1;
                } else {
                    if (value.length === 0) return true;
                    return value.length === 1;
                }
            })
            .test('fileType', 'El dataset solo puede contener archivos .csv', (value) => {
                if (isCreation) {
                    return value.length > 0 ? value[0].name.slice(-3) === 'csv' : false;
                } else {
                    if (value.length === 0) return true;
                    return value[0].name.slice(-3) === 'csv';
                }
            }),
    });

    /**
     * método para crear o edirar un dataset.
     */
    const onFormSubmit = async (data, setSubmitting, resetForm) => {
        setSubmitting(true);
        const body = new FormData();
        body.append('name', data.name);
        body.append('description', data.description);
        if (data.dataset.length > 0) body.append('dataset', data.dataset[0]);

        try {
            const res = isCreation
                ? await DatasetController.create(body)
                : await DatasetController.update(record.id, body);
            const msj = isCreation ? `Dataset ${data.name} registrado!` : `Dataset ${data.name} actualizado!`;

            setSubmitting(false);
            resetForm();
            setFiles([]);

            if (res.status < 400) {
                resetForm();
                Common.fireMiniMessage(msj);

                onCancel();
                onUpdate();
            }
        } catch (error) {
            setSubmitting(false);
        }
    };

    /**
     * metodo para actualizar el archivo.
     */
    const handleFileChange = (setFieldValue, files) => {
        setFieldValue('dataset', files);

        const _files = Array.from(files).map((file, index) => {
            return { file: file, index: index };
        });
        setFiles(_files);
    };

    /**
     * metodo para cargar el archivo.
     */
    const handleFileUpload = (setFieldValue, event) => {
        const { target } = event;
        if (target.files) {
            handleFileChange(setFieldValue, target.files);
        }
    };

    /**
     * metodo para cargar el archivo.
     */
    const handleRemoveFile = (setFieldValue, setTouched, index) => {
        const filtered = files.filter((f) => f.index !== index);
        const fileList = filtered.map((item) => item.file);
        setFiles(filtered);
        setFieldValue('dataset', fileList);
    };

    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
        >
            {({ isSubmitting, setFieldValue, errors, touched, setTouched }) => (
                <Form className="inherit-w">
                    <DefaultField name="name" label="Nombre" size="small" />
                    <div className="sm-es"></div>

                    <DefaultField name="description" label="Descripción" size="small" multiline rows={4} />

                    <input
                        ref={fileUploadRef}
                        style={{ visibility: 'hidden' }}
                        type="file"
                        onChange={(e) => handleFileUpload(setFieldValue, e)}
                    />

                    <div
                        className={errors.dataset && touched.dataset ? styles.file_upload_error : styles.file_upload}
                        onClick={() => {
                            setTouched('dataset', true);
                            fileUploadRef.current.click();
                        }}
                        onDragOver={(e) => {
                            e.preventDefault();
                        }}
                        onDrop={(e) => {
                            e.preventDefault();
                            handleFileChange(setFieldValue, e.dataTransfer.files);
                        }}
                    >
                        <FontAwesomeIcon icon={['fas', 'file-import']} className={styles.file_upload_icon} />
                        <br />
                        <p style={{ padding: 5 }}>
                            {errors.dataset && touched.dataset
                                ? errors.dataset
                                : 'Escoge un archivo de extensión .csv y suéltalo aquí'}
                        </p>
                    </div>

                    {files.length > 0 ? <br /> : null}
                    {files.map((item) => (
                        <Chip
                            key={item.index}
                            label={item.file.name}
                            variant="outlined"
                            color="primary"
                            style={{ margin: 5 }}
                            onDelete={() => handleRemoveFile(setFieldValue, item.index)}
                        />
                    ))}

                    <div className="md-es"></div>
                    <div className={Common.rowBetweenMiddle()}>
                        <div className={Common.colJoin(3)} />
                        <div className={Common.colJoinLg_MdSmXs(4, 5)}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                size="medium"
                                className="ls-custom fl-right"
                                disabled={isSubmitting}
                                fullWidth
                                disableElevation
                            >
                                {isCreation ? 'Guardar' : 'Editar'} &nbsp;
                                {isSubmitting ? <CircularProgress size={20} /> : null}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
