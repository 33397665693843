import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './nav-link.module.scss';
import { useSpring, animated } from 'react-spring';

import { useLocation, Link } from 'react-router-dom';
import { LocalStoreService } from '../../../utils';

const localStorage = new LocalStoreService();

/**
 * Componente que se encarga de renderizar los enlaces dentro de la barra de navegación.
 * @param {object} props0 props del componente.
 */
export const NavLink = ({
    to,
    className,
    activeClassName,
    inactiveClassName,
    isDropdown,
    children,
    sublinks,
    ...props
}) => {
    let location = useLocation();
    let isActive;
    if (typeof to === 'object') {
        isActive = to.find((t) => location.pathname === t) !== undefined;
    } else {
        isActive = location.pathname === to;
    }

    let classes = className + (isActive ? ` ${activeClassName}` : ` ${inactiveClassName}`);

    const [showDropdown, setShowDropdown] = useState(false);

    const propsWithChildren = { ...props, children };

    const spring = useSpring({
        from: { opacity: 0, top: 60 },
        opacity: showDropdown ? 1 : 0,
        top: showDropdown ? 50 : 80,
    });

    return isDropdown ? (
        <div
            className={classes + ' ' + styles.absolute_parent + ' clickable'}
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
        >
            <span>{children}</span> &nbsp;
            {showDropdown ? (
                <FontAwesomeIcon icon={['fas', 'caret-up']} />
            ) : (
                <FontAwesomeIcon icon={['fas', 'caret-down']} />
            )}
            <animated.div className={styles.dropdown} style={spring}>
                {sublinks ? (
                    <ul className={styles.dropdown_list}>
                        {sublinks.map((sl, idx) => {
                            if (sl.rights.includes(localStorage.getUserRol())) {
                                return (
                                    <Link key={idx} className={styles.sub_link} to={sl.route}>
                                        {sl.display}
                                    </Link>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </ul>
                ) : (
                    JSON.stringify(sublinks)
                )}
            </animated.div>
        </div>
    ) : (
        <Link className={classes} to={to} {...propsWithChildren} />
    );
};
