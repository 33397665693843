import env from '../../../env.json';
import { api } from '../axios';

const controller = env.REACT_APP_API_VERSION + 'graph';

/**
 * Realiza una petición con el método GET a la API bajo el controlador /graph, en la
 * ruta /intensity con el fin de crear obtener un listado aleatorio de intensidades.
 *
 * @deprecated Este método fue desarrollado con motivos de demostración.
 */
export const getGEOJson = () =>
	new Promise((resolve, reject) => {
		api.get(`${controller}/intensity`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
