import React, { Fragment, useEffect, useState } from 'react';

import {
	AddButton,
	Card,
	CenteredContainer,
	Modal,
	NoConnection,
	NoResults,
	Spinner,
} from '../../../../layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Common, ModelController } from '../../../../../utils';
import { IconButton, Tooltip } from '@material-ui/core';
import SocketContext from '../../../../../utils/contexts/socket.context';
import { ModelSaveForm } from '../../../../forms';

/**
 * Vista que se encarga de renderizar todo lo correspondiente al módulo de
 * modelos.
 * @param {Object} param0 acutalmente el socket que se recibe siempre sera nulo.
 */
const ModelWithSocket = ({ socket }) => {
	const [modelCreation, setModelCreation] = useState(false);
	const [models, setModels] = useState([]);
	const [loading, setLoading] = useState(true);
	const [updating, setUpdating] = useState(false);
	const [noConnection, setNoConnection] = useState(false);

	/**
	 * Se solicitan la lista de modelos y se actualiza la interfaz grafica.
	 */
	const fetch = async () => {
		try {
			setLoading(true);
			setNoConnection(false);
			const res = await ModelController.list();
			if (res.status < 400) {
				setModels(res.data);
			}
		} catch (error) {
			setNoConnection(true);
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	/**
	 * Este metodo se uso para probar los sockets en entorno de pruebas
	 */
	useEffect(() => {
		if (socket) {
			socket.onopen = (event) => socket.send(JSON.stringify({ command: 'ping' }));
			socket.onmessage = (event) => {};
		}
	}, [socket]);

	/**
	 * Este metodo actualiza cual es modelo que se usara por defecto para las predicciones.
	 * @param {number} id id del modelo
	 * @param {string} name nombre del modelo
	 */
	const setModelAsDefult = async (id, name) => {
		setUpdating(true);
		const res = await ModelController.setAsDefault(id);
		setUpdating(false);

		if (res.status < 400) {
			Common.fireMiniMessage(`El modelo '${name}' ha sido seleccionado como modelo por defecto`);
			fetch();
		}
	};

	const handleDownload = async (item) => {
		const response = await ModelController.download_metrics(item.id);
		if (response.status < 400) {
			const fileName = `${item.name}_metrics.csv`;
			const href = await URL.createObjectURL(response.data);
			const link = document.createElement('a');
			link.href = href;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	return (
		<Fragment>
			<CenteredContainer colSize={10}>
				<div className="row inherit-w zero">
					<h2 className="tab-title">Modelos</h2>
				</div>
				<hr />
				<br />
				<div className="inherit-w">
					{noConnection && !loading ? <NoConnection /> : null}
					{loading ? <Spinner /> : null}

					{!loading && !noConnection && models.length === 0 ? (
						<NoResults />
					) : (
						models.map((model) => {
							const date = new Date(model.created_at);
							// const headers = [model.name, model.user.email, date.toLocaleString()];
							let icon = '';
							if (model.state === 'IN') icon = 'icon:fas:play-circle:Sin entrenar';
							if (model.state === 'TR') icon = 'icon:fas:brain:Entrenando';
							if (model.state === 'CP') icon = 'icon:fas:check-circle:Entrenamiento completado';
							const headers = [icon, model.name, date.toLocaleString()];
							const headerSizes = [1, 5, 5, 1];
							return (
								<Card
									key={model.id}
									headers={headers}
									headerSizes={headerSizes}
									data={model}
									cardType={Common.CardType.MODEL}
									onRemove={() => fetch()}
									onUpdate={() => fetch()}
									canExpand
									canDelete
									canDownload={model.params[0].metrics[0].metrics !== null}
									onDownload={() => handleDownload(model)}
								>
									{model.default ? (
										<p>
											Este modelo se usara por defecto para todos los pronosticos
											correspondientes a la demanda de energía.
										</p>
									) : (
										<div className={Common.rowBetween()}>
											<div></div>

											{model.state === 'CP' ? (
												<Tooltip
													title="Seleccionar como modelo por defecto"
													placement="left"
												>
													<IconButton
														className="ls-custom fl-right"
														disabled={updating}
														onClick={() => setModelAsDefult(model.id, model.name)}
													>
														<FontAwesomeIcon
															className="primary"
															icon={['fas', 'award']}
														/>
													</IconButton>
												</Tooltip>
											) : null}
										</div>
									)}
									{/* <p>Dataset: {model.dataset.name + ' - v' + model.dataset.version}</p> */}
								</Card>
							);
						})
					)}
				</div>
				<br />

				{Common.isInManager() && <AddButton onClick={() => setModelCreation(true)} />}
			</CenteredContainer>

			{modelCreation ? (
				<Modal width={700} show={modelCreation} onClose={() => setModelCreation(false)}>
					<ModelSaveForm
						socket={socket}
						close={() => {
							setModelCreation(false);
							fetch();
						}}
						isCreation
					/>
				</Modal>
			) : null}
		</Fragment>
	);
};

/**
 * Proveedor de contexto de sockets.
 * @deprecated no se usa debido a que los sockets se crean en la instacia donde son invocados debido a como es su ciclo de vida.
 */
export const Model = (props) => (
	<SocketContext.Consumer>
		{(socket) => <ModelWithSocket {...props} socket={socket} />}
	</SocketContext.Consumer>
);
