import React, { Fragment } from 'react';
import { AccordionActions, Button, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from '../../layout';
import { Common, DATA, Methods, Schemas, Transformations } from '../../../utils';
import { useState } from 'react';
import { EquivalentUnitsConfigForm, RemovalForm } from '../../forms';

const ExchangesConfig = ({ plant, plantType, onChanged }) => {
	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState(undefined);

	const handleEdit = () => {
		setShowModal(true);
		setModalContent(DATA.MODAL.CONTENT.PLANT.EXCHANGE.UPDATE);
	};

	const handleRemove = () => {
		setShowModal(true);
		setModalContent(DATA.MODAL.CONTENT.PLANT.EXCHANGE.REMOVE);
	};

	const handleClose = () => {
		setShowModal(false);
		setModalContent(undefined);
	};

	const renderExchanges = () => {
		return Transformations.normalizeExchanges(plantType, plant).map((exchange) => {
			return (
				<div
					key={`plant-exchange-config-${exchange.id}`}
					className={Common.rowCenterMiddle() + ' inherit-w zero'}
				>
					<div className={Common.colJoin(6)}>
						<p className="txt-center">{exchange.mercado.name}</p>
					</div>
					<div className={Common.colJoin(6)}>
						<p className="txt-center">{exchange.unidades_equiv}</p>
					</div>
				</div>
			);
		});
	};

	const getModalContent = () => {
		const controller = Transformations.plantTypeToController(plantType);
		switch (modalContent) {
			case DATA.MODAL.CONTENT.PLANT.EXCHANGE.UPDATE:
				return (
					<EquivalentUnitsConfigForm
						data={plant}
						plant={plant}
						plantType={plantType}
						onUpdate={onChanged}
						onCancel={handleClose}
					/>
				);

			case DATA.MODAL.CONTENT.PLANT.EXCHANGE.REMOVE:
				return (
					<RemovalForm
						id={plant.id}
						entity={`config. unidades equivalentes, de la planta ${plant.name}`}
						prefix="la"
						validationSchema={Schemas.stringBasic('name', 'El nombre de la planta es requerido')}
						formInitialValues={{ name: '' }}
						formFields={[{ key: 'name', display: 'Nombre de la planta' }]}
						validationMethod={Methods.compare(plant.name)}
						removeEndpoint={async () => {
							return await controller.saveEquivalentUnits(plant.id, []);
						}}
						removeMessage={`La config. de unidades equivalentes de la planta (${plant.name}) ha sido eliminada!`}
						onRemove={onChanged}
						onCancel={handleClose}
					/>
				);

			default:
				return undefined;
		}
	};

	const renderModal = () => {
		const content = getModalContent();

		return (
			<Modal show={showModal} onClose={handleClose}>
				{content}
			</Modal>
		);
	};

	return (
		<Fragment>
			<StyledAccordion>
				<StyledAccordionSummary
					expandIcon={<FontAwesomeIcon icon={['fas', 'angle-down']} />}
					aria-controls="plant-exchange-config-content"
					id="plant-exchange-config-header"
				>
					<Typography>Unidades equivalentes</Typography>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					<div className={Common.colJoin(12) + ' zero'}>
						<div
							key={`plant-${plant.id}-ex-config-headers`}
							className={Common.rowMiddle() + ' inherit-w zero'}
						>
							<div className={Common.colJoin(6)}>
								<h4 className="txt-center">Mercado</h4>
							</div>
							<div className={Common.colJoin(6)}>
								<h4 className="txt-center">Unidades equivalentes</h4>
							</div>
						</div>
						{renderExchanges()}
					</div>
				</StyledAccordionDetails>
				<hr className="gray" />
				<AccordionActions>
					<Button variant="outlined" size="small" onClick={handleRemove}>
						<FontAwesomeIcon icon={['far', 'trash-alt']} />
						&nbsp; Eliminar
					</Button>
					<Button
						disableElevation
						variant="contained"
						size="small"
						color="secondary"
						onClick={handleEdit}
					>
						<div className={Common.rowCenterMiddle() + ' inherit-w zero'}>
							<FontAwesomeIcon icon={['far', 'edit']} />
							&nbsp; Editar
						</div>
					</Button>
				</AccordionActions>
			</StyledAccordion>

			{showModal && renderModal()}
		</Fragment>
	);
};

export default ExchangesConfig;
