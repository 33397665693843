import React from 'react';

import { Common, TokenController } from '../../../utils';

import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { DefaultField } from '../default';
import { Button, CircularProgress } from '@material-ui/core';

const validationSchema = yup.object({
    third_party: yup.string('El nombre es de tipo texto').required('El nombre es requerido'),
    description: yup.string('La descripción es de tipo texto'),
});

export const TokenSaveForm = ({ data: record, onUpdate, onCancel, isCreation = false }) => {
    const formInitialValues = {
        third_party: record ? record.third_party : '',
        description: record ? record.description : '',
    };

    /**
     * método para crear o actualizar un token para terceros.
     */
    const onFormSubmit = async (data, setSubmitting, resetForm) => {
        try {
            setSubmitting(true);

            const body = { ...data };

            const res = isCreation ? await TokenController.create(body) : await TokenController.update(record.id, body);
            const msj = isCreation ? 'Token registrado!' : 'Token actualizado!';

            setSubmitting(false);

            if (res.status < 400) {
                resetForm();
                Common.fireMiniMessage(msj);

                onCancel();
                onUpdate();
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
        >
            {({ isSubmitting }) => (
                <Form className="inherit-w">
                    <DefaultField name="third_party" label="Usuario" size="small" />
                    <div className="sm-es"></div>

                    <DefaultField name="description" label="Descripción" size="small" multiline rows={4} />
                    <div className="sm-es"></div>

                    <div className="md-es"></div>
                    <div className={Common.rowBetweenMiddle()}>
                        <div className={Common.colJoin(3)} />
                        <div className={Common.colJoinLg_MdSmXs(4, 5)}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                size="medium"
                                className="ls-custom fl-right"
                                disabled={isSubmitting}
                                fullWidth
                                disableElevation
                            >
                                {isCreation ? 'Agregar' : 'Editar'} &nbsp;
                                {isSubmitting ? <CircularProgress size={20} /> : null}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
