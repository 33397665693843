import React from 'react';
import { ArchController, Common, DateHelper, Methods, Schemas } from '../../utils';
import { Card, Meta } from '../layout';

const ArchitectureList = ({ items, onItemUpdate }) => {
	return items
		.filter(({ created_at }) => {
			const itemDate = DateHelper.strToDate(created_at);
			return itemDate.getTime() > DateHelper.strToDate('2022-07-07 00:00:01');
		})
		.map((item) => {
			const upperName = item.name.split('_').join(' ').toUpperCase();
			const headers = [upperName];
			const headerSizes = [10, 2];

			return (
				<Card
					key={`arch_${item.id}`}
					headers={headers}
					headerSizes={headerSizes}
					cardType={Common.CardType.ARCHITECTURE}
					data={item}
					canEdit
					canRemove
					canExpand
					removalProps={{
						entity: 'arquitectura',
						prefix: 'esta',
						id: item.id,
						validationSchema: Schemas.stringBasic(
							'name',
							'El nombre de la arquitectura es requerido'
						),
						formInitialValues: { name: '' },
						formFields: [{ key: 'name', display: 'Nombre' }],
						validationMethod: Methods.compare(item.name),
						removeEndpoint: ArchController.remove,
						removeMessage: `La arquitectura (${item.name}) ha sido eliminada!`,
					}}
					onRemove={onItemUpdate}
					onUpdate={onItemUpdate}
				>
					<div className={Common.colJoinTop(12) + ' zero'}>
						<div className={Common.rowMiddle()}>{item.description}</div>
						<div className={Common.rowMiddle()}>
							<Meta
								noPadding
								key={`arch_${item.id}.created_at`}
								title="Fecha de creación"
								content={DateHelper.formatStrDate(item.created_at, 'yyyy-MM-dd HH:mm')}
							/>
						</div>
					</div>
				</Card>
			);
		});
};

export default ArchitectureList;
