import React from 'react';
import classes from './line-chart.module.scss';

import { Chip, Slider } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinechartSliderLabel from './LinechartSliderLabel';

const LinechartControls = ({
	chartName,
	lines,
	hidden,
	colors,
	sliderRange,
	sliderValue,
	onToggle,
	onSliderChange,
}) => {
	const valueAsTime = sliderValue.map((date) => new Date(date).getTime());
	const rangeAsTime = sliderRange.map((date) => new Date(date).getTime());

	const renderLineChips = () => {
		return lines.map((line, index) => {
			let chipStyle = {
				margin: '5px',
				color: '#FFFFFF',
			};

			const isHidden = hidden.includes(line);

			if (isHidden) {
				chipStyle['border'] = `1px solid ${colors[index]}`;
				chipStyle['backgroundColor'] = 'transparent';
				chipStyle['color'] = colors[index];
			} else {
				chipStyle['backgroundColor'] = colors[index];
			}

			let iconStyle = {
				paddingRight: '5px',
				color: isHidden ? colors[index] : '#FFFFFF',
			};

			const iconText = isHidden ? 'eye-slash' : 'eye';
			return (
				<Chip
					key={line}
					label={line}
					variant={'outlined'}
					style={chipStyle}
					deleteIcon={<FontAwesomeIcon icon={['far', iconText]} style={iconStyle} />}
					onDelete={() => onToggle(line, isHidden)}
					onClick={() => onToggle(line, isHidden)}
				/>
			);
		});
	};

	return (
		<>
			<p className={classes.text}>{chartName} - Variables: </p>
			{renderLineChips()}
			<div className="sm-es"></div>
			<p className={classes.text}>Filtrar por fecha: </p>
			<Slider
				value={valueAsTime}
				onChange={onSliderChange}
				min={rangeAsTime[0]}
				max={rangeAsTime[1]}
				step={3600 * 1000}
				ValueLabelComponent={LinechartSliderLabel}
			/>
		</>
	);
};

export default React.memo(LinechartControls);
