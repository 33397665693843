import { AccordionSummary, withStyles } from '@material-ui/core';

const StyledAccordionSummary = withStyles({
	root: {
		backgroundColor: 'rgba(0, 0, 0, .03)',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {},
	expanded: {},
})(AccordionSummary);

export default StyledAccordionSummary;
