import React, { useEffect, useState } from 'react';

import { Common, SubareaController } from '../../utils';

import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { DefaultCheckbox, DefaultField } from '../forms/default';
import { Button, CircularProgress } from '@material-ui/core';

const validationSchema = yup.object({
	name: yup.string('El nombre es de tipo texto').required('El nombre es requerido'),
});

const SaveSubareaForm = ({ data: record, onUpdate, onCancel, isCreation = false }) => {
	const [initialValues, setInitialValues] = useState(null);

	useEffect(() => {
		setInitialValues(record);
	}, [record]);

	const formInitialValues = {
		name: initialValues ? initialValues.name : '',
		is_great_consumer: initialValues ? initialValues.is_great_consumer : false,
	};

	/**
	 * metodo crear o editar una entrada de glosario.
	 */
	const onFormSubmit = async (data, setSubmitting, resetForm) => {
		try {
			setSubmitting(true);

			const body = { ...data };

			const res = isCreation
				? await SubareaController.create(body)
				: await SubareaController.update(record.id, body);
			const msj = isCreation ? 'Subárea registrada!' : 'Subárea actualizada!';

			setSubmitting(false);

			if (res.status < 400) {
				resetForm();
				Common.fireMiniMessage(msj);

				onCancel();
				onUpdate();
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={formInitialValues}
			validationSchema={validationSchema}
			onSubmit={(data, { setSubmitting, resetForm }) => onFormSubmit(data, setSubmitting, resetForm)}
		>
			{({ isSubmitting }) => (
				<Form className="inherit-w">
					<div className={Common.rowBetween()}>
						<div className={Common.colJoin(5)}>
							<Field
								name="is_great_consumer"
								type="checkbox"
								as={DefaultCheckbox}
								hasLabel={true}
								label="¿Esta subárea es un gran consumidor?"
								labelPlacement="start"
								tooltipConfig={{
									title: '¿Esta subárea es un gran consumidor?',
									placement: 'bottom',
								}}
							/>
						</div>
						<div className={Common.colJoin(3)}>
							<DefaultField name="name" label="Subárea" size="small" />
						</div>

						<div className={Common.colJoin(4)}>
							<div className={Common.rowBetweenMiddle()}>
								<div className={Common.colJoin(6)}>
									{!isCreation && (
										<Button
											color="primary"
											variant="contained"
											size="large"
											className="ls-custom btn-cancel btn-rmv"
											disabled={isSubmitting}
											fullWidth
											disableElevation
											onClick={() => onCancel()}
										>
											Cancelar
										</Button>
									)}
								</div>
								<div className={Common.colJoin(6)}>
									<Button
										type="submit"
										color="primary"
										variant="contained"
										size="large"
										className="ls-custom fl-right"
										disabled={isSubmitting}
										fullWidth
										disableElevation
									>
										{isCreation ? 'Agregar' : 'Editar'} &nbsp;
										{isSubmitting ? <CircularProgress size={20} /> : null}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default SaveSubareaForm;
