import { useEffect, useState } from 'react';
import { MarketController } from '../../utils';

/**
 * Método para obtener el listado de áreas.
 */
export const useFetchMarkets = () => {
	const [markets, setMarkets] = useState([]);

	useEffect(() => {
		const fetch = async () => {
			try {
				const res = await MarketController.list();

				if (res.status === 200) {
					const _markets = res.data.map((m) => {
						return { id: m.id, value: m.name };
					});
					setMarkets(_markets);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetch();
	}, []);

	return markets;
};
