import * as yup from 'yup';

/**
 *
 * @param {string} name the field name
 * @param {string} message the error message for the required validation.
 * @returns a yup validation schema.
 */
const stringBasic = (name, message) => {
	const stringSchema = yup.object({
		[name]: yup.string().required(message),
	});

	return stringSchema;
};

export default stringBasic;
