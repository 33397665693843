import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { LocalStoreService, ROUTES } from '../../../utils';

const localStorage = new LocalStoreService();

/**
 * Este metodo valida si una ruta es valida dado sus roles.
 * @param {Object} param0 props del componente.
 */
export const ProtectedRolRoute = ({ roles, is404 = false, ...props }) => {
	const isLoggedIn = /true/i.test(localStorage.getIsLoggedIn());
	if (isLoggedIn) {
		const userCanSeeModule = roles.includes(localStorage.getUserRol());
		if (userCanSeeModule) {
			return <Route {...props} />;
		} else {
			return <Redirect to={ROUTES.CLIENT.HOME} />;
		}
	} else {
		return <Redirect to={ROUTES.AUTH.LOGIN} />;
	}
};
