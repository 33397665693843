const { createMuiTheme } = require('@material-ui/core');

const turkishBlue = { main: '#1c3545', contrastText: '#fff' };

const colors = {
	turkishBlue,
	primary: { main: '#A5DA00', contrastText: '#fff' },
	secondary: { main: '#173DB8', contrastText: '#fff' },
	warning: { main: '#F0A71F', contrastText: '#fff' },
	danger: { main: '#dd2c00', contrastText: '#fff' },
	neutral: { main: '#747474', contrastText: '#fff' },
};

/**
 * Tema de estilos usados por la libreria de material-ui para definir el estilo de sus
 * componentes.
 */
const theme = createMuiTheme({ palette: { ...colors } });

export default theme;
export { colors };
