const TYPES = [
	{ id: 'SOL', value: 'Solar' },
	{ id: 'EOL', value: 'Eólica' },
	{ id: 'PCH', value: 'PCH' },
];

const IDS = {
	SOLAR: 'SOL',
	EOLIC: 'EOL',
	PCH: 'PCH',
};

export { TYPES, IDS };
