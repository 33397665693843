/** Function that count occurrences of a substring in a string;
 * @param {String} string               The string
 * @param {String} subString            The sub string to search for
 * @param {Boolean} [allowOverlapping]  Optional. (Default:false)
 *
 * @author Vitim.us https://gist.github.com/victornpb/7736865/edit
 * @see Unit Test https://jsfiddle.net/Victornpb/5axuh96u/
 * @see http://stackoverflow.com/questions/4009756/how-to-count-string-occurrence-in-string/7924240#7924240
 */
function occurrences(string, subString, allowOverlapping = false) {
	if (!string || !subString) {
		throw new Error('Invalid params! string and subString params must be supplied');
	}

	if (typeof string !== 'string' || typeof subString !== 'string') {
		throw new Error('Invalid params! string and subString params must be supplied as strings');
	}

	// This case is just to match something like "abcd".match(new RegExp('', 'g')),
	// which will return ["", "", "", "", ""] (5).
	// Thus, we will avoid any kind of infinite loops.
	if (subString.length <= 0) {
		return string.length + 1;
	}

	let n = 0;
	let pos = 0;
	const step = allowOverlapping ? 1 : subString.length;

	while (true) {
		pos = string.indexOf(subString, pos);

		if (pos >= 0) {
			++n;
			pos += step;
		} else {
			break;
		}
	}

	return n;
}

export default occurrences;
